import React, { useState } from 'react';
import { useStore, useDispatch } from 'react-redux';
import { Redirect } from '@reach/router';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { MatchesUI } from '../ui/MatchesUI';
import { Waiting } from './StaticPages';
import { PopupNote } from '../ui/PopupNote';

import { wait_for_data, set_wait_for_data } from '../Main';
import { signed_in } from '../../App';
import { blacklist, poke, goto_profile } from './utils';
import { config$matches_on_page, config$recalc_interval }
  from '../../config';
import { recalc_matches } from '../../matching';
import { refresh } from '../../utils';
import { optional_fields, optional_field_values, fbDateTime }
  from '../../dbstructure';

import { JOKE_CAT } from '../../enums';
import { profile_update_action, ui_update_action } from '../../store';

const Matches = () => {

  const [ dis, str ] = [useDispatch(), useStore()];
  const [ ready, setReady ] = React.useState(false);
  const [ dberr, setDberr ] = React.useState('');

  // only overlay is user-report dialog
  const [ showOverlay, setShowOverlay ] = useState(null);

  // wait for user's data to load (load is initiated in Signin), and
  // also possibly wait for matches to be recalc'd
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('matches')) {
        wait_for_data.then(() => setReady(true))
                     .catch(() => {
                        setDberr('database error - try reloading page');
                        setReady(true);
                      });
      }
    };
    f();
  }, [ ready ]);

  // match order is in store, but use local state to ensure re-render
  const [order, setOrder] = useState(str.getState().ui.match_order);
  function toggleOrder () {
    const neworder = order === 'humor' ? 'humor+': 'humor';
    setOrder(neworder);
    dis(ui_update_action({ match_order: neworder }));
  }

  if (! ready) {
    return <Waiting />;
  }

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  // affinity is a triple [humor, n, humor+]
  const comp_profs = (p1, p2) => {
    const o = order === 'humor' ? 0 : 2;
    return p2.affinity[o] - p1.affinity[o];
  }

  const store = str.getState();
  const others = store.others;
  const profile = store.profile;
  const matches = Object.values(others.matches)
                        .sort(comp_profs);

  // decide whether to recalc matches: (1) not enough matches left,
  // and enough time has passed since last recalc (or we're new here:
  // last_matchcalc_time = ''); or (2) profile has changed since last recalc
  // recalc means wait for data then refresh
  const too_few_matches = matches.length < config$matches_on_page;
  const calc_time = new Date(profile.last_matchcalc_time || 0);
  const now_str = fbDateTime();
  const now = new Date(now_str);
  const time_since_recalc = (now - calc_time) / 1000; // seconds
  const no_recent_recalc = time_since_recalc > config$recalc_interval;
  // check if profile changed in a way that requires fetching new matches
  const profile_changed = calc_time < new Date(profile.last_profedit_time);
  if (profile_changed
      || (too_few_matches && no_recent_recalc)) {
    setReady(false);
    dis(profile_update_action({ last_matchcalc_time: now_str }));
    set_wait_for_data(recalc_matches(dis, str)
                      .then(() => setReady(true)));
  }

  const buttons = {
    blacklist: p => () => { blacklist(dis, str, p);
                            refresh();
                           },
    poke: p => () => { poke(dis, str, p);
                       refresh();
                     },
    see_prof: p => () =>{ goto_profile(p.id)},
  }

  // for reminder messages:
  const newCoreJokes = store.jokes.unvoted.length > 0
                       && store.jokes.unvoted[0].cat === JOKE_CAT.CORE;
  const newJokes = ! newCoreJokes
                   && store.jokes.unvoted.some(j => j.cat === JOKE_CAT.NONCORE);

  const unfilled = optional_field_values.map(([f,v]) => [profile[f], v])
                   .filter(([a,b]) => a === b)
                   .length;
  // % of unfilled out of approximate total profile fields
  const total_fields = optional_fields.length;
  const filledPct = Math.round(100 * ((total_fields - unfilled) / total_fields));

  return (
    <React.Fragment>
      <MatchesUI matches={matches}
                 buttons={buttons}
                 showOverlay={showOverlay}
                 setShowOverlay={setShowOverlay}
                 order={order} sort={toggleOrder}
                 partialProfile={filledPct}
                 newJokes={newJokes}
                 newCoreJokes={newCoreJokes} />
      <PopupNote notmsg={dberr}
                 onclose={() => setDberr('')}
                 nottype="error" />
    </React.Fragment>
  );
}

export { Matches };
