import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { GoodPromise } from './utils'; // eslint-disable-line no-unused-vars
import { navigate } from '@reach/router';
import { Main, set_wait_for_data, restore_state
       } from './components/Main';
import { LIVE_MODE, FS_EMUL_MODE, FS_MODE, TEST_MODE, CYPRESS_MODE
       } from './config';
import { FSDatabase, MyAuth, db2fsdb, init_users, clear_db } from './fsdb';
import { FSQuery, FSAuth } from './fsquery';
import { create_test_db } from './test/testdb';

import { firebase } from '@firebase/app';
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/storage";
import "@firebase/functions";

let auth, storage;
let email_auth_provider;
let db_query;
let fb_functions;

const init_test_db = () => {
  const url = window.location.href;
  let testnum = url.substring(url.lastIndexOf('/') + 1);
  const olddb = create_test_db(testnum);
  // FIXME: db2fsdb operates by performing db ops, which requires
  //        that it return a promise. It should just create the
  //        required data structure (which is no harder to do) and
  //        create db directly or use _reset
  //        (furthermore, should not go through old db at all...)
  if (FS_EMUL_MODE) {
    // this email/pw can only be used in test mode
    // site has user testadmin@m.com with real pw so no user can
    // create or use this email. the email address appears in
    // firestore rules, but is used nowhere else
    let uid;
    return auth.signInWithEmailAndPassword('testadmin@m.com', 'testadminpw')
           // use absence of testadmin do indicate absence of all users
           .catch(err => { auth.createUserWithEmailAndPassword(
                                           'testadmin@m.com', 'testadminpw');
                           init_users();
                         })
           .then(() => clear_db(global.fsdb))
           .then(() => db2fsdb(olddb, global.fsdb))
           .then(() => auth.signOut()) // testadmin signs out
           .then(() => auth.signInWithEmailAndPassword('a@email.com', 'apwapw')
                       .then(() => { uid = auth.currentUser.uid }))
                       .catch(err => consolelog('app49', err))
           .then(() => auth.signOut())
           .then(() => auth.signInWithEmailAndPassword(
                              'testadmin@m.com', 'testadminpw'))
           .then(() => db_query.update('user', { id: "1", uid: uid }))
           .then(() => auth.signOut());
  } else {
    return db2fsdb(olddb, global.fsdb) // cannot fail
           .then(() => localStorage.setItem('laughstruck_test_db',
                                            JSON.stringify(global.fsdb.db)))
  }
}

function getCurrentUser(auth) {
  if (FS_MODE) {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  } else {
    return GoodPromise(localStorage.getItem('laughstruck_user')
           && JSON.parse(localStorage.getItem('laughstruck_user')));
  }
}

// these functions are applicable after getCurrentUser, or one of the
// signin functions, has been called.
const no_auth = () => ! auth || ! auth.currentUser;
const anon_user = () => ! no_auth() && auth.currentUser.isAnonymous;
const signed_in = () => ! no_auth() && ! auth.currentUser.isAnonymous;

// startup: connect to db, auth, storage
// in FS MODE, nothing else to do
// in test mode, depends on url:
//   /test/i is test mode: clear everything, create test_db
//   otherwise: restore db and auth from localStorage
function App(props) {
  const [dis, str] = [useDispatch(), useStore()];

  let fsdb;
  if (FS_MODE) {
    let firebaseConfig = {
      apiKey: "AIzaSyAqPpuHguSC5bhZLpzn1rSDAOZR-JC28wQ",
      appId: "1:806871168934:web:ebe1aa2cc741db5c1ec167",
      authDomain: "laughstruckproto.firebaseapp.com",
      databaseURL: "https://laughstruckproto.firebaseio.com",
      projectId: "laughstruckproto",
      storageBucket: "laughstruckproto.appspot.com",
      messagingSenderId: "806871168934",
    }

    firebase.initializeApp(firebaseConfig);
    fsdb = firebase.firestore();
    fb_functions = firebase.functions();

    if (FS_EMUL_MODE) {
      fsdb.settings({
        host: "localhost:8080",
        ssl: false,
        ...(CYPRESS_MODE && { experimentalForceLongPolling: true })
      });
      // firebase.firestore().useEmulator('http://localhost:8080');
      // warning is helpful if using emulator manually, not in cypress
      firebase.auth().useEmulator('http://localhost:9099',
                                  { disableWarnings: CYPRESS_MODE });
    }

    auth = new FSAuth(firebase.auth());

    // TODO: this was added because site sometimes gets so stuck that
    //       it's impossible to sign out. remove it at some point.
    if (! LIVE_MODE) {
      global.lsauth = auth;
    }
    email_auth_provider = firebase.auth.EmailAuthProvider.credential;
    // Note: there is no storage emulator
    storage = firebase.storage();
  } else { // LOCAL_MODE
    // operating from fsdb, fake persistent storage
    auth = new FSAuth(new MyAuth());
    email_auth_provider = (m, p) => ({ email: m, password: p });

    if (TEST_MODE) {
      // starting fresh, no user, no init db
      localStorage.removeItem('laughstruck_user');
      fsdb = new FSDatabase();
    } else {
      // local but not debug, may restore db and user from localStorage
      let db = localStorage.getItem('laughstruck_test_db');
      fsdb = new FSDatabase(db && JSON.parse(db));
      const user = localStorage.getItem('laughstruck_user');
      if (user) {
        auth._restoreSignin(JSON.parse(user));
      }
    }
  }

  db_query = new FSQuery(fsdb);
  if (! LIVE_MODE) {
    global.fsdb = fsdb;
    global.db_query = db_query; // used in cypress tests
  }

  if (TEST_MODE) {
    set_wait_for_data(
      // if testing using emuls, sign out before each test
      (FS_EMUL_MODE
       ? auth.signOut()
             .catch(() => null)
       : GoodPromise())
      .then(() =>
        init_test_db()  // this cannot fail, so no need for catch
        .then(() => {
          navigate("/splash", { replace: true })
        .catch(err => consolelog('app165', err));
        }))
    );
  } else {
    set_wait_for_data(getCurrentUser(auth)
                      .then(user => restore_state(user, dis, str)));
  }

  return (
    <Main />
  );
}

export { App, firebase, auth, storage, email_auth_provider, db_query,
         fb_functions,
         no_auth, anon_user, signed_in,
         getCurrentUser };
