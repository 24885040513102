import React, { useState, useEffect } from 'react';

import { DownArrowIcon } from '../../assets/icons/down-arrow';

const Dropdown = ({ show, choices, cy_prefix, labeledby, action }) => {

  const [fst, last, mid ] = [choices[0], choices[choices.length - 1],
                             choices.slice(1, choices.length - 1)];
  return (
    <div className={`origin-top-right absolute right-0 top-10
                     grid grid-rows-${choices.length}
                     mt-2 w-full rounded-lg bg-white
                     z-10
                     divide-y divide-gray-200
                     border-2 border-orange border-opacity-30
                     ${ show ? "" : "hidden" }`}
         style={{ boxShadow: "0px 3px 50px #0000000D" }}
         aria-orientation="vertical"
         aria-labelledby={labeledby}
         onClick={action}>
        <div className="block px-4 py-2 text-sm text-gray-text
                        hover:rounded-t-lg
                        hover:bg-gray-bg hover:text-gray-text"
                label={fst}
                data-cy={`${cy_prefix}0`}>
         {fst}
        </div>
        { mid.map((lab, idx) =>
            <div className="block px-4 py-2 text-sm text-gray-text
                    hover:bg-gray-bg hover:text-gray-text"
                 key={idx}
                 label={lab}
                 data-cy={`${cy_prefix}${idx + 1}`}>
              {lab}
            </div>)
        }
        <div className="block px-4 py-2 text-sm text-gray-text
             hover:rounded-b-lg
             hover:bg-gray-bg hover:text-gray-text"
             label={last}
             data-cy={`${cy_prefix}${choices.length - 1}`}>
          {last}
        </div>
    </div>
  );
}

const DropdownSw = ({ the_ref, label, errmsg, reset_errmsg,
                      dflt, prompt,
                      unit, margin,
                      choices, cy_prefix, action }) => {
  const [show, setShow] = useState(false);
  const [choice, setChoice] = useState(dflt || '');

  useEffect(() => {
    const close = e => setShow(false);

    // If the item is active (ie open) then listen for clicks
    if (show) {
      window.addEventListener('click', close);
    }

    return () => {
      window.removeEventListener('click', close);
    }

  }, [show]);

  const onselect = ev => {
    if (action) {
      action(ev);
    }
    setChoice(ev.target.getAttribute('label'));
    if (reset_errmsg) {
      reset_errmsg();
    }
  }

  const display = choice && choice !== 'noinfo' ? choice : '';

  return (
    <div className={`${ margin || '' }
                     ${ unit ? ""
                        : "flex flex-col space-y-2 justify-start mr-4"}`}
         ref={the_ref}
         value={choice}>
      <div className="text-sm text-gray-text font-normal"
           id={`${label}-dropdown`}>
        {label}
      </div>
      <div className={`flex flex-row items-center
                       ${ unit ? 'py-0' : 'py-2.5'}
                       relative
                       justify-between
                       bg-gray-bg rounded-lg
                       ${errmsg ? 'border-2 border-red-400' : ''}`}
           data-cy={cy_prefix}
           onClick={() => setShow(true)} >
        <div className={`flex flex-row ${unit ? 'hidden' : ''} w-full
                         justify-between items-center`}>
          <div className={`ml-4 text-sm font-normal
                           ${display ? 'text-gray-text' : 'text-gray-para'}`}>
            { display || prompt }
          </div>
          <div className="mr-4">
            <DownArrowIcon />
          </div>
        </div>
        <div className={`flex flex-row ${unit ? '' : 'hidden'} w-full
                         items-center`}>
          <div className="flex w-1/3 h-full bg-gray-border
                      justify-center py-2.5
                      rounded-l-lg items-center">
            <div className="text-sm text-gray-text font-normal">
              {unit}
            </div>
          </div>
          <div className="flex flex-row w-2/3 items-center
            py-2.5
                          mx-4 justify-between">
            <div className="text-sm text-gray-text font-normal">
              { display || prompt }
            </div>
            <div className="flex self-justify-end">
              <DownArrowIcon />
            </div>
          </div>
        </div>
        <Dropdown show={show}
                  choices={choices.filter(c => c !== 'noinfo')}
                  cy_prefix={cy_prefix}
                  labeledby={`${label}-dropdown`}
                  action={onselect} />
      </div>
      <div className={`text-xs font-normal mx-2 text-red-600
                      mx-4 mt-1
                      ${errmsg ? '' : 'hidden'}`}>
        {errmsg}
      </div>
    </div>
  );
}

export { DropdownSw };
