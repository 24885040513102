import React from 'react';

const TopLogoIcon = () => {
  const ar = 18.008 / 42.779;
  const width = 15;
  const [w, h] = [`${width}`, `${width/ar}`];
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={w} height={h} viewBox="90 0 18.008 42.779">
      <defs>
        <radialGradient id="a" cx="0.5" cy="0.5" r="0.394"
                        gradientTransform="translate(-0.518) scale(2.036 1)">
          <stop offset="0" stopColor="#e6e7e8"/>
          <stop offset="0.236" stopColor="#f2f2f3"/>
          <stop offset="0.568" stopColor="#fbfcfc"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <linearGradient id="b" x1="0.5" x2="0.5" y2="1">
          <stop offset="0" stopColor="#ffbb23"/>
          <stop offset="1" stopColor="#ef0b1b"/>
        </linearGradient>
        <radialGradient id="c" cx="-0.186" cy="0.659" r="1.52"
                        gradientTransform="translate(-0.431) scale(1.861 1)">
          <stop offset="0" stopColor="#ff005d"/>
          <stop offset="0.023" stopColor="#ff0c58"/>
          <stop offset="0.121" stopColor="#ff3e44"/>
          <stop offset="0.224" stopColor="#ff6934"/>
          <stop offset="0.331" stopColor="#ff8e25"/>
          <stop offset="0.442" stopColor="#ffac1a"/>
          <stop offset="0.558" stopColor="#ffc311"/>
          <stop offset="0.682" stopColor="#ffd40a"/>
          <stop offset="0.821" stopColor="#ffdd07"/>
          <stop offset="1" stopColor="#ffe106"/>
        </radialGradient>
      </defs>
      <g transform="translate(-51 -88.174)">
        <g transform="translate(141.545 88.174)">
          <path style={{ fill: "#ff4f23" }}
                d="M460.455,517.164l-.009.035-.022.095-.02.079-1.841,2.27-7.283,8.979,4.635-10.589,2.908-6.643C461.2,512.292,460.875,515.371,460.455,517.164Z"
                transform="translate(-451.097 -485.843)"/>
          <path style={{ fill: "url(#a)" }}
                d="M525.161,493.16a1.548,1.548,0,0,1-.35,1.01l-.185.227-.117.09.3-.372A1.557,1.557,0,0,0,525.161,493.16Z"
                transform="translate(-508.219 -471.623)"/>
          <path style={{ fill: "url(#b)" }}
                d="M488.329,397.507l-6.946,15.87-.02-.009-.037.088-.015-.068c-.029-.007-.057-.013-.086-.022-8.394-2.215-4.159-16.922-3.8-18.116Z"
                transform="translate(-470.41 -395.25)"/>
          <path style={{ fill: "url(#c)" }}
                d="M467.041,417.741l-.3.372.117-.09-7.1,8.757.02-.079.022-.095.009-.035c.42-1.793.748-4.872-1.632-5.774a.078.078,0,0,0-.02-.009l0-.009-.238-.07-5.1-1.5-.044-.013a3.3,3.3,0,0,1-2.158-4.2.218.218,0,0,1,.011-.035l3-8.66c0-.015.011-.029.015-.044l3.814-11.009c-.33,1.1-3.964,13.719,2.081,17.4.018.009.035.02.055.031a17.13,17.13,0,0,0,1.8.7l.037.011.018.007,4.938,1.907.026.011a1.563,1.563,0,0,1,.622,2.433Z"
                transform="translate(-450.448 -395.25)"/>
          <path style={{ fill: "#d34242" }}
                d="M500.267,477.635l-.037-.011,0,0,.02.009Z"
                transform="translate(-489.28 -459.501)"/>
        </g>
      </g>
    </svg>
  );
}

export { TopLogoIcon };
