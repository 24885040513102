import React, { useRef } from 'react';

import { RegPage } from './RegPage';
import { TextSw, TextareaSw } from '../widgets/TextWidget';
import { config$text_limits, config$test_url } from '../../config';

const BaseContactUI = ({submit, email, errmsg, reset_errmsg}) => {
  let subj_ref = useRef();
  let email_ref = useRef();
  let text_ref = useRef();
  const clear = () => { [ subj_ref, email_ref, text_ref ]
                        .forEach(r => r.current.clear()) };
  return (
    <div className="flex flex-col mx-auto w-full mb-8">
      <div className="w-4/5">
        <div className="flex text-3xl font-semibold text-gray-text my-4">
          Contact Us
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-3 flex flex-col space-y-4
                        bg-white rounded-lg pb-4"
             style={{ boxShadow: "0px 3px 50px #0000000D" }}>
          <div className="flex text-sm font-normal text-gray-text my-6 mx-4">
            For complaints, suggestions, love notes, observations of a comical nature,
            write to us at contact@laughstruck.com, or submit your message here:
          </div>
          <div className="flex flex-col space-y-2 justify-start">
            <div className="flex flex-row space-x-2 justify-start
                            w-full items-center">
              <div className="ml-4 text-sm font-normal text-gray-text">
                From:
              </div>
              <div className="w-full">
                <TextSw ref={email_ref}
                        label=""
                        type="text"
                        margin="mx-4"
                        maxchars={config$text_limits.email}
                        errmsg={ errmsg.email }
                        reset_errmsg={reset_errmsg}
                        dflt={email}
                        prompt={`Email ${
                            ''}(${config$text_limits.email} chars max)`}
                        cy_name="contact_email" />
              </div>
            </div>
            <TextSw ref={subj_ref}
                    label=""
                    type="text"
                    margin="mx-4"
                    maxchars={config$text_limits.contact_subject}
                    errmsg={ errmsg.subject }
                    reset_errmsg={reset_errmsg}
                    prompt={`Subject ${
                        ''}(${config$text_limits.contact_subject} chars max)`}
                    cy_name="contact_subject" />
          </div>
          <TextareaSw ref={text_ref}
                      errmsg={ errmsg.message }
                      margin="mx-4"
                      rows={7}
                      reset_errmsg={reset_errmsg}
                      maxchars={config$text_limits.contact_message}
                      prompt={`Type your message ${
                          ''}(${config$text_limits.contact_message} chars max)`}
                      cy_name="contact_message" />
          <div className="flex flex-row justify-end">
            <div className="bg-orange rounded-lg h-10 w-36 flex
                            mr-4 justify-center items-center">
              <button>
                <div className="font-semibold text-white text-sm"
                     data-cy="contact-send"
                     onClick={submit(subj_ref, email_ref, text_ref, clear)}>
                  Send Message
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="col-span-1 sm:hidden" />
          <img style={{ height: "30rem" }}
               alt="mock ad"
               src={`${config$test_url}/images/side_ad_5.png`}
               className="ml-4 rounded-lg"/>
      </div>
      <div className="h-8" />
    </div>
  );
}

const ContactUI = RegPage(BaseContactUI);

export { ContactUI };
