import React from 'react';

// used to indicate drop-down menu in edit pages
const HamburgerIcon = () =>
  <div className="bg-gray-bg rounded-lg self-center">
  <svg width="30px" height="30px" viewBox="0 0 72 72">
    <g id="color"/>
    <g id="hair"/>
    <g id="skin"/>
    <g id="skin-shadow"/>
    <g id="line">
      <line x1="16" x2="56" y1="26" y2="26"
            fill="none" stroke="#000000"
            strokeLinecap="round" strokeLinejoin="round"
            strokeMiterlimit="10" strokeWidth="2"/>
      <line x1="16" x2="56" y1="36" y2="36"
            fill="none" stroke="#000000"
            strokeLinecap="round" strokeLinejoin="round"
            strokeMiterlimit="10" strokeWidth="2"/>
      <line x1="16" x2="56" y1="46" y2="46"
            fill="none" stroke="#000000"
            strokeLinecap="round" strokeLinejoin="round"
            strokeMiterlimit="10" strokeWidth="2"/>
    </g>
  </svg>
  </div>

export { HamburgerIcon };
