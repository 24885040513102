import React, { useState } from 'react';
import { useStore } from 'react-redux';
import axios from 'axios';

import { ContactUI } from '../ui/ContactUI';
import { TechSupportUI } from '../ui/TechSupportUI';
import { PopupNote } from '../ui/PopupNote';
import { signed_in } from '../../App';
import { db_query } from '../../App';
import { LIVE_MODE } from '../../config';

function record_message(type, email, id, username, subj, text) {
  const now = new Date();
  const mailparams = {
    type: type,
    from: email,
    date: now.toLocaleString(), // readable form in email
    subject: subj,
    text: text.replaceAll('\n', '</p><p>'),
    id: id || '',
    username: username || '',
  }
  const dbentry = {
    ...mailparams,
    date: now.toISOString(), // db form
    status: 'new',
    notes: '',
  }
  db_query.add('contact', dbentry);
  if (LIVE_MODE) {
    axios.get("https://us-central1-laughstruckproto.cloudfunctions.net/sendMail",
              { params: mailparams })
         .then(res => console.log("Successful contact:", res))
         .catch(err => console.log("Contact error:", err));
  }
}

const Contact = props => {

  const [errmsg, setErrmsg] = useState({});
  const [confirm, setConfirm] = useState('');

  const str = useStore();
  const profile = str.getState().profile;

  const [email, id, username] = [ profile.email, profile.id, profile.username ];

  const submit = (subj_ref, email_ref, text_ref, clear) => {
    return e => {
      e.preventDefault();

      const subj = subj_ref.current.getText();
      if (subj === '') {
        setErrmsg({ subject: "Please fill in this field" });
        return;
      }

      const m = email_ref.current.getText();
      if (m === '') {
        setErrmsg({ email: "Please fill in this field" });
        return;
      }

      const text = text_ref.current.getText();
      if (text === '') {
        setErrmsg({ message: "Empty message" });
        return;
      }

      setConfirm('Your message has been sent. Thank you for contacting us.')
      record_message('contact', m, id, username, subj, text);
      clear();
    }
  }

  return (
    <div>
      <ContactUI submit={submit}
                 errmsg={errmsg}
                 email={email}
                 reset_errmsg={() => setErrmsg({})}
                 footeronly={! signed_in()} />
      <PopupNote notmsg={confirm}
                 onclose={() => setConfirm('')}
                 nottype="info" />
    </div>
  )
}

const TechSupport = props => {

  const [errmsg, setErrmsg] = useState({});
  const [confirm, setConfirm] = useState('');

  const str = useStore();
  const profile = str.getState().profile;

  const [email, id, username] = [ profile.email, profile.id, profile.username ];

  const submit = (subj_ref, email_ref, text_ref, clear) => {
    return e => {
      e.preventDefault();

      let subj = subj_ref.current.getText();
      if (subj === '') {
        setErrmsg({ subject: "Please fill in this field" });
        return;
      }

      let m = email_ref.current.getText();
      if (m === '') {
        setErrmsg({ email: "Please fill in this field" });
        return;
      }

      let text = text_ref.current.getText();
      if (text === '') {
        setErrmsg({ message: "Empty message" });
        return;
      }

      setConfirm('Your message has been sent. Thank you for contacting us.')
      record_message('techsupport', m, id, username, subj, text);
      clear();
    }
  }

  return (
    <div>
      <TechSupportUI submit={submit}
                 errmsg={errmsg}
                 email={email}
                 reset_errmsg={() => setErrmsg({})}
                 footeronly={! signed_in()} />
      <PopupNote notmsg={confirm}
                 onclose={() => setConfirm('')}
                 nottype="info" />
    </div>
  )
}

export { Contact, TechSupport };
