import React, { useRef, useState, useEffect } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
// import { useMediaQuery } from 'react-responsive';

import { RegPage } from './RegPage';

import { InfoIcon } from '../../assets/icons/info';
import { ConvoBigIcon } from '../../assets/icons/convo-big';
import { SendIcon } from '../../assets/icons/send';
import { DislikeIcon } from '../../assets/icons/useractions';
import { Explainer } from './Explainer';
import { format_date, report_user_explainer,
         explain_humor, explain_humorp } from './utils';
import { ReportUserOverlay } from './ReportUserOverlay';
import { UserMinicard } from './UserMinicard';
import { HumorfaceIcon, HumorplusfaceIcon } from '../../assets/icons/humorface';
import { goto_profile } from '../pages/utils';
import { config$test_url } from '../../config';
import { config$text_limits } from '../../config';
import { MessageTextSw } from '../widgets/TextWidget';

const isMobile = window.screen.width <= 500 || window.screen.height <= 500;
const mobileHeight = Math.max(window.screen.height, window.screen.width);

const Mebox = ({ msg, time }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-start">
        <div className="bg-gray-bg rounded-r-lg rounded-t-lg mx-4">
          <div className="text-sm font-normal text-gray-text mx-4 my-4"
               style={{ whiteSpace: "pre-wrap" }}>
            {msg}
          </div>
        </div>
      </div>
      <div className="text-xs font-normal text-gray-para mx-4 mt-2">
        {time}
      </div>
    </div>
  );
}

const Thembox = ({ msg, time }) => {
  return (
    <div className="flex flex-col px-4">
      <div className="flex flex-wrap justify-end">
        <div className="bg-orange rounded-l-lg rounded-t-lg"
             style={{ background: `transparent
                        linear-gradient(139deg, #FFBB23 0%, #FF4F23 100%)
                        0% 0% no-repeat padding-box` }} >
          <div className="text-sm font-normal text-white mx-4 my-4"
               style={{ whiteSpace: "pre-wrap" }}>
            {msg}
          </div>
        </div>
      </div>
      <div className="flex justify-end text-xs font-normal
                  text-gray-600 mx-2 mt-2">
        {time}
      </div>
    </div>
  );
}

const message_list = (oid, messages) => {
  let date = '';
  let msglist = [];

  const show_date = d =>
    <div className="flex flex-row justify-center"
         key={d} >
      <div className="text-sm font-normal text-gray-para">
        {d}
      </div>
    </div>;

  const show_msg = (m, t, i) => {
    return (
      m.other_user  === oid
      ? <Mebox msg={m.msg} time={t} key={i} />
      : <Thembox msg={m.msg} time={t} key={i} />
    );
  }

  messages.forEach((m, i) => {
    const [d, t] = format_date(m.date);
    if (d === date) {
      msglist.push(show_msg(m, t, i));
    } else {
      date = d;
      msglist.push(show_date(d));
      msglist.push(show_msg(m, t, i));
    }
  });

  return msglist;
}

// height is arbitrary height defined in BaseConversationsUI, currently
// .6 of window height
const Messages = ({ oprofile, messages, sendmsg, buttons, text, setText,
                    height, setShowOverlay }) => {

  // pop-ups for 'report user' info button and humor explanations
  const [ reportUserEx, setReportUserEx ] = useState(false);
  const [ explainHumor, setExplainHumor ] = useState(false);
  const [ explainHumorP, setExplainHumorP ] = useState(false);

  // humor explainers are "ganged"
  const setExpHumor = () => { setExplainHumor(! explainHumor);
                              setExplainHumorP(false); };
  const setExpHumorP = () => { setExplainHumorP(! explainHumorP);
                               setExplainHumor(false); };
  const resetExpHumors = () => { setExplainHumor(false);
                                 setExplainHumorP(false); }

  // only purpose of this is to trigger render here
  const [ textarea_ht, setTextarea_ht ] = useState(0);

  const [ msgs_ht, setMsgs_ht ] = useState(0);

  // references to top area (username, etc), area containing message
  // list (possibly with scrollbar), and text entry field
  // idea is to get height of head and input and calculate available
  // space for old messages.
  const head_ref = useRef(null);
  const msgs_ref = useRef(null);
  const textarea_ref = useRef(null);

  useEffect(() => {
    const f = () => {
      // oprofile defined means right side is populated,
      // which means head_ref and textarea_ref are defined
      if (oprofile) {
        msgs_ref.current.scrollTop = msgs_ref.current.scrollHeight;
        const head_ht = head_ref.current.getBoundingClientRect().height;
        const text_ht = textarea_ref.current.getBoundingClientRect().height;
        const ht = height - text_ht - head_ht - 20;
        if (ht !== msgs_ht) {
          setMsgs_ht(ht);
        }
      }
    };
    f();
  });

  if (! oprofile) return <span />;

  // right side has top section with username, bottom with msgs and msg input
  return (
    <div className="relative flex flex-col h-full
                    bg-white rounded-lg overflow-y-hidden"
         style={{ boxShadow: "0px 3px 50px #0000000D",
                  height: height }}>
      <div className="bg-white bg-opacity-100 z-10 h-auto w-full"
           ref={head_ref}>
        <div className="flex flex-col h-auto mx-6 justify-start
                        bg-white bg-opacity-100">
          <div className="flex flex-row justify-between items-center
                          mt-4 bg-white bg-opacity-100 z-10">
            <div className="flex flex-col space-y-2 justify-start items-start">
              <button className="text-base font-semibold text-gray-text"
                   onClick={() => goto_profile(oprofile.id)}>
                {oprofile.username}
              </button>
              <div className="flex flex-row justify-end my-1 space-x-6">
                <button className={`flex flex-row space-x-2`}
                     onClick={buttons.blacklist(oprofile)}
                     data-cy="convo-blacklist-button">
                  <DislikeIcon />
                  <div className="text-xs font-normal text-gray-text ml-1">
                    Pass
                  </div>
                </button>
                <div className="relative flex flex-row space-x-2">
                  <div data-cy="open-reportuser"
                       onClick={() => setReportUserEx(! reportUserEx)}>
                    <InfoIcon size="small" />
                  </div>
                  <Explainer open={reportUserEx}
                             pos="-left-8 top-8`"
                             cy_name="reportuserex"
                             close={() => setReportUserEx(false)}
                             exp={report_user_explainer} />
                  <button className="text-xs font-normal text-gray-para"
                       data-cy="show-report-user"
                       onClick={() => setShowOverlay([oprofile, 'report'])}>
                    Report user
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-start mt-2
                            flex-row lg:flex-col md:flex-row sm:hidden">
              <div className="relative" data-cy="open-exphumor-convo"
                   onClick={setExpHumor}>
                <div className="flex flex-row items-center my-2 mx-2 px-2
                                bg-gray-bg rounded-lg border-2">
                  <HumorfaceIcon />
                  <div className="text-sm font-normal text-gray-text my-1 ml-1">
                    {oprofile.affinity[0]}%
                  </div>
                </div>
                <Explainer open={explainHumor}
                           cy_name="exphumorconvo"
                           pos="-left-36 top-8 sm:-left-24"
                           close={resetExpHumors}
                           exp={explain_humor} />
              </div>
              <div className="relative" data-cy="open-exphumorp-convo"
                   onClick={setExpHumorP}>
                <div className="flex flex-row items-center my-2 mx-2 px-2
                                bg-gray-bg rounded-lg border-2">
                  <HumorplusfaceIcon />
                  <div className="text-sm font-normal text-gray-text my-1 ml-1">
                    {oprofile.affinity[2]}%
                  </div>
                </div>
                <Explainer open={explainHumorP}
                           cy_name="exphumorpconvo"
                           pos="-left-64 top-8 sm:-left-24"
                           close={resetExpHumors}
                           exp={explain_humorp} />
              </div>
            </div>
          </div>
          <div className="flex flex-row py-4 justify-center">
            <div className="w-full h-0.5 bg-gray-bg" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start">
        <div className="overflow-y-auto mb-4"
             ref={msgs_ref}
             style={{ height: `${msgs_ht}px`,
                      scrollSnapAlign: "end"  }} >
          <div className="flex flex-col space-y-3"
               data-cy="message-thread">
            { message_list(oprofile.id, messages) }
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-11/12 h-auto pb-6"
           ref={textarea_ref}>
        <div className="relative flex flex-row w-full justify-between mx-4">
          <MessageTextSw
                  area_ht={textarea_ht}
                  setArea_ht={setTextarea_ht}
                  text={text}
                  change={ ev => setText(ev.target.value) }
                  label=""
                  margin="w-10/12"
                  maxchars={config$text_limits.brief_intro}
                  prompt="Your message"
                  cy_name="convo-msg" />
            <button
              className="absolute bottom-2.5 right-4 sm:right-3"
                 data-cy="sendit-button"
                 onClick={() => { if (sendmsg(text)) {
                                    setText('');
                                  }
                                }
                         }>
              <SendIcon />
            </button>
        </div>
      </div>
    </div>
  );
}

const BaseConversationsUI = ({oprofile, messages,
                              showOverlay, setShowOverlay,
                              threads,
                              text, setText,
                              sendmsg, buttons }) => {

  // force rerender upon resize - needed here (and only here) because
  // widget size is *explicitly* calculated as a function of screen size
  const [ ht, setHt ] = useState(window.innerHeight);
  window.addEventListener('resize', () => setHt(window.innerHeight));

  // for phones, always use "physical" phone height (even in landscape)
  const height = (isMobile ? mobileHeight : ht) * 0.6;
  const height_prop =  `${height}px`;

  return (
    <div className="flex flex-col mx-auto w-full">
      <div className="hidden md:block">
          <img style={{ height: "10rem" }}
             alt="small mock ad"
             data-cy="joke-ad-sm"
             src={`${config$test_url}/images/app_ad.png`}
             className="rounded-lg col-span-1 mx-auto"/>
      </div>
      <div className="flex text-3xl font-semibold text-gray-text my-4
                      justify-start md:justify-center sm:hidden">
        Conversations
      </div>
      <div className="grid grid-cols-10 mt-4 gap-x-4">
        <div className={`col-span-3 space-y-0 bg-white rounded-lg
                         overflow-y-auto
                         lg:col-span-4
                         ${ oprofile ? 'md:hidden' : 'md:col-span-10'}`}
             style={{ boxShadow: "0px 3px 50px #0000000D",
                      height: height_prop }}>
          { threads.length > 0
            ? threads.map(p => <div className="flex mx-2" key={p.id}>
                                 <UserMinicard oprofile={p} />
                               </div>
                         )
            : <div className="mx-6 text-base font-normal text-gray-text
                              mt-6 text-center">
               You have no conversations started.
               Once you message one of your connections - or they message
               you - you'll see those users here.
              </div>
          }
        </div>
        <div className={`grid col-span-5 lg:col-span-4 rounded-lg
                         ${oprofile ? 'md:col-span-10' : 'md:hidden'}`}
             style={{ boxShadow: "0px 3px 50px #0000000D",
                      height: height_prop }}>
          <div className={`flex flex-col h-full justify-center
                           ${oprofile ? " hidden" : ""}`}>
            <div className="flex justify-center opacity-50">
              <ConvoBigIcon />
            </div>
            <div className="mx-6 text-sm font-normal text-gray-para
                            mt-6 text-center">
              Select a laugh-match to begin conversation!
            </div>
          </div>
          <div className={`${oprofile ? "" : "hidden"} overflow-y-auto`}>
              <Messages oprofile={oprofile}
                        height={height}
                        messages={messages}
                        buttons={buttons}
                        text={text} setText={setText}
                        setShowOverlay={setShowOverlay}
                        blacklist={buttons.blacklist}
                        sendmsg={sendmsg} />
          </div>
        </div>
        <div className="col-span-2 md:hidden">
          <img style={{ height: "30rem" }}
               alt="mock ad"
               src={`${config$test_url}/images/side_ad_5.png`}
               className="ml-4 rounded-lg"/>
        </div>
      </div>
      <div className="h-8" />
    </div>
  );
}

const ConversationsUI = RegPage(BaseConversationsUI, ReportUserOverlay);

export { ConversationsUI };
