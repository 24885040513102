import React from 'react';

// Used for "liking" and "blacklisting"
const LikeIcon = () =>
  // <img alt="like" src="images/like.svg" />;
  <svg xmlns="http://www.w3.org/2000/svg"
       width="8.585" height="18.702" viewBox="0 0 8.585 18.702">
    <title>Like</title>
    <path style={{ fill: "#b1b1b1",
                   stroke: "#b1b1b1",
                   strokeLinecap: "round",
                   strokeLinejoin: "round",
                   strokeMiterlimit: "10" }}
          d="M457.614,404.162a.766.766,0,0,1-.165.438l-.127.154-.031.038-2.905,3.505-.779.939-3.081,3.716,1.96-4.382,1.23-2.749-.008,0,0,0-.1-.029-2.157-.619-.019-.005a1.36,1.36,0,0,1-.913-1.739.085.085,0,0,1,0-.015l1.269-3.584c0-.006,0-.012.007-.018l1.613-4.556,4.613.934-2.938,6.567-.008,0v0l.016,0,.007,0,2.089.789.011,0A.646.646,0,0,1,457.614,404.162Z"
          transform="translate(-449.943 -394.75)"/>
  </svg>;

const DislikeIcon = () =>
  // <div className="mt-0.5">
  //   <img alt="dislike" src="images/dislike.svg" />
  // </div>
  <svg xmlns="http://www.w3.org/2000/svg"
       className="mt-0.5"
       width="16.081" height="15.076" viewBox="0 0 16.081 15.076">
    <title>Pass</title>
    <path style={{ fill: "#b1b1b1" }}
          d="M20.848,1H19.173A1.174,1.174,0,0,0,18,2.173V9.878a1.174,1.174,0,0,0,1.173,1.173h1.675A1.174,1.174,0,0,0,22.02,9.878V2.173A1.174,1.174,0,0,0,20.848,1Z"
          transform="translate(-5.939 -0.832)"/>
    <path style={{ fill: "#b1b1b1" }}
          d="M3.43.75A2.676,2.676,0,0,0,.79,2.975L.04,7.33a2.679,2.679,0,0,0,2.64,3.136H5.842a6.47,6.47,0,0,0-.5,2.68,2.461,2.461,0,0,0,2.178,2.68c.67,0,1.005-.335,1.005-2.01,0-1.592,1.542-2.873,2.534-3.533v-8.3A15.618,15.618,0,0,0,4.5.75Z"
          transform="translate(0 -0.75)"/>
  </svg>;


export { LikeIcon, DislikeIcon };
