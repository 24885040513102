import React from 'react';

const BellIcon = () =>
  // <img alt="bell" src="images/reminder-bell.svg" />
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20" height="24" viewBox="0 0 20 24">
    <title>Notification</title>
    <g transform="translate(-2)">
      <path style={{ fill: "#fff" }}
            d="M21.379,16.913A6.7,6.7,0,0,1,19,11.788V9a7.006,7.006,0,0,0-6-6.92V1a1,1,0,0,0-2,0V2.08A7,7,0,0,0,5,9v2.788a6.705,6.705,0,0,1-2.388,5.133A1.75,1.75,0,0,0,3.75,20h16.5a1.75,1.75,0,0,0,1.129-3.087Z"/>
      <path style={{ fill: "#fff" }}
            d="M12,24a3.756,3.756,0,0,0,3.674-3H8.326A3.756,3.756,0,0,0,12,24Z"/>
    </g>
  </svg>

export { BellIcon };
