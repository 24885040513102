import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { mk_redux_store } from './store';
import { init_store } from './components/Main';

const store = mk_redux_store(init_store());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
