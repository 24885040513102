const JOKE_TYPES = {
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3
}
// 'isCore' is boolean, but we're leaving this in case we want to classify
// jokes somehow in the future
const JOKE_CAT = {
  CORE: 1,
  NONCORE: 2,
}
const JOKE_FLAG = {
  NONE: 0,
  OBSCENE: 1,
  RACIST: 2,
  SEXIST: 3,
  OTHER: 4
}

const GENDER = {
  NOINFO: 0, // initial value, but cannot appear in db entry of signed-up user
  FEMALE: 1,
  MALE: 2,
  NONBINARY: 3,
}

const SEEKING = {
  NOINFO: 0, // initial value, but cannot appear in db entry of signed-up user
  NOPREF: 1,
  ROMANCE: 2,
  FRIENDSHIP: 3,
}

const MARITAL_STATUS = {
  NOINFO: 0,
  SINGLE: 1,
  MARRIED: 2,
  OPEN: 3,
}

// since marital pref is given as an array, can't use NOINFO value;
// represent no info by null
const MARITAL_PREF = {
  SINGLE: 1,
  MARRIED: 2,
  OPEN: 3,
}

const SMOKER = {
  NOINFO: 0,
  SMOKER: 1,
  NONSMOKER: 2,
}

const SMOKER_PREF = {
  NOINFO: 0,
  NOPREF: 1,
  NONSMOKER: 2,
}

const DRINKER = {
  NOINFO: 0,
  DRINKER: 1,
  NONDRINKER: 2,
}

const DRINKER_PREF = {
  NOINFO: 0,
  NOPREF: 1,
  NONDRINKER: 2,
}

const CHILDREN = {
  NOINFO: 0,
  CHILDREN: 1,
  NOCHILDREN: 2,
}

const CHILDREN_PREF = {
  NOINFO: 0,
  NOPREF: 1,
  NOCHILDREN: 2,
}

const EDUC_LEVEL = {
  NOINFO: 0,
  NODEGREE: 1,
  HIGHSCHOOL: 2,
  ASSOC: 3,
  BACHELOR: 4,
  GRADUATE: 5
}

// prefs match up with educ_level
const EDUC_PREF = {
  NOINFO: 0,
  NOPREF: 1,
  HIGHSCHOOL: 2,
  ASSOC: 3,
  BACHELOR: 4,
  GRADUATE: 5,
}

const RELIGIOUS = {
  NOINFO: 0,
  RELIGIOUS: 1,
  SOMEWHAT: 2,
  ATHEIST: 3,
}

// values here match RELIGIOUS
const RELIGIOUS_PREF = {
  NOINFO: 0,
  RELIGIOUS:1,
  SOMEWHAT: 2,
  ATHEIST: 3,
  NOPREF: 4,
}

const DB_ERR = {
  BAD_QUERY:      "BAD_QUERY",     // badly-formed query; should never happen
  NOT_UNIQUE:     "NOT_UNIQUE",    // a field expected to be a key matches multiple records
  ALREADY_EXISTS: "EXISTS",        // trying to add a record that already exists
  MISSING_ARG:    "MISSING_ARG",   // missing reqd arg
  EXTRA_ARG:      "EXTRA_ARG",     // missing reqd arg
  UNKNOWN_QUERY:  "UNKNOWN_QUERY", // should never happen
  NOT_FOUND:      "NOT_FOUND",     // item expected to exist (for update)
  OTHER:          "OTHER",         // specialized
}

const LOGLEVEL = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
}

export { JOKE_TYPES, JOKE_CAT, JOKE_FLAG,
         GENDER, SEEKING,
         MARITAL_STATUS, MARITAL_PREF,
         SMOKER, SMOKER_PREF,
         DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         RELIGIOUS, RELIGIOUS_PREF,
         DB_ERR, LOGLEVEL,
};
