import React, { Component, useState } from 'react';

const Button = ({selected}) => {
  return (
    <span className={"w-3.5 h-3.5 inline-block mr-1 rounded-full "
                + (selected ? "bg-orange" : "bg-gray-bg")} />
  );
}

const RadioButton = ({selected, label}) => {
  return (
    Boolean(label)
    ? <span className="flex flex-row space-x-2">
        <Button selected={selected} />
        <span className="text-sm font-normal text-black -mt-0.5">
          {label}
        </span>
      </span>
    : <Button selected={selected} />
  );
}

class RadioButtonSw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: props.dflt || props.choices[0],
    }

    this.setChoice = this.setChoice.bind(this);
    this.getChoice = this.getChoice.bind(this);
  }

  setChoice(c) { this.setState({ choice: c })}
  getChoice() { return this.state.choice; }

  render() {
    const { the_ref, choices, cy_prefix } = this.props;
    return (
      <div className="flex flex-col justify-start mx-6 space-y-4"
           ref={the_ref} value={this.state.choice}>
        { choices.map((c, idx) =>
            <span className="flex flex-row space-x-2" key={c}
                  data-cy={`${cy_prefix}${idx}`}
                  onClick={() => this.setChoice(c)}>
              <RadioButton selected={this.state.choice === c} />
              <span className="text-sm font-normal text-gray-text">
                {c}
              </span>
            </span>
          )}
      </div>
    );
  }
}

const RadioButtonGridSw = ({ the_ref, choices, cols, dflt, cy_prefix }) => {
  const [ choice, setChoice ] = useState(dflt || choices[0]);
  return (
    <div className={`grid grid-cols-${cols} gap-y-4 ml-6`}
         ref={the_ref} value={choice}>
      { choices.map((c, idx) =>
          <div data-cy={`${cy_prefix}${idx}`}
               key={c}
               onClick={() => setChoice(c)}>
            <RadioButton selected={choice === c} label={c} />
          </div>
        )}
    </div>
  );
}

class MultiRadioSw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.dflt || []
    }

    this.toggleSelection = this.toggleSelection.bind(this);
    this.getSelected = this.getSelected.bind(this);
  }

  toggleSelection(ch) {
    if (this.state.selected.includes(ch)) {
      this.setState({ selected: this.state.selected.filter(c => c !== ch) });
    } else {
      this.setState({ selected: this.state.selected.concat([ch]) });
    }
  }
  getSelected() { return this.state.selected; }

  render() {
    const { the_ref, choices, cy_prefix, errmsg, reset_errmsg } = this.props;
    return (
      <div className="flex flex-col justify-start mx-6 space-y-4"
           ref={the_ref} value={this.state.choice}
           data-cy={cy_prefix} >
        { choices.map((c, idx) =>
            <span className="flex flex-row space-x-2" key={c}
                  data-cy={`${cy_prefix}${idx}`}
                  onClick={() => { reset_errmsg(); this.toggleSelection(c); }}>
              <RadioButton selected={this.state.selected.includes(c)} />
              <span className="text-sm font-normal text-gray-text">
                {c}
              </span>
            </span>
          )}
          <div className={`${ errmsg ? "" : "hidden" }
                           text-xs font-normal mx-2 text-red-600 mx-4 mt-1`}>
            {errmsg}
          </div>
      </div>
    );
  }
}

export { RadioButton, RadioButtonSw, RadioButtonGridSw, MultiRadioSw };
