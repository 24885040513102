import React from 'react';

import { calculate_age } from '../../utils';
import { last_msg_from } from '../pages/utils';
import { changePage } from '../../utils';
import { minitime } from './utils';

// small cards on left of Convos page
const UserMinicard = ({ oprofile }) => {
  const last_msg = last_msg_from(oprofile.messages, oprofile.id);
  const short_msg = last_msg ? last_msg.msg.substr(0, 20)
                             : "No response yet";
  const short_msg_style = last_msg ? '' : 'italic';
  const time = last_msg ? minitime(last_msg.date) : '';
  const [w, h] = oprofile.snapshot_ar <= 1
                 ? [ `${oprofile.snapshot_ar * 4}rem`, '4rem' ]
                 : [ '4rem', `${4 / oprofile.snapshot_ar}rem` ];
  return (
    <button className={`flex flex-row w-full mx-0 rounded-xl
                        justify-start
                        my-2
                        ${ oprofile.msg_avail ? "border-2 border-orange"
                                              : "border" }`}
         data-cy={`minicard${oprofile.id}`}
         onClick={() => changePage('convos', oprofile.id)}>
      <div className="grid grid-cols-12 gap-x-4 mx-2 my-2">
        <div className="col-span-3 -ml-1 pr-2 flex flex-row
                        items-center justify-start"
                        style={{ width: "4rem" }}>
          <img src={oprofile.snapshot_url} alt={`face of ${oprofile.username}`}
               style={{ width: w, height: h }}
               className="rounded-xl"/>
        </div>
        <div className="col-span-9">
          <div className="grid grid-cols-1 mt-2 gap-2">
            <div className="flex flex-row justify-start items-center">
              <div className="text-sm font-semibold text-gray-text">
                {oprofile.username}
              </div>
              <div className="text-xs font-normal text-gray-text ml-2">
                { `${calculate_age(oprofile.birthday)}y` }
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className={`text-sm font-normal text-gray-text
                               ${short_msg_style}`}>
                { short_msg }
              </div>
              <div className="text-sm font-normal text-center text-gray-para">
                { time }
              </div>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

export { UserMinicard };
