import React, { useRef } from 'react';
import { useStore } from 'react-redux';
import axios from 'axios';

import { db_query } from '../../App';
import { fbDateTime } from '../../dbstructure';

import { CancelIcon } from '../../assets/icons/cancel';
import { RadioButtonGridSw } from '../widgets/RadioButton';
import { TextareaSw } from '../widgets/TextWidget';
import { LIVE_MODE, config$text_limits } from '../../config';

const ReportUserOverlay = ({ buttons, showOverlay, setShowOverlay }) => {
  // pop up report summary when done
  //const [rptsummary, setRptsummary] = useState('');

  const str = useStore();
  const oprofile = showOverlay[0];

  const report_loc = useRef(),
        report_tag = useRef(),
        report_text = useRef();

  // const show_report = () => {
  //   const loc = report_loc.current.getAttribute('value');
  //   const tag = report_tag.current.getAttribute('value');
  //   const text = report_text.current.getAttribute('value');
  //
  //   const s = (
  //         `You are reporting user ${oprofile.username}
  //          for ${tag}
  //          in their ${loc}.
  //          You added: "${text}"`
  //   );
  //   setRptsummary(s);
  // };

  const send_report = () => {
    // TODO: send mail
    // no need to wait
    const date = fbDateTime();
    const profile = str.getState().profile;
    db_query.add('userreport', {
      reporter: profile.id,
      subject: oprofile.id,
      date: date, //fbDateTime(),
      complaint: report_tag.current.getAttribute('value'),
      location: report_loc.current.getAttribute('value'),
      comment: report_text.current.getAttribute('value'),
    });
    // buttons.blacklist(oprofile) is normally associated with
    // a button; that's why we need to apply to ().
    buttons.blacklist(oprofile)();
    const mailparams = {
      type: 'user_report',
      from: profile.email,
      date: date.toLocaleString(), // readable form in email
      subject: 'User report',
      text: `User ${profile.id} reporting user ${oprofile.id}`,
      id: profile.id,
      username: profile.username,
    };
    if (LIVE_MODE) {
      axios.get("https://us-central1-laughstruckproto.cloudfunctions.net/sendMail",
                { params: mailparams })
           .then(res => console.log("Successful user report:", res))
           .catch(err => console.log("User report error:", err));
    }
  };

  return (
    <div className="flex my-8 content-center justify-center">
      <div className="relative flex flex-col bg-white rounded-lg
                      space-y-6
                      w-1/2 lg:w-5/8 md:w-3/4 sm:w-11/12"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <button className="absolute top-0 right-0 mt-4 mr-4"
             onClick={() => setShowOverlay(null)}>
          <CancelIcon />
        </button>
        <div className="mx-12 text-2xl font-bold text-gray-text text-center mt-6">
          Report User
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
          We're sorry you're having problems with this user.
          Once you submit this form,
          they will no longer be able to see or contact you.
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
          To help us identify the objectionable content and take
          appropriate action, can you answer a couple of questions?
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-6">
          Where did you find the objectionable content from
          this user?
        </div>
        <RadioButtonGridSw the_ref={report_loc}
                           cols={3}
                           cy_prefix="report-loc"
                           choices={['Username', 'Message to you', 'Photo',
                                     'Profile', 'Other field']} />
        <div className="mx-6 text-sm font-normal text-gray-text mt-6">
          It is objectionable due to:
        </div>
        <RadioButtonGridSw the_ref={report_tag}
                           cols={2}
                           cy_prefix="report-tag"
                           choices={['Harassment or personal attack',
                                     'Bigotry (Racism, sexism, etc.)',
                                     'Lewd or profane content',
                                     'Other']} />
        <div>
          <div className="mx-6 text-sm font-normal text-gray-text mb-4">
            Add any comment that will help us better understand the issue:
          </div>
          <TextareaSw the_ref={report_text}
                      margin="mx-4"
                      label=""
                      rows={3}
                      maxchars={config$text_limits.report_user}
                      prompt={`Limit of ${config$text_limits.report_user} characters`}
                      cy_name="report-text" />
        </div>
        <div>
          <div className="flex flex-col items-center mr-4">
            <button>
              <div className="flex flex-col bg-orange rounded-lg mt-8 sm:mt-12
                              w-28 h-10 content-center"
                   data-cy="send_report"
                   onClick={() => {
                              setShowOverlay(null);
                              send_report();
                            }}>
                <div className="font-semibold text-white text-sm text-center mt-2">
                  Report
                </div>
              </div>
            </button>
            <button className="items-start text-sm font-normal text-gray-para
                            mt-2 hover:underline"
                 onClick={() => setShowOverlay(null)}>
              Cancel
            </button>
          </div>
        </div>
        <div className="h-4" />
      </div>
    </div>
  );
}

export { ReportUserOverlay };
