import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { navigate } from '@reach/router';
import { get_subobj, split } from './utils';
import { db_query } from './App';
import { locate_other, fetch_other, last_msg_from }
 from './components/pages/utils';
import { other_remove_action, other_update_action, others_add_action,
         blacklist_add_action,
         jokes_reset_action,
         profile_update_action,
         profile_not_add_action,
         user_messages_reset_action,
       } from './store';
import { sort_jokes } from './components/Main';
import { fbDateTime, thread_id } from './dbstructure';
import { affinity } from './matching';

// listen to changes in private profile, public profile, and jokes:
//   private profile: can be changed in separate app instance, and
//                    (more commonly) changed by other users by poking, etc.
//   public profile: can only be changed by us in separate app instance
//   jokes: these may be updated periodically
// we do not listen for changes in Messages. new messages are signalled
// by change in 'msg_avail' (and related fields) in private profile;
// messages are loaded by app on demand, never pushed

// private profile
//
// these changes can be made by other users (they can blacklist,
// poke, send messages (i.e. update thread)), or by us on a
// different device
//
// docref is the new doc (private profile of this user) in the db;
// we need to determine what has changed by comparing to what is in
// store; keep in mind that the store is not an identical copy of the
// db (that is, it is structured differently; info should be the same)
//
// we assume that there can be multiple changes, although in practice
// there will usually (always?) be just one change at a time
//
// respond to most db changes just by changing store. one exception:
// if change represents another user liking us, it may be the first
// first time we have any connection: download user's profile
//
// another interesting case is when the change is setting the 'msg_avail'
// field in a profile. but there's nothing special to do here: we always
// refresh the page after getting the new data; if the msg_avail bit is
// newly set and we're actually looking at that user's messages, the
// page will download the new messages
//
// TODO: there is supposedly a way to just see doc changes rather than the
//       whole new doc, but I haven't figured out how it works.

function refresh() {
  navigate(window.location.href, { replace: true });
}

function onProfileUpdate(dis, str) {
  return docref => {
    const id = str.getState().profile.id;
    // if change was made on this device, nothing to do
    // (when debugging, all updates are obv local; external_update
    // set by test code says to treat this as external anyway)
    if (db_query.just_subscribed('PrivateProfiles', id)) {
      return;
    }
    if (docref.metadata.hasPendingWrites) {
      return;
    }
    // two stages: (1) go through doc and see where changes were
    // made, do as many as possible. (in future, use 'onChange' for
    // greater efficiency) (2) in some cases, need to download
    // other users' public profs
    const doc = docref.data();
    const profile = str.getState().profile;
    const others = str.getState().others;
    const db_others = doc.others;
    // separate out bl's. (we also including users bl'd in store
    // but not in db; that shouldn't actually ever happen)
    const [ bl, dbids ] = split(Object.keys(db_others),
                                oid => db_others[oid].blacklist
                                       || db_others[oid].blacklisted_by
                                       || profile.blacklist[oid]);

    // first handle simple fields
    // these are the only simple fields that can be changed
    const fields = ['email',
                    'last_profedit_time',
                    'last_matchcalc_time',
                    'obscene_okay',
                    'signup_date'];
    dis(profile_update_action(get_subobj(doc, fields)));

    // blacklist - add to local blacklist, remove other prof if present
    // (note that this works whether it is us blacklisting other user
    // on another device or other user blacklisting us)
    // possible (remotely) that we already have this person blacklisted
    // but they are adding a 'blacklisted_by'; to maintain consistency
    // with db, we modify our own bl record
    // NB: does not allow blacklist entries to go from true to false -
    //     i.e. only adding bl, not removing it.
    for (var oid of bl) {
      dis(blacklist_add_action({ id: oid, ...db_others[oid] }));
      if (locate_other(oid, others)) {
        dis(other_remove_action({ id: oid }));
        db_query.unsubscribe('Messages', thread_id(id, oid));
      }
    }

    let new_pokes = [];
    for (let oid of dbids) {
      const c_old = fetch_other(oid, others) || { pokedby: false },
            c_new = db_others[oid];
      if (c_new.pokedby && ! c_old.pokedby) {
        new_pokes.push(oid);
      }
    }

    // next, distinguish between existing connections and new ones
    const [ olds, news ] = split(dbids,
                                 id => Boolean(locate_other(id, others)));
    // existing connection: just update state
    for (let oid of olds) {
      const o = fetch_other(oid, others);
      if (! o) continue; // impossible
      dis(other_update_action({ id: oid, ...db_others[oid] }));
      if (db_others[oid].thread) {
        // subscribe ignores if already subscribed
        const tid = thread_id(id, oid);
        db_query.subscribe('Messages', tid, onMessagesUpdate(dis, str));
      }
    }

    const complete_profile = p => {
      const oprof = { ...p, ...db_others[p.id] };
      return { ...oprof, affinity: affinity(profile, oprof) };
    }

    const poke_notify = oid => {
      const now = fbDateTime();
      const name = fetch_other(oid, str.getState().others).username;
      dis(profile_not_add_action(
            { id: `${oid}-${now}`,
              date: now,
              cat: 'newpoke',
              text: `You have a new 'like' from ${name}`
            }));
    }

    // after changes, refresh page. may not be necessary, but it's hard
    // to determine the conditions, and relatively harmless to refresh
    if (news.length > 0) {
      db_query.get('profiles', { ids: news })
      .then(ps => {
        const newprofs = Object.fromEntries(
                           ps.filter(p => p.active)
                             .map(p => [p.id, complete_profile(p)]));
        dis(others_add_action(newprofs));
        refresh();
        new_pokes.forEach(oid => poke_notify(oid));
      })
    } else {
      refresh();
      new_pokes.forEach(oid => poke_notify(oid));
    }

  }
}

// unlike private profiles, other users cannot do anything that causes
// a change in public profile. we listen on pub profile only in case
// this user makes changes on another device. in that case,
// refresh refreshes from db. (will be a problem if user is on
// matches page, but oh well)
function onPubProfileUpdate(dis, str) {
  return docref => {
    const id = str.getState().profile.id;
    if (db_query.just_subscribed('PublicProfiles', id)) {
      return;
    }
    // if change was made on this device, nothing to do
    if (docref.metadata.hasPendingWrites) {
      return;
    }
    refresh();
  }
}

// since users retain jokes locally, need to update when new
// jokes are added or existing jokes change (e.g. joke is marked obscene,
// go inactive)
// should be able to rely on just this to initialize and maintain joke
// list, but it's complicated: if we subscribe on app start-up, then we
// can't re-subscribe when users signs in, which is when we have to
// divide jokes into voted/not voted.
function onJokesUpdate(dis, str) {
  return docref => {
    if (db_query.just_subscribed('Jokes', 'jokes')) {
      return;
    }
    // change to jokes def wasn't made on this device, but leaving
    // this because it was made on *some* device and, for all I know,
    // that device might be running this app
    if (docref.metadata.hasPendingWrites) {
      return;
    }
    const jokes = Object.values(docref.data()).filter(j => j.active);
    const votes = str.getState().profile.votes;
    const [voted, unvoted] = [[], []];
    jokes.forEach(j => (votes[j.id] ? voted : unvoted).push(j));
    dis(jokes_reset_action({ unvoted: sort_jokes(unvoted),
                             voted: voted }));
    refresh();
  }
}

// unlike other listeners, onMessagesUpdate ignores "just_subscribed"
// it resets messages whenever called.
function onMessagesUpdate(dis, str) {
  return docref => {
    const id = str.getState().profile.id;
    let msgs = docref.data().messages;
    if (msgs.length === 0) { // can't happen
      return;
    }
    const oid = msgs[0].user === id ? msgs[0].other_user
                                    : msgs[0].user;
    const oprofile = fetch_other(oid, str.getState().others);
    if (oprofile.messages && msgs.length === oprofile.messages.length) {
      // msgs can only grow, so no change in length = no change
      // this happens on start-up: existing messages are read first
      // (to be sure they're all read before we start rendering pages)
      // then we subscribe and onMessagesUpdate is called and we
      // see the same set of messages
      // also happens when we send a first message
      // (would be harmless to reset messages, but we want to
      // avoid posting notification in that case)
      return;
    }

    // reset message list
    dis(user_messages_reset_action({ other_user: oid, msgs: msgs }));

    // if there is a message from other user, and it's new, set
    // msg_avail and add notification. do this only if not currently
    // viewing messages from that user. (if we are, the new message
    // will show up immediately; no need for notification)
    // (it is possible that messages have been added but there is no
    // new message from other user: the new message might be from us)
    if (! window.location.pathname.includes(`convos/${oid}`)) {
      const last_msg = last_msg_from(msgs, oid);
      if (last_msg) {
        // have a message from oid
        const last_read = str.getState().others.threads[oid].last_read;
        // ! last_read if user has never read oid's messages before
        if (! last_read || last_read < last_msg.date) {
          // the message is new
          dis(other_update_action({ id: oid, msg_avail: true,
                                    latest_msg: last_msg.date }));
          const now = fbDateTime();
          dis(profile_not_add_action(
                { id: `${oid}-${now}`,
                  date: now,
                  cat: 'newmsg',
                  text: `You have a new message from ${oprofile.username}`
                }));
        }
      }
    }
    refresh();
  }
}

export { onProfileUpdate, onPubProfileUpdate, onJokesUpdate,
         onMessagesUpdate };
