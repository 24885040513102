import React from 'react';

const SendIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24.191" height="23.724" viewBox="0 0 24.191 23.724"
       style={{ height: "1.3rem", width: "1.3rem" }}>
    <title>Send message</title>
    <g transform="translate(0 -4.688)">
      <g transform="translate(0 4.688)">
        <g transform="translate(0 0)">
          <path style={{ fill: "#ff4f23" }}
                d="M22.9,14.254,3.662,4.944A2.55,2.55,0,0,0,.183,8.187L3.188,15.7h21A2.529,2.529,0,0,0,22.9,14.254Z"      transform="translate(0 -4.688)"/>
        </g>
      </g>
      <g transform="translate(0.001 17.399)">
        <g transform="translate(0 0)">
          <path style={{ fill: "#ff4f23" }}
                d="M3.219,259.9.213,267.417a2.551,2.551,0,0,0,3.479,3.245l19.239-9.313a2.53,2.53,0,0,0,1.289-1.445Z"      transform="translate(-0.032 -259.904)"/>
        </g>
      </g>
    </g>
  </svg>

export { SendIcon };
