import { get_subobj, objectify_array, rm_attr } from './utils';
import { GoodPromise, BadPromise, merge } from './utils';
import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { DB_ERR, } from './enums';
import { pvtfields, pubfields } from './dbstructure';
import { auth } from './App';
import { LOCAL_MODE } from './config';

function fbDateTime() { return new Date().toISOString(); }

/******************************************
* OTHER-USER PROFILES in store (str.getState().others.{pokes, ...}
* every user that is associated with this user in the sense of
* having a thread, or being listed on matches page, or being poked
* by this user or vice versa, is included here. no blacklisted
* users are included. all users included here are displayed on one
* of the pages matches, connects, or convos, with one exception:
* users poked by this user (but who have not poked them) do not
* appear anywhere.
*
* these profiles consist of public profile plus some additional fields:
*   affinity: 3-int array; [humor, joke_count, humor+] (details in matching.js)
*   match: boolean - this user is a potential match
*   pokes: boolean - this user has poked other user
*   pokedby: boolean - this user has been poked by other user
*   thread: boolean - this user is in a convo with other user
*   these field are present if thread is true:
*     msg_avail: boolean - unread msg from other user;
*                inv: msg_avail = last_read < latest_message
*     latest_message: time - date of latest message from other user
*                (absent => other user has not sent any messages)
*     last_read: time - last time user read msgs from other user
*                (absent => user has never read this thread)
* because these fields are present, 'threads' and 'pokes' fields are not
* needed in user's profile (though they are in db in user's private profile).
* 'votes' field is retained because it is used to calculate affinity
******************************************/

/******************************************
*  LOCAL MOCK (for debugging)
*
*  database is really just map from collection names to collections (objects)
*  collection names have the form {<coll-name>.<doc-name>}*.<coll-name>.
*    get(coll) returns that collection
*    put(coll, nm, obj) add document 'obj' with name 'nm' to collection coll
*    collection(nm) returns an FSCollection object for collection with that name
*    uid just returns an id not yet used in this db
*
*  PublicProfiles and PrivateProfiles are top-level collections.
*  Both are keyed on user id.
*  PublicProfiles contain values that are shown to other users
*  PrivateProfiles are either administrative (e.g. email), or prefs (used
*  to calculate matches but not shown to others)
*  We  include id as a field just for convenience, although it is redundant
*  TODO: add structure to these lists, e.g. put prefs in 'prefs' object; do
*        this after switching completely to fsdb
******************************************/

// there should be no fields whose value is 'undefined'
function check4undef (o) {
  for (var [k,v] of Object.entries(o)) {
    if (v === undefined) {
      throw new Error(k);
    } else if (v && typeof(v) === 'object') {
      check4undef(v);
    }
  }
}

class SnapshotMetadata {
  constructor(m) {
    this.m = m
  }

  // infer this is a local write if it is a write to our own profile
  get hasPendingWrites() {
    if (global.external_update) return false;
    let id = global.the_store().profile.id;
    return this.m && this.m === id;
  }
}

class Snapshot {
  constructor (v, i) {
    this.val = v;
    this.data = this.data.bind(this);
    this.md = new SnapshotMetadata(v ? v.id : v);
    this.i = i;
  }

  data() { return this.val; }
  get metadata() { return this.md; }
  get exists() { return this.val !== undefined; }
  get id() { return this.i; }
}

class Transaction {
  constructor() {
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.update = this.update.bind(this);
  }

  get(d) {
    return d.get();
  }

  update(d, v) {
    return d.update(v);
  }

  set(d, v) {
    return d.set(v);
  }
}

class FieldValueClass {
  arrayUnion(v) { return { arrayUnion: v };}
  delete(v) { return { delete: true };}
  increment(n) { return { increment: n };}
}

class FSDatabase {
  constructor(db) {
    if (db) {
      // if defined, db = {PrivateProfiles: ..., PublicProfiles: ..., ...}
      this.db = db;
      this.db.listeners = {};
      // this.db.passwords = {};
    } else {
      this.db = { listeners: {}, passwords: {} };
    }

    this._get = this._get.bind(this);
    this._put = this._put.bind(this);
    this._delete = this._delete.bind(this);
    this.collection = this.collection.bind(this);
    this._reset = this._reset.bind(this);
    this.add_listener = this.add_listener.bind(this);
    this.get_listener = this.get_listener.bind(this);
    this.add_password = this.add_password.bind(this);
    this.get_password = this.get_password.bind(this);
  }

  get FieldValue() {
    return new FieldValueClass();
    // function u(v) { return ({ arrayUnion: v })}
    // function d() { return ({ delete: true })}
    // return ({ arrayUnion: u,
    //           delete: d });
  }

  // _get, _put, _delete are internal methods, called only from FSCollection
  _get(coll) {
    const copy = o => JSON.parse(JSON.stringify(o));

    let path = coll.get_name();
    if (! this.db[path]) {
      this.db[path] = {};
    }
    return copy(this.db[path]);
  }

  add_listener(path, f) {
    this.db.listeners[path] = true;
  }
  remove_listener(path) {
    this.db.listeners[path] = null;
  }
  get_listener(path) {
    return this.db.listeners[path];
  }
  add_password(user, pwd) {
    this.db.passwords[user] = pwd;
  }
  change_password(user, pwd) {
    this.db.passwords[user] = pwd;
  }
  get_password(user) {
    return this.db.passwords[user];
  }
  rm_password(user) {
    delete this.db.passwords[user];
  }

  _put(coll, nm, obj, options) {
    function recursive_merge(base, nm, obj) {
      for (var [k,v] of Object.entries(obj)) {
        if (typeof(v) === 'object') {
          if (! base[nm][k]) {
            base[nm][k] = {};
          }
          recursive_merge(base[nm], k, v);
        } else {
          base[nm][k] = v;
        }
      }
    }

    let full_name = coll.get_name();
    if (this.db[full_name] && this.db[full_name][nm]
        && options && options.merge) {
      // merge is interesting only if collection and doc exist
      // - ow it's just setting doc to obj
      recursive_merge(this.db[full_name], nm, obj);
    } else if (this.db[full_name]) {
      this.db[full_name][nm] = obj;
    } else {
      this.db[full_name] = { [nm]: obj };
    }

    check4undef(this.db)
  }

  _delete(coll, nm) {
    let full_name = coll.get_name();
    let c = this.db[full_name];
    if (! c) {
      return;
    }
    let { [nm]: _, ...rest } = c;
    this.db[full_name] = rest;
  }

  collection(nm) {
    return new FSCollection(nm, null, this);
  }

  // debugging - allow db to be altered in flight
  _reset(db) {
    this.db = db;
  }

  runTransaction(f) {
    return f(new Transaction());
  }
}

// FSCollection and FSDocument represent *handles* for collections
// and documents. Actual values are contained the database.

// An FSCollection is a collection name as defined above,
// together with the db containing it.
//   ctor takes an existing doc - basically a name of the form
//     {<coll-name>.<doc-name>}+ - and adds the collection name;
//     |doc| may be null, indicating this is a top-level collection.
//   get_name and get_db are gettors
//   doc(nm) create a 'handle' of a document in this collection
//   add(j, nm) adds a document, with name |nm| and object |j|;
//     if nm is omitted, a unique name is created
//   get() returns the entire collection as an object
//   new_collection(doc, nm) creates an FSCollection with name |nm|
//     in the given doc. Inv: doc's underlying collection is this.
class Query {
  constructor(q) {
    this.q = q;
    this.get = this.get.bind(this);
    this.where = this.where.bind(this);
   }

   get () {
     return GoodPromise(new QuerySnapshot(this.q));
   }

   where(fld, optr, val) {
     const optrfuns = {
       '==': (x,y) => x === y,
       '>=': (x,y) => x >= y,
       '>': (x,y) => x > y,
       '<=': (x,y) => x <= y,
       '<': (x,y) => x < y,
       '!=': (x,y) => x !== y,
       'in': (x,y) => y.includes(x),
       'array-contains': (x,y) => x.includes(y),
     }
     const docs = this.q.map(v => v.val);
     const vals = docs.filter(d => optrfuns[optr](d[fld], val))
                      .map(v => new Snapshot(v, v.id));
     return new Query(vals);
   }
}

class QuerySnapshot {
  // vals is array of Snapshot
  constructor(vals) {
    this.vals = vals;
    this.size = this.vals.length;
    this.forEach = this.forEach.bind(this);
    this.md = new SnapshotMetadata(vals);
  }
  forEach(f) { this.vals.forEach(f); }
  get docs() { return this.vals; }
  get metadata() { return this.md; }
}

class FSCollection {
  constructor(nm, doc, db) {
    this.name = doc ? `${doc.get_path()}.${nm}` : nm;
    this.db = db;

    this.get_name = this.get_name.bind(this);
    this.doc = this.doc.bind(this);
    this._add = this._add.bind(this);
    this._get = this._get.bind(this);
    this.get = this.get.bind(this);
    this.new_collection = this.new_collection.bind(this);
    // this.onSnapshot = this.onSnapshot.bind(this);
    // this.get_snapshot = this.get_snapshot.bind(this);
    // this.listen = this.listen.bind(this);
  }

  get_name() { return this.name; }
  get_db() { return this.db; }

  _add(j, nm, options) {
    let n = nm || this.db.uid();
    this.db._put(this, n, j, options);
  }
  _get() { return this.db._get(this); }

  doc(nm) {
    return new FSDocument(this, nm);
  }

  new_collection(doc, nm) {
    return new FSCollection(nm, doc, this.db);
  }

  get() {
    let vals = Object.values(this._get());
    return GoodPromise(
             new QuerySnapshot(
               vals.map(v => new Snapshot(v, v.id))
             ));
  }

  where(fld, optr, val) {
    const optrfuns = {
      '==': (x,y) => x === y,
      '>=': (x,y) => x >= y,
      '>': (x,y) => x > y,
      '<=': (x,y) => x <= y,
      '<': (x,y) => x < y,
      '!=': (x,y) => x !== y,
      'in': (x,y) => y.includes(x),
      'array-contains': (x,y) => x.includes(y),
    }
    const docs = Object.values(this.db._get(this));
    const vals = docs.filter(d => optrfuns[optr](d[fld], val))
                     .map(v => new Snapshot(v));
    return new Query(vals);
  }

  // onSnapshot(f) {
  //   this.db.add_listener(this.name, f);
  //   return (f => this.db.remove_listener(this.name));
  // }
  //
  // get_snapshot() {
  //   // const c = Object.values(this._get())
  //   //           .map(v => new Snapshot(v));
  //   // return new QuerySnapshot(c);
  //   const c = Object.entries(this._get())
  //             .map(([id, v]) => new Snapshot(v, id));
  //   return new QuerySnapshot(c);
  // }
  //
  // listen() {
  //   const l = this.db.get_listener(this.name);
  //   if (l) {
  //     const c = this._get();
  //     if (c) {
  //       if (this.name.includes('Messages')) {
  //         global.onmessagesupdate(this.get_snapshot());
  //       }
  //     }
  //   }
  // }

}

// An FSDocument is basically a document name, which has the
// form {<coll-name>.<doc-name>}+ (very similar to collection name),
// but structured as a collection and a name.
//   get_path() returns the full name as a string
//   get() return the value (object) of this object
//   set(j) associated object j with this document
//   collection(nm) returns an FSCollection that will be a subcollection
//     of this document.
//   update(j) modifies doc by updating with object j
class FSDocument {
  constructor(coll, nm) {
    this.coll = coll;
    this.doc = nm;
    this.path = `${coll.get_name()}.${nm}`;

    this.get_path = this.get_path.bind(this);
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.update = this.update.bind(this);
    this.onSnapshot = this.onSnapshot.bind(this);
    this.listen = this.listen.bind(this);
    this.collection = this.collection.bind(this);
    this.delete = this.delete.bind(this);
  }

  onSnapshot(f) {
    this.coll.db.add_listener(this.path, f);
    return (f => this.coll.db.remove_listener(this.path));
  }

  get_snapshot() {
    const c = this.coll._get()[this.doc];
    return new Snapshot(c);
  }

  listen() {
    const l = this.coll.db.get_listener(this.path);
    if (l) {
      const c = this.coll._get()[this.doc];
      if (c) {
        // see main for explanation. bottom line: I cannot get
        // the stored listener function to work here, I don't know why,
        // so instead just want to call onProfileUpdate (which is what
        // the callback would be anyway). but, calling onProfileUpdate
        // here causes cypress to have one of its fits; workaround is
        // to set a window variable to onProfileUpdate (see Main/Main)
        if (this.path.includes('Jokes')) {
          global.onjokesupdate(new Snapshot(c));
        } else if (this.path.includes('Public')) {
          global.onpubprofileupdate(new Snapshot(c));
        } else if (this.path.includes('Private')) {
          global.onprofileupdate(new Snapshot(c));
        } else if (this.path.includes('Messages')) {
          global.onmessagesupdate(new Snapshot(c));
        }
      }
    }
  }

  delete() {
    return GoodPromise(this.coll.db._delete(this.coll, this.doc));
  }

  get_path() { return this.path; }
  get() {
    const c = this.coll._get();
    return GoodPromise(new Snapshot(c[this.doc]));
  }

  set(j, options) {
    this.coll._add(j, this.doc, options);
    this.listen();
    return GoodPromise();
  }

  collection(nm) {
    return this.coll.new_collection(this, nm);
  }

  // there is a two-argument version of update - update(keys, value) is,
  // I think, the same as update({keys: value})) - which we do not provide
  update(j) {
    const d = this.coll._get()[this.doc];
    if (! d) {
      return BadPromise(new Error([ DB_ERR.DOES_NOT_EXIST, this.path ]));
    } else {
      for (var a of Object.entries(j)) {
        let c = d;
        const is_delete = a[1].delete;
        const keys = a[0].split('.');
        for (var i = 0; i < keys.length - 1; ++i) {
          if (! c[keys[i]]) {
            if (is_delete) {
              break;
            } else {
              c[keys[i]] = {};
            }
          }
          c = c[keys[i]];
        }
        const k = keys[keys.length - 1];
        if (a[1].arrayUnion) {
          c[k] = c[k].concat(a[1].arrayUnion);
        } else if (a[1].increment) {
          c[k] = c[k] + a[1].increment;
        } else if (is_delete && c[k]) {
          delete c[k];
        } else {
          c[k] = a[1];
        }
      }
      try {
        this.set(d);
      }
      catch (err) {
        consolelog('fsdb485, null error: ', err, j);
        throw new Error(`Error in update, field ${err} undefined in ${j}`);
      }
      return GoodPromise();
    }
  }
}

class EmailAuthPr {
  credential(m, p) {
    var x = { email: m, password: p };
    return x;
  }
}

// let pwds = {}
class MyAuth {
  constructor() {
    this.user = null;
    this.signInWithEmailAndPassword
      = this.signInWithEmailAndPassword.bind(this);
  }
  // debugging only
  _restoreSignin(u) {
    this.user = new FSUser(u);
  }
  signInWithEmailAndPassword(m, p) {
    if (m === 'kamin@illinois.edu'
        || m === 'julia.kamin@gmail.com') {
      this.user = new FSUser({ email: m, password: p,
                               uid: `uid-${m}` });
      return GoodPromise();
    }
    if (global.fsdb.get_password(m) === p) {
      this.user = new FSUser({ email: m, password: p,
                               uid: `uid-${m}` });
      return GoodPromise();
    }
    else {
      return ! global.fsdb.get_password(m)
             ? BadPromise({ code: 'auth/user-not-found' })
             : BadPromise({ code: 'auth/wrong-password' });
    }
  }
  signInAnonymously() {
    this.user = new FSUser({ uid: 'uid-anon' });
    return GoodPromise({});
  }
  setPersistence(x) {
    return GoodPromise();
  }
  sendPasswordResetEmail(m) {
    alert(`Debug message from fsdb sim: Sending password reset message to ${m}`);
    return GoodPromise();
  }
  get currentUser() {
    return this.user;
  }
  get EmailAuthProvider() {
    return new EmailAuthPr();
  }
  set currentUser(u) {
    this.user = u;
  }
  signOut() {
    this.user = null;
    return GoodPromise();
  }
}

class FSUser {
  constructor(cred) {
    this.creationTime = fbDateTime();
    this.profile = cred || {};
  }
  updatePassword(p) {
    if (p.length < 2) {
      return BadPromise({ code: 'auth/weak-password' });
    }
    this.profile.password = p;
    global.fsdb.change_password(this.profile.email, p);
    return GoodPromise();
  }
  updateEmail(m) {
    // check if email already in use
    const pw = global.fsdb.get_password(m);
    if (pw) {
      return BadPromise({ code: 'auth/email-already-in-use' });
    } else {
      // change entry in pw table from oldemail -> pw to m -> pw
      const oldpw = global.fsdb.get_password(this.profile.email);
      global.fsdb.rm_password(this.profile.email);
      this.profile.email = m;
      global.fsdb.add_password(m, oldpw);
      return GoodPromise();
    }
  }
  linkWithCredential(cred) {
    if (global.fsdb.get_password(cred.email)) {
      return BadPromise({ code: 'auth/email-already-in-use' });
    }
    global.fsdb.add_password(cred.email, cred.password);
    this.profile = cred;
    return GoodPromise();
  }
  reauthenticateWithCredential(cred) {
    if (global.fsdb.get_password(cred.email) === cred.password) {
      return GoodPromise();
    } else {
      return BadPromise({ code: "auth/wrong-password" });
    }
  }
  get uid() {
    return this.profile.uid;
  }
  get email() {
    return this.profile.email;
  }
  updateProfile(o) {
    this.profile = { ...this.profile, ...o };
  }
  get metadata() {
    return { creationTime: this.creationTime };
  }
  get isAnonymous() {
    return ! this.profile.email;
  }
}

// add users a@email.com, ..., t@email.com
// only used in FS_EMUL_MODE
function init_users() {
  return Promise.all(
      [...Array(20).keys()]
       .map(id => {
         const charcode = 'a'.charCodeAt(0);
         const c = String.fromCharCode(charcode + id);
         const email = `${c}@email.com`;
         const password = `${c}pw${c}pw`;
         return auth.createUserWithEmailAndPassword(email, password)
                    .catch(() => null);
        })
    );
}

// usedonly in emulator mode
function clear_db(fsdb) {
  const deletedocs = collname => {
    const coll = fsdb.collection(collname);
    return (
      coll.get()
          .then(queryss =>
                  Promise.allSettled(
                    queryss.docs.map(dss => dss.ref.delete())
                  )
               )
    );
  }

  const collnames = [
    'PublicProfiles', 'PrivateProfiles', 'Jokes',
    'JokeStats', 'Messages', 'NextId', 'Admin_logs'
  ];
  return Promise.all(
    collnames.map(nm => deletedocs(nm))
  )
}

// given db (in old style - output from create_test_db), reconstruct
// data into new fsdb
function db2fsdb(db, fsdb) {
  const tbls = db.db;
  let pubprofs = fsdb.collection('PublicProfiles'),
      pvtprofs = fsdb.collection('PrivateProfiles'),
      jokes = fsdb.collection('Jokes'),
      jokestats = fsdb.collection('JokeStats'),
      messages = fsdb.collection('Messages'),
      nextid = fsdb.collection("NextId"),
      Admin_logs = fsdb.collection('Admin_logs');
      // Admin_userreports = fsdb.collection('Admin_userreports'),
      // Admin_prospective = fdb2fsdb.collection('Admin_prospective');
  let promises = [];
  for (var k of Object.keys(tbls.message_threads)) {
    if (tbls.message_threads[k].last_read === "")
      delete tbls.message_threads[k].last_read;
    if (tbls.message_threads[k].latest_message === "")
      delete tbls.message_threads[k].latest_message;
  }
  Object.values(tbls.users)
        .forEach(u => {
               let doc = get_subobj(u, pvtfields);
               let threads = Object.fromEntries(
                               db.get('message_threads', { user: u.id }).data
                                 .map(t => t.last_read === ""
                                           ? rm_attr('last_read', t)
                                           : t)
                                 .map(t => [t.other_user,
                                            { thread: true,
                                              ...get_subobj(t, ['last_read']) }]));
               let blacklist = Object.fromEntries(
                                  db.get('blacklists', { user: u.id }).data
                                    .map(b => [b.other_user,
                                               { blacklist: b.blacklist,
                                                 blacklisted_by: b.blacklisted_by}]));
               const pokes = db.get('pokes', { user: u.id }).data;
               const pokes0 = Object.fromEntries(pokes[0].map(
                                       p => [p.other_user,
                                            { pokes: true, poketime: p.poketime }]));
               const pokes1 = Object.fromEntries(pokes[1].map(
                                       p => [p.user,
                                            { pokedby: true, poketime: p.poketime }]));
               doc.others = merge(threads, blacklist, pokes0, pokes1);
               if (LOCAL_MODE) {
                 fsdb.add_password(u.email, u.password);
               }
               delete doc.password;
               doc.notifications = {};
               const p = pvtprofs.doc(u.id);
               promises.push(p.set(doc));
             });
  Object.values(tbls.users)
        .forEach(u => {
               let doc = get_subobj(u, pubfields);
               doc.votes = {};
               db.get('votes_of', { user: u.id })
                 .data
                 .forEach(v => {
                   doc.votes[v.joke] = get_subobj(v,
                                          ['vote', 'flag', 'flag_comment']);
                  });
               promises.push(pubprofs.doc(u.id).set(doc));
             });
  const maxid = Math.max(...Object.keys(tbls.users).map(s => parseInt(s)));
  promises.push(nextid.doc('nextid1').set({ nextid: maxid + 1 }));
  // adjust jokes: remove stats fields
  const cleaned_jokes = Object.fromEntries(
                          Object.entries(tbls.jokes)
                                .map(([i,j]) => { let { count, mean, stdev, ...rest} = j;
                                                  rest.comments = '';
                                                  return [i, rest];
                                                }));
  promises.push(jokes.doc('jokes').set(cleaned_jokes));
  Object.entries(tbls.jokes)
        .forEach(([id,j]) => promises.push(jokestats.doc(id)
                                                .set({ id: id + '',
                                                       count: 0,
                                                       votes: { 1: 0, 2: 0, 3: 0,
                                                                4: 0, 5: 0},
                                                       reports: [] })));
  const msgs = objectify_array(tbls.messages, 'thread_id');
  Object.keys(msgs)
        .forEach(k => promises.push(messages.doc(k).set({ messages: msgs[k] })));
  promises.push(Admin_logs.doc('current').set({ '1/1/20': [0, 0] }));
  return Promise.all(promises);
}


export { FSDatabase, FSCollection, FSDocument, MyAuth,
         db2fsdb, init_users, clear_db };
