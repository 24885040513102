import React from 'react';

// used in user cards next to humor score
const HumorfaceIcon = () =>
  // <div className="mt-1.5">
  //   <img alt="humor score" src="images/humor.svg" />
  // </div>;
<svg xmlns="http://www.w3.org/2000/svg"
     width="16.078" height="16.078" viewBox="0 0 16.078 16.078">
     className="mt-1.5">
  <title>Humor score</title>
  <path style={{ fill: "#ff9c83" }}
        d="M16.078,8.039A8.039,8.039,0,1,1,8.039,0,8.039,8.039,0,0,1,16.078,8.039Zm0,0"/>
  <path style={{ fill: "#ff4f23" }}
        d="M264.039,8.039A8.039,8.039,0,0,0,256,0V16.078A8.039,8.039,0,0,0,264.039,8.039Zm0,0"
        transform="translate(-247.961)"/>
  <path style={{ fill: "#fff" }}
        d="M309.1,141.46a2.611,2.611,0,0,0-3.693,0h0a.471.471,0,1,0,.666.666,1.669,1.669,0,0,1,2.361,0,.471.471,0,0,0,.666-.666Zm0,0"
        transform="translate(-295.681 -136.277)"/>
  <path style={{ fill: "#fff" }}
        d="M84.165,141.46a2.611,2.611,0,0,0-3.693,0h0a.471.471,0,1,0,.666.666,1.669,1.669,0,0,1,2.361,0,.471.471,0,0,0,.666-.666Zm0,0"
        transform="translate(-77.811 -136.277)"/>
  <path style={{ fill: "#edf6ff" }}
        d="M90.894,243H80.8a.471.471,0,0,0-.471.471,5.516,5.516,0,0,0,11.033,0A.471.471,0,0,0,90.894,243Zm0,0"
        transform="translate(-77.809 -235.369)"/>
  <path style={{ fill: "#d5e9ff" }}
        d="M261.045,243H256v5.988a5.516,5.516,0,0,0,5.516-5.516A.471.471,0,0,0,261.045,243Zm0,0"
        transform="translate(-247.961 -235.369)"/>
</svg>


// used in user cards next to humor-plus score
const HumorplusfaceIcon = () =>
  // <div className="mt-0.5">
  //   <img alt="humor-plus score" src="images/humorplus.svg" />
  // </div>;
  <svg xmlns="http://www.w3.org/2000/svg"
     width="20.24" height="21.453" viewBox="0 0 20.24 21.453">
    <title>Humor-plus score</title>
    <g transform="translate(0 0.707)">
      <g transform="translate(0 4.667)">
        <path style={{ fill: "#ff9c83" }}
              d="M16.078,8.039A8.039,8.039,0,1,1,8.039,0,8.039,8.039,0,0,1,16.078,8.039Zm0,0"/>
        <path style={{ fill: "#ff4f23" }}
              d="M264.039,8.039A8.039,8.039,0,0,0,256,0V16.078A8.039,8.039,0,0,0,264.039,8.039Zm0,0" transform="translate(-247.961)"/>
        <path style={{ fill: "#fff" }}
              d="M309.1,141.46a2.611,2.611,0,0,0-3.693,0h0a.471.471,0,1,0,.666.666,1.669,1.669,0,0,1,2.361,0,.471.471,0,0,0,.666-.666Zm0,0" transform="translate(-295.681 -136.277)"/>
        <path style={{ fill: "#fff" }}
              d="M84.165,141.46a2.611,2.611,0,0,0-3.693,0h0a.471.471,0,1,0,.666.666,1.669,1.669,0,0,1,2.361,0,.471.471,0,0,0,.666-.666Zm0,0" transform="translate(-77.811 -136.277)"/>
        <path style={{ fill: "#edf6ff" }}
              d="M90.894,243H80.8a.471.471,0,0,0-.471.471,5.516,5.516,0,0,0,11.033,0A.471.471,0,0,0,90.894,243Zm0,0" transform="translate(-77.809 -235.369)"/>
        <path style={{ fill: "#d5e9ff" }}
              d="M261.045,243H256v5.988a5.516,5.516,0,0,0,5.516-5.516A.471.471,0,0,0,261.045,243Zm0,0" transform="translate(-247.961 -235.369)"/>
      </g>
      <g transform="translate(12.198 3.668) rotate(-45)">
        <g style={{ fill: "#ff4f23" }}>
          <path style={{ strok: "none" }}
                d="M 4.733459949493408 5.436619281768799 C 4.590499877929688 5.436619281768799
                   4.456399917602539 5.381129264831543 4.355870246887207 5.280379295349121
                   L 2.593447685241699 3.518020868301392 L 0.8308800458908081 5.280579566955566
                   C 0.7303500771522522 5.381179332733154 0.5963200330734253 5.436619281768799
                   0.4535400569438934 5.436619281768799 C 0.3106800615787506 5.436619281768799
                   0.1766000539064407 5.381179332733154 0.07601005584001541 5.280509471893311
                   L -0.09384994208812714 5.110679626464844 C -0.1944899410009384 5.009969234466553
                   -0.2498699426651001 4.875969409942627 -0.2498699426651001 4.733289241790771
                   C -0.2498699426651001 4.590679168701172 -0.1945099383592606 4.456699371337891
                   -0.093999944627285 4.356039524078369 L 1.668657302856445 2.59330940246582
                   L -0.09391994029283524 0.8306593894958496 C -0.1944399476051331 0.7300693988800049
                   -0.249849945306778 0.5961794257164001 -0.2499999403953552 0.4535894095897675
                   C -0.2499999403953552 0.3103794157505035 -0.1944899410009384 0.1762793958187103
                   -0.09369994699954987 0.07573940604925156 L 0.0760500580072403 -0.09398059546947479
                   C 0.1764600574970245 -0.1945406049489975 0.3105600476264954 -0.2500005960464478
                   0.4535000622272491 -0.2500005960464478 C 0.5963500738143921 -0.2500005960464478
                   0.7304100394248962 -0.1945406049489975 0.8309700489044189 -0.09382059425115585
                   L 2.593394041061401 1.668567180633545 L 4.356040000915527 -0.09396059811115265
                   C 4.456240177154541 -0.1944805979728699 4.590360164642334 -0.2500005960464478
                   4.733409881591797 -0.2500005960464478 C 4.876410007476807 -0.2500005960464478
                   5.010499954223633 -0.1944805979728699 5.110990047454834 -0.09368059784173965
                   L 5.280670166015625 0.07588940113782883 C 5.381470203399658 0.176679402589798
                   5.436960220336914 0.3107094168663025 5.436960220336914 0.4533593952655792
                   C 5.436960220336914 0.5960593819618225 5.381440162658691 0.7300993800163269
                   5.280620098114014 0.8307793736457825 L 3.518139600753784 2.593305349349976
                   L 5.280730247497559 4.355859279632568 C 5.38139009475708 4.456669330596924
                   5.436749935150146 4.590659618377686 5.436749935150146 4.733289241790771
                   C 5.436749935150146 4.875959396362305 5.381370067596436 5.00995922088623
                   5.280799865722656 5.110599517822266 L 5.110720157623291 5.280609607696533
                   C 5.010429859161377 5.381129264831543 4.876359939575195 5.436619281768799
                   4.733459949493408 5.436619281768799 Z"/>
          <path style={{ stroke: "none", fill: "#ff4f23" }}
               d="M 0.4535002708435059 -4.76837158203125e-07 C 0.3774700164794922
                  -4.76837158203125e-07 0.3062400817871094 0.02930927276611328 0.252810001373291
                  0.08281946182250977 L 0.08285999298095703 0.2527294158935547
                  C 0.02943992614746094 0.3060293197631836 0 0.3773393630981445 0 0.4533295631408691
                  C 8.0108642578125e-05 0.5292792320251465 0.02952003479003906 0.6005091667175293
                  0.08285999298095703 0.6538891792297363 L 2.022200107574463 2.59330940246582
                  L 0.08291006088256836 4.532679557800293 C 0.02956008911132812 4.586109161376953
                  0.0001301765441894531 4.657339572906494 0.0001301765441894531 4.733289241790771
                  C 0.0001301765441894531 4.809239387512207 0.02956008911132812 4.880499362945557
                  0.08291006088256836 4.933889389038086 L 0.2528500556945801 5.103799343109131
                  C 0.3062801361083984 5.157269477844238 0.3775100708007812 5.186619281768799
                  0.4534602165222168 5.186619281768799 L 0.4535398483276367 5.186619281768799
                  C 0.5294499397277832 5.186619281768799 0.6006698608398438 5.157269477844238
                  0.6540999412536621 5.103799343109131 L 2.593440055847168 3.164469480514526
                  L 4.53285026550293 5.103799343109131 C 4.58620023727417 5.157269477844238
                  4.65746021270752 5.186619281768799 4.733459949493408 5.186619281768799
                  C 4.809319972991943 5.186619281768799 4.880630016326904 5.157269477844238
                  4.933979988098145 5.103799343109131 L 5.103960037231445 4.933889389038086
                  C 5.157350063323975 4.880459308624268 5.186749935150146 4.809239387512207
                  5.186749935150146 4.733289241790771 C 5.186749935150146 4.657339572906494
                  5.157350063323975 4.586109161376953 5.103960037231445 4.532639503479004
                  L 3.164590120315552 2.59330940246582 L 5.103960037231445 0.6538891792297363
                  C 5.214620113372803 0.5433692932128906 5.214620113372803 0.3633794784545898
                  5.103960037231445 0.2527294158935547 L 4.933929920196533 0.08281946182250977
                  C 4.880589962005615 0.02930927276611328 4.809319972991943 -4.76837158203125e-07
                  4.733409881591797 -4.76837158203125e-07 C 4.65742015838623 -4.76837158203125e-07
                  4.586150169372559 0.02930927276611328 4.532810211181641 0.08281946182250977
                  L 2.593389987945557 2.022109508514404 L 0.654059886932373 0.08281946182250977
                  C 0.6006302833557129 0.02930927276611328 0.5294098854064941 -4.76837158203125e-07
                  0.4535002708435059 -4.76837158203125e-07 M 0.4535002708435059 -0.5000004768371582
                  C 0.6631999015808105 -0.5000004768371582 0.8600802421569824 -0.418480396270752
                  1.007880210876465 -0.2704706192016602 L 2.593398332595825 1.315014839172363
                  L 4.179269790649414 -0.2707505226135254 C 4.326430320739746 -0.4183807373046875
                  4.523429870605469 -0.5000004768371582 4.733409881591797 -0.5000004768371582
                  C 4.943340301513672 -0.5000004768371582 5.140310287475586 -0.4183807373046875
                  5.288050174713135 -0.2701706886291504 L 5.457389831542969 -0.1009507179260254
                  C 5.605470180511475 0.0471196174621582 5.686950206756592 0.243929386138916
                  5.686950206756592 0.4533596038818359 C 5.686950206756592 0.6628994941711426
                  5.605390071868896 0.8597493171691895 5.457290172576904 1.007669448852539
                  L 3.871699094772339 2.593301296234131 L 5.457509994506836 4.179079532623291
                  C 5.605430126190186 4.327229499816895 5.686749935150146 4.52394962310791
                  5.686749935150146 4.733289241790771 C 5.686749935150146 4.942709445953369
                  5.605390071868896 5.139459609985352 5.457650184631348 5.287309169769287
                  L 5.287459850311279 5.4574294090271 C 5.140160083770752 5.605059623718262
                  4.943250179290771 5.686619281768799 4.733459949493408 5.686619281768799
                  C 4.523620128631592 5.686619281768799 4.326670169830322 5.605059623718262
                  4.178899765014648 5.456959247589111 L 2.593441486358643 3.871566295623779
                  L 1.007649898529053 5.457349300384521 C 0.8599700927734375 5.605149269104004
                  0.6631302833557129 5.686619281768799 0.4535398483276367 5.686619281768799
                  C 0.2438602447509766 5.686619281768799 0.04697990417480469 5.605149269104004
                  -0.1008400917053223 5.45721960067749 L -0.2706098556518555 5.287469387054443
                  C -0.4185099601745605 5.139469623565674 -0.4998698234558105 4.942709445953369
                  -0.4998698234558105 4.733289241790771 C -0.4998698234558105 4.523969650268555
                  -0.4185600280761719 4.327259540557861 -0.2709097862243652 4.179389476776123
                  L 1.315104722976685 2.59330940246582 L -0.2706999778747559 1.007439613342285
                  C -0.4183897972106934 0.8596391677856445 -0.4997801780700684 0.6630792617797852
                  -0.5 0.4538593292236328 C -0.5 0.2435193061828613 -0.4184198379516602
                  0.0465693473815918 -0.2702999114990234 -0.1012206077575684 L -0.1006999015808105
                  -0.2707705497741699 C 0.04679012298583984 -0.418480396270752 0.2437100410461426
                  -0.5000004768371582 0.4535002708435059 -0.5000004768371582 Z"/>
          </g>
        </g>
      </g>
    </svg>

export { HumorfaceIcon, HumorplusfaceIcon };
