import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { isEmptyObj } from '../../utils';
import { useStore, useDispatch } from 'react-redux';
import { Waiting } from './StaticPages';
import { PopupNote } from '../ui/PopupNote';

import { EditPrefsUI, EditBillingUI, EditAccountUI, EditProfileUI, }
  from '../ui/EditPagesUI';
import { changed_fields, make_changes, upload_snapshot,
         gender_ui2db, snapshot_check,
         marital_ui2db, smoker_ui2db, drinker_ui2db,
         children_ui2db, educlevel_ui2db, religious_ui2db,
         seeking_ui2db, genderpref_ui2db,
         maritalpref_ui2db, smokerpref_ui2db,
         drinkerpref_ui2db, childrenpref_ui2db,
         educpref_ui2db, religiouspref_ui2db,
         agepref_check, email_check, zipcode_check, pwd_add_check,
         birthday_check }
  from './utils';
import { CYPRESS_MODE } from '../../config';
import { auth, signed_in, email_auth_provider } from '../../App';
import { wait_for_data, set_wait_for_data } from '../Main';
import { GoodPromise, toISO, ft2inches } from '../../utils';
import { fbDateTime } from '../../dbstructure';
import { recalc_matches_fields, recalc_affinities_fields, recalc_affinities }
  from '../../matching';

// Profile-editing pages
// See 'Fields for site'
// User is signed in, so we have email; nothing else is guaranteed

// Identity: email, username, location
const EditAccount = () => {

  const [done, setDone] = useState('');
  const [errmsg, setErrmsg] = useState({});
  const [dis, str] = [useDispatch(), useStore()];

  // wait for error response
  const [ ready, setReady ] = React.useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('edit/account')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  if (! ready) {
    return <Waiting />;
  }

  const profile = str.getState().profile;

  // TODO: verify email if changed
  const submit = (refs, pwdreset) => {
    return e => {
      e.preventDefault();

      const m = refs.email.current.getAttribute('value');
      const emailerr = email_check(m);
      if (emailerr) {
        setErrmsg({ email: emailerr });
        return;
      }

      // since default for pwds is not filled in,
      // we know if passwd has changed just by whether it is non-empty
      const oldpwd = refs.oldpwd.current.getAttribute('value');
      const [p, p2] = refs.newpwd.map(r => r.current.getAttribute('value'));
      if (oldpwd || p || p2) {
        if (oldpwd && ! p) {
            setErrmsg({ pwd1: "Please fill out this field" });
            return;
        }
        if (p && ! p2) {
          setErrmsg({ pwd2: "Please fill out this field" });
          return;
        }
        if (p2 && !p) {
          setErrmsg({ pwd1: "Please fill out this field" });
          return;
        }

        let pwderr = pwd_add_check(p, p2);
        if (pwderr) {
          setErrmsg({ pwd2: pwderr });
          return;
        }
      }

      const z = refs.zipcode.current.getAttribute('value');
      const zipcodeerr = zipcode_check(z);
      if (zipcodeerr) {
        setErrmsg({ zipcode: zipcodeerr });
      }
      const o = refs.obscene.current.getAttribute('value') === 'Yes';

      // flds2 are those that require recalcing matches, flds1 those
      // that do not. specifically, fld2 means last_profedit_time is updated
      let flds = changed_fields(
                     profile, [
                       ['email', m],
                       ['obscene_okay', o],
                       ['zipcode', z],
                     ]);

      if (isEmptyObj(flds) && ! p) {
        return;
      }

      const id = str.getState().profile.id;

      const user = auth.currentUser;
      const verify_pwd = oldp => {
        // note use of 'm' here. if email has changed, credentials
        // are [new email, old password]
        var credential = email_auth_provider(m, oldp);
        return user.reauthenticateWithCredential(credential);
      }

      const pwd_promise = () => p ? verify_pwd(oldpwd)
                                    .then(() => { user.updatePassword(p);
                                                  pwdreset(); })
                                  : GoodPromise();

      const email_promise = () =>
            'email' in flds ? auth.currentUser.updateEmail(m)
                            : GoodPromise();

      if (Object.keys(flds).some(f => recalc_matches_fields.has(f))) {
        flds['last_profedit_time'] = fbDateTime();
      }
      const change_promise = () => make_changes(flds, id, dis);

      const report_error = err => {
        if (! err.code) {
          setDone('Database error - please retry');
          return;
        }
        switch(err.code) {
          case 'auth/wrong-password': {
              setErrmsg({ oldpwd: "Incorrect password" });
              break;
            }
          case 'auth/weak-password': {
              setErrmsg({ pwd1: "Stronger password required" });
              break;
            }
          case 'auth/requires-recent-login': {
              setErrmsg({ email: "Timed out;"
                                     + " please logout and login again" });
              break;
            }
          case 'auth/invalid-email': {
              setErrmsg({ email: "Invalid email" });
              break;
            }
          case 'auth/email-already-in-use': {
              setErrmsg({ email: "Email already in use" });
              break;
            }
          default: {
              setDone('Database error - please retry');
              break;
          }
        }
      };

      // TODO: I think it makes sense to have this setReady call, but it causes
      //       a problem: in case of an error (eg. email already in use), it
      //       results in a react warning: "Can't perform a React state update
      //       on an unmounted component". It's because the state change cause
      //       the page to be refreshed and creates new instances of the
      //       input widgets; so the error message is added to a widget that has
      //       been removed from the dom.
      //setReady(false);
      set_wait_for_data(
        email_promise()
        .then(() => pwd_promise())
        .then(() => change_promise())
        .then(() => { setDone('Changes submitted');
                      if (Object.keys(flds)
                                .some(f => recalc_affinities_fields.has(f))) {
                        recalc_affinities(dis, str);
                      }
                    })
        .catch(err => report_error(err))
      );
    }
  }

  return (
    <div>
      <EditAccountUI profile={profile}
                     submit={submit}
                     errmsg={errmsg}
                     reset_errmsg={() => setErrmsg('')} />
      <PopupNote notmsg={done}
                 onclose={() => setDone('')}
                 nottype="info" />
    </div>
  );
}

// Personal: gender, snapshot, brief_intro, obscene_okay, height,
//           married, smoker, drinker, children, educ_level, religious, religion
const EditProfile = () => {

  const [done, setDone] = useState('');

  const [errmsg, setErrmsg] = useState({});

  const [dis, str] = [useDispatch(), useStore()];

  let profile = str.getState().profile;
  // snapshot is [photo name, photo url, aspect ratio, file object]
  // file object is non-null only for new uploads (SnapshotSw), other
  // fields come from profile
  const [snapshot, setSnapshot] =
        useState([ profile.snapshot, profile.snapshot_url,
                   profile.snapshot_ar, null ])

  // show crop popup
  // const [showCrop, setShowCrop] = useState(false);

  // wait in case new photo was chosen
  const [ ready, setReady ] = React.useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('edit/profile')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  if (! ready) {
    return <Waiting />;
  }

  profile = str.getState().profile;

  const submit = refs => {
    return e => {
      e.preventDefault();

      const n = refs.username.current.getAttribute('value').trim();
      if (! n) {
        setErrmsg({ username: "Please fill out this field" });
        return;
      }

      const g = refs.gender.current.getAttribute('value');
      if (! g) {
        setErrmsg({ gender: "Please select one" });
        return;
      }

      const b = refs.dob.current.getAttribute('value');
      if (! b) {
        setErrmsg({ dob: "Please fill out this field" });
        return;
      }
      const bderr = birthday_check(b);
      if (bderr) {
        setErrmsg({ dob: bderr });
        return;
      }

      if (! snapshot[0]) {
        setErrmsg({ snapshot: "Please select a photo" });
        return;
      }
      // ss is a file object
      const ss = CYPRESS_MODE ? window.fileobject : snapshot[3];
      // if ! ss, no change was made (no photo uploaded)
      if (ss) {
        let sserr = snapshot_check(ss);
        if (sserr) {
          setErrmsg({ snapshot: sserr });
          return;
        }
      }

      const [ftref, inref] = refs.height;
      const f = ftref.current.getAttribute('value');
      const i = inref.current.getAttribute('value');
      if (f && ! i) {
        setErrmsg({ inches: "Please select one"});
        return;
      }
      if (! f && i) {
        setErrmsg({ feet: "Please select one"});
        return;
      }
      const h = f === '' ? 0 : ft2inches(parseInt(f), parseInt(i));

      const bi = refs.brief_intro.current.getAttribute('value');
      if (bi.length === 0) {
        setErrmsg({ brief_intro: "Please fill in this field"})
        return;
      }

      const m = refs.marital.current.getAttribute('value');
      const s = refs.smoker.current.getAttribute('value');
      const d = refs.drinker.current.getAttribute('value');
      const c = refs.children.current.getAttribute('value');
      const el = refs.educlevel.current.getAttribute('value');
      const r = refs.religious.current.getAttribute('value');
      const rl = refs.religion.current.getAttribute('value');
      const like = refs.like_to_do.current.getAttribute('value');
      const have = refs.have_to_do.current.getAttribute('value');
      const discuss = refs.like_to_discuss.current.getAttribute('value');
      const read = refs.like_to_read.current.getAttribute('value');
      const listen = refs.like_to_listen.current.getAttribute('value');
      const watch = refs.like_to_watch.current.getAttribute('value');
      const seek = refs.looking_for.current.getAttribute('value');

      let flds = changed_fields(profile, [
        ['username', n],
        ['gender', gender_ui2db[g]],
        ['birthday', toISO(b)],
        ['height', h],
        ['married', marital_ui2db[m]],
        ['smoker', smoker_ui2db[s]],
        ['drinker', drinker_ui2db[d]],
        ['children', children_ui2db[c]],
        ['educ_level', educlevel_ui2db[el]],
        ['religious', religious_ui2db[r]],
        ['religion', rl],
        ['brief_intro', bi],
        ['like_to_do', like],
        ['have_to_do', have],
        ['like_to_discuss', discuss],
        ['like_to_read', read],
        ['like_to_listen', listen],
        ['like_to_watch', watch],
        ['looking_for', seek],
      ]);

      // to see if snapshot is new, check if there is a file object
      if (ss) {
        flds = { ...flds, snapshot: ss.name, snapshot_ar: snapshot[2] };
      }

      if (isEmptyObj(flds)) {
        return;
      }

      const id = str.getState().profile.id;
      if (Object.keys(flds).some(f => recalc_matches_fields.has(f))) {
        flds['last_profedit_time'] = fbDateTime();
      }

      setReady(false);
      set_wait_for_data(
        (flds.hasOwnProperty('snapshot')
          ? upload_snapshot(ss, profile.id)
            .then(url => { flds.snapshot_url = url; })
          : GoodPromise()
        )
        .then(() => make_changes(flds, id, dis))
        .then(() => { setDone('Changes submitted');
                      if (Object.keys(flds)
                                .some(f => recalc_affinities_fields.has(f))) {
                        recalc_affinities(dis, str);
                      }
                    })
        .catch(e => {
          if (e.api === 'compress') {
            setDone(`${e.msg}`);
          } else if (e.api === 'storage') {
            setDone(`${e.msg}; please retry`);
          } else {
            setDone('Database error - please retry');
          }
        })
      );
    }
  }

  return (
    <div>
      <EditProfileUI profile={profile}
                     submit={submit}
                     snapshot={snapshot}
                     setSnapshot={setSnapshot}
                     errmsg={errmsg}
                     reset_errmsg={() => setErrmsg('')} />
      <PopupNote notmsg={done}
                 onclose={() => setDone('')}
                 nottype="info" />
    </div>
  );
}

const EditBilling = props => {

  const [done, setDone] = useState('');

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  const submit = billing_ref => {
    return f => {
      f.preventDefault();
      // let billing = billing_ref.current
      // if (! billing.validate()) {
      //   return;
      // }
      // let b = billing.get();

      setDone('done');
    }
  }

  return (
    <div>
      <EditBillingUI submit={submit} />
      <PopupNote notmsg={done}
                 onclose={() => setDone('')}
                 nottype="info" />
    </div>
  );
}

// Preferences: seeking, gender_pref, age range, zip (zip included because
// it is needed to tell if a match is appropriate; will also include
// geog_limit if we ever get to using that)
const EditPrefs = () => {

  const [done, setDone] = useState('');
  const [errmsg, setErrmsg] = useState({});
  const [dis, str] = [useDispatch(), useStore()];

  // wait for db error - if user's changes were not properly
  // recorded in db, they can retry
  const [ ready, setReady ] = React.useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('edit/prefs')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  if (! ready) {
    return <Waiting />;
  }

  const profile = str.getState().profile;

  const submit = (refs) => {
    return e => {
      e.preventDefault();

      const rp = refs.seeking.current.getAttribute('value');

      const gp = refs.genderpref.current.getSelected();
      if (gp.length === 0) {
        setErrmsg({ genderpref: "Pick at least one" });
        return;
      }

      const [agelow, agehigh] = refs.agepref;
      const la = agelow.current.getAttribute('value');
      const ha = agehigh.current.getAttribute('value');
      if (! la) {
        setErrmsg({ agelow: "Please fill out this field" });
        return;
      }
      if (! ha) {
        setErrmsg({ agehigh: "Please fill out this field" });
        return;
      }
      const [where, msg] = agepref_check(la, ha);
      if (where) {
        setErrmsg({ [where === 'lo' ? 'agelow' : 'agehigh']: msg })
        return;
      }

      const [htpref, lowft, lowin, highft, highin] = refs.heightpref;
      const hp = htpref.current.getAttribute('value');
      const fl = lowft.current.getAttribute('value');
      const il = lowin.current.getAttribute('value');
      const fh = highft.current.getAttribute('value');
      const ih = highin.current.getAttribute('value');
      if (hp === 'Between') {
        if (! fl) {
          setErrmsg({ lowfeet: "Please select one"});
          return;
        }
        if (! il) {
          setErrmsg({ lowinches: "Please select one"});
          return;
        }
        if (! fh) {
          setErrmsg({ highfeet: "Please select one"});
          return;
        }
        if (! ih) {
          setErrmsg({ highinches: "Please select one"});
          return;
        }
      }
      const [lo, hi] =
        hp === 'No preference'
               ? [ 1, 200 ]
               : [ ft2inches(parseInt(fl), parseInt(il)),
                   ft2inches(parseInt(fh), parseInt(ih)) ];
      if (lo > hi) {
        setErrmsg({ highfeet: "Max height cannot be less than min height"});
        return;
      }

      const mp = refs.maritalpref.current.getSelected();
      if (mp.length === 0) {
        setErrmsg({ maritalpref: "Pick at least one" });
        return;
      }

      const sp = refs.smokerpref.current.getAttribute('value');

      const dp = refs.drinkerpref.current.getAttribute('value');

      const cp = refs.childrenpref.current.getAttribute('value');

      const ep = refs.educpref.current.getAttribute('value');

      const rlp = refs.religiouspref.current.getAttribute('value');

      const flds = changed_fields(
                     profile, [
                       ['age_low', la],
                       ['age_high', ha],
                       ['low_height', lo],
                       ['high_height', hi],
                       ['married_pref', maritalpref_ui2db(mp)],
                       ['smoker_pref', smokerpref_ui2db[sp]],
                       ['drinker_pref', drinkerpref_ui2db[dp]],
                       ['children_pref', childrenpref_ui2db[cp]],
                       ['educ_pref', educpref_ui2db[ep]],
                       ['religious_pref', religiouspref_ui2db[rlp]],
                       ['seeking', seeking_ui2db[rp]],
                       ['gender_pref', genderpref_ui2db(gp)],
                    ]);

      if (isEmptyObj(flds)) {
        return;
      }

      if (Object.keys(flds).some(f => recalc_matches_fields.has(f))) {
        flds['last_profedit_time'] = fbDateTime();
      }

      const id = str.getState().profile.id;
      // flds.last_profedit_time = fbDateTime();
      // we wait here just to make changes really happened.
      // TODO: make_changes cannot fail - docref.update fails only if
      //       the doc does not exist, which it does. however, it can
      //       hang waiting for resolution. should add timer her, so
      //       as not to leave user hanging
      setReady(false);
      set_wait_for_data(
        make_changes(flds, id, dis)
        .then(() => { setDone('Changes submitted');
                      if (Object.keys(flds)
                                .some(f => recalc_affinities_fields.has(f))) {
                        recalc_affinities(dis, str);
                      }
                    })
      );
    };
  }

  return (
    <div>
      <EditPrefsUI profile={profile} submit={submit}
                   errmsg={errmsg}
                   reset_errmsg={() => setErrmsg('')} />
      <PopupNote notmsg={done}
                 onclose={() => setDone('')}
                 nottype="info" />
    </div>
  );
}

export { EditPrefs, EditBilling, EditAccount, EditProfile };
