import React from 'react';
import { Link } from '@reach/router';
import { useDispatch } from 'react-redux';
// import ReactHtmlParser from 'react-html-parser';
import { RegPage } from '../ui/RegPage';
import { signed_in } from '../../App';
import { signout } from '../Main';

const WaitingUI = RegPage(() => {
  return <h3>Waiting</h3>;
});
const Waiting = () => <WaitingUI footeronly={! signed_in()} />;

const SignOut = () => {
  signout(useDispatch());
  return <div />;
}

const NotFoundUI = RegPage(() => {
  return <div className="text-xl font-normal">
            Nothing here!
         </div>;
});
const NotFound = () => <NotFoundUI footeronly={! signed_in()} />;

// const TechSupportUI = RegPage(() => (
//   <div className="infopage">
//     <h2>Tech support</h2>
//     <p>
//       For tech support questions, please email us at techsupport@laughstruck.com.
//     </p>
//     <p>
//     (FAQ to come.)
//     </p>
//   </div>
// ));
// const TechSupport = () => <TechSupportUI footeronly={! signed_in()} />;

// const CareersUI = RegPage(() => (
//   <div className="infopage">
//     <h2>Careers with us</h2>
//     <p>
//       We are not currently hiring at Laughstruck.
//     </p>
//   </div>
// ));
// const Careers = () => <CareersUI footeronly={! signed_in()} />;

const AboutUI = RegPage(() => (
  <div className="flex flex-col mx-auto w-full mb-8 bg-white rounded-lg"
       style={{ boxShadow: "0px 3px 50px #0000000D" }}>
    <div className="flex flex-col mx-4 my-4">
      <div className="flex text-xl font-semibold text-gray-text">
        About Laughstruck
      </div>
      <div className="flex text-base font-normal text-gray-text mt-4">
        Laughstruck connects you to potential friends,
        lovers and partners based on your sense of humor.
        We take a simple approach: you rate a bunch of jokes on
        a scale from 1 to 5 and we match you with others who
        laugh at the same stuff.
      </div>
      <div className="flex text-base font-normal text-gray-text mt-4">
        Everyone has a sense of humor (and 95% think theirs is “above average,”
        which is itself kind of funny), but not all humor is the same.
        At Laughstruck we believe that what people laugh at says a lot about how they think,
        relate to others and view the world around them;
        if we wanted to get really intense we’d say our humor cuts to our deepest selves.
        That’s why we bring people together based on their humor;
        if two people laugh at the same things, we know they’ll “click.”
      </div>
      <div className="flex text-base font-normal text-gray-text mt-4">
        To help you find your laughmates we frontload ten jokes pre-tested
        to separate out the most common humor types.
        If we've done our job right you'll find a couple of jokes brilliantly
        funny but also find at least one embarrassingly awful.
        (Yes, there are people who think that painfully bad joke is hysterical
        - and you can thank us for making sure you won't go on a date with them.)
        But we know there aren't just ten types of humor;
        in fact, we think there are countless, each a fairly unique recipe of ingredients
        – for example, self-deprecation with large doses of irony and a dash of slapstick
        (which would describe one of this site's founders).
        That's why we give you the option to score more than ten jokes:
        the more you rate the better we can identify those ingredients
        – or “dimensions” – that make you laugh and connect you to others
        who share your cocktail for laughter and love.
        Cheers!
      </div>
      <div className="flex mt-6 text-xl font-semibold text-gray-text">
        About us
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        Julia Kamin is a career do-gooder and fan of democracy.
        She's advocated for students in NYC public schools,
        founded an after-school theater organization for NYC teens,
        started a civic discussion site and event series,
        got a PhD in political science and now researches online behavior
        trying to make the internet less of a disaster for society.
        All along she’s done a lot of dating.
        On one date – a classic dinner and movie – she was watching a
        comedy with a guy who laughed at all the dumb jokes.
        Meanwhile another guy one row in front and down a few seats was
        laughing at the exact same times she was and she thought
        “God, I wish I was on a date with <strong>him</strong>."
        Years later recalling that event she shared an idea with a friend
        “Wouldn’t it be great if a dating site matched you with guys
        who had a similar sense of humor?”
        The friend thought that idea was beyond brilliant – as did pretty much
        every woman she spoke with (and a few men).
        Eventually she thought “Hey, if no one else is making that site,
        I might as well.”
      </div>
      <div className="flex text-base font-normal text-gray-text mt-4">
        Sam Kamin is a computer science guy, history buff, and bluegrass guitar aficionado.
        He’s the tortoise to Julia’s hare in work and in relationships
        (they’re brother and sister, in case you’re wondering).
        Sam was a Computer Science professor for a few decades before retiring and working
        a few gigs to keep him entertained.
        He hasn’t dated much - he met his wife the old-fashioned way, at work.
        After his last gig he was wondering what to do next and Julia convinced him
        that he really wanted to build a dating site to connect lovelorn singles.
        Bless his heart, for some reason he agreed.
      </div>
    </div>
  </div>
));

const About = () => <AboutUI footeronly={! signed_in()} />;

const TosUI = RegPage(() => (
  <div className="flex flex-col mx-auto w-full mb-8 bg-white rounded-lg"
       style={{ boxShadow: "0px 3px 50px #0000000D" }}>
    <div className="flex flex-col mx-4 my-4">
      { /*
      <div className="text-base font-normal text-gray-text mt-4">
        We do not retain
        payment-related information like credit card numbers on our site.
        We use online payment service
        <a href="http://stripe.com" className="text-blue-500"> Stripe</a>;
        Stripe's privacy policy is detailed at
        <a href="https://stripe.com/privacy-center/legal"
           className="text-blue-500"> Stripe Privacy Center</a>.
      </div>
      */ }
      <div className="flex mt-6 text-xl font-semibold text-gray-text">
        Terms of service
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        The basic requirement for you to use this site are:
        (1) You must use the site only for its intended purposes;
        you cannot use it as a data-storage facility, to conspire
        in the commission of a crime, stuff like that.
        (2) You must be civil. We retain the right to terminate any
        user for any reason, a right we will exercise in cases of
        harassment, or the posting of objectionable material - racist,
        sexist, pornographic, etc. -
        either in your bio or in messages to other users.
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        Please know we do NOT do background checks.
        Be sensible in sharing information with others you meet online
        and if you do meet someone IRL, use common sense and caution.
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        The full, legal terms of service, in all their scintillating
        glory, can be found
        <Link to='/tos/tos'
              className="mx-1 text-blue-500">HERE.</Link>
      </div>
      <div className="flex mt-6 text-xl font-semibold text-gray-text">
        Privacy policy
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        We collect such personal information as is needed to communicate
        with you (email), and to create a profile for other users to see.
        Other than email, this
        information is shared with other users in the ordinary
        business of the website.
        Messages are never shown to anyone other than the intended
        recipient.
        On occasion we may ask if you wish to share any or all of
        your individual information with third parties, but will never do so without
        your permission.
        We may also use aggregated, anonymized data for research and analysis,
        including possibly for publication.
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        The full, legal privacy policy can be found
        <Link to='/tos/privacy'
              className="mx-1 text-blue-500">HERE.</Link>
      </div>
      <div className="flex mt-6 text-xl font-semibold text-gray-text">
        Use of cookies
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        We use cookies - as is common - to improve the user experience,
        and to aid in analysis of our site's performance.
        Examples are cookies containing identifying keys used to maintain continuity
        of user sessions (so as not to require repeated signins),
        and cookies containing information about ad clicks used
        to provide more useful ads.
      </div>
      <div className="text-base font-normal text-gray-text mt-4">
        The complete story on our use of cookies can be found
        <Link to='/tos/cookies'
              className="mx-1 text-blue-500">HERE.</Link>
      </div>
    </div>
  </div>
));

const FullTosUI = RegPage(() => (
  <div className="flex flex-col mx-auto w-full mb-8 bg-white rounded-lg"
       style={{ boxShadow: "0px 3px 50px #0000000D" }}>
    <div className="flex text-xl font-semibold text-gray-text mx-2 my-2">
      Terms of service
    </div>
  </div>
));

const FullCookiesUI = RegPage(() => (
  <div className="flex flex-col mx-auto w-full mb-8 bg-white rounded-lg"
       style={{ boxShadow: "0px 3px 50px #0000000D" }}>
    <div className="flex text-xl font-semibold text-gray-text mx-2 my-2">
      Use of Cookies
    </div>
  </div>
));

const FullPrivacyUI = RegPage(() => (
  <div className="flex flex-col mx-auto w-full mb-8 bg-white rounded-lg"
       style={{ boxShadow: "0px 3px 50px #0000000D" }}>
    <div className="flex text-xl font-semibold text-gray-text mx-2 my-2">
      Privacy policy
    </div>
  </div>
));

const Tos = () => <TosUI footeronly={! signed_in()} />;
const FullTos = () => <FullTosUI footeronly={! signed_in()} />;
const FullCookies = () => <FullCookiesUI footeronly={! signed_in()} />;
const FullPrivacy = () => <FullPrivacyUI footeronly={! signed_in()} />;

export { NotFound, Waiting, SignOut, /*Careers,*/ About, Tos,
         FullTos, FullCookies, FullPrivacy };
