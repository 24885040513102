import React, { useState } from 'react';
import { Link } from '@reach/router';

import { InfoIcon } from '../../assets/icons/info';
import { CycleIcon } from '../../assets/icons/cycle';

import { RegPage } from './RegPage';
import { UserCard, EmptyUserCard } from './UserCard';
import { Explainer } from './Explainer';
import { ReportUserOverlay } from './ReportUserOverlay';
import { EARLY_BETA_TEST, config$test_url,
         config$matches_on_page } from '../../config';

const sort_explainer = order =>
  order === 'humor'
  ? `You are currently seeing your top Humor matches,
     based on shared joke ratings only.
     Change to top Humor+ matches, based on shared
     joke ratings and other dating prefs.`
  : `You are currently seeing your top Humor+ matches,
     based on shared joke ratings and your other dating prefs.
     Change to top Humor matches, based on your joke ratings alone.`;

const Message = ({ text, show, cy_name }) => {
  return (
    <div className={`h-4/5 ${show ? '' : 'hidden'}`}
         cy-data={cy_name}>
      <div className="flex flex-row mt-4 justify-between
                  items-center bg-white rounded-lg">
        <div className="mx-4 my-4">
          { text }
        </div>
      </div>
    </div>
  );
}

// matches is array of pairs, [oprofile, buttons]
const BaseMatchesUI = ({ matches, buttons, sort, order,
                         showOverlay, setShowOverlay,
                         partialProfile, newJokes, newCoreJokes}) => {
  const [exp, setExp] = useState(false);
  return (
    <div className="flex flex-col mx-auto w-full">
      <div className="w-4/5 sm:w-full">
        <div className="flex flex-row my-3 justify-between items-center
                        sm:flex-col sm:space-y-4">
          <div className="hidden sm:block">
              <img style={{ height: "10rem" }}
                 alt="small mock ad"
                 data-cy="joke-ad-sm"
                 src={`${config$test_url}/images/app_ad.png`}
                 className="mx-auto rounded-lg col-span-1"/>
          </div>
          <div className="flex text-3xl font-semibold text-gray-text sm:hidden">
            Top matches
          </div>
          <div className="flex flex-row space-x-2">
            <button onClick={sort} data-cy="match_sort">
              <CycleIcon />
            </button>
            <div className="flex text-sm font-normal text-gray-text">
              Change to humor{order === 'humor' ? '+' : ''} match
            </div>
            <div className="relative">
              <div className="mt-0.5"
                   data-cy="expon-order"
                   onClick={() => setExp(! exp)}>
                <InfoIcon size="tiny" />
              </div>
              <Explainer open={exp}
                         cy_name="order"
                         close={() => setExp(false)}
                         exp={sort_explainer(order)} />
            </div>
          </div>
        </div>
        <div className="flex text-xl font-normal text-gray-text">
          { matches.length < 4
            ? `Your top matches.`
            : `Your top matches. Like or pass on each to see more.`}
        </div>
      </div>
      <div className="grid grid-cols-5 mt-4">
        <div className="col-span-4 sm:col-span-5">
          { matches.length === 0
            ? <div className="flex flex-row mt-4 justify-between
                          items-center bg-white rounded-lg">
                <div className="ml-4 my-4">
                  There are no matches that meet your criteria.
                  { EARLY_BETA_TEST
                    ? ` We're early in our beta test, so we
                        don't have many users yet. Please come back
                        tomorrow.`
                    : ` We're adding new users all the time. Please
                        come back tomorrow.` }
                </div>
              </div>
            : <div className="flex flex-row flex-wrap gap-x-4 gap-y-4
                              touch:flex-nowrap
                              touch:overflow-x-auto">
                { matches.slice(0, config$matches_on_page)
                         .map((p, i) =>
                        <div key={i}>
                          { p ? <UserCard oprofile={p}
                                          buttons={buttons}
                                          setShowOverlay={setShowOverlay} />
                              : <EmptyUserCard />
                          }
                        </div>)
                }
              </div>
          }
          <div className="flex flex-col">
            <Message show={partialProfile < 100}
                     cy_name="matches_profile_reminder"
                     text={
               <div>
                 Your profile and preferences are <span className="font-bold">
                 {partialProfile}% complete</span>.
                 Fill in more to get better matches.
                 Select
                 <Link to='/edit/profile'
                       className="mx-1 text-blue-500">My Profile</Link>
                 and
                 <Link to='/edit/prefs'
                       className="mx-1 text-blue-500">My Prefs</Link>
                 in the <span className="font-bold">Settings</span> menu.
               </div>} />
            <Message show={newJokes}
                     cy_name="matches_newjoke_reminder"
                     text={
              <div>
                You can {partialProfile < 100 ? 'also' : ''} rate
                more jokes to improve your
                humor match accuracy!
                Click
                on <span className="font-bold">Jokes</span> in the menu bar.
              </div>} />
           <Message show={newCoreJokes}
                    cy_name="matches_vote_reminder"
                    text={
               <div>
                 We've got some new "<span className="font-bold">core</span>"
                 jokes for you to vote on; these
                 are the jokes we find most helpful for matching, so good idea
                 to click
                 on <span className="font-bold">Jokes</span> in the menu bar.
               </div>} />
          </div>
        </div>
        <div className="sm:hidden">
          <img style={{ height: "30rem" }}
               alt="mock ad"
               src={`${config$test_url}/images/side_ad_5.png`}
               className="ml-4 rounded-lg"/>
        </div>
      </div>
    </div>
  );
}

const MatchesUI = RegPage(BaseMatchesUI, ReportUserOverlay);

export { MatchesUI };
