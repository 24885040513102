import React from 'react';

import { SadfaceIcon, LaughingfaceIcon, HappyfaceIcon,
         SmileyfaceIcon, ConfusedfaceIcon }
  from '../../assets/icons/joke-faces';

const VoteSw = ({ the_ref, joke_id, dflt }) => {

  const [vote, setVote] = React.useState(dflt + '');
  const [joke, setJoke] = React.useState(0);

  // changing jokes => reset widget, unless there
  // was a previous vote on this joke
  if (joke !== joke_id && ! dflt) {
    setJoke(joke_id);
    setVote('');
  }

  const vote_attr = v => {
    return v === vote
           ? ['big', '-ml-1 -mr-0.5', 'h-6 w-6 rounded-full']
           : ['normal', '', 'h-4 w-4 rounded-full'];
  }

  return (
    <div className={`w-full mb-6
                     flex flex-col space-y-4
                     ${ vote ? 'mt-0' : 'mt-2'}`}
         data-value={vote}
         ref={the_ref}>
      <div className="mx-auto flex flex-row justify-items-center
                        w-1/2 lg:w-1/2 md:w-3/4 sm:w-full">
        <div className="mx-auto flex flex-row justify-between no-outline
                        w-full pl-1
                        items-center">
          <button className={`${vote_attr("1")[1]}`}
                  onClick={() => setVote("1")}>
            <SadfaceIcon size={vote_attr("1")[0]}/>
          </button>
          <button className={`${vote_attr("2")[1]}`}
                  onClick={() => setVote("2")}>
            <ConfusedfaceIcon size={vote_attr("2")[0]} />
          </button>
          <button className={`${vote_attr("3")[1]}`}
                  onClick={() => setVote("3")}>
            <SmileyfaceIcon size={vote_attr("3")[0]} />
          </button>
          <button className={`${vote_attr("4")[1]}`}
                  onClick={() => setVote("4")}>
            <HappyfaceIcon size={vote_attr("4")[0]} />
          </button>
          <button className={`${vote_attr("5")[1]}`}
                  onClick={() => setVote("5")}>
            <LaughingfaceIcon size={vote_attr("5")[0]} />
          </button>
        </div>
      </div>
      <div className="w-full mx-auto flex flex-row justify-items-center
                      space-x-4 md:-space-x-1
                      justify-center">
        <div className="w-1/4 font-normal text-gray-para text-center
                        md:hidden">
          Not at all funny
        </div>
        <div className="md:px-2 w-1/2 lg:w-1/2 md:w-3/4 sm:w-full">
          <div className="relative flex flex-row justify-around
                          items-center mt-2">
            <div className="mx-auto h-1 bg-orange w-full rounded-full"
                 style={{ background:
                            `transparent
                             linear-gradient(90deg, #FFBB23 0%, #FF4F23 100%)
                             0% 0% no-repeat padding-box`}} />
            <div className="absolute w-full flex flex-row justify-between
                            items-center"
                   onClick={ev => setVote(ev.target.getAttribute('data-value'))}>
              <button className={`${vote_attr("1")[2]}`}
                   data-value="1"
                   data-cy="vote1"
                   style={{ background: '#ffbb23'}} />
              <button className={`${vote_attr("2")[2]}`}
                   data-value="2"
                   data-cy="vote2"
                   style={{ background: '#ff9723'}} />
              <button className={`${vote_attr("3")[2]}`}
                   data-value="3"
                   data-cy="vote3"
                   style={{ background: '#ff9723'}} />
              <button className={`${vote_attr("4")[2]}`}
                   data-value="4"
                   data-cy="vote4"
                   style={{ background: '#ff7323'}} />
              <button className={`${vote_attr("5")[2]}`}
                   data-value="5"
                   data-cy="vote5"
                   style={{ background: '#ff4f23'}} />
            </div>
          </div>
        </div>
        <div className="w-1/4 font-normal text-gray-para text-center
                        md:hidden">
          Extremely funny
        </div>
      </div>
      <div className="hidden md:block">
        <div className="flex flex-row justify-between mt-2">
          <div className="font-normal text-gray-para text-sm sm:-mx-4">
            Not at all funny
          </div>
          <div className="font-normal text-gray-para text-sm sm:-mx-4">
            Extremely funny
          </div>
        </div>
      </div>
    </div>
  );
}

export { VoteSw };
