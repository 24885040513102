import React from 'react';

const LeftChevronIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="10.882" height="18.5" viewBox="0 0 10.882 18.5">
    <title>Left arrow</title>
    <g transform="translate(224.214 18.832) rotate(180)">
      <path style={{ fill: "#ff4f23" }}
            d="M224.214,9.582a1.026,1.026,0,0,1-.185.6l-5.441,8.162a1.08,1.08,0,0,1-.9.49H214.42a1.092,1.092,0,0,1-1.088-1.088,1.026,1.026,0,0,1,.185-.6l5.039-7.563-5.039-7.563a1.026,1.026,0,0,1-.185-.6A1.092,1.092,0,0,1,214.42.332h3.265a1.08,1.08,0,0,1,.9.49l5.441,8.162A1.026,1.026,0,0,1,224.214,9.582Zm0,0"/>
    </g>
  </svg>;

const RightChevronIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="10.882" height="18.5" viewBox="0 0 10.882 18.5">
    <title>Right arrow</title>
    <g transform="translate(-213.332 -0.332)">
      <path style={{ fill: "#ff4f23" }}
            d="M224.214,9.582a1.026,1.026,0,0,1-.185.6l-5.441,8.162a1.08,1.08,0,0,1-.9.49H214.42a1.092,1.092,0,0,1-1.088-1.088,1.026,1.026,0,0,1,.185-.6l5.039-7.563-5.039-7.563a1.026,1.026,0,0,1-.185-.6A1.092,1.092,0,0,1,214.42.332h3.265a1.08,1.08,0,0,1,.9.49l5.441,8.162A1.026,1.026,0,0,1,224.214,9.582Zm0,0"/>
    </g>
  </svg>;

export { LeftChevronIcon, RightChevronIcon };
