import React, { useState, useRef } from 'react';

import { PopupNote } from './PopupNote';

import { CancelIcon } from '../../assets/icons/cancel';

import { TextSw } from '../widgets/TextWidget';
import { config$text_limits } from '../../config';

// 'mode' refers to where this is called:
//  '0' if directly from splash page (so need to get zipcode and email)
//  5-digit zipcode if from opening signup dialog box (so only need email)
const Prospective = ({ save_prospect, open, onclose, mode,
                       errmsg, reset_errmsg }) => {

  const emailref = useRef();

  const needzip = mode === '0';
  const zipref = useRef();

  const [goodbye, setGoodbye] = useState('');

  return (
    <React.Fragment>
    <div className={`flex my-16 content-center justify-center
                     ${open ? '' : 'hidden'}`}>
      <div className="relative flex flex-col bg-white rounded-lg
                      space-y-6 pt-8
                      w-5/12 lg:w-1/2 md:w-7/12 sm:w-11/12"
           data-cy="prospect-dialog"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <button className="absolute top-0 right-0 mt-4 mr-4"
             data-cy="cancelprospect-icon"
             onClick={() => { onclose(); }}>
          <CancelIcon />
        </button>
        <div className={`mx-12 text-2xl font-bold text-gray-text
                         text-center
                         ${needzip ? 'hidden' : ''}`} >
          Darn! Laughstruck isn't in your area yet.
        </div>
        <div className="mx-12 text-sm font-normal text-gray-text
                        text-center">
          <span className={mode==='0' ? '' : 'hidden'}>
            Please leave your email address and zip code.
            We'll let you know when Laughstruck is in your area.</span>
          <span className={mode!=='0' ? '' : 'hidden'}>
            Please leave your email address.
            We'll let you know when we open up in the {mode} area.</span>
        </div>
        <TextSw the_ref={emailref}
                label="Email"
                margin="mx-4"
                errmsg={errmsg.email}
                reset_errmsg={reset_errmsg}
                maxchars={config$text_limits.email}
                prompt="Enter email"
                cy_name="prospect_email" />
        { needzip
          ? <TextSw the_ref={zipref}
                    label="ZIP code"
                    margin="mx-4"
                    errmsg={errmsg.zip}
                    reset_errmsg={reset_errmsg}
                    maxchars={5}
                    prompt="Enter zip code"
                    cy_name="prospect_zip" />
          : <span />
        }
        <div>
          <div className="flex flex-col items-center mr-4">
            <button>
              <div className="flex flex-col bg-orange rounded-lg mt-8 w-28
                              h-10 content-center items-center"
                   data-cy="saveprospect-button"
                   onClick={() => { if (save_prospect(mode, emailref, zipref)) {
                                      setGoodbye('Got it! We\'ll be in touch');
                                    }
                                  }
                           } >
                <div className="font-semibold text-white text-sm
                                text-center mt-2.5">
                  Submit
                </div>
              </div>
            </button>
            <button className="items-start text-sm font-normal text-gray-para
                            mt-2 hover:underline"
                 data-cy="cancelprospect-button"
                 onClick={() => { onclose(); }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="h-8">
        </div>
      </div>
    </div>
    <PopupNote notmsg={goodbye}
               onclose={() => { setGoodbye(''); onclose(); }}
               nottype="info" />
    </React.Fragment>
  );
}

export { Prospective };
