import React from 'react';

// adjust accounts for some strange behavior in signup/done: when the
// text is more than two lines, checkmark reduces to half size
const CheckmarkIcon = ({ adjust }) =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width={ adjust ? "48" : "24.289"}
       height="22.224"
       viewBox="0 0 24.289 22.224">
    <title>Checkmark</title>
    <path style={{ fill: "#ff4f23" }}
      d="M93.206,262.148a1.434,1.434,0,0,0-2.026.1l-13.2,14.563L71.749,271.7a1.444,1.444,0,0,0-2.1,1.947l6.473,9.216a2.716,2.716,0,0,0,.673.665,2.666,2.666,0,0,0,3.708-.689l.106-.154,12.816-18.667A1.435,1.435,0,0,0,93.206,262.148Z"
      transform="translate(-69.388 -261.776)"/>
  </svg>

  //<img src="images/checkmark.svg" alt="checkmark"/>;

export { CheckmarkIcon };
