import React, { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';

// used for adding username on page (for debugging)
// import { check_invs } from '../../test/db-invs';
// import { LIVE_MODE } from '../../config';
import { signout } from '../Main';
import { changePage } from '../../utils';
import { JOKE_CAT } from '../../enums';
import { Explainer } from './Explainer';
import { exp_core_badge } from './utils';

import { HamburgerIcon } from '../../assets/icons/hamburger';
import { HeaderSmileyIcon, HeaderBoltIcon,
         HeaderCoupleIcon, HeaderConvoIcon,
         HeaderGearIcon } from '../../assets/icons/header-icons';

const Header = ({ oprofile }) => {

  const [dropdown, setDropdown] = useState(false);
  // show explainer for core joke badge
  const [ expCore, setExpCore ] = useState(false);

  const str = useStore();

  const others = str.getState().others;
  const not_seen = oprofs => Object.values(oprofs)
                                   .filter(o => ! o.pokedby_seen)
                                   .length;
  const props = {
    msgcnt: Object.values(others.threads).filter(p => p.msg_avail).length,
    newpokes: not_seen(others.pokedbys) + not_seen(others.copokes),
    debugname: str.getState().profile.username,
    newCoreJokes: str.getState().jokes.unvoted.length > 0
                 && str.getState().jokes.unvoted[0].cat === JOKE_CAT.CORE,
  };

  const path = window.location.pathname;
  const page = path.includes('vote') ? 'vote'
               : path.includes('matches') ? 'matches'
               : path.includes('connects') ? 'connects'
               : path.includes('convos') ? 'convos'
               : path.includes('edit') ? 'edit'
               : path.includes('/contact') ? 'contact'
               : path.includes('/tos') ? 'tos'
               : path.includes('/techsupport') ? 'techsupport'
               : path.includes('/about') ? 'about'
               : path.includes('/other') ? 'other'
               : '';

  const convo_id = str.getState().ui.convo_id;

  const buttons = {
    vote:        () => changePage('vote'),
    matches:     () => changePage('matches'),
    connects:    () => changePage('connects'),
    convos:      () => changePage('convos', convo_id),
    profile:     () => changePage('edit/profile'),
    prefs:       () => changePage('edit/prefs'),
    account:     () => changePage('edit/account'),
    billing:     () => changePage('edit/billing'),
  };

  const text_class = p =>
    p === page ? `relative font-sans font-semibold sm:text-sm
                  md:text-xs lg:text-sm text-base text-orange`
               : `relative font-sans font-normal sm:text-sm
                  md:text-xs lg:text-sm text-base text-gray-text`;

  const Badge = ({ n, which }) =>
     <div className={`absolute -right-3 -top-1.5
                      flex justify-start ${ n ? '' : 'hidden' }`}
          data-cy={`badge-${which}`}>
       <div className="flex flex-row rounded-lg bg-orange">
         <div className="flex flex-row mx-1">
           <div className="text-xs font-normal text-white">
             {n}
           </div>
         </div>
       </div>
     </div>

  return (
    <div className="relative mx-auto flex flex-row w-10/12 bg-white
                    rounded-lg space-x-3">
      <div className="w-full flex flex-row sm:flex-col
                      justify-around items-center">
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'vote' && ! dropdown && 'sm:hidden' }`}
             data-cy="tb-vote"
             onClick={buttons.vote}>
          <HeaderSmileyIcon selected={page === 'vote'} />
          <button className="-my-1">
            <span className={ text_class('vote') }>Jokes
              <span onClick={ev => { ev.stopPropagation();
                                     setExpCore(! expCore); }}
                             className="relative">
                <Badge n={props.newCoreJokes && <span className="text-xs">
                                                 C</span>} which="votes" />
                <Explainer open={expCore}
                           cy_name="header-expcore"
                           pos="left-4 top-2 sm:-left-24"
                           close={ev => { ev.stopPropagation();
                                          setExpCore(false); }}
                           exp={exp_core_badge} />
              </span>
            </span>
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'matches' && ! dropdown && 'sm:hidden' }`}
             data-cy="tb-matches"
             onClick={buttons.matches}>
          <HeaderBoltIcon selected={page === 'matches'} />
          <button className={ text_class('matches') }>
            Matches
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'connects' && ! dropdown && 'sm:hidden' }`}
             data-cy="tb-connects"
             onClick={buttons.connects}>
          <HeaderCoupleIcon selected={page === 'connects'} />
          <button className="-my-1">
            <span className={ text_class('connects') }>Connections
              <Badge n={props.newpokes} which="connects" />
            </span>
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'convos' && ! dropdown && 'sm:hidden' }`}
             data-cy="tb-convos"
             onClick={buttons.convos}>
          <HeaderConvoIcon selected={page === 'convos'} />
          <button>
            <span className={ text_class('convos') }>Conversations
              <Badge n={props.msgcnt} which="convos" />
            </span>
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'edit' && ! dropdown && 'sm:hidden' }`}
             data-cy="tb-edit"
             onClick={buttons.profile}>
          <HeaderGearIcon selected={page === 'edit'} />
          <button>
            <span className={ text_class('edit') }>Settings</span>
          </button>
        </div>
        <div className={`hidden flex flex-row h-12 justify-center items-center
                         ${ page === 'other' && ! dropdown && 'sm:flex' }`}>
          <div className={ text_class('other') }>
            User { oprofile && oprofile.username }
          </div>
        </div>
        <div className={`hidden flex flex-row h-12 justify-center items-center
                         ${ page === 'contact' && ! dropdown && 'sm:flex' }`}
             data-cy="tb-contact">
          <div className={ text_class('contact') }>Contact us</div>
        </div>
        <div className={`hidden flex flex-row h-12 justify-center items-center
                         ${ page === 'tos' && ! dropdown && 'sm:flex' }`}
             data-cy="tb-contact">
          <div className={ text_class('tos') }>Terms of Service</div>
        </div>
        <div className={`hidden flex flex-row h-12 justify-center items-center
                         ${ page === 'techsupport' && ! dropdown && 'sm:flex' }`}
             data-cy="tb-contact">
          <div className={ text_class('techsupport') }>Tech support</div>
        </div>
        <div className={`hidden flex flex-row h-12 justify-center items-center
                         ${ page === 'about' && ! dropdown && 'sm:flex' }`}
             data-cy="tb-contact">
          <div className={ text_class('about') }>About us</div>
        </div>
        <div className={`hidden sm:block absolute right-3 top-2.5`}>
          <button onClick={() => setDropdown(!dropdown)}>
            <HamburgerIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

const EditSidebar = () => {

 const dis = useDispatch();

 const path = window.location.pathname;
 const page = path.includes('profile') ? 'profile'
              : path.includes('prefs') ? 'prefs'
              : path.includes('account') ? 'account'
              : path.includes('billing') ? 'billing'
              : '';

 const buttons = {
   profile:     () => changePage('edit/profile'),
   prefs:       () => changePage('edit/prefs'),
   account:     () => changePage('edit/account'),
   billing:     () => changePage('edit/billing'),
   signout:     () => signout(dis)
 };

  const Selection = ({ label, sel }) => {
    // data-cy: use sb-profile1 and sb-profile2 (etc.) for
    // when this item is current or not current (so tests almost
    // always reerence '2' since clicking on '1' is redundant)
    return (
      <div>
        <div className={`flex flex-row -mx-6 -mb-1
                    ${ sel === page ? '' : 'hidden'}`}>
          <div className="h-6 w-1 bg-orange" />
          <div className="ml-5 text-sm text-orange font-semibold md:ml-2"
               data-cy={`sb-${sel}1`}
               sel={sel}>
            { label }
          </div>
        </div>
        <button className={`text-sm text-gray-text font-normal md:-mx-3
                    ${ sel === page ? 'hidden' : ''}`}
             data-cy={`sb-${sel}2`}
             sel={sel}>
          { label }
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-64 bg-white rounded-lg"
         style={{ boxShadow: "0px 3px 50px #0000000D" }}
         onClick={ev => {
                    const sel = ev.target.getAttribute('sel');
                    // make sure click is on a button
                    if (sel) {
                      buttons[sel]();
                    }}}>
      <div className={`ml-6 space-y-4 my-6`}>
        <Selection label="My Profile" sel="profile" />
        <Selection label="My Preferences" sel="prefs" />
        <Selection label="Account Settings" sel="account" />
        <Selection label="Billing" sel="billing" />
        <Selection label="Sign Out" sel="signout" />
      </div>
    </div>
  );
}

const EditDropdown = () => {

  const [dropdown, setDropdown] = useState(false);

  const dis = useDispatch();

  const path = window.location.pathname;
  const page = path.includes('profile') ? 'profile'
               : path.includes('prefs') ? 'prefs'
               : path.includes('account') ? 'account'
               : path.includes('billing') ? 'billing'
               : '';

  const buttons = {
    profile:     () => changePage('edit/profile'),
    prefs:       () => changePage('edit/prefs'),
    account:     () => changePage('edit/account'),
    billing:     () => changePage('edit/billing'),
    signout:     () => signout(dis)
  };

  const text_class = p =>
    p === page ? `relative font-sans font-semibold sm:text-sm
                  md:text-xs lg:text-sm text-base text-orange`
               : `relative font-sans font-normal sm:text-sm
                  md:text-xs lg:text-sm text-base text-gray-text`;

  return (
    <div className="relative mx-auto flex flex-row w-10/12 bg-white
                    rounded-lg space-x-3">
      <div className="w-full flex flex-row sm:flex-col
                      space-y-2 sm:space-y-0
                      justify-around items-center">
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'profile' && ! dropdown && 'sm:hidden' }`}
             data-cy="edit-profile-sm"
             onClick={buttons.profile}>
          <button className={ text_class('profile') }>
            My Profile
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'prefs' && ! dropdown && 'sm:hidden' }`}
             data-cy="edit-prefs-sm"
             onClick={buttons.prefs}>
          <button className={ text_class('prefs') }>
            My Preferences
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'account' && ! dropdown && 'sm:hidden' }`}
             data-cy="edit-account-sm"
             onClick={buttons.account}>
          <button className="-my-1">
            <span className={ text_class('account') }>
              Account Settings
            </span>
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ page !== 'billing' && ! dropdown && 'sm:hidden' }`}
             data-cy="edit-billing-sm"
             onClick={buttons.billing}>
          <button>
            <span className={ text_class('billing') }>
              Billing
            </span>
          </button>
        </div>
        <div className={`mx-auto flex flex-row h-12
                         items-center space-x-1
                         ${ ! dropdown && 'sm:hidden' }`}
             data-cy="edit-signout-sm"
             onClick={buttons.signout}>
          <button>
            <span className={ text_class('Signout') }>
              Sign Out
            </span>
          </button>
        </div>
        <div className={`hidden sm:block absolute right-3 top-2.5`}>
          <button onClick={() => setDropdown(!dropdown)}>
            <HamburgerIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export { Header,  EditSidebar, EditDropdown };
