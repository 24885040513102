import React from 'react';

const MessageAckIcon = () =>
<svg xmlns="http://www.w3.org/2000/svg"
     style={{ height: "3rem", width: "3rem" }}
     width="63.497" height="63.5" viewBox="0 0 63.497 63.5">
  <title>Message sent</title>
  <g transform="translate(-0.001)">
    <path style={{ fill: "#ffbb23" }}
          d="M105.045,252.592l-1.3-11.951a1.861,1.861,0,0,0-1.648-1.648l-11.951-1.3a1.859,1.859,0,0,0-1.926,1.151l-6.721,16.6c-.015.036-.028.073-.04.11a4.965,4.965,0,0,0,.371,4.547,3.886,3.886,0,0,0,3.266,1.553h0a6.654,6.654,0,0,0,2.094-.369c.037-.012.074-.026.11-.04l16.6-6.721A1.861,1.861,0,0,0,105.045,252.592Zm0,0"
          transform="translate(-71.033 -208.202)"/>
    <path style={{ fill: "#ff4f23" }}
          d="M111.587,253.7a1.857,1.857,0,0,0-.534-1.113L90.047,273.589a3.993,3.993,0,0,0,2.891,1.114h0a6.657,6.657,0,0,0,2.094-.369c.037-.013.074-.026.11-.041l16.6-6.721a1.86,1.86,0,0,0,1.151-1.926Zm0,0"
          transform="translate(-78.878 -221.256)"/>
    <path style={{ fill: "#fef4f5" }}
          d="M62.761,1.553A3.884,3.884,0,0,0,59.495,0,6.657,6.657,0,0,0,57.4.369L3.438,18.356C.583,19.308.063,20.97.006,21.875s.255,2.619,2.97,3.915L22.257,35A15.945,15.945,0,0,1,28.5,41.244l9.208,19.28c1.237,2.59,2.867,2.977,3.729,2.977.77,0,2.672-.335,3.706-3.437L63.132,6.1A4.963,4.963,0,0,0,62.761,1.553Zm0,0"/>
    <path style={{ fill: "#ffebe6" }}
          d="M244.753,8.984,208.188,45.55a14.024,14.024,0,0,1,2.682,3.563l9.207,19.28c1.237,2.59,2.868,2.977,3.729,2.977.77,0,2.672-.335,3.706-3.437L245.5,13.97a4.963,4.963,0,0,0-.372-4.547A3.624,3.624,0,0,0,244.753,8.984Zm0,0"
          transform="translate(-182.367 -7.87)"/>
    <path style={{ fill: "#ffbb23" }}
          d="M205.675,42.236a1.861,1.861,0,0,1-1.315-3.176L238.293,5.127a1.86,1.86,0,1,1,2.631,2.631L206.991,41.691A1.853,1.853,0,0,1,205.675,42.236Zm0,0"
          transform="translate(-178.537 -4.014)"/>
    <path style={{ fill: "#ffbb23" }}
          d="M208.207,45.541a1.86,1.86,0,0,0,2.631,0l33.934-33.933a1.861,1.861,0,0,0,0-2.631Zm0,0"
          transform="translate(-182.384 -7.863)"/>
  </g>
</svg>

export { MessageAckIcon };
