import React from 'react';
import { changePage } from '../../utils';
import { TopLogo } from './TopLogo';

const Footer = () => {

  const toolbar =
    [['Contact us', 'contact'],
     ['Terms of service', 'tos'],
     ['Tech support', 'techsupport'],
     // ['Careers', 'careers'],
     ['About us', 'about'],
   ];

  return (
    <div className="w-full mt-6">
      <div className="flex flex-row w-full items-center bg-gray-footer
                      space-x-3 justify-around py-4 px-4
                      sm:flex-col sm:space-y-3">
        <div className="ml-4">
          <button onClick={() => changePage('splash')}>
            <TopLogo size="medium" />
          </button>
        </div>
        { toolbar.map(([lab, btn]) => (
              <button className="hover:text-blue-500"
                      data-cy={`${btn}-button`}
                      key={btn}
                      onClick={() => changePage(btn)} >
                {lab}
              </button>
            )
          )
        }
      </div>
    </div>
  );
}

export { Footer };
