import React, { useEffect } from 'react';
import { consolelog } from '../utils'; // eslint-disable-line no-unused-vars
import { useStore, useDispatch } from 'react-redux';
import { Router, globalHistory } from '@reach/router';
import { changePage, GoodPromise, clone, split, get_subobj } from '../utils';
import { TEST_MODE, CYPRESS_MODE, NONPROD_MODE, // eslint-disable-line no-unused-vars
         LIVE_MODE, FS_MODE } from '../config';
import { JOKE_CAT } from '../enums';
import { empty_store, profile_reset_action, jokes_reset_action,
         reset_store, others_reset_action, profile_update_action,
       } from '../store';
import { Splash, Signin, PasswordResetReq } from './pages/Splash';
import { NotFound, Waiting, SignOut } from './pages/StaticPages';
import { Tos, About, FullTos, FullCookies, FullPrivacy
       } from './pages/StaticPages';
import { Contact, TechSupport } from './pages/Contact';
import { Vote } from './pages/Vote';
import { Matches } from './pages/Matches';
import { Connections } from './pages/Connections';
import { Conversations } from './pages/Conversations';
import { OtherUser } from './pages/OtherUser';
import { EditPrefs, EditProfile, EditAccount, EditBilling }
  from './pages/EditPages';
import { Signup1, Signup2, Signup3, SignupVote, SignupDone }
  from './pages/SignupPages';
import { Admin } from './admin/Admin';
import { affinity } from '../matching';
import { onProfileUpdate, onPubProfileUpdate, onJokesUpdate,
         onMessagesUpdate }
  from '../listeners';
import { db_query, firebase, auth } from '../App';
import { classify, fetch_thread } from './pages/utils';
import { thread_id } from '../dbstructure';

/**************************************************************
* Handling promises
*
* Almost every page begins with a useEffect call that contains:
* 'wait_for_data.then(() => setReady(true))' (or something to that effect).
* When a db operation happens (ie an FSQuery op), it returns its result
* as a promise; there may be some then clauses added, and that promise goes
* into wait_for_data. The page shows a "waiting" message until the
* setReady(true) call. There are a few pages that never need data; there
* are also a few that wait for data *they themselves request*. Anyway,
* point is that no page uses data that hasn't been loaded. (There are a
* few cases where database calls are made but the promise does not go
* into wait_for_data; these are cases where there's just no need to wait.)
*
* Error-handling is a big issue. If a db op fails, there's not really
* much to do except try again. But it's not always clear *where* the
* error should be detected. (There are also some cases, like signing up
* with an already-used email, where there is a reasonable response that
* can be helpful to the user.) Usually, we should add a catch clause
* to the db call, and stay on the same page, but sometimes we should
* put the catch clause in the useEffect call. See comments, esp on
* useEffect calls.
*
* Note that wait_for_data is initialized with GoodPromise, so it *always*
* contains a promise.
***************************************************************/
let wait_for_data = GoodPromise();
const set_wait_for_data = promise => {
  wait_for_data = promise;
  // wait_for_data.then(() => { wait_for_data = promise });
};

function fbDateTime() {
  return FS_MODE
         ? firebase.firestore.Timestamp.now().toDate().toISOString()
         : new Date().toISOString();
}

// log file temporarily stored in localStorage
// m is a string, data an object
// ls_logfile in localStorage is an array of stringified objects
function log_message(m, data) {
  let oldlogs = localStorage.getItem("ls_logfile");
  let newlogs = oldlogs ? JSON.parse(oldlogs) : [];
  const msg = { time: fbDateTime(), msg: m, ...data && { data: data } };
  newlogs.push(msg);
  localStorage.setItem("ls_logfile", JSON.stringify(newlogs));
  if (TEST_MODE && ! CYPRESS_MODE) {
    console.log("LOG MESSAGE", JSON.parse(JSON.stringify(msg)));
    console.trace("Stack trace");
  }
}

/**********************************************
* INITIALIZATION OF STORE for user signing in.
***********************************************/

// jokes come from db in order of index (numerically), so
// we don't actually sort them here, just divide into core/noncore
function sort_jokes(jokes) {
  const [core, noncore] = split(jokes, r => r.cat === JOKE_CAT.CORE);
  return core.concat(noncore);
}

// profile is database profile, which includes 'others' - list of
// users somehow associated with this one
function init_others(dispatch, store, profile) {

  // augmented_profiles: return augmented profiles:
  //   combine public profiles (oprofiles) and relationship bits
  //   (like pokes, thread, etc.) from profile.others
  //   will be stored in 'others' in store
  // ids in oprofiles may be a proper subset of those in profile.others,
  // as some users may just be missing. further, some public profs may
  // be inactive. filter those, combine data from profiles, return list
  function augmented_profiles(oprofiles, profile) {
    const others = profile.others;
    const goodprofs = oprofiles.filter(p => p.active);
    const newprofs = goodprofs.map(p => ({ ...p, ...others[p.id],
                                           affinity: affinity(profile, p) }));
    return newprofs;
  }

  function fetch_messages(oids) {
    return Promise.all(oids.map(oid => fetch_thread(dispatch, store, oid)));
  }

  // only fetch profiles of non-blacklisted users
  const [ bl, goodids ] = split(Object.keys(profile.others),
                                oid => profile.others[oid].blacklist
                                       || profile.others[oid].blacklisted_by);
  // blacklist is kept in profile (not in 'others')
  dispatch(profile_update_action(
    { blacklist: Object.fromEntries(
        bl.map(id => [ id, get_subobj(profile.others[id],
                                      ['blacklist', 'blacklisted_by'])])
    )}
  ));
  if (goodids.length === 0) {
    return GoodPromise();
  } else {
    return (
      db_query
        .get('profiles', { ids: goodids })
        .then(ps => {
          // ps should include a profile for each 'goodid'.
          // if they are missing from db, query won't return their
          // profiles (so ps.length may be < goodids.length)
          // augmented_profiles filters for inactive, and augments
          let goodprofs = augmented_profiles(ps, profile);
          // eliminate matches that are no longer matches
          let [ badmatches, goodprofs2 ] =
            split(goodprofs,
                  p => classify(p) === 'matches' && p.affinity[2] === 0.0);
          db_query.remove('others', { user: profile.id,
                                      other_users: badmatches.map(m => m.id) });
          let others = clone(empty_store.others);
          for (var p of goodprofs2) {
            const c = classify(p);
            // remove matches whose affinity is zero
            if (c !== 'unknown') { // can't really happen
              others[c][p.id] = p;
            }
          }
          dispatch(others_reset_action(others));
          const thread_ids = Object.keys(others.threads);
          return fetch_messages(thread_ids)
                 .then(() => null);
        })
    );
  }
}

// called from sign-in process (Splash component). profile has been fetched,
// so is up-to-date; get other users' profiles
// jokes and messages will be fetched by listeners, as user will be
// subscribed to those docs after return from here
function init_store_from_db(dispatch, store, profile) {
  let { others: o, ...newprof } = profile;
  dispatch(profile_reset_action(newprof));
  const votes = profile.votes;
  const [voted, unvoted] = [[], []];
  return (
    db_query
      .get('jokes')
      .then(jokes => { // ADDCATCH
        jokes.forEach(j => (votes[j.id] ? voted : unvoted).push(j));
        // sort into core then noncore
        dispatch(jokes_reset_action({ unvoted: sort_jokes(unvoted),
                                      voted: voted }));
        return init_others(dispatch, store, profile);
      })
    );
}

// called from App for user who is returning from offsite. uid was
// obtained from auth. user may be anonymous or signed in, but
// if signed in must be active
function init_store_from_uid(dispatch, store, uid) {
  return (
    db_query
      .get('user_by_uid', { uid: uid })
      .then(p => {
        if (p.length === 0) {
          return 'error';
        }
        const profile = { ...p[0], ...p[1] }; // p = [pvt prof, pub prof]
        if (profile.email && ! profile.active) {
          // non-anonymous but inactive
          return GoodPromise('inactive');
        } else {
          return init_store_from_db(dispatch, store, profile);
        }
      })
  );
}

// value of store to use when visitor first arrives at site, or reset
// to when user signs out
function init_store() {
  return clone(empty_store);
}

// restore_state - fetch data for this user from db. called when:
// (1) user signs in (successfully) - called from Splash/signin
// (2) user returns from off-site. the user may be
//     anonymous, but they still have an entry in the db under their
//     uid, and they may have started filling in signup form
const restore_state = (user, dis, str) => {
  // initial visit to site; no user, nothing to restore
  if (! user) {
    return;
  }

  // user exists (possibly anonymous)
  const uid = user.uid;
  const email = user.email;

  if (email === 'kamin@illinois.edu'
      || email === 'julia.kamin@gmail.com') {
    changePage('admin');
    return GoodPromise();
  }

  return (
    init_store_from_uid(dis, str, uid)
    .then(e => {
      if (e) {
        return e;
      }
      if (! user.isAnonymous) {
        const id = str.getState().profile.id;
        db_query.subscribeAll([
          ['PrivateProfiles', id, onProfileUpdate(dis, str) ],
          ['PublicProfiles', id, onPubProfileUpdate(dis, str) ],
          ['Jokes', 'jokes', onJokesUpdate(dis, str) ],
        ]);
        const thread_ids = Object.keys(str.getState().others.threads)
                                 .map(k => thread_id(id, k));
        db_query.subscribeAll(
          thread_ids.map(tid => [ 'Messages', tid, onMessagesUpdate(dis, str) ])
        )
      }
    })
  );
}

const signout = dis => {
  reset_store(dis, init_store());
  localStorage.removeItem('laughstruck_user')
  db_query.unsubscribeAll();
  auth.signOut()
      .then(() => {
        changePage('splash');
  });
}

const Main = () => {
  const [dis, str] = [useDispatch(), useStore()];

  // wait for getCurrentUser
  // this guarantees: if main gets past "waiting", auth is correctly init'd,
  // so auth.currentUser, and functions no_auth, anon_user,
  // and signed_in (in App) can be relied upon
  const [ ready, setReady ] = React.useState(false);
  useEffect(() => {
    const f = () => wait_for_data
                    .then(() => setReady(true))
                    .catch(() => {
                        // setting ready true causes this code to be repeated
                        if (! ready) {
                          alert('There has been an unrecoverable database error.'
                                + '\nYou will be signed out; please sign in again.'
                                + '\nWe apologize for the inconvenience.');
                          setReady(true);
                          signout(dis);
                        }
                      });
    f();
  }, [ dis, ready ]);

  useEffect(() => {
    return globalHistory.listen(
      () => {
        window.scrollTo(0, 0);
      })
  }, []);

  if (! ready) {
    return <Waiting />;
  }

  // FOR DEBUGGING ONLY:
  if (! LIVE_MODE) {
    global.the_store = () => str.getState(); // for debugging only
    // FIXME: hard to explain this. it's just for debugging. in fsdb,
    // profile update listener is set (onSnapshot). for some reason, I can't get
    // that to work, so instead I just use a fixed function, onprofileupdate
    // (which is the only callback used anyway). however, I can't
    // just call onProfileUpdate directly, because this crashes cypress (?),
    // so instead do this nutty work-around.
    global.onprofileupdate = onProfileUpdate(dis, str);
    global.onpubprofileupdate = onPubProfileUpdate(dis, str);
    global.onmessagesupdate = onMessagesUpdate(dis, str);
    global.onjokesupdate = onJokesUpdate(dis, str);
  }

  return (
    <Router primary={true}>
      <Admin path="/admin" />
      <Splash path="/" />
      <Splash path="/splash" />
      <Signin path="/signin" />
      <PasswordResetReq path="/pwdresetreq" />
      <Contact path="/contact" />
      <Tos path="/tos" />
      <FullTos path="/tos/tos" />
      <FullCookies path="/tos/cookies" />
      <FullPrivacy path="/tos/privacy" />
      <TechSupport path="/techsupport" />
      {/* <Careers path="/careers" /> */}
      <About path="/about" />
      <Vote path="/vote/*" />
      <Matches path="/matches" />
      <Connections path="/connects" />
      <Conversations path="/convos/*" />
      <OtherUser path="/other/:oid" />
      <EditProfile path="/edit/profile" />
      <EditAccount path="/edit/account" />
      <EditPrefs path="/edit/prefs" />
      <EditBilling path="/edit/billing" />
      <SignupVote path="/signup/vote/*" />
      <Signup1 path="/signup/1" />
      <Signup2 path="/signup/2" />
      <Signup3 path="/signup/3" />
      <SignupDone path="/signup/done" />
      <Waiting path="/wait" />
      <SignOut path="/signout" />
      <NotFound default  />
    </Router>
  );
}

/*********************************
 DEV ONLY
*********************************/
// call db2text() in console, cut and paste into file, following format of testdb.js
// then call cleardb() if you want to continue working on site
function db2text() { // eslint-disable-line no-unused-vars
  let text = localStorage.getItem('laughstruck_test_db');
  text = JSON.stringify(JSON.parse(text), null, 4);
  let loc = document.getElementById('react-container');
  var node = document.createElement("pre");
  node.setAttribute("id", "textdb");
  node.setAttribute("style", "font-size:10px");
  var textnode = document.createTextNode(text);
  node.appendChild(textnode);
  loc.appendChild(node);
}
function cleardb() { // eslint-disable-line no-unused-vars
  let loc = document.getElementById('react-container');
  let node = document.getElementById('textdb');
  loc.removeChild(node);
}

export { log_message, sort_jokes,
         init_store, restore_state,
         init_store_from_db, init_store_from_uid, init_others,
         Main, wait_for_data, set_wait_for_data,
         signout,
       };
