import React from 'react';

// cy is solely for cypress id
const Explainer = ({ exp, cy_name, open, close, pos }) => {
  if (! pos) {
    pos = `-right-8 top-8`;
  }
  return (
    <div className={`absolute flex flex-row ${pos}
                     bg-white w-96 rounded-lg
                     z-10 sm:w-56
                     ${ open ? "" : "hidden" }`}
         data-cy={cy_name}
         style={{ boxShadow: "0px 3px 50px #0000000D" }}>
      <div className="m-4 text-sm font-normal text-gray-text"
           onClick={close}>
        { exp }
      </div>
    </div>
  );

}


export { Explainer };
