import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { profile_update_action,
         other_remove_action, other_update_action,
         user_messages_reset_action,
         blacklist_add_action } from '../../store';
import { changePage, invert, obj_equal, calculate_age, isAlpha, isBlank,
         GoodPromise } from '../../utils';
import { BadPromise } from '../../utils'; // eslint-disable-line no-unused-vars
import { db_query, storage } from '../../App';
import { config$test_snapshot_url, CYPRESS_MODE, PROD_MODE }
  from '../../config';
import { onMessagesUpdate } from '../../listeners';

import { GENDER, SEEKING,
         MARITAL_STATUS, SMOKER, SMOKER_PREF,
         DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         RELIGIOUS, RELIGIOUS_PREF } from '../../enums';
import { fbDateTime, thread_id } from '../../dbstructure';
import { storage_put, format_error } from '../../fsquery';
import imageCompression from 'browser-image-compression';
/***************************************************************
* Variable naming convention:
*   Use 'id' and 'profile' for this user
*   Use 'oid' and 'oprofile' for other user
***************************************************************/

/***************************************************************
* ACTIONS - these are all actions of buttons in ordinary (non-signup)
* pages. app or comp is always an argument, as these actions can usually
* be taken from multiple pages.
* TODO: should be consistent about using either app or comp
***************************************************************/

// classify(profile) - other users are classified according to their
// connection with this user. The seven classifications are in
// three groups representing toolbar pages:
//   matches:       matches
//   connections:   pokes, pokedbys, copokes (pokes not shown on any page)
//   conversations: threads
//
// there can be overlaps between these cases, but each other user goes
// into exactly one class: pokes and pokedbys can overlap; overlaps are 'copokes'
//
// as described in store, store.others is an object in which each other-user
// profile is kept in one of seven bins according to their classification.
// profiles redundantly contain bits (pokes, pokedby, thread, msg_avail,
// match) that are used to calculate their classification; it is an
// invariant of the store that for any other-user profile p,
// store.others[classify(p)][p.id] = p (and ow store.other[c][p.id] is undef)

// return classification of profile p
function classify(p) {
  return p.blacklist || p.blacklisted_by ? 'blacklist'
         : p.thread ? 'threads'
         : p.pokes && p.pokedby ? 'copokes'
         : p.pokes ? 'pokes'
         : p.pokedby ? 'pokedbys'
         : p.match ? 'matches'
         : 'unknown';
}

// others = store.others; return classification of id,
// or undef if id not present
function locate_other (id, others) {
  // this may be called (from onProfileUpdate) before others is init'd
  if (! others) {
    return null;
  }
  return Object.keys(others).find(k => Boolean(others[k][id]));
}

// get profile of user id
function fetch_other(id, others) {
  const l = locate_other(id, others)
  return l && others[l][id];
}

// change profile values to those from form if they differ from profile
// fields is array of pairs [fieldname, value]. value is a string
// check if there are changes
// if so, try to make changes in database
// if successful, make them in local store; if not, leave local store alone
//
// match type in profile in one way: profile val is int => cvt form val to int
//
// return object with changed fields
function changed_fields(profile, fields) {
  let changes = {};
  for (var [field, val] of fields) {
    let v = val;
    let f = profile[field];
    let t = typeof(f);
    if (t === "number") v = parseInt(val);
    const changed = t === 'object' || t === 'array'
                    ? ! obj_equal(v, f)
                    : v !== f;
    if (changed) {
      changes[field] = v;
    }
  }
  return changes;
}

// TODO: why is this so different from make_signup_changes? Only real
// diff is that there the changes are not written to db
function make_changes(changes, id, dis) {
  return (
    db_query.update('user', { id: id, ...changes })
            .then(() => dis(profile_update_action(changes)))
  );
}

// changes during signup are not written to db. I'm not sure if
// this whole bit about determining which fields have changed matters,
// but I'm leaving it for now. (fields *can* change, because user can
// go back during signup, but the thing of determining which Fields
// have changed was only to avoid a pointless db operation
function make_signup_changes(profile, dis, fields) {
  let changes = []
  for (var [field, val] of fields) {
    let v = val;
    let f = profile[field];
    let t = typeof(f);
    if (t === "number") { v = parseInt(val); }
    const changed = t === 'object' || t === 'array'
                    ? ! obj_equal(v, f)
                    : v !== f;
    if (changed) {
      changes[field] = v;
    }
  }

  dis(profile_update_action(changes));

  // return list of fields that changed
  return Object.keys(changes);
}

function compress_image(ss) {
  const options = {
    maxSizeMB: 0.1,          // (default: Number.POSITIVE_INFINITY)
    // see https://github.com/Donaldcwl/browser-image-compression
    //maxWidthOrHeight: number,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    //onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    //useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

    // following options are for advanced users
    // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
  }
  const errmsg = `error processing file ${ss.name}; please try a different file`;
  return imageCompression(ss, options)
         .catch(err => {
                  throw format_error('compress', 'compress_image')
                                    ({ msg: errmsg });
                });
}

function upload_snapshot(ss, id) {
  // would like to use regular storage when in FS mode if not
  // running emulators, but I don't know how to check that.
  if (PROD_MODE) { // (FS_MODE && ! CYPRESS_MODE) {
    const f = storage.ref().child(`snapshots/${id}/${ss.name}`);
    return compress_image(ss)
           .then(css => storage_put(f, css))
           .then(snapshot => snapshot.ref.getDownloadURL());
  } else if (CYPRESS_MODE) {
    return GoodPromise(`${config$test_snapshot_url}/${ss.name}`);
  } else {
    return compress_image(ss)
           .then(css => GoodPromise(`${config$test_snapshot_url}/${css.name}`));
  }
}

// go to OtherUser page
function goto_profile(oid) {
  changePage('other', oid);
}

// blacklist(dis, str, oprofile) - blacklist user
// on db, add blacklist, and remove other user from
// pokes if they are there
// locally, remove oprofile from others, unsubscribe if there is thread
// TODO: combining blacklist operation with page refresh seems
//       bogus. (E.g. in Report, there is a call 'blacklist(...)()';
//       that kind of thing shouldn't happen.) Same with poke below. Fix this.
// TODO: probably blacklist should no longer return a nullary function,
//       but simply do the thing.
function blacklist(dis, str, oprofile) {
  let id = str.getState().profile.id;
  let oid = oprofile.id;
  const now = fbDateTime();

  // remove other_user from local store and add blacklist
  dis(other_remove_action({ id: oid }));
  dis(blacklist_add_action({ id: oid,
                             blacklist: true,
                             blacklist_time: now,
                             blacklisted_by: false }));
  // add blacklist to db; no need to wait
  db_query.add('blacklist', { user: id, other_user: oid,
                              blacklist_time: now });
  // will be ignored if not subscribed
  db_query.unsubscribe('Messages', thread_id(id, oid));
}

// poke(dis, str, oprofile) - poke user
function poke(dis, str, oprofile) {
  const id = str.getState().profile.id;
  const oid = oprofile.id;
  const now = fbDateTime();
  const args = { user: id, other_user: oid, poketime: now };
  dis(other_update_action({ id: oid, pokes: true }));

  // add poke to db; no need to wait
  db_query.add('poke', args);

  // display with new 'others'
  //refresh();
}

/***************************************************************
* MESSAGE THREADS
*   last_msg_from:    newest message from other user in msg list
*   fetch_thread:     get msgs from db and set fields in oprofile
*   send_message:     send message to other user; if first-time
*                     message, subscribe to doc in Messages
***************************************************************/

// most recent message from oid, or null
const last_msg_from = (msgs, oid) => {
  for (var i = msgs.length - 1; i >= 0; --i) {
    if (msgs[i].user === oid) {
      return msgs[i];
    }
  }
  return null;
}

// get all messages in db from/to this user
// in store, add msg_avail and, if applicable, latest_msg (date of
// newest message from that user)
function fetch_thread(dis, str, oid) {
  const id = str.getState().profile.id;
  const oprofile = fetch_other(oid, str.getState().others);

  if (! oprofile) {
    return BadPromise();
  }

  return (
    db_query
    .get('messages', { user: id, other_user: oid })
    .then(msgs => {
      dis(user_messages_reset_action({ other_user: oid, msgs: msgs }));
      const last_msg = last_msg_from(msgs, oid);
      const latest_msg = last_msg && last_msg.date;
      const msg_avail = last_msg &&
                        (! oprofile.last_read
                         || oprofile.last_read < latest_msg);
      dis(other_update_action({ id: oid,
                                msg_avail: Boolean(msg_avail),
                                ...(msg_avail && { latest_msg: latest_msg}) }));
    })
  );
}

// send message to other user. if this is first message,
// db_query will add thread bit to both user's private profs;
// we subscribe to Messages/tid.
// returns false if msg is invalid, true ow
// TODO: should this function return promise, or put it on wait_for_data?
const send_message = (dis, str, oprofile, msg) => {
  if (isBlank(msg)) {
    return false;
  }
  const oid = oprofile.id;
  let id = str.getState().profile.id;
  const args0 = { user: id, other_user: oid };
  const tid = thread_id(id, oid);
  const now = fbDateTime();
  const first_msg = ! oprofile.thread;
  const msg_obj = { ...args0, thread_id: tid, msg: msg, date: now };
  const msg_args = { message: msg_obj,
                     first_message: first_msg };
  db_query.add('message', msg_args)
  .then(() => {
    dis(other_update_action( { id: oid, thread: true,
                               pokes: false, pokedby: false }));
    if (first_msg) {
      db_query.subscribe('Messages', tid, onMessagesUpdate(dis, str));
    }
  });
  return true;
}

// Fields for user data. <field>_ui2db maps string value to enum (integer) value
// Keys should be given in order that they are to appear in UI -
// JS guarantees preserving order of string keys, but not numeric keys, so
// define <field>_ui2db first, and invert that
const seeking_ui2db = {
  Romance: SEEKING.ROMANCE,
  Friendship: SEEKING.FRIENDSHIP,
  'Romance or friendship': SEEKING.NOPREF,
};
const seeking_db2ui = invert(seeking_ui2db)

// These objects represent the interface between UI values for selections
// (i.e. strings used as labels) and the corresponding database values (integers)
// If we want to give the UI more freedom, we'll have to fix this

// sorting not necessary but helpful for tests
const genderpref_ui2db =  u =>
  u.map(s => ({ Men: GENDER.MALE,
                Women: GENDER.FEMALE,
                'Nonbinary people': GENDER.NONBINARY })[s]).sort();

// Note: Order of genders in GENDER enum is different from order in UI
// No particular reason for this, and it doesn't matter, but can be confusing
const genderpref_db2ui = d =>
  d.map(i => ['Women', 'Men', 'Nonbinary people'][i - 1]);

const gender_ui2db = {
  Male: GENDER.MALE,
  Female: GENDER.FEMALE,
  Nonbinary: GENDER.NONBINARY,
}
const gender_db2ui = invert(gender_ui2db);

const marital_ui2db = {
  Single:  MARITAL_STATUS.SINGLE,
  'In a non-open relationship': MARITAL_STATUS.MARRIED,
  'In an open relationship': MARITAL_STATUS.OPEN,
  noinfo: MARITAL_STATUS.NOINFO,
}
const marital_db2ui = invert(marital_ui2db);

const maritalpref_ui2db =  u =>
  u.map(s => ({ Single: MARITAL_STATUS.SINGLE,
                'In non-open relationship': MARITAL_STATUS.MARRIED,
                'In open relationship': MARITAL_STATUS.OPEN})[s]);

const maritalpref_db2ui = d =>
  d.map(i => ['Single', 'In non-open relationship',
              'In open relationship'][i - 1]);

const smoker_ui2db = {
  Smoker: SMOKER.SMOKER,
  'Non-smoker': SMOKER.NONSMOKER,
  noinfo: SMOKER.NOINFO,
}
const smoker_db2ui = invert(smoker_ui2db);

const smokerpref_ui2db = {
  'Non-smoker': SMOKER_PREF.NONSMOKER,
  'No preference': SMOKER_PREF.NOPREF,
  noinfo: SMOKER_PREF.NOINFO,
}
const smokerpref_db2ui = invert(smokerpref_ui2db);

const drinker_ui2db = {
  Drinker: DRINKER.DRINKER,
  'Non-drinker': DRINKER.NONDRINKER,
  noinfo: DRINKER.NOINFO,
}
const drinker_db2ui = invert(drinker_ui2db);

const drinkerpref_ui2db = {
  'Non-drinker': DRINKER_PREF.NONDRINKER,
  'No preference': DRINKER_PREF.NOPREF,
  noinfo: DRINKER_PREF.NOINFO,
}
const drinkerpref_db2ui = invert(drinkerpref_ui2db);

const children_ui2db = {
  Kids: CHILDREN.CHILDREN,
  'No kids': CHILDREN.NOCHILDREN,
  noinfo: CHILDREN.NOINFO,
}
const children_db2ui = invert(children_ui2db);

const childrenpref_ui2db = {
  'No kids': CHILDREN_PREF.NOCHILDREN,
  'No preference': CHILDREN_PREF.NOPREF,
  noinfo: CHILDREN_PREF.NOINFO,
}
const childrenpref_db2ui = invert(childrenpref_ui2db);

const educlevel_ui2db = {
  'Rather not say': EDUC_LEVEL.NOINFO,
  'No degree': EDUC_LEVEL.NODEGREE,
  'High school': EDUC_LEVEL.HIGHSCHOOL,
  'Associate\'s': EDUC_LEVEL.ASSOC,
  'Bachelors': EDUC_LEVEL.BACHELOR,
  'Graduate': EDUC_LEVEL.GRADUATE,
}
const educlevel_db2ui = invert(educlevel_ui2db);

const educpref_ui2db = {
  'High school degree': EDUC_PREF.HIGHSCHOOL,
  'Associate\'s degree': EDUC_PREF.ASSOC,
  'College degree': EDUC_PREF.BACHELOR,
  'Graduate degree': EDUC_PREF.GRADUATE,
  'No preference': EDUC_PREF.NOPREF,
  noinfo: EDUC_PREF.NOINFO,
}
const educpref_db2ui = invert(educpref_ui2db);

const religious_ui2db = {
  'Rather not say': RELIGIOUS.NOINFO,
  'Religious': RELIGIOUS.RELIGIOUS,
  'Somewhat religious': RELIGIOUS.SOMEWHAT,
  'Not religious': RELIGIOUS.ATHEIST,
}
const religious_db2ui = invert(religious_ui2db);

const religiouspref_ui2db = {
  'Not religious': RELIGIOUS_PREF.ATHEIST,
  'At least somewhat religious': RELIGIOUS_PREF.SOMEWHAT,
  'Religious': RELIGIOUS_PREF.RELIGIOUS,
  'No preference': RELIGIOUS_PREF.NOPREF,
  noinfo: RELIGIOUS_PREF.NOINFO,
}
const religiouspref_db2ui = invert(religiouspref_ui2db);

const vote_db2ui = {
  0: 'noinfo',
  1: 'groan',
  2: 'meh',
  3: 'okay',
  4: 'alright',
  5: 'right on!'
}
const vote_ui2db = invert(vote_db2ui);

const birthday_check = b => {
  const match = b.match(/^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/);
  if (! match) {
    return "Please enter valid date: mm/dd/yyyy";
  }
  const [m, d] = match.slice(1,3).map(f => parseInt(f));
  if (m < 1 || m > 12) {
    return "Not a valid month";
  }
  if (d < 1 || d > 31) {
    return "Not a valid day";
  }
  const bdate = new Date(b);
  if (bdate.toString() === 'Invalid Date') {
    // only way date can be invalid is if day is not valid for month
    return "Not a valid date";
  }
  if (calculate_age(b) < 18) {
    return "You must be 18 or older to use this site";
  }
  return '';
}

// lo, hi non-empty strings
const agepref_check = (lo, hi) => {
  const l = parseInt(lo);
  if (! l) {
    return ["lo", "Please enter a valid age (>= 18)"];
  }
  const h = parseInt(hi);
  if (! h) {
    return ["hi", "Please enter a valid age (>= 18)"];
  }
  if (l < 18) {
    return ["lo", "Minimum age is 18"];
  }
  if (lo > hi) {
    return ["hi", "Max age cannot be less than min age"];
  }
  return ['', ''];
}

const username_changecheck = nm => {
  if (nm[0] === ' ') {
    return "Username cannot start with blank";
  }
  for (var c of nm) {
    if (! isAlpha(c) && c !== ' ') {
      return "Only letters and spaces allowed in user names";
    }
  }
  return '';
}

const snapshot_check = ss => {
  if (! ss.type.startsWith('image/')) {
    return "Not an image file"
  }
  if (ss.size > 5 * (2 ** 20)) {
    return "Exceeds max file size of 5MB";
  }
  return '';
}

const pwd_add_check = (pw1, pw2) => {
  if (pw1.length < 6) {
    return "Password must be at least 6 characters";
  }
  if (pw1 !== pw2) {
    return "Passwords must be the same";
  }
  return '';
}

const email_check = m => {
  const email_re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  if (! m.match(email_re)) {
    return 'Please enter valid email address';
  }
  return '';
}

const zipcode_check = z => {
  return z.match('^[0-9]{5}$')
         ? ''
         : 'Zip code is exactly five digits';
}

export { classify, locate_other, fetch_other,
         changed_fields, make_changes, make_signup_changes,
         upload_snapshot,
         goto_profile,
         blacklist, poke,
         last_msg_from, send_message, fetch_thread,
         seeking_db2ui, seeking_ui2db, genderpref_db2ui, genderpref_ui2db,
         gender_db2ui, gender_ui2db,
         marital_ui2db, marital_db2ui, maritalpref_ui2db, maritalpref_db2ui,
         smoker_ui2db, smoker_db2ui, smokerpref_ui2db, smokerpref_db2ui,
         drinker_ui2db, drinker_db2ui, drinkerpref_ui2db, drinkerpref_db2ui,
         children_ui2db, children_db2ui, childrenpref_ui2db, childrenpref_db2ui,
         educlevel_ui2db, educlevel_db2ui, educpref_ui2db, educpref_db2ui,
         religious_ui2db, religious_db2ui, religiouspref_ui2db, religiouspref_db2ui,
         vote_ui2db, vote_db2ui,
         birthday_check, agepref_check, username_changecheck,
         snapshot_check, pwd_add_check, email_check, zipcode_check,
       };
