import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { ConversationsUI } from '../ui/ConversationsUI';

import { fetch_other, send_message, blacklist, last_msg_from } from './utils';
import { signed_in, db_query } from '../../App';
import { changePage, split } from '../../utils';
import { other_update_action, ui_update_action } from '../../store';

// prop 'oid' indicates user has requested msg thread of that user
const Conversations = props => {

  const [ showOverlay, setShowOverlay ] = useState(null);

  const [dis, str] = [useDispatch(), useStore()];

  // inv: if oid = '', then text = ''
  //const [ oid, setOid1 ] = useState(str.getState().ui.convo_id);
  const [ text, setText1 ] = useState(str.getState().ui.convo_msg);
  //const setOid = i => { dis(ui_update_action({ convo_id: i })); setOid1(i); }
  const setText = t => { dis(ui_update_action({ convo_msg: t })); setText1(t); }

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  // maintain invariant: oid in path = stored ui.convo_id
  // oid in path is always accurate if that user has a thread
  // (following lines: I thought we could just check props['*'], but
  //  it can retain previous value, so need to check actual path. weird.)
  const path_has_oid = window.location.href.match(/.*[0-9]$/);
  const oid = path_has_oid && props['*'];
  const convo_id = str.getState().ui.convo_id;
  const convo_msg = str.getState().ui.convo_msg;
  const ui_update_oid = oid => dis(ui_update_action({ convo_id: oid }));
  // if inv holds, nothing to do
  if (path_has_oid) {
    if (oid !== convo_id) {
      setText('');
      if (str.getState().others.threads[oid]) {
        ui_update_oid(oid);
      } else {
        return <Redirect to={`/convos`} noThrow />;
      }
    } else {
      if (text !== convo_msg) {
        setText1(convo_msg);
      }
    }
  } else if (convo_id) {
    setText('');
    ui_update_oid('');
  }

  let oprofile = fetch_other(oid, str.getState().others); // null if oid undef

  const sendmsg = msg => send_message(dis, str, oprofile, msg);
  const by_time = (m1, m2) => m1.date < m2.date ? -1 : 1;
  const messages = oid &&
                   str.getState().others.threads[oid].messages.sort(by_time);

  // if we are reading messages from oid, reset msg_avail,
  // and if we are reading a new message, change last_read both in
  // store and in db
  if (oid && oprofile.msg_avail) {
    dis(other_update_action({ id: oid, msg_avail: false }));
    const last_msg = last_msg_from(messages, oid);
    if (last_msg) {
      // have a message from oid
      const latest = last_msg.date;
      const last_read = str.getState().others.threads[oid].last_read;
      if (last_read < latest) {
        dis(other_update_action({ id: oid, last_read: latest }));
        db_query.update('other', { id: str.getState().profile.id,
                                   oid: oid,
                                   last_read: latest });
      }
    }
  }

  // profiles of users with/without unread messages
  // we only need one list, but want it sorted with unread msgs first,
  // by date-of-newest-message within each group
  const by_last_msg = (c1, c2) => {
    const t = c => c.latest_msg || new Date();
    return new Date(t(c2)) - new Date(t(c1));
  }
  const this_thread = oid && str.getState().others.threads[oid];
  const threads = Object.values(str.getState().others.threads)
                  .filter(t => t.id !== oid);
  let thread_list = split(threads, p => p.msg_avail)
                    .map(profs => profs.sort(by_last_msg))
                    .flat();
  if (oid) {
    thread_list = [this_thread].concat(thread_list);
  }

  const buttons = {
    blacklist: p => () => { blacklist(dis, str, p);
                            changePage('convos'); }
  };

  // if there was a db_error, do not show send-message dialog
  return (
    <ConversationsUI threads={thread_list}
                     buttons={buttons}
                     text={text} setText={setText}
                     oprofile={oprofile}
                     messages={messages}
                     showOverlay={showOverlay}
                     setShowOverlay={setShowOverlay}
                     sendmsg={sendmsg}>
    </ConversationsUI>
  );
}

export { Conversations };
