import React from 'react';

// used as info marker in some places (like info icon)
const QuestionIcon = () =>
  // <img src="images/question.svg" alt="question mark"/>;
  <svg xmlns="http://www.w3.org/2000/svg"
       width="15.904" height="15.904" viewBox="0 0 15.904 15.904">
    <title>Question mark</title>
    <path style={{ fill: "#ff4f23" }}
          d="M7.952,0A7.952,7.952,0,1,0,15.9,7.952,7.961,7.961,0,0,0,7.952,0ZM7.621,13.254a.994.994,0,1,1,.994-.994A1,1,0,0,1,7.621,13.254Zm.994-4.042v1.06a.331.331,0,0,1-.331.331H6.958a.331.331,0,0,1-.331-.331V8.615A1.327,1.327,0,0,1,7.952,7.289,1.325,1.325,0,1,0,6.627,5.964V6.3a.331.331,0,0,1-.331.331H4.97A.331.331,0,0,1,4.639,6.3V5.964A3.313,3.313,0,1,1,8.615,9.212Z"/>
  </svg>

export { QuestionIcon };
