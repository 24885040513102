import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { PopupNote } from '../ui/PopupNote';

import { VoteNoJokes, VoteUI } from '../ui/VoteUI';
import { NotFound } from './StaticPages';
import { NONPROD_MODE } from '../../config';
import { db_query, signed_in } from '../../App';
import { vote_add_action, joke_remove_action, vote_update_action, }
  from '../../store';
import { JOKE_CAT, JOKE_FLAG } from '../../enums';
import { changePage } from '../../utils';

// only prop is jokeid, if present
//
// this component uses only data that is guaranteed to already be
// loaded - namely, the jokes - so no useEffect waiting for data
// (subtlety: if we're returning to this url from off-site, useEffect
// in Main guarantees that state has been restored)
const Vote = props => {
  const has_jokeid = props['*'].match(/^[0-9]+$/);
  const jokeid = has_jokeid && props['*'];

  const [ alert, setAlert ] = useState('');

  // overlay is joke-report dialog
  const [ showOverlay, setShowOverlay ] = useState(false);

  const [dis, str] = [useDispatch(), useStore()];

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  function choose_joke() {
    const jokes = str.getState().jokes;
    const obscene_ok = str.getState().profile.obscene_okay;
    if (jokes.unvoted.length === 0) {
      return '0';
    }
    const joke = jokes.unvoted[0];
    if (joke.isObscene && ! obscene_ok) {
      dis(joke_remove_action({ jokeid: joke.id }));
      return choose_joke();
    }
    if (joke.cat === JOKE_CAT.CORE) {
      return joke.id;
    }
    if (NONPROD_MODE) {
      // make joke choice predictable for tests
      return joke.id;
    }
    const i = Math.floor(Math.random() * jokes.unvoted.length);
    return jokes.unvoted[i].id;
  }

  if (! jokeid) {
    const j = choose_joke();
    return <Redirect to={`/vote/${j}`} noThrow />;
  }

  if (jokeid === '0') {
    return (
      <VoteNoJokes onclick={() => changePage('matches')} />
    );
  }

  const fetch_joke = () => {
    let jokes = str.getState().jokes;
    // normally these are noncore jokes, since user has already voted on
    // all core jokes in onboarding; but core jokes may change, and users
    // should always get the new core jokes first
    // this may be re-vote. result is joke plus previous vote, if any
    let joke = jokes.unvoted.find(j => j.id === jokeid);
    let prev_vote = Boolean(str.getState().profile.votes[jokeid]);
    if (! joke && prev_vote) {
      joke = jokes.voted.find(j => j.id === jokeid);
    }
    return { joke: joke, prev_vote: prev_vote };
  }

  const record_vote = vote => {
    const profile = str.getState().profile;
    if (profile.votes[jokeid]) {
      dis(vote_update_action(vote));
    } else {
      dis(vote_add_action(vote));
    }
    // add vote doesn't care if it's add or update (i.e. new vote or revote)
    // no need to wait; don't care if db op fails
    db_query.add('vote', { user: profile.id, ...vote });
    if (vote.vote > 0) {
      db_query.update('jokestat', vote);
    } else {
      db_query.update('jokereport', { id: profile.id, ...vote });
    }
  }

  const submitvote = rating_ref => {
    return e => {
      e.preventDefault();
      let rating = rating_ref.current.getAttribute('data-value');
      if (! rating) {
        setAlert('You forgot to vote!')
        return;
      }
      dis(joke_remove_action({ jokeid: jokeid }));
      let r = parseInt(rating);
      let vote = { joke: jokeid, vote: r,
                   flag: JOKE_FLAG.NONE, flag_comment: "" };
      // no need to wait
      record_vote(vote);
      changePage('vote', choose_joke());
    };
  }

  // user reports joke as objectionable
  const submitreport = (obscene_ref, report_ref) => {
    return e => {
      e.preventDefault();
      let o = obscene_ref.current.getAttribute('value');
      let r = report_ref.current.getAttribute('value');

      let flag = { Obscene: JOKE_FLAG.OBSCENE,
                   Racist: JOKE_FLAG.RACIST,
                   Sexist: JOKE_FLAG.SEXIST,
                   Other: JOKE_FLAG.OTHER,
                 }[o];

      dis(joke_remove_action({ jokeid: jokeid }));
      let vote = { joke: jokeid, vote: 0, flag: flag, flag_comment: r };
      // no need to wait
      record_vote(vote);
      changePage('vote', choose_joke());
    }
  }

  const { joke, prev_vote } = fetch_joke();

  if (! joke) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <VoteUI joke={joke}
              prev_vote={prev_vote}
              showOverlay={showOverlay}
              actions={{ vote: submitvote, report: submitreport,
                         show_report: setShowOverlay }} />
      <PopupNote notmsg={alert}
                 onclose={() => setAlert('')}
                 nottype="info" />
    </React.Fragment>
  );
}

export { Vote };
