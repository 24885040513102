import React from 'react';

const style = {
  fill: "none",
  stroke: "#b1b1b1",
  strokeLinecap: "round",
  strokeWidth: "2px"};

const CancelIcon = () =>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="14.17" height="14.17" viewBox="0 0 14.17 14.17">
    <title>Cancel</title>
    <g transform="translate(-369.185 -109.415)">
      <line style={style} x2="16.04" transform="translate(370.599 122.171) rotate(-45)"/>
      <line style={style} x2="16.04" transform="translate(370.599 110.829) rotate(45)"/>
    </g>
  </svg>;

  //<img alt="cancel" src="images/cancel-x.svg" />;

export { CancelIcon };
