import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { JOKE_FLAG } from './enums';
import { calculate_age, // latest_bday, earliest_bday,
         objectify, all_combs } from './utils';
import { SEEKING,
         MARITAL_STATUS, MARITAL_PREF,
         SMOKER, SMOKER_PREF,
         DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         RELIGIOUS, RELIGIOUS_PREF,
       } from './enums';
import { db_query } from './App';
import { others_add_action, other_remove_action, other_update_action }
 from './store';
import { locate_other } from './components/pages/utils';

// if certain fields change, need to recalc matches, i.e. fetch
//   potential matches from db, etc. (see 'filter' below)
// if certain other fields change, should recalc affinities
// this is about which fields are involved 'filter' (i.e. query to
// the db) and which in 'recalc_affinities' (affinities of profiles
// already fetched into store)
const recalc_matches_fields = new Set([
  'gender', 'gender_pref',
  'zipcode', // note: not currently used, but would require recalc
  // not used in 'filter', but non-matching profiles may be eliminated
  // after db fetch, so we need to refetch. (these fields *should*
  // be handled in 'filter')
  'seeking',  'married', 'married_pref',
])
const recalc_affinities_fields = new Set([
  'birthday', 'age_low', 'age_high',
  'height', 'low_height', 'high_height',
  'smoker', 'smoker_pref', 'drinker', 'drinker_pref',
  'children', 'children_pref', 'educ_level', 'educ_pref',
  'religious', 'religious_pref',
]);

// avg distance between two users' scores: 0 <= avg <= 4
// scale to range 50-100
const humor_scale = avg => Math.round(50 + (4 - avg) * 12.5);
// avg is same, pen is prefs_penalty, range 0 - 1
// scale to range 50 - 100
const humorplus_scale = (avg, pen) => Math.round(50 + (4 - avg) * pen * 12.5);

// basic affinity of two users based only on joke votes
// u, v lists of votes
// return [affinity, # of jokes in common]
// where affinity is average difference (0 - 5), inverted (5 - avg),
// and scaled to 100
// if no jokes in common, return [0, 0]
function humor(votes1, votes2) {
  let sum = 0, n = 0;
  for (var [j, v1] of Object.entries(votes1)) {
    const v2 = votes2[j];
    if (v2) {
      if (v1.flag === JOKE_FLAG.NONE
          && v2.flag === JOKE_FLAG.NONE) {
        ++n;
        sum += Math.abs(v1.vote - v2.vote);
      }
    }
  }
  return n === 0 ? [0, 0] : [sum / n, n];
}

// return a percentage to be multiplied by affinity to produce
// affinity+. If all prefs are satisfied, this will be 100%; if
// some very critical pref is violated - e.g. other user is married -
// then it will be 0%. Most violations are in the middle. Result of
// this call is product of individual penalties of each pref.
// see spreadsheet 'algorithm notes' for all penalties
//
// args: p1, p2 profiles of two users. p1 is the current user and
// p2 some other user, but that doesn't actually matter: prefs_penalty
// is commutative - for each item, we calculate the penalty in
// both directions then take the maximum.
//
// penalties are generally in the 10-25% range - e.g. if p1 prefers a
// non-smoker and p2 is a smoker, we assess a 25% penalty. in the code,
// we work in the "positive" direction, so in this case we calculate the
// penalty as 75%; that way, we can add up penalties by multiplying.
// in cases like height, the penalty depends upon how much the preferred
// height range differs from the actual height.
//
// some of these penalties can never occur because the filter for
// fetching prospective matches eliminates them (see 'filter' below). but
// we will not assume anything about p1 and p2 (except that they are both
// active), because the 'filter' function is very much in flux
function prefs_penalty(p1, p2) {
  // penalties derived from 'algorithm notes'
  // when there are two numbers given, the second one represents
  // the case where one user has a pref but the other has not provided
  // a value; only one number is given in the cases where the value is
  // provided at sign-up (e.g. age), so cannot be missing.
  const penalties = {
    seeking: 0.0,          // value is mandatory
    gender: 0.0,           // value is mandatory
    age: 0.0,              // value is mandatory; penalty per year off (below
                           // min or above max)
    height: [.95, .85],    // penalty per inch off if height known; fixed
                           // 15% penalty if height not given by p2
    marital: [0.0, 0.85],  // disqualifying if marital status known and
                           // incompatible; fixed 15% if not given
    smoker: [.75, .875],   // [known & incompatible, not given]
    drinker: [.75, .875],  // [known & incompatible, not given]
    children: [.75, .875], // [known & incompatible, not given]
    educ: .90,             // penalty per difference (to subtract); if not given,
                           // assume p2 has associate's degree
    // for religious pref, if not given, assume p2 is "somewhat religious"
    religious1: .85,       // penalty if off by 1
    religious2: .75,       // penalty if off by 2
  };

  let penalty = 1.0;
  let l; // general-purpose intermediate value

  // seeking
  // possibilities are friendship, romance, or either (i.e. no pref)
  // penalty only if both have prefs and they differ
  const s = [ SEEKING.FRIENDSHIP, SEEKING.ROMANCE ];
  if (s.includes(p1.seeking) && s.includes(p2.seeking)
      && p1.seeking !== p2.seeking) {
    penalty *= penalties.seeking;
  }

  // gender
  // possible gender are M, F, other; possible prefs M, F, anyone (no pref)
  // penalty if either has pref and it is different from other's gender
  const gender_pen = (p, q) => ! (p.gender_pref.includes(q.gender));
  if (gender_pen(p1, p2) || gender_pen(p2, p1)) {
    penalty *= penalties.gender;
  }

  // age - penalty determined by how many years the actual age differs
  //       from pref (below min or above max)
  const age1 = calculate_age(p1.birthday),
        age2 = calculate_age(p2.birthday);
  // penalty of profile p given other user's age
  const years_off = (p, age) =>
    Math.max(0, parseInt(p.age_low) - age, age - parseInt(p.age_high));
  l = Math.max(years_off(p1, age2), years_off(p2, age1));
  if (l > 0) {
    penalty *= Math.max(0.0, penalties.age ** l);
  }

  // height - penalty determined by how many inches the actual height
  //          differs from the pref (below min or about max)
  // height and height_pref optional
  const ht_pen = (p, q) => {
    // pref not given
    if (p.low_height === 1) {
      return 1.0;
    }
    if (q.height === 0) {
      return penalties.height[1];
    }
    const htq = q.height;
    const lop = p.low_height;
    const hip = p.high_height;
    const height_diff = Math.max(0, lop - htq, htq - hip);
    return penalties.height[0] ** height_diff;
  }
  // penalties in pos direction, so lower is worse penalty
  l = Math.min(ht_pen(p1, p2), ht_pen(p2, p1));
  penalty *= l;

  // marital
  // in this case, prefs is a set; actual status is in the set (no
  // penalty) or not (full penalty)
  const marital_pen = (p, q) => {
    const m2mp = {
      [MARITAL_STATUS.SINGLE]: MARITAL_PREF.SINGLE,
      [MARITAL_STATUS.MARRIED]: MARITAL_PREF.MARRIED,
      [MARITAL_STATUS.OPEN]: MARITAL_PREF.OPEN,
      [MARITAL_STATUS.NOINFO]: 0, // can't be in p's prefs
    };
    // no penalty if pref not given or is satisfied
    // ow penalty if marital status not given or not satisfied
    return (p.married_pref.length === 0
              || p.married_pref.includes(m2mp[q.married]))
            ? 1.0
            : q.married === MARITAL_STATUS.NOINFO
              ? penalties.marital[1]
              : penalties.marital[0]
  }
  // penalties in pos direction, so lower is worse penalty
  l = Math.min(marital_pen(p1, p2), marital_pen(p2, p1));
  penalty *= l;

  // smoker
  // note that prefs here are either noinfo, nopref, or nonsmoker
  // so full penalty is only if user has nonsmoker pref and other is smoker
  const smoker_pen = (p, q) => {
    return (p.smoker_pref === SMOKER_PREF.NOINFO
            || p.smoker_pref === SMOKER_PREF.NOPREF)
           ? 1.0
           : q.smoker === SMOKER.NOINFO
             ? penalties.smoker[1]
             : q.smoker === SMOKER.SMOKER
               ? penalties.smoker[0]
               : 1.0;
  }
  // penalties in pos direction, so lower is worse penalty
  l = Math.min(smoker_pen(p1, p2), smoker_pen(p2, p1));
  penalty *= l;

  // drinker
  // same as smoker - only prefs are noinfo, nopref, and nondrinker
  const drinker_pen = (p, q) => {
    return (p.drinker_pref === DRINKER_PREF.NOINFO
            || p.drinker_pref === DRINKER_PREF.NOPREF)
           ? 1.0
           : q.drinker === DRINKER.NOINFO
             ? penalties.drinker[1]
             : q.drinker === DRINKER.DRINKER
               ? penalties.drinker[0]
               : 1.0;
  }
  // penalties in pos direction, so lower is worse penalty
  l = Math.min(drinker_pen(p1, p2), drinker_pen(p2, p1));
  penalty *= l;

  // children
  // same as smoker - only prefs are noinfo, nopref, and nochildren
  const children_pen = (p, q) => {
    return (p.children_pref === CHILDREN_PREF.NOINFO
            || p.children_pref === CHILDREN_PREF.NOPREF)
           ? 1.0
           : q.children === CHILDREN.NOINFO
             ? penalties.children[1]
             : q.children === CHILDREN.CHILDREN
               ? penalties.children[0]
               : 1.0;
  }
  // penalties in pos direction, so lower is worse penalty
  l = Math.min(children_pen(p1, p2), children_pen(p2, p1));
  penalty *= l;

  // educ_pref
  // no penalty if pref = NOPREF or NOINFO
  // if value is NOINFO, assume assoc's degree
  // ow penalty is (off-by-one penalty)^(# degrees off)

  // difference between p's educ pref and q's educ level,
  // if q's educ level is below p's pref; 0, ow
  const educ_diff = (p, q) => {
    if (p.educ_pref === EDUC_PREF.NOINFO ||
        p.educ_pref === EDUC_PREF.NOPREF) {
      return 0;
    }
    const qlevel = q.educ_level === EDUC_LEVEL.NOINFO
                   ? EDUC_LEVEL.ASSOC
                   : q.educ_level;
    // NB educ levels and prefs agree (see enums)
    return Math.max(0, p.educ_pref- qlevel)
  }
  // higher diff => higher penalty
  const n = Math.max(educ_diff(p1, p2), educ_diff(p2, p1));
  penalty *= penalties.educ ** n;

  // no penalty if pref is noinfo or nopref
  // otherwise depends on how far apart
  // if value is noinfo, assume 'somewhat religious'
  // or p prefers non-drinker and q is a non-drinker
  const religious_pen = (p, q) => {
    if (p.religious_pref === RELIGIOUS_PREF.NOINFO
        || p.religious_pref === RELIGIOUS_PREF.NOPREF) {
      return 1.0;
    }
    const qrelig = q.religious === RELIGIOUS.NOINFO
                   ? RELIGIOUS.SOMEWHAT
                   :q.religious;
    // NB religious and religious prefs agree (see enums)
    const diff = Math.abs(p.religious_pref - qrelig);
    return [1.0, penalties.religious1, penalties.religious2][diff];
  }
  // penalties in pos direction, so lower is worse penalty
  penalty *= Math.min(religious_pen(p1, p2), religious_pen(p2, p1));

  return penalty;
}

// return [humor-affinity, # of jokes, humor-plus or 0]
// humor-plus is scaled to 50-100, but return 0 if penalty = 0
function affinity(profile, oprofile) {
  let [a, n] = humor(profile.votes || [], oprofile.votes || []);
  let l = prefs_penalty(profile, oprofile);
  return [humor_scale(a), n, l === 0 ? 0 : humorplus_scale(a, l)];
}

// recalculate affinities of existing or new matches
// called when new matches are fetched (from recalc_matches) and
// when pref or profile change in a way that can affect affinities
// but not invoke a recalc_matches
// TODO: we never recalculate affinities of existing users other than
//       matches. we should. I don't think we should delete those users
//       even if their new affinity is zero, but we should recalc.
const recalc_affinities = (dis, str) => {
  const profile = str.getState().profile;

  // users to remove if affinity changes to 0
  let removed = [];
  for (var p of Object.values(str.getState().others.matches)) {
    const aff = affinity(profile, p);
    if (aff[0] === p.affinity[0] && aff[2] === p.affinity[2]) {
      continue;
    }
    if (aff[2] === 0.0) {
      dis(other_remove_action({ id: p.id }));
      removed.push(p.id);
    } else {
      dis(other_update_action({ id: p.id, affinity: aff }));
    }
  }
  if (removed.length > 0) {
    // no need to wait
    // also, note that we don't want to remove users who are blacklisted,
    // but those would not be in others.matches.
    db_query.remove('others', { user: profile.id,
                                other_users: removed });
  }
}

// get potential matches, filter out blacklists, pokes, threads,
// calculate humor and humor+, put new profiles in oprofiles.
//
// returns a promise
//
function recalc_matches(dis, str) {
  const profile = str.getState().profile;

  function get_users(filter) {
    return db_query.get('potential_matches', { conditions: filter });
  }

  // filter(level): generate a db query to obtain prospective matches
  //
  // note  that 'level' is not currently used;
  // idea is to have a first-level, highly constrained filter (level 0), and
  // make it less constrained if not enough users matched, but not doing
  // this for now. instead, we fetch every match; long term this may not
  // be viable.
  //
  // returns an array of queries, which should represent disjoint sets.
  // each query is a list of where clauses, each where clause an
  // array [field, operator, value]. So typical query is:
  //    [['active', '==', true], ['gender', ==, 2],
  //     ['gender_pref', 'array-contains', 1]]
  // currently, can generate up to four queries: if this user is seeking
  // romance, there will be two equality tests for other users seeking
  // (romance or nopref) (and likewise for friendship), and if the user has
  // also given two married prefs, those will generate two equality tests.
  //
  // filter takes care of all 'eliminational' prefs except two: (1) we cannot
  // test other users' married prefs, because that would require an
  // 'array-contains' clause, and we can only have one of those per
  // db query. note that these are still calculated in prefs_penalty
  // above, just as a failsafe. (2) we do not test for age range here.
  // since we don't store age
  function filter(level) {
    // for each 'eliminational' field, generate from zero to two
    // 'where' clauses. then create all combinations of those and
    // db_query will send a query for each combination.
    const active = [ ['active', '==', true] ];
    const genderpref = [ ['gender_pref', 'array-contains', profile.gender] ];
    const gender = profile.gender_pref.length === 1
                        ? [ ['gender', '==', profile.gender_pref[0]] ]
                        : profile.gender_pref.length === 2
                          ? [ ['gender', 'in', profile.gender_pref] ]
                          : [];
    // const lo_age = [ ['birthday', "<=", latest_bday(profile.age_low)] ];
    // const hi_age = [ ['birthday', ">=", earliest_bday(profile.age_high)] ];
    const seeking = profile.seeking === SEEKING.NOPREF
                    ? []
                    : profile.seeking === SEEKING.ROMANCE
                      ? [ ['seeking', '==', SEEKING.ROMANCE],
                          ['seeking', '==', SEEKING.NOPREF] ]
                      : // profile.seeking = SEEKING.FRIENDSHIP
                        [ ['seeking', '==', SEEKING.FRIENDSHIP],
                          ['seeking', '==', SEEKING.NOPREF] ];
    const marital = profile.married_pref.length === 1
                    ? [['married', '==', profile.married_pref[0]]]
                    : profile.married_pref.length === 2
                      ? [ ['married', '==', profile.married_pref[0]],
                          ['married', '==', profile.married_pref[1]] ]
                      : // profile.marital_pref.length = 3
                        [];
    return all_combs([ active, genderpref, gender, // lo_age, hi_age,
                       seeking, marital ]);
  }

  // oprofiles = array of public profiles
  // filter out this user, and blacklisted, or already present users
  // return array of new legit matches
  function filter_friends(oprofiles) {
    const bl = str.getState().profile.blacklist;
    const good_filt = o => ! bl[o.id]
                           && o.id !== profile.id
                           && ! locate_other(o.id, str.getState().others);
    return oprofiles.filter(good_filt);
  }

  // add new profile to store if affinity > 0
  function add_to_others(oprofiles) {
    var newprofs = [];
    for (var p of oprofiles) {
      const aff = affinity(profile, p);
      if (aff[2] === 0.0) {
        continue
      }
      p.pokes = false;
      p.pokedbys = false;
      p.thread = false;
      p.match = true;
      p.affinity = aff;
      newprofs.push(p);
    }
    dis(others_add_action(objectify(newprofs, 'id')));
  }

  return (
    get_users(filter(0))
    .then(users => {
      const matches = filter_friends(users);
      if (matches.length > 0) {
        add_to_others(matches);
        const args = { user: profile.id,
                       other_users: matches.map(m => m.id) };
        // not returning promise here because there is no need to wait for it
        db_query.add('matches', args)
      }
      // always recalc affinities; either we have new matches, or we're here
      // because prof/prefs changed
      recalc_affinities(dis, str);
    })
  );
}

export { recalc_matches, affinity };
// prefs_penalty exported only for testing
export { prefs_penalty };
export { recalc_matches_fields, recalc_affinities_fields,
         recalc_affinities };
