import React from 'react';

// used on matches page to change sort order
const CycleIcon = () =>
  // <img alt="cycle" src="images/refresh.svg" />
  <svg xmlns="http://www.w3.org/2000/svg"
       width="16.018" height="16.018" viewBox="0 0 16.018 16.018">
    <title>Change sort order</title>
    <path style={{ fill: "#ff4f23" }}
          d="M1.924,8.748a6.337,6.337,0,0,1-.047-.739A6.106,6.106,0,0,1,12.123,3.495l-.541.551a.48.48,0,0,0-.122.469.491.491,0,0,0,.357.319c.094.019,3.2,1.019,3,.979a.475.475,0,0,0,.554-.554c-.019-.095-.706-3.542-.666-3.344a.465.465,0,0,0-.788-.235l-.46.45A8.014,8.014,0,0,0,0,8.009v.019c0,.265.012.469.026.625A.469.469,0,0,0,.4,9.071l.964.193A.47.47,0,0,0,1.924,8.748Zm0,0"/>
    <path style={{ fill: "#ff4f23" }}
          d="M35.424,215.628l-.967-.2a.47.47,0,0,0-.56.517,6.389,6.389,0,0,1,.048.743A6.1,6.1,0,0,1,23.7,221.2l.541-.541a.48.48,0,0,0,.122-.469.491.491,0,0,0-.357-.319c-.094-.019-3.2-1.019-3-.979a.46.46,0,0,0-.422.131.467.467,0,0,0-.131.422l.667,3.313a.47.47,0,0,0,.328.357.451.451,0,0,0,.46-.122l.451-.45a8,8,0,0,0,13.465-5.847v-.028c0-.208-.008-.412-.025-.615A.469.469,0,0,0,35.424,215.628Zm0,0" transform="translate(-19.805 -208.682)"/>
  </svg>

export { CycleIcon };
