import React, { Component, useState, useRef, useEffect } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { text_rows } from '../../utils';
import { snapshot_check } from '../pages/utils';

// this is one of - if not the - only component that is a class
// this is for one case: so we can reset password fields (using setText)
// after submitting form. these are the only fields that need to be reset
class TextSw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.dflt || '',
      localerr: ''
    }

    this.change = this.change.bind(this);
    this.setText = this.setText.bind(this);
    this.getText = this.getText.bind(this);
    this.setLocalerr = this.setLocalerr.bind(this);
  }

  getText() { return this.state.text; }
  setText(t) { this.setState({ text: t }); }
  setLocalerr(e) { this.setState({ localerr: e }); }
  clear() { this.setState( { text: '', localerr: '' }); }

  change(ev) {
    const { onchange, reset_errmsg } = this.props;
    const { localerr } = this.state;

    // existing value is okay; if new value bad, display err and leave value
    if (onchange) {
      const err = onchange(ev.target.value);
      if (err) {
        this.setLocalerr(err);
        return;
      }
    }
    this.setText(ev.target.value);
    // there is only one error message per page, ever, so here we
    // are resetting the entire page. (may want to do better in future)
    if (reset_errmsg) {
      reset_errmsg();
    }
    if (localerr) {
      this.setLocalerr('');
    }
  }

  render() {
    const { the_ref, label, errmsg, margin, disabled,
            prompt, type, maxchars, cy_name } = this.props;
    const { text, localerr } = this.state;
    const err = errmsg || localerr;

    return (
      <div ref={the_ref} value={text} data-cy={cy_name}
           className={ margin || '' }>
        <label className="mr-4 text-sm font-normal text-gray-text"
             htmlFor={`${cy_name}_in`}>
          {label}
        </label>
        <div className={`flex flex-col bg-gray-bg h-10 justify-center
                         rounded-lg mt-2
                         ${err ? 'border-2 border-red-400' : ''}`}>
          <div className="px-2 text-sm font-normal mx-2 text-gray-text">
            <input type={ type || "text" }
                   onChange={this.change}
                   placeholder={prompt}
                   maxLength={maxchars}
                   value={text}
                   disabled={Boolean(disabled)}
                   data-cy={`${cy_name}_in`}
                   id={`${cy_name}_in`}
                   className="bg-gray-bg w-full outline-none
                              placeholder-gray-para" />
          </div>
        </div>
        <div className={`text-xs font-normal mx-2 text-red-600
                        mx-4 mt-1
                        ${err ? '' : 'hidden'}`}>
          {err}
        </div>
      </div>
    );
  }
}

class TextareaSw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.dflt || '',
      localerr: ''
    }

    this.change = this.change.bind(this);
    this.setText = this.setText.bind(this);
    this.getText = this.getText.bind(this);
    this.setLocalerr = this.setLocalerr.bind(this);
  }

  getText() { return this.state.text; }
  setText(t) { this.setState({ text: t }) }
  setLocalerr(e) { this.setState({ localerr: e }) }
  clear() { this.setState( { text: '', localerr: '' }); }

  change(ev) {
    const { onchange, reset_errmsg } = this.props;
    const { localerr } = this.state;
    if (onchange) {
      const err = onchange(ev.target.value);
      if (err) {
        this.setLocalerr(err);
        return;
      }
    }
    this.setText(ev.target.value);
    if (reset_errmsg) {
      reset_errmsg();
    }
    if (localerr) {
      this.setLocalerr('');
    }
  }

  render() {
    const { the_ref, label, errmsg, cols,
            rows, margin, prompt, maxchars, cy_name } = this.props;
    const { text, localerr } = this.state;

    const err = errmsg || localerr;

    return (
      <div ref={the_ref} value={text} data-cy={cy_name}
           className={`h-full ${ margin || '' }`}>
        <div className={`text-sm font-normal text-gray-text mb-2
                         ${label ? '' : 'hidden'}`}>
          { label }
        </div>
        <div className={`flex flex-col bg-gray-bg
                         h-full
                         rounded-lg justify-start"
                         ${err ? 'border-2 border-red-400' : ''}`}>
          <div className="text-sm font-normal m-4 text-gray-text">
            <textarea
              data-cy={`${cy_name}_in`}
              onChange={this.change}
              rows={rows}
              cols={cols}
              maxLength={maxchars}
              placeholder={prompt}
              className="bg-gray-bg w-full outline-none
                         placeholder-gray-para text-wrap"
              value={text}
            />
          </div>
        </div>
        <div className={`text-sm font-normal mx-2 text-red-600
                  mx-4 mt-2 mb-8
                  ${err ? '' : 'hidden'}`}>
          {err}
        </div>
      </div>
    );
  }
}


const NakedTextSw = ({ the_ref, label, errmsg, reset_errmsg, dflt, margin,
                       prompt, type, cy_name }) => {

  const [ text, setText ] = useState(dflt || '');

  const change = ev => {
    setText(ev.target.value);
    if (errmsg) {
      reset_errmsg();
    }
  }

  return (
    <div ref={the_ref} value={text} data-cy={cy_name}
         className={ margin || '' } >
      <div className={`flex flex-col bg-gray-bg h-10
                       rounded-lg justify-center mt-1
                       ${errmsg ? 'border-2 border-red-400' : ''}`}>
        <div className="text-small font-normal mx-2 text-gray-text">
          <input type={ type || "text" }
                 onChange={change}
                 placeholder={prompt}
                 value={text}
                 data-cy={`${cy_name}_in`}
                 className="bg-gray-bg w-full outline-none
                            placeholder-gray-para" />
        </div>
      </div>
      <div className={`text-xs font-normal mx-2 text-red-600
                      mx-4 mt-1
                      ${errmsg ? '' : 'hidden'}`}>
        {errmsg}
      </div>
    </div>
  );
}

// message box in convos
const MessageTextSw = ({ the_ref, margin, prompt, maxchars, cy_name,
                         area_ht, setArea_ht,
                         text, change }) => {
  const topref = useRef();
  useEffect(() => {
    const f = () => {
      const rect = topref.current.getBoundingClientRect();
      if (! area_ht
          || Math.abs(area_ht - rect.height) > 0.1) {
        setArea_ht(rect.height);
      }
    };
    f();
  });

  return (
    // TODO: I don't understand how the 'text_rows' thing is working
    //       It is supposed to give an approximation of the number of
    //       rows, but the approx is not accurate. It's somehow good
    //       enough. But I don't know why this is necessary at all. I
    //       *think* what should work is to just replace 'rows' by
    //       'min_rows=1', but that gives a weird result - actually gives
    //       enough height for two rows; another idea is "height="auto""
    //       but that does the same two-row thing
    <div data-cy={cy_name}
         ref={topref}
         className={ margin || '' } >
      <div className="flex flex-col bg-whitew-full
                      pr-4 border-orange border-2
                      rounded-lg justify-center">
          <textarea onChange={change}
                    placeholder={prompt}
                    rows={text_rows(48, text)}
                    value={text}
                    maxLength={maxchars}
                    data-cy={`${cy_name}_in`}
                    className="w-full border-none outline-none
                               resize-none my-2
                               text-small font-normal ml-2 text-gray-text
                               placeholder-gray-para text-wrap" />
      </div>
    </div>
  );
}

const SnapshotSw = ({ the_ref, errmsg, reset_errmsg, dflt, label, margin,
                      textmargin, prompt, cy_name, snapshot, setSnapshot }) => {

  const [ localerr, setLocalerr ] = useState('');

  const change = ev => {
    setLocalerr('');
    const files = ev.target.files || ev.dataTransfer.files;
    if (! files) {
      return;
    }
    if (files.length > 1) {
      setLocalerr("Choose a single file");
      return;
    }
    const f = files[0];
    const e = snapshot_check(f);
    if (e) {
      setLocalerr(e);
      return;
    }

    var reader = new FileReader();
    reader.onload = function (e) {
      var img = new Image();
      img.onload = function() {
        const ar = img.naturalWidth / img.naturalHeight;
        setSnapshot([f.name, URL.createObjectURL(f), ar, f])
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(f);

    setSnapshot([f.name, URL.createObjectURL(f), 0.8, f]);
    setLocalerr('');
    reset_errmsg();
  }

  const err = errmsg || localerr;
  const ar = snapshot[2];
  const [w, h] = ! ar
                 ? ['7rem', '7rem']
                 : ar <= 1
                   ? [ `${ar * 7}rem`, '7rem' ]
                   : [ '7rem', `${7 / ar}rem` ];

  return (
    <div data-cy={cy_name}
         className={ margin || '' } >
      <div className={`mx-0 text-sm font-normal text-gray-text mb-2
                       ${label ? '' : 'hidden'}`}>
        {label}
      </div>
      <div onDrop={ev => { ev.stopPropagation();
                           ev.preventDefault();
                           change(ev); }}
           onDragOver={ev => { ev.stopPropagation();
                               ev.preventDefault();}} >
        <div className={`flex flex-col bg-gray-bg h-28 rounded-lg
                         justify-center
                         ${err ? 'border-2 border-red-400' : ''}`}>
          <div className="grid grid-cols-12 h-full ">
            <div className={`col-span-4 ${snapshot[1] ? '' : 'hidden'}
                             flex flex-row items-center`}>
              <img src={snapshot[1]} alt="user's profile pic"
                   style={{ width: w, height: h }}
                   className="rounded-l-lg"/>
            </div>
            <div className="grid items-center col-span-8">
              <div className={`text-sm font-normal ${textmargin}
                               text-gray-text`}>
                Drag and drop your profile pic here or
                <label htmlFor="browse" className="underline font-semibold">
                  &nbsp;browse files
                </label>
                .&nbsp;{prompt}
                <input type="file" id="browse" name="browse"
                       accept="image/*"
                       onChange={change}
                       style={{ display: "none" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`text-xs font-normal mx-2 text-red-600
                      mx-4 mt-1
                      ${err ? '' : 'hidden'}`}>
        {err}
      </div>
    </div>
  );
}

export { TextSw, NakedTextSw, SnapshotSw,
         MessageTextSw, TextareaSw };
