import React, { useRef } from 'react';

import { TextareaSw } from '../widgets/TextWidget';
import { config$text_limits } from '../../config';
import { CancelIcon } from '../../assets/icons/cancel';

const FirstMessageOverlay = ({ showOverlay, setShowOverlay, buttons }) => {
  const oprofile = showOverlay[0];
  const msgref = useRef();

  const sendmsg = () => {
    const msg = msgref.current.getAttribute('value');
    if (msg.length === 0) return;
    if (buttons.sendmsg(oprofile, msg)) {
      setShowOverlay([oprofile, 'msgack']);
    }
  }

  return (
    <div className="flex my-32 content-center justify-center">
      <div className="relative flex flex-col bg-white rounded-lg space-y-6
                      w-1/3 md:w-1/2 sm:w-11/12"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <button className="absolute top-0 right-0 mt-4 mr-4"
             onClick={() => setShowOverlay(null)}>
          <CancelIcon />
        </button>
        <div className="mx-12 text-2xl font-bold text-gray-text text-center mt-6">
          Message
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
          Send a message to {oprofile.username}
        </div>
        <TextareaSw the_ref={msgref}
                    margin="mx-4"
                    rows={3}
                    maxchars={config$text_limits.brief_intro}
                    prompt={`Your message (${config$text_limits.brief_intro} character limit)`}
                    cy_name="first-message" />
        <div>
          <div className="flex flex-col items-center mr-4">
            <div className="w-10">
            </div>
            <button data-cy="first-message-send"
                    onClick={sendmsg} >
              <div className="flex flex-col bg-orange rounded-lg mt-4 w-28
                              h-10 content-center">
                <div className="font-semibold text-white text-sm
                                text-center my-auto">
                  Send
                </div>
              </div>
            </button>
            <button className="items-start text-sm font-normal
                            text-gray-para mt-2 hover:underline"
                 onClick={() => setShowOverlay(null)}>
              Cancel
            </button>
          </div>
        </div>
        <div className="h-10">
        </div>
      </div>
    </div>
  );
}

export { FirstMessageOverlay };
