import { Database } from '../db';
import { config$joke_url, config$test_url } from '../config';
// import { fbDateTime } from '../dbstructure';
import { JOKE_TYPES, JOKE_CAT, GENDER, SEEKING,
         MARITAL_STATUS, MARITAL_PREF,
         SMOKER, SMOKER_PREF,
         DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         RELIGIOUS, RELIGIOUS_PREF, } from '../enums';

export { create_test_db };

function create_test_db (num) {
  let db = testdbs()[num];
  if (! db) {
    throw new Error(`Test database ${num} does not exist`);
  }
  console.log(`Using testdb ${num} [${typeof(db)}]`);
  if (typeof(db) === 'object') {
    return new Database(db);
  }
  // typeof(db) = function
  let thedb = new Database();
  try {
    db(thedb);
  }
  catch (e) {
    console.log("Error in testdb init:", e);
  }
  return thedb;
}

// testdbs returns object mapping testdb numbers to objects or
// functions; the latter are of type db -> void; the function
// will be applied to a blank database to initialize it.
// it is recommended that every database operation within that
// function be wrapped in t(...), so it will raise an exception
// if there is an error
function testdbs() {

  function t(resp) {
    if (resp.error) {
      throw(resp);
    }
    return resp;
  }

  const ars = {
    a: 1,
    b: 1,
    c: 1,
    d: 201/251,
    e: 183/275,
    f: 183/275,
    g: 275/183,
    h: 299/168,
    i: 1,
    j: 1,
    k: 1,
    l: 275/183,
    m: 299/168,
    n: 1,
    o: 1,
    p: 1,
    q: 1,
    r: 1,
  }

  const mk_user = id => {
    let charcode = 'a'.charCodeAt(0);
    let Charcode = 'A'.charCodeAt(0);
    let c = String.fromCharCode(charcode - 1 + id);
    let C = String.fromCharCode(Charcode - 1 + id);
    let today = new Date();
    let y = today.getFullYear() - 25;
    let bdate = `${today.getMonth() + 1}/${today.getDate()}/${y}`;
    let yesterday = new Date(new Date() - 24 * 60 * 60 * 1000).toISOString();
    return {
      username: `${c}${c}${c}${c}`,
      active: true,
      email: `${c}@email.com`,
      uid: `uid-${c}@email.com`,
      password: `${c}pw${c}pw`,
      birthday: new Date(bdate).toISOString(),
      age_low: 20,
      age_high: 30,
      seeking: SEEKING.FRIENDSHIP,
      gender_pref: [ GENDER.MALE ],
      brief_intro: `I am ${c}; ${c} I am. This is my pic.`,
      signup_date: new Date().toISOString(),
      last_profedit_time: yesterday,
      last_matchcalc_time: yesterday,
      snapshot: `face${C}.jpg`,
      snapshot_ar: ars[c],
      snapshot_url: `${config$test_url}/images/face${C}.jpg`,
      zipcode: "10003",
      gender: GENDER.MALE,
      geog_limit: 100,
      obscene_okay: true,
      height: 5 * 12 + 7,
      low_height: 1,
      high_height: 200,
      married: MARITAL_STATUS.MARRIED,
      married_pref: [ MARITAL_PREF.MARRIED ],
      smoker: SMOKER.NONSMOKER,
      smoker_pref: SMOKER_PREF.NOINFO,
      drinker: DRINKER.NONDRINKER,
      drinker_pref: DRINKER_PREF.NOPREF,
      children: CHILDREN.CHILDREN,
      children_pref: CHILDREN_PREF.NOPREF,
      educ_level: EDUC_LEVEL.BACHELOR,
      educ_pref: EDUC_PREF.BACHELOR,
      religious: RELIGIOUS.RELIGIOUS,
      religious_pref: RELIGIOUS_PREF.RELIGIOUS,
      religion: "my own business",
      like_to_read: 'Romance novels; the Bible',
      have_to_do: 'Dig many ditches',
      like_to_do: "",
      like_to_discuss: "",
      like_to_listen: "",
      like_to_watch: "",
      looking_for: "",
    };
  }

  function base_db(d) {
    let users = [1,2,3,4,5,6,7,8].map(id => mk_user(id));
    for (var u of users) {
      t(d.add('user', u));
    }
    t(d.add('joke', {
       type: JOKE_TYPES.TEXT,
       active: true,
       name: "joke1",
       text: `
Whena visitor to a town in Alabama spotted a dog attacking a boy, he grabbed the animal and throttled it with his bare hands. An impressed reporter saw the incident and told him the next day’s headline would scream “Valiant Local Man Saves Child by Killing Vicious Animal.”

“I’m not from this town,” said the hero.

“Then,”the reporter said, “it will say ‘Alabama Man Saves Child by Killing Dog.’”

“Actually,”said the man, “I’m from New Hampshire.”

“Inthat case,” the reporter grumbled, “the headline will be ‘Yankee Kills Family Pet.’”
`,
       url: "",
       isObscene: false,
       cat: 1,
       source: "public_domain",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    t(d.add('joke', {
       type: JOKE_TYPES.IMAGE,
       active: true,
       name: "image joke2",
       text: "",
       url: config$joke_url + "/images/joke_silly1.png",
       isObscene: false,
       cat: 1,
       source: "public_domain",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    t(d.add('joke', {
       type: JOKE_TYPES.IMAGE,
       active: true,
       name: "image joke3",
       text: "",
       url: config$joke_url + "/images/chicken.jpg",
       isObscene: false,
       cat: 2,
       source: "Twitter @jokester",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    t(d.add('joke', {
       type: JOKE_TYPES.TEXT,
       active: true,
       name: "joke4",
       text: `
Boy:The principal is so dumb!

Girl:Do you know who I am?

Boy:No...

Girl:I am the principal's daughter!

Boy:Do you know who I am?

Girl:No...

Boy:Good! *Walks away*`,
       url: "",
       isObscene: false,
       cat: 2,
       source: "public_domain",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    t(d.add('joke', {
       type: JOKE_TYPES.VIDEO,
       active: true,
       name: "joke5",
       text: "",
       url: config$joke_url + "/videos/videojoke1.mp4",
       isObscene: false,
       cat: 2,
       source: "public_domain",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    t(d.add('joke', {
       type: JOKE_TYPES.TEXT,
       active: true,
       name: "joke6",
       text: "interviewer: what makes you want to be a firefighter?\n\nme: a fire killed my dadinterviewer: i'm so sorryme: don't be. i will have my revengeinterviewer: you want to kill the fire that killed your father?me: no. i'm not an idiot.interviewer:me: i'm gonna kill its dad",
       isObscene: false,
       cat: 2,
       source: "public_domain",
       copyright_holder: "",
       date_added: new Date("12/20/2014").toISOString(),
     }));
    let votes = [
        {
          user: "1", // id of user
          joke: 1,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "1", // id of user
          joke: 2,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "2", // id of user
          joke: 1,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "2", // id of user
          joke: 2,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "3", // id of user
          joke: 1,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "3", // id of user
          joke: 2,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "4", // id of user
          joke: 1,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
        {
          user: "4", // id of user
          joke: 2,   // id of joke
          vote: 3,   // votes are integers [1, 5]
          flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
          flag_comment: ""
        },
      ];
    for (var v of votes) {
      t(d.add('vote', v));
    }
    let blacklists = [
        // { user: "1", other_user: "2",
        //      blacklist: true, blacklisted_by: false }
      ];
    for (var b of blacklists) {
      t(d.add('blacklist', b));
    }
    let matches = [
        {
          user: "1",
          other_user: "2",
          affinity: [60, 70, 75],
        },
        {
          user: "1",
          other_user: "3",
          affinity: [70, 60, 65],
        },
      ];
    t(d.add('matches', { matches: matches }));
    let pokes = [
        {
          user: "1",
          other_user: "4",
          affinity: [100, 50, 55],
          poketime: "2020-01-18T22:19:20.674Z",
        },
        {
          user: "5",
          other_user: "1",
          affinity: [90, 60, 65],
          poketime: "2020-01-18T22:20:20.674Z",
        },
        {
          user: "1",
          other_user: "6",
          affinity: [80, 90, 95],
          poketime: "2020-01-18T22:21:20.674Z",
        },
        {
          user: "6",
          other_user: "1",
          affinity: [50, 50, 55],
          poketime: "2020-01-18T22:22:20.674Z",
        },
      ];
    for (var r of pokes) {
      t(d.add('poke', r));
    }
    let message_threads = [
        {
          user: "1",
          other_user: "7",
          affinity: [50, 51, 53],
        },
        {
          user: "1",
          other_user: "8",
          affinity: [54, 52, 55],
        },
      ];
    for (var th of message_threads) {
      t(d.add('message_thread', th));
    }
    t(d.update('message_thread', {
       user: "1",
       other_user: "7",
       msg_avail: true,
       last_read: "2020-01-18T22:19:30.674Z",
       latest_message: "2020-01-19T22:19:30.674Z"
     }));
    t(d.update('message_thread', {
       user: "7",
       other_user: "1",
       msg_avail: true,
       last_read: "2020-01-18T22:19:20.674Z",
       latest_message: "2020-01-18T22:19:30.674Z",
     }));
     t(d.update('message_thread', {
        user: "8",
        other_user: "1",
        msg_avail: false,
        last_read: "2020-01-18T22:19:30.674Z"
      }));
    let msgs = [
        {
          thread_id: "1,7",
          user: "1",
          other_user: "7",
          msg: "hi, gggg",
          date: "2020-01-18T22:19:30.674Z",
        },
        {
          thread_id: "1,7",
          user: "7",
          other_user: "1",
          msg: "hi back, aaaa",
          date: "2020-01-19T22:19:30.674Z",
        },
        {
          thread_id: "1,8",
          user: "1",
          other_user: "8",
          msg: "hello, hhhh",
          date: "2020-01-19T22:19:30.674Z",
        }
      ];
    for (var m of msgs) {
      t(d.add('message', m));
    }
  }

  return {
    0: d => {;}, // leave db in initial (empty) state
    // 1 is simple database with nine users, all of same age, same age pref, etc.,
    // i.e. all potential matches of each other; no blacklists, inactive users, etc.
    // User 1 has all possible connections: match, poke, pokedby, copoke,
    //   thread with, thread without
    1: base_db,
    2: d => {
         base_db(d);
         d.update('user', { id: "2",
                            active: false,
                          });
       },
    3: d => {
         base_db(d);
         d.update('user', { id: "2",
                          });
         let votes = [
             {
               user: "2", // id of user
               joke: 1,   // id of joke
               vote: 3,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
           ];
         for (var v of votes) {
           t(d.add('vote', v));
         }
       },
    4: d => {
         base_db(d);
         d.update('user', { id: "2",
                          });
         let votes = [
             {
               user: "2", // id of user
               joke: 1,   // id of joke
               vote: 3,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
             {
               user: "2", // id of user
               joke: 2,   // id of joke
               vote: 3,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
           ];
         for (var v of votes) {
           t(d.add('vote', v));
         }
       },
    5: d => {
         base_db(d);
         d.update('user', { id: 2,
                          });
         let votes = [
             {
               user: "2", // id of user
               joke: 1,   // id of joke
               vote: 3,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
             {
               user: "2", // id of user
               joke: 2,   // id of joke
               vote: 3,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
           ];
         for (var v of votes) {
           t(d.add('vote', v));
         }
         let matches = [
             {
               user: "2",
               other_user: "3",
               affinity: [30, 40, 45],
             },
           ];
         t(d.add('matches', { matches: matches }));
       },
    7: d => {
         base_db(d);
         let matches = [
             {
               user: "1",
               other_user: "3",
               affinity: [76, 80, 85],
             },
             {
               user: "1",
               other_user: "4",
               affinity: [93, 90, 95],
             },
             {
               user: "1",
               other_user: "5",
               affinity: [83, 40, 45],
             },
           ];
         t(d.add('matches', { matches: matches }));
       },
    8: d => {
         base_db(d);
         let users = [9,10,11].map(id => mk_user(id));
         for (var u of users) {
           t(d.add('user', u));
         }
         let matches = [
             {
               user: "1",
               other_user: "9",
               affinity: [37, 40, 45],
             },
             {
               user: "1",
               other_user: "10",
               affinity: [33, 40, 45],
             },
             {
               user: "1",
               other_user: "11",
               affinity: [97, 99, 95],
             },
           ];
         t(d.add('matches', { matches: matches }));
       },
    9: d => {
        base_db(d);
        t(d.update('user', { id: "1", married: MARITAL_STATUS.NOINFO }));
      },
    10: d => {
        base_db(d);
        t(d.add('joke', {
           type: JOKE_TYPES.TEXT,
           active: true,
           name: "newcorejoke",
           text: "core joke",
           url: "",
           isObscene: false,
           cat: JOKE_CAT.CORE,
           source: "public_domain",
           copyright_holder: ""
         }));
       },
     11: d => {
         base_db(d);
         t(d.update('user', {
            id: "1",
            low_height: 60,
            high_height: 100,
            smoker: SMOKER.NONSMOKER,
            smoker_pref: SMOKER_PREF.NOPREF,
            drinker_pref: DRINKER_PREF.NOPREF,
            children_pref: CHILDREN_PREF.NOPREF,
            like_to_do: "likes",
            like_to_discuss: "talks",
            like_to_listen: "listens",
            like_to_watch: "watches",
            looking_for: "looking",
          }));
       },
       12: d => {
           base_db(d);
           const u1 = { ...mk_user(15), email: ''},
                 u2 = { ...mk_user(16), email: ''};
           t(d.add('user', u1));
           t(d.add('user', u2));
       },
       13: d => {
           base_db(d);
           const u1 = { ...mk_user(15), active: false};
           t(d.add('user', u1));
       },
       14: d => {
           base_db(d);
           t(d.update('joke', { id: "1", isObscene: true }));
       },
       15: d => {
           base_db(d);
           t(d.update('joke', { id: "2", isObscene: true }));
       },
       16: d => {
           base_db(d);
           t(d.update('joke', { id: "3", isObscene: true }));
           t(d.update('user', { id: "1", obscene_okay: false }));
       },
       17: d => {
         base_db(d);
         let users = [9,10,11].map(id => mk_user(id));
         for (var u of users) {
           t(d.add('user', u));
         }
         let votes = [
             {
               user: "9", // id of user
               joke: 1,   // id of joke
               vote: 1,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
             {
               user: "10", // id of user
               joke: 1,   // id of joke
               vote: 5,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
             {
               user: "11", // id of user
               joke: 1,   // id of joke
               vote: 4,   // votes are integers [1, 5]
               flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
               flag_comment: ""
             },
           ];
         for (var v of votes) {
           t(d.add('vote', v));
         }
         t(d.update('user', {
           id: "11",
           educ_level: EDUC_LEVEL.HIGHSCHOOL,
           gender: GENDER.FEMALE,
           religious: RELIGIOUS.ATHEIST,
         }));
     },
    18: d => {
      base_db(d);
      let now = new Date();
      let t1 = new Date(now - 60 * 1000);
      let t2 = new Date(now - 20 * 60 * 1000);
      let t3 = new Date(now - 4 * 60 * 60 * 1000);
      let t4 = new Date(now - 28 * 60 * 60 * 1000);
      let t5 = new Date(now - 52 * 60 * 60 * 1000);
      let t6 = new Date(new Date('12/7/2020') - (-4 * 60 * 60 * 1000));
      let msgs = [
          {
            thread_id: "1,7",
            user: "1",
            other_user: "7",
            msg: "december",
            date: t6.toISOString()
          },
          {
            thread_id: "1,7",
            user: "1",
            other_user: "7",
            msg: "sunday",
            date: t5.toISOString()
          },
          {
            thread_id: "1,7",
            user: "1",
            other_user: "7",
            msg: "yesterday",
            date: t4.toISOString()
          },
          {
            thread_id: "1,7",
            user: "1",
            other_user: "7",
            msg: "four hours ago",
            date: t3.toISOString()
          },
          {
            thread_id: "1,7",
            user: "7",
            other_user: "1",
            msg: "twenty minutes ago",
            date: t2.toISOString()
          },
          {
            thread_id: "1,7",
            user: "1",
            other_user: "7",
            msg: "just now",
            date: t1.toISOString()
          },
        ];
      for (var m of msgs) {
        t(d.add('message', m));
      }
    },
    19: d => {
      base_db(d);
      let blacklists = [
        { user: "1", other_user: "2",
             blacklist: true, blacklisted_by: false }
      ];
      for (var b of blacklists) {
        t(d.add('blacklist', b));
      }
    },
    20: d => {
      base_db(d);
      d.update('user', { id: "2",
                         gender: GENDER.FEMALE,
                       });
    },
    21: d => {
      base_db(d);
      let pokes = [
          {
            user: "2",
            other_user: "1",
            affinity: [90, 60, 65],
            poketime: "2020-01-18T22:34:20.674Z",
          },
          {
            user: "3",
            other_user: "1",
            affinity: [50, 50, 55],
            poketime: "2020-01-18T22:30:20.674Z",
          },
        ];
      for (var r of pokes) {
        t(d.add('poke', r));
      }
    },
    22: d => {
      base_db(d);
      d.update('user', { id: "1",
                         height: 0,
                       });
    },
    23: d => {
      base_db(d);
      let users = [9,10,11,12,13].map(id => mk_user(id));
      for (var u of users) {
        t(d.add('user', u));
      }
      let pokes = [
          {
            user: "2",
            other_user: "1",
            affinity: [90, 60, 65],
            poketime: "2020-01-18T22:34:20.674Z",
          },
          {
            user: "3",
            other_user: "1",
            affinity: [50, 50, 55],
            poketime: "2020-01-18T22:30:20.674Z",
          },
          {
            user: "9",
            other_user: "1",
            affinity: [50, 50, 55],
            poketime: "2020-01-18T22:30:20.674Z",
          },
          {
            user: "10",
            other_user: "1",
            affinity: [50, 50, 55],
            poketime: "2020-01-18T22:30:20.674Z",
          },
          {
            user: "11",
            other_user: "1",
            affinity: [50, 50, 55],
            poketime: "2020-01-18T22:30:20.674Z",
          },
        ];
      for (var r of pokes) {
        t(d.add('poke', r));
      }
    },
    24: d => {
         base_db(d);
         d.update('user', { id: "2",
                            religious_pref: RELIGIOUS_PREF.SOMEWHAT,
                            educ_level: EDUC_LEVEL.NODEGREE,
                            children_pref: CHILDREN_PREF.NOCHILDREN,
                          });
         d.update('user', { id: "3",
                            religious_pref: RELIGIOUS_PREF.ATHEIST,
                          });
         let votes = [
            {
              user: "1", // id of user
              joke: 3,   // id of joke
              vote: 1,   // votes are integers [1, 5]
              flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
              flag_comment: ""
            },
            {
              user: "3", // id of user
              joke: 3,   // id of joke
              vote: 5,   // votes are integers [1, 5]
              flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
              flag_comment: ""
            },
            {
              user: "2", // id of user
              joke: 3,   // id of joke
              vote: 3,   // votes are integers [1, 5]
              flag: 0,   // flags are integers [0, 4]; 0 is "no flag"
              flag_comment: ""
            },
          ];
        for (var v of votes) {
          t(d.add('vote', v));
        }
      },
      25: d => {
        base_db(d);
        let users = Array.from({ length: 9 }, (v, i) => i + 9)
                         .map(id => mk_user(id));
        for (var u of users) {
          t(d.add('user', u));
        }
      },
  };
}
