import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { PopupNote } from '../ui/PopupNote';

import { sort_jokes, restore_state,
         set_wait_for_data } from '../Main';
import { jokes_reset_action, profile_update_action,
         profile_field_rm_action } from '../../store';
import { SplashUI, SigninUI, PasswordResetReqUI } from '../ui/SplashUI';
import { init_profile, fbDateTime } from '../../dbstructure';
import { db_query, firebase, auth, signed_in, anon_user } from '../../App';
import { changePage, BadPromise } from '../../utils';
import { current_zipcodes } from '../../assets/NYZips';
import { email_check, zipcode_check } from './utils';

// splash page: user can sign in, go to sign up page, or start
// rating jokes
const Splash = () => {

  const [alert, setAlert] = useState('');
  const [obscene_ok, setObscene_ok] = useState(init_profile.obscene_okay);

  // value is a pun: '' for don't show dialog;
  // otherwise, 5-digit zipcode if given an in-range zip,
  // '0' if coming directly from splash page (zip unknown)
  const [openSignup, setOpenSignup] = useState('');

  // value is a pun: '' for don't show dialog;
  // otherwise, 5-digit zipcode if given an out-of-range zip,
  // '0' if coming directly from splash page (zip unknown)
  const [openProspect, setOpenProspect] = useState('');

  // const toggle_obscene = () => setObscene_ok(! obscene_ok);
  const [errmsg, setErrmsg] = useState({});

  const [dis, str] = [useDispatch(), useStore()];

  // loading of jokes happens at these times:
  //   user signs in: in sign_in above, init_store_from_db is called
  //   user goes from splash page to vote page:
  //     in sign_up or rate_jokes below, load_jokes is called
  const load_jokes = () => {
    return (
      db_query
        .get('jokes')
        .then(jokes => {
          dis(jokes_reset_action({ unvoted: sort_jokes(jokes), voted: [] }));
        })
    );
  }

  // add anonymous user to database
  // only data we have now are auth and id in profile
  const sign_up = () => {
    const user = auth.currentUser;
    const now = new Date(user.metadata.creationTime).toISOString();
    const uid = user.uid;
    dis(profile_update_action({ signup_date: now,
                                uid: uid,
                                obscene_okay: obscene_ok,
                              }));
    localStorage.setItem('laughstruck_user', JSON.stringify({ uid: uid }));
    return (db_query.add('user', str.getState().profile)
            .then(() => {
              // others is in db in private profile, but separate in store
              dis(profile_field_rm_action({ field: 'others' }));
            })
          );
  }

  // get fresh id (this is "short" id used in urls, not user's uid) and
  // download jokes. choose a jokeid and go to signup/vote.
  // zip code can come from two fields: if from 'prospective' dialog,
  // value is in zip; ow zipref is ref to text field in 'before' overlay
  const rate_jokes = (zipref, zip) => {
    const z = zip.length === 5 ? zip : zipref.current.getAttribute('value');
    if (z === '') {
      setErrmsg({ zip: 'Please fill in this field'});
      return;
    }
    const zipcodeerr = zipcode_check(z);
    if (zipcodeerr) {
      setErrmsg({ zip: zipcodeerr });
      return;
    }
    if (! current_zipcodes.has(z)) {
      setOpenSignup('');
      setOpenProspect(z);
      return;
    }
    const p = () => (
      Promise.all([
        auth.signInAnonymously(),
        db_query
          .get('fresh_id')
          .then(id => {
            dis(profile_update_action({ ...init_profile, id: id, zipcode: z }))
          }),
        load_jokes()
      ])
      .then(() => {
          return (sign_up()
                  .then(() => { setOpenSignup('');
                                navigate(`/signup/vote`); })
                  .catch(() => { setOpenSignup('');
                                 setAlert('Database error: please retry'); })
                 );
        })
      .catch(e => {
          setOpenSignup('');
          setAlert('Database error: please retry');
        })
    );
    // unusual case: user starts signup, so is signed in anonymously,
    // then navigates to splash page and clicks startup button again.
    // signInAnonymously returns existing uid, which we could use to
    // get their db record and return them to previous spot, but it's
    // easier, and probably what they want, to start them over from scratch.
    set_wait_for_data(
      anon_user()
      ? auth.signOut().then(() => p())
      : p()
    );
  }

  // argtype = true => zip null, input email and zip
  //         = false => zip is string, input just email
  const save_prospect = (zip, emailref, zipref) => {

    const m = emailref.current.getAttribute('value');
    const emailerr = email_check(m);
    if (emailerr) {
      setErrmsg({ email: emailerr });
      return;
    }

    const z = zip === '0' ? zipref.current.getAttribute('value') : zip;
    if (z === '') {
      setErrmsg({ zip: 'Please fill in this field'});
      return;
    }
    if (! z.match('^[0-9]{5}$')) {
      setErrmsg({ zip: 'Zip code is exactly five digits' });
      return;
    }

    if (current_zipcodes.has(z)) {
      setOpenProspect('');
      setOpenSignup(z);
      return;
    }

    // no need to wait
    db_query.add('prospective',
                   { date: fbDateTime(),
                     zipcode: z,
                     email: m, });
    // setOpenProspect('');
    setOpenSignup(false);
    return true;
  }

  if (signed_in()) {
    return <Redirect to='/matches' noThrow />;
  }

  return (
    <React.Fragment>
      <SplashUI username={str.getState().username}
                obscene_ok={obscene_ok}
                open_signup={openSignup}
                open_prospect={openProspect}
                errmsg={errmsg}
                reset_errmsg={() => setErrmsg({})}
                actions={{ sign_in: () => changePage('signin'),
                           open_signup: setOpenSignup,
                           open_prospect: setOpenProspect,
                           rate_jokes: rate_jokes,
                           set_obscene: setObscene_ok,
                           save_prospect: save_prospect,
                         }} />
      <PopupNote notmsg={alert}
                 onclose={() => setAlert('')}
                 nottype="error" />
    </React.Fragment>
  );
}

const Signin = () => {

  const [alert, setAlert] = useState('');
  const [dis, str] = [useDispatch(), useStore()];

  const [errmsg, setErrmsg] = useState({});

  // loading of jokes happens at these times:
  //   user signs in: in sign_in above, init_store_from_db is called
  //   user goes from splash page to vote page:
  //     in sign_up or rate_jokes below, load_jokes is called
  const load_jokes = () => {
    return (
      db_query
        .get('jokes')
        .then(jokes => {
          dis(jokes_reset_action({ unvoted: sort_jokes(jokes), voted: [] }));
        })
    );
  }

  const sign_in = refs => {
    return e => {
      e.preventDefault();
      let email = refs.email.current;
      let pwd = refs.pwd.current;

      const m = email.getAttribute('value');
      const email_re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
      if (! m.match(email_re)) {
        setErrmsg({ email: "Please enter valid email address" });
        return;
      }

      const p = pwd.getAttribute('value');

      set_wait_for_data(
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => auth.signInWithEmailAndPassword(m, p))
        .then(() => {
          if (m === 'kamin@illinois.edu'
              || m === 'julia.kamin@gmail.com') {
            return load_jokes()
                   .then(() => changePage('admin'));
          } else {
            const user = auth.currentUser;
            localStorage.setItem('laughstruck_user',
              JSON.stringify({ uid: user.uid,
                               email: m } ));
            return restore_state(user, dis, str)
                   .then(e => {
                           if (e === 'inactive') {
                             return BadPromise({ api: 'db',
                                                 msg: 'Inactive user' })
                           } else {
                             changePage('matches');
                           }
                         });
          }
        })
        .catch(error => {
          switch(error.api) {
            case 'auth': {
                if (error.msg === 'auth/wrong-password') {
                  setErrmsg({ pwd: 'Incorrect password' });
                } else {
                  const msg = {
                      'auth/invalid-email': 'Invalid email',
                      'auth/user-not-found': 'No user with that email',
                      'auth/user-disabled': 'No user with that email',
                    }[error.msg];
                  if (msg) {
                    setErrmsg({ email: msg });
                  } else {
                    setAlert("Database error - please retry");
                  }
                }
                break;
              }
            case 'db': {
              setErrmsg({ email: error.msg });
              break;
            }
            default: { }
          }
        })
      );
    }
  }

  return (
    <React.Fragment>
      <SigninUI signin={sign_in}
                errmsg={errmsg}
                reset_errmsg={() => setErrmsg({})}
                lost_passwd={() => changePage('pwdresetreq')} />
      <PopupNote notmsg={alert}
                 onclose={() => setAlert('')}
                 nottype="error" />
    </React.Fragment>
  );
}

const PasswordResetReq = () => {

  const [alert, setAlert] = useState('');
  const [errmsg, setErrmsg] = useState({});

  const lost_passwd = refs => {
    return f => {
      f.preventDefault();
      let email = refs.current.getText();
      if (email === '') {
        setErrmsg({ email: 'Please provide email address' });
        return;
      }
      const ch = email_check(email);
      if (ch) {
        setErrmsg({ email: ch });
        return;
      }
      // fails if email is not in auth list
      auth.sendPasswordResetEmail(email)
      .then(function() {
        setAlert(`Sending password reset message to ${email}`);
      })
      .catch(function(error) {
        setErrmsg({ email: `Unable to send password reset message;
                            check email address or try again later` });
      });
    }
  }

  return (
    <React.Fragment>
      <PasswordResetReqUI lost_passwd={lost_passwd}
                          errmsg={errmsg}
                          reset_errmsg={() => setErrmsg('')} />;
      <PopupNote notmsg={alert}
                 onclose={() => { setAlert(''); changePage('splash'); }}
                 nottype="error" />
    </React.Fragment>
  );
}

export { Splash, Signin, PasswordResetReq };
