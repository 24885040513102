import React, { useState, useRef } from 'react';

import { InfoIcon } from '../../assets/icons/info';
import { TextSw } from '../widgets/TextWidget';
import { CancelIcon } from '../../assets/icons/cancel';

const obscene_tip = `Some of our core jokes are kind of vulgar,
  so you can opt out of those. It doesn't mean you will never be
  matched with anyone who likes those jokes, but it will lower
  your chances. You can modify this choice in your profile
  after you sign up.`;


const BeforeOverlay = ({ cancel, obscene_ok, actions, open_signup,
                         errmsg, reset_errmsg }) => {
  const have_zip = open_signup && open_signup.length === 5;
  const [obsceneex, setObsceneex] = useState(false);
  const zipref = useRef();
  return (
    <div className="flex content-center justify-center">
      <div className="relative flex flex-col bg-white
                      rounded-lg space-y-6
                      w-5/12 lg:w-1/2 md:w-7/12 sm:w-11/12"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <button className="absolute top-0 right-0 mt-4 mr-4"
                data-cy="cancelbefore-icon"
                onClick={cancel}>
          <CancelIcon />
        </button>
        <div className="pt-4">
          <div className={`mx-12 text-lg font-bold text-gray-text
                           text-center
                           ${ have_zip ? '' : 'hidden'}`}>
            You're in luck! We are opened in zipcode {open_signup}.
          </div>
          <div className="mx-12 text-2xl font-bold text-gray-text
                          text-center">
            Before we start...
          </div>
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-2 text-center">
          Our algorithms need to figure out your sense of humor.
          On the following pages you'll see a number of jokes;
          tell us how funny you think each is. Don't overthink it.
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-2">
          Just { have_zip ? 'one thing' : 'two things' } first:
        </div>
        <div>
          <div className="flex flex-row w-4/5 mx-6 space-x-2">
            <div className="flex text-sm font-normal text-gray-text">
              Are you okay with seeing obscene jokes?
            </div>
            <div className="relative">
              <button onClick={() => setObsceneex(! obsceneex)}>
                <InfoIcon size="small" />
              </button>
              <div className={`absolute flex flex-row -right-8 top-8
                               bg-white w-96 sm:w-64 rounded-lg
                               ${ obsceneex ? "" : "hidden" }`}
                   style={{ boxShadow: "0px 3px 50px #0000000D" }}>
                <div className="m-4 text-sm font-normal text-gray-text"
                     onClick={() => setObsceneex(false)}>
                  { obscene_tip }
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center sm:justify-center">
          <span className="flex flex-row mt-6
                           sm:flex-col sm:space-y-2 ">
            <span className="flex flex-row space-x-2 sm:block"
                  data-cy="signup-obscene-ok"
                  onClick={() => actions.set_obscene(true)} >
              <span className={`w-4 h-4 inline-block mr-1 rounded-lg
                                ${obscene_ok ? 'bg-orange' : 'bg-gray-bg'}`} />
              <span className="text-sm font-normal text-gray-text">
                Okay
              </span>
            </span>
            <span className="flex flex-row space-x-2 ml-8
                             sm:mx-auto sm:block"
                  data-cy="signup-obscene-notok"
                  onClick={() => actions.set_obscene(false)} >
              <span className={`w-4 h-4 inline-block mr-1 rounded-lg
                                ${! obscene_ok ? 'bg-orange' : 'bg-gray-bg'}`} />
              <span className="text-sm font-normal text-gray-text">
                Not okay
              </span>
            </span>
          </span>
          </div>
        </div>
        <div className={`w-4/5 ml-6 ${ have_zip ? 'hidden' : ''}`}>
          <TextSw the_ref={zipref}
                  label="What's your zip code?"
                  dflt={open_signup === '0' ? '' : open_signup}
                  errmsg={errmsg.zip}
                  reset_errmsg={reset_errmsg}
                  prompt="Enter zip code"
                  maxchars={5}
                  cy_name="before_zip" />
        </div>
        <div>
          <div className="flex flex-col items-center mr-4">
            <div className="w-10">
            </div>
            <button className="bg-orange rounded-lg mt-4 w-36 h-8"
                 data-cy="signup_okay"
                 onClick={() => actions.rate_jokes(zipref, open_signup)} >
              <div className="font-semibold text-white text-sm text-center mt-0">
                Let's get started!
              </div>
            </button>
            <button className="items-start text-sm font-bold text-gray-400
                               mt-2 hover:underline"
                 onClick={cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className="h-10">
        </div>
      </div>
    </div>
  );
}

export { BeforeOverlay };
