import React, { useState } from 'react';
import { Redirect, navigate } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { config$signup_jokes_for_testing, PROD_MODE, CYPRESS_MODE,
         LOCAL_MODE,
       } from '../../config';
import { vote_add_action, joke_remove_action, vote_update_action,
       } from '../../store';
import { set_wait_for_data, wait_for_data } from '../Main';
import { NotFound, Waiting } from './StaticPages';
import { SignupVoteUI, Signup1UI, Signup2UI, Signup3UI, SignupDoneUI
       } from '../ui/SignupUI';
import { upload_snapshot, make_changes } from './utils';
import { onProfileUpdate, onPubProfileUpdate, onJokesUpdate }
  from '../../listeners';
import { JOKE_CAT, JOKE_FLAG } from '../../enums';
import { db_query, auth, email_auth_provider,
         no_auth, signed_in } from '../../App';
import { fbDateTime } from '../../dbstructure';
import { gender_ui2db, seeking_ui2db, genderpref_ui2db,
         birthday_check, agepref_check, snapshot_check,
         pwd_add_check, email_check }
  from '../pages/utils';
import { toISO, changePage } from '../../utils';

// for signup jokes, we just take the core jokes in order
const SignupVote = props => {
  const has_jokeid = props['*'].match(/^[0-9]+$/);
  const jokeid = has_jokeid && props['*'];

  const [dis, str] = [useDispatch(), useStore()];

  const [errmsg, setErrmsg] = useState('');

  // waiting for fresh id and jokes
  // see splash/rate_jokes for explanation.
  const [ ready, setReady ] = useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('signup/vote')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! ready) {
    return <Waiting />;
  }

  // what actually happens here (and similarly below) is that if
  // profile is empty, we restore user, which triggers useEffect (eventually)
  // and a re-render, and then it may turn out that we need to redirect.
  // iow, this looks like it either restores or decides whether to
  // redirect, but it actually can do both, in sequence
  if (no_auth()) {
    return <Redirect to='/splash' noThrow />;
  }
  if (signed_in()) {
    return <Redirect to='/matches' noThrow />;
  }

  const recordvote = (jokeid, rating) => {
    // write vote to local store
    let vote = { joke: jokeid, vote: rating,
                 flag: JOKE_FLAG.NONE, flag_comment: "" };
    // check for (unusual) case that this is a re-vote
    if (str.getState().profile.votes[jokeid]) {
      dis(vote_update_action(vote));
    } else {
      dis(vote_add_action(vote));
    }

    // remove joke so we don't offer it again
    dis(joke_remove_action({ jokeid: jokeid }));

    // no need to wait
    db_query.add('vote', { user: str.getState().profile.id, ...vote });
    db_query.update('jokestat', vote);
  };


  if (! jokeid) {
    const store = str.getState();
    if (! store.profile.obscene_okay) {
      // need to do this a little carefully, because 'recordvote'
      // side-effects store.jokes.unvoted
      const bad = [];
      for (var j of store.jokes.unvoted) {
        if (j.cat !== JOKE_CAT.CORE) break;
        if (j.isObscene) bad.push(j);
      }
      for (j of bad) {
        recordvote(j.id, 1); // moves joke to voted
      }
    }
    // 'store' does not have modified jokes
    const jokes = str.getState().jokes.unvoted;
    if (jokes.length > 0 && jokes[0].cat === JOKE_CAT.CORE) {
      return <Redirect to={`/signup/vote/${jokes[0].id}`} noThrow />;
    } else {
      return <Redirect to='/splash' noThrow />;
    }
  }

  const nextpage = () => {
    const jokes = str.getState().jokes.unvoted;
    const jokes_voted = str.getState().jokes.voted.length;
    if (! PROD_MODE
        && jokes_voted >= config$signup_jokes_for_testing) {
      return [ 'signup/1' ];
    } else if (jokes.length && jokes[0].cat === JOKE_CAT.CORE) {
      return [ 'signup/vote', jokes[0].id ];
    } else {
      return [ 'signup/1' ];
    }
  }

  const submitvote = rating_ref => {
    return e => {
      e.preventDefault();
      let rating = rating_ref.current.getAttribute('data-value');
      if (! rating) {
        setErrmsg('You forgot to vote!');
        return;
      }
      let r = parseInt(rating);
      // put vote in store and db; no need to wait
      recordvote(jokeid, r);

      changePage(...nextpage());
    }
  }

  // need to account for case where user backed into this page. we can
  // allow re-vote, but it needs to be treated a little differently;
  // use |prev_vote| != undef to indicate that case
  let jokes = str.getState().jokes.unvoted;
  let joke, jokes_left, prev_vote;
  // inv: core jokes precede noncore jokes in jokes.unvoted
  let i = jokes.findIndex(j => j.cat === JOKE_CAT.NONCORE);
  // num of *remaining* core jokes
  let core_jokes_cnt = i === -1 ? jokes.length : i;
  // usual case is jokeid matches first unvoted joke; ow this is revote
  if (jokes.length && jokes[0].id === jokeid) {
    joke = jokes[0];
    jokes_left = core_jokes_cnt - 1;
  } else {
    joke = str.getState().jokes.voted.find(j => j.id === jokeid);
    if (joke) {
      prev_vote = str.getState().profile.votes[jokeid];
    }
  }

  const voted = str.getState().jokes.voted.length;
  const total_core_jokes = core_jokes_cnt + voted;
  const progress = voted / total_core_jokes;

  // in case this is a bad url
  if (! Boolean(joke)) {
      return <NotFound />;
  }

  return (
    <SignupVoteUI joke={joke}
                  progress={progress}
                  jokes_left={jokes_left}
                  prev_vote={prev_vote}
                  errmsg={errmsg}
                  actions={{ vote: submitvote,
                             reset_errmsg: () => setErrmsg('') }} />
  );
}

// Page 1 - gender, seeking, genderpref, bday, agepref
const Signup1 = () => {

  const [dis, str] = [useDispatch(), useStore()];

  const [errmsg, setErrmsg] = useState({});

  // TODO: this wait is only to make sure store and db match.
  //       it waits after submit, before going on to signup2; it
  //       has to wait because of signup2 does not see the store
  //       updated (specifically, it checks agelow), then it
  //       assumes signup1 wasn't done and goes back. If we didn't
  //       care about the consistency, we would write to the db
  //       (without waiting) and update the store, and then go
  //       to signup2. So, is the consistency important?
  //       In any case, this can only be a problem if there is
  //       a db failure, and we don't catch the failed call anyway,
  //       so this all can't be right
  const [ ready, setReady ] = useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('signup/1')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! ready) {
    return <Waiting />;
  }

  if (no_auth()) {
    return <Redirect to='/splash' noThrow />;
  }
  if (signed_in()) {
    return <Redirect to='/matches' noThrow />;
  }

  let jokes = str.getState().jokes.unvoted;
  if (jokes.length && jokes[0].cat === JOKE_CAT.CORE){
    let joke = str.getState().jokes.unvoted[0];
    navigate(`/signup/vote/${joke.id}`, { replace: true });
  }

  const submit = refs => {
    return e => {
      e.preventDefault();

      const g = refs.gender.current && refs.gender.current.getAttribute('value');
      if (! g) {
        setErrmsg({ gender: "Please select one" });
        return;
      }

      const rp = refs.seeking.current && refs.seeking.current.getAttribute('value');
      if (! rp) {
        setErrmsg({ seeking: "Please select one" });
        return;
      }

      const gp = refs.genderpref.current.getSelected();
      if (gp.length === 0) {
        setErrmsg({ genderpref: "Pick at least one" });
        return;
      }

      const [agelow, agehigh] = refs.agepref;
      const lo = agelow.current && agelow.current.getAttribute('value');
      const hi = agehigh.current && agehigh.current.getAttribute('value');
      if (! lo) {
        setErrmsg({ agelow: "Please fill out this field" });
        return;
      }
      if (! hi) {
        setErrmsg({ agehigh: "Please fill out this field" });
        return;
      }
      const [where, msg] = agepref_check(lo, hi);
      if (where) {
        setErrmsg({ [where === 'lo' ? 'agelow' : 'agehigh']: msg })
        return;
      }

      const b = refs.dob.current
                && refs.dob.current.getAttribute('value');
      if (! b) {
        setErrmsg({ dob: "Please fill out this field" });
        return;
      }
      const bderr = birthday_check(b);
      if (bderr) {
        setErrmsg({ dob: bderr });
        return;
      }

      const profile = str.getState().profile;
      setReady(false);
      // should set a timer to allow for failure, since make_changes
      // cannot fail
      set_wait_for_data(
        make_changes({ gender: gender_ui2db[g],
                       seeking: seeking_ui2db[rp],
                       gender_pref: genderpref_ui2db(gp),
                       birthday: toISO(b),
                       age_low: parseInt(lo),
                       age_high: parseInt(hi),
                     }, profile.id, dis)
        .then(() => changePage('signup/2'))
      );
    }
  }

  const profile = str.getState().profile;
  return (
    <Signup1UI profile={profile}
               errmsg={errmsg}
               reset_errmsg={() => setErrmsg('')}
               submit={submit} />
  );
}

// Page 2 - username, photo, bio
const Signup2 = () => {

  const [dis, str] = [useDispatch(), useStore()];

  const [errmsg, setErrmsg] = useState({});

  const prof = str.getState().profile;

  // snapshot is [photo name, photo url, aspect ratio, file object]
  const [snapshot, setSnapshot] =
        useState([ prof.snapshot, prof.snapshot_url,
                   prof.snapshot_ar, null ])

  // wait for snapshot upload to fail
  const [ ready, setReady ] = React.useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('signup/2')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! ready) {
    return <Waiting />;
  }

  if (no_auth()) {
    return <Redirect to='/splash' noThrow />;
  }
  if (signed_in()) {
    return <Redirect to='/matches' noThrow />;
  }
  if (! str.getState().profile.age_low) {
    return <Redirect to='/signup/1' noThrow />;
  }

  const submit = (refs) => {
    return e => {
      e.preventDefault();

      // in cypress mode, bypass snapshot widget entirely
      // if ( (CYPRESS_MODE && ! window.fileobject)
      //      || (! CYPRESS_MODE && ! snapshot[0]) ) {
      //   setErrmsg({ snapshot: "Please select a photo" });
      //   return;
      // }
      // ss is a file object; cypress tests do this in an ad hoc way
      const ss = CYPRESS_MODE ? window.fileobject : snapshot[3];
      // if ! ss, no change was made (no photo uploaded)
      // FIXME: I don't see how !ss is possible - input field requires
      //        actual file, and if it was bypassed, that would cause
      //        error above
      if (ss) {
        let sserr = snapshot_check(ss);
        if (sserr) {
          setErrmsg({ snapshot: sserr });
          return;
        }
      }

      const n = refs.username.current
                && refs.username.current.getAttribute('value').trim();
      if (! n) {
        setErrmsg({ username: "Please fill out this field" });
        return;
      }

      const b = refs.brief_intro.current
                && refs.brief_intro.current.getAttribute('value');
      if (! b) {
        setErrmsg({ brief_intro: "Please fill out this field" });
        return;
      }

      const id = str.getState().profile.id;
      setReady(false);
      set_wait_for_data(
        ( ss
          ? upload_snapshot(ss, id)
            .then(url =>
                make_changes({ username: n,
                               snapshot: ss.name,
                               snapshot_ar: snapshot[2],
                               snapshot_url: url,
                               brief_intro: b,
                             }, id, dis))
          : make_changes({ username: n,
                           brief_intro: b,
                         }, id, dis)
        )
        .then(() => changePage('signup/3'))
        .catch(e => {
          if (e.api === 'storage') {
            // I believe 'storage' is the only possible error here
            setErrmsg({ snapshot: `${e.msg}; please retry` });
          } else {
            alert(`Error: ${e.msg}; please retry`);
          }
        })
      );
    }
  }

  const profile = str.getState().profile;

  return (
    <Signup2UI profile={profile}
               errmsg={errmsg}
               reset_errmsg={() => setErrmsg('')}
               snapshot={snapshot}
               setSnapshot={setSnapshot}
               submit={submit} />
  );
}

// Page 3 - Email, Password
const Signup3 = () => {

  const [dis, str] = [useDispatch(), useStore()];

  const [errmsg, setErrmsg] = useState({});

  const [ ready, setReady ] = useState(false);
  React.useEffect(() => {
    const f = () => {
      if (window.location.href.includes('signup/3')) {
        wait_for_data.then(() => setReady(true));
      }
    };
    f();
  }, [ ready ]);

  if (! ready) {
    return <Waiting />;
  }

  // this is really subtle. problem is that signed-in users shouldn't
  // be here - they get redirected to matches page. but after submit button,
  // user actually *is* signed in. there can be an instant where we
  // have finished submit, are signed in and have called
  // changePage('/signup/done'), but we're still on signup/3 -
  // so useEffect above sets ready true. if we don't
  // take care, code below will redirect to splash.
  // (doesn't matter what we return here - just need to do something;
  // changePage is sending us to /signup/done.)
  if (! window.location.href.includes('signup/3')) {
    return <Waiting />;
  }

  if (no_auth()) {
    return <Redirect to='/splash' noThrow />;
  }
  if (signed_in()) {
    return <Redirect to='/matches' noThrow />;
  }
  if (! str.getState().profile.username) {
    return <Redirect to='/signup/2' noThrow />;
  }

  const complete_signup = (m, p) =>
    auth.currentUser.linkWithCredential(email_auth_provider(m, p)) // ADDCATCH
    .then(() => {
      const id = str.getState().profile.id;
      // no need to subscribe to messages, since there aren't any threads yet
      // onJokesUpdate will immediately be called and send all jokes, which is
      // unnecessary but harmless. on[Pub]ProfileUpdate won't do this because
      // they check for first time subscription.
      db_query.subscribeAll([
        ['PrivateProfiles', id, onProfileUpdate(dis, str) ],
        ['PublicProfiles', id, onPubProfileUpdate(dis, str) ],
        ['Jokes', 'jokes', onJokesUpdate(dis, str) ]
      ]);
    });

  const submit = (refs) => {
    return e => {
      e.preventDefault();

      const m = refs.email.current.getAttribute('value');
      if (! m) {
        setErrmsg({ email: "Please fill out this field" });
        return;
      }
      const emailerr = email_check(m);
      if (emailerr) {
        setErrmsg({ email: emailerr });
        return;
      }

      const [p, p2] = refs.pwds.map(r => r.current.getAttribute('value'));
      if (! p) {
        setErrmsg({ pwd1: "Please fill out this field" });
        return;
      }
      if (! p2) {
        setErrmsg({ pwd2: "Please fill out this field" });
        return;
      }

      let pwderr = pwd_add_check(p, p2);
      if (pwderr) {
        setErrmsg({ pwd1: pwderr });
        return;
      }

      const r = LOCAL_MODE || CYPRESS_MODE
                || Boolean(refs.recaptcha.current.getValue());
      if (! r) {
        setErrmsg({ recaptcha: "Hmm, we're not sure. Please try again."})
        return;
      }


      const profile = str.getState().profile;

      // see comment in EditAccount explaining why we don't call
      // setReady(false) here
      set_wait_for_data(
        make_changes({ email: m,
                       last_profedit_time: fbDateTime(),
                       active: true,
                     }, profile.id, dis)
        .then(() => complete_signup(m, p))
        .then(() => {
          localStorage.setItem('laughstruck_user',
            JSON.stringify({ uid: profile.uid,
                             email: m } ));
          changePage('signup/done');
        })
        .catch(error => {
          switch(error.code) {
            case 'email already in use': { // error in non-FS mode
                setErrmsg({ email: "Email already in use" });
                break;
              }
            case 'auth/email-already-in-use': {
                setErrmsg({ email: "Email already in use" });
                break;
              }
            case 'auth/invalid-email': {
                setErrmsg({ email: "Invalid email" });
                break;
              }
            case 'auth/weak-password': {
                setErrmsg({ email: "Stronger password required" });
                break;
              }
            default: {
                break;
              }
          }
        })
      );
    }
  }

  return (
    <Signup3UI profile={str.getState().profile}
               errmsg={errmsg}
               reset_errmsg={() => setErrmsg('')}
               submit={submit} />
  );
}

const SignupDone = () => {

  const str = useStore();

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }
  if (! str.getState().profile.email) {
    return <Redirect to='/signup/3' noThrow />;
  }

  const done = () => {
    changePage('matches');
  }

  return (
    <SignupDoneUI onclick={done} />
  );
}

export { SignupVote, Signup1, Signup2, Signup3, SignupDone };
