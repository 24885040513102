import React from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { Header, EditSidebar, EditDropdown } from './Header';
import { Footer } from './Footer';
import { Notifications } from './Notifications';
import { TopLogo } from './TopLogo';
import { changePage } from '../../utils';

const RegPage = (Comp, Overlay) => props => {
  return (
    <React.Fragment>
      <div className={`relative pt-3 flex flex-col justify-between
                       min-h-screen bg-gray-bg
                       ${props.showOverlay ? " opacity-20" : ""}`}>
        <div>
          <div className="flex justify-center my-3">
            <button onClick={() => changePage('splash')}
                    data-cy='toplogo'>
              <TopLogo />
            </button>
          </div>
          <div className={`center my-3 flex
                           ${ props.footeronly ? "hidden" : "" }`}>
            <Header { ...props } />
          </div>
          <div className="mx-auto flex flex-row w-10/12 items-center
                          rounded-lg space-x-3">
            <Comp { ...props } />
          </div>
        </div>
        <Footer />
      </div>
      <Notifications page={window.location.pathname} />
      {  // showOverlay is null or pair [oid, overlaytype]
        props.showOverlay
        ? <div className="absolute inset-0">
            <Overlay { ...props } />
          </div>
        : <span className="hidden" />
      }
    </React.Fragment>
  );
}

// TODO: should use showOverlay thing here as well as above, to get
//       proper modal dialogs
const FooterPage = Comp => props => {
  return (
    <div className="flex flex-col justify-between min-h-screen bg-gray-bg">
      <Comp { ...props } />
      <Footer />
    </div>
  );
}

// TODO: should use showOverlay thing here as well as above, to get
//       proper modal dialogs
const EditPage = Comp => props => {
  return (
    <React.Fragment>
      <div className="relative pt-3 flex flex-col justify-between
                      min-h-screen bg-gray-bg">
        <div>
          <div className="flex justify-center my-3">
            <button onClick={() => changePage('splash')}>
              <TopLogo />
            </button>
          </div>
          <div className="center my-3 flex
                          sm:my-0 sm:mt-3 sm:mb-2">
            <Header />
          </div>
          <div className="hidden sm:block center mb-2 flex">
            <EditDropdown />
          </div>
          <div className="mx-auto flex flex-row w-10/12 items-center
                          rounded-lg space-x-3">
            <div className="flex flex-col mx-auto w-full mb-8">
              <div className="w-4/5">
                <div className="flex text-3xl font-semibold text-gray-text my-4">
                  Settings
                </div>
              </div>
              <div className="grid gap-4
                              grid-cols-5
                              lg:grid-cols-8
                              sm:grid-cols-1">
                <div className="sm:hidden col-span-1 lg:col-span-3
                                md:col-span-2">
                  <EditSidebar />
                </div>
                <Comp { ...props } />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div>
        <Notifications page={window.location.pathname} />
      </div>
    </React.Fragment>
  );
}

export { RegPage, FooterPage, EditPage };
