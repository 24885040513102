import { createStore, combineReducers } from 'redux';
import { rm_attr, get_subobj } from './utils';
import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { classify, locate_other } from './components/pages/utils';

// overall structure
var empty_store = {
  profile:     {}, // user's profile; init'd in App ctor, reset upon sign-in, upd'd upon sign-up
                   // includes votes (as in db), but not blacklists, pokes, or
                   // threads; those are in db, but in store they are represented
                   // in others (or, in the case of blacklist, by the
                   // *absence* in others)
  jokes:       { unvoted: [], voted: [] }, // {id, name, type, text/url, cat}
                   // of unvoted-on core/noncore jokes and voted-on jokes (of either type)
                   // voted-on jokes are saved just in case users navigate to them, but
                   // revoting is not allowed
  // messages:    {}, // arrays of messages (as in db), keyed on other_user (id); this
  //                  // is the only field not fully populated at the start, but
  //                  // instead loaded on demand. each array is ordered by date.
  others: { pokes: {}, pokedbys: {}, copokes: {}, threads: {}, matches: {} },
                   // public profiles of all users, divided as
                   // per utils/classify. in addition to public profile in db,
                   // we add affinity, plus bits poke, pokedby, thread,
                   // msg_avail, and match (which are needed only to determine
                   // profile's bin, so are actually redundant).
                   // also includes messages, an exact copy of the database
                   // entry Messages.id,oid
                   // there is no overlap among these sublists
                   // initialized on signin; see init_store_from_db
  ui: { match_order: 'humor+', convo_id: '', convo_msg: '' }
                   // global ui items:
                   //   match_order: 'humor' or 'humor+'
                   //   convo_id and convo_msg: reset when we select a new
                   //     user; keep track of current message
}

// ACTIONS
// types: ADD, UPDATE, REMOVE, RESET
// e.g.
// { type: "update_profile",
//   args: { interests: "not much, really" }

// ADD actions are for adding to existing field, generally to add an individual
// element to an existing value.
// Not represented:
//   profile: use UPDATE; for new user, initial values set in App ctor
//   other_profs: use RESET; list of profiles comes from db
const ADD_ACTIONS = {
  others:    "add_others",    // add users to others
  message:   "add_message",   // user sends message
  vote:      "add_vote",      // non-signed-up user votes
  blacklist: "add_blacklist", // blacklist other user
  joke:      "add_joke",      // used only in admin
}

// ADD actions
const mk_add_action = what => args => ({
    type: ADD_ACTIONS[what],
    args: args
  });

const others_add_action = mk_add_action('others');
const message_add_action = mk_add_action('message');
const vote_add_action = mk_add_action('vote');
const blacklist_add_action = mk_add_action('blacklist');
const joke_add_action = mk_add_action('joke');

// UPDATE actions are to update a field in an existing value

// update for:
//   profile:    change field in profile
//   other_prof: change field in other_prof[id]
//   vote:       change vote
//
// no update for:
//   jokes:    downloaded from db, "read-only"
//   messages: only added, never changed

const UPDATE_ACTIONS = {
  profile:    "update_profile",
  rm_field:   "profile_rm_field",
  rm_not:     "profile_rm_not",
  add_not:    "profile_add_not",
  other:      "update_other",
  vote:       "update_vote",
  joke:       "update_joke",
  match:      "update_match",
  ui:         "update_ui",
}

const mk_update_action = what => args => ({
    type: UPDATE_ACTIONS[what],
    args: args
  });

// args = fields with new values
const profile_update_action = mk_update_action('profile');
// args = { id: <id>, ...changed fields }
// if "added" fields mentioned above are changed, move to correct place
const other_update_action = mk_update_action('other');
// args = { vote: <vote object> }
const vote_update_action = mk_update_action('vote');
// args = { field: <field name> }
const profile_field_rm_action = mk_update_action('rm_field');
// args = { id: <notification id> }
const profile_not_add_action = mk_update_action('add_not');
// args = { id: <notification id> }
const profile_not_rm_action = mk_update_action('rm_not');
// args = { joke: <joke> }
const joke_update_action = mk_update_action('joke');
// args = object with keys match_order, convo_id, or convo_msg
const ui_update_action = mk_update_action('ui');

// REMOVE actions remove a single item from an object/array
// args provide identity of item to be removed
const REMOVE_ACTIONS = {
  joke:       "remove_joke",       // remove joke after voting on it
  other:      "remove_other",      // remove profile (after blacklisting)
}

const mk_remove_action = what => args => ({
    type: REMOVE_ACTIONS[what],
    args: args
  });

// args = { joke: <id>, cat: [1-3] }
const joke_remove_action = mk_remove_action('joke');
// args = { id: <id> }
const other_remove_action = mk_remove_action('other');

// RESET actions replaces entire field with new values (usually, this just
// adds all db values to field that was empty)
// args give entire list of values, but it may be transformed (e.g. from
// array to object) by the action
// Note: reset_messages is used only for signout; ow use add message (don't
//   reset the entire messages field, just add messages of a single user)
const RESET_ACTIONS = {
  profile:     "reset_profile",
  jokes:       "reset_jokes",
  user_messages: "reset_user_messages",
  votes:       "reset_votes",
  others:      "others",
  ui:          "ui",
}

// RESET actions
const mk_reset_action = what => args => ({
    type: RESET_ACTIONS[what],
    args: args
  });

// args = user's profile as downloaded from db
const profile_reset_action = mk_reset_action('profile');
// args = object { core: [..jokes], noncore: [..jokes] }; each array
//   of jokes is a subobject of db object (see Query.get)
const jokes_reset_action = mk_reset_action('jokes');
// args = other_user, msgs. reset messages from this user
const user_messages_reset_action = mk_reset_action('user_messages');
// args = array of subobjects of db profiles (see db.get.profiles) of
//   other users, with added fields (see app.load_store_from_db)
//   array is converted to object by this action
const others_reset_action = mk_reset_action('others');
// args = empty
const ui_reset_action = mk_reset_action('ui');

// REDUCERS
// arguments for all cases are described above

const profile = (state={}, action) => {
  switch (action.type) {
    case UPDATE_ACTIONS.profile: {
      return { ...state, ...action.args };
    }
    case RESET_ACTIONS.profile: return action.args;
    case ADD_ACTIONS.vote: {
      const votes = state.votes;
      const vote = get_subobj(action.args, ['vote', 'flag', 'flag_comment']);
      return { ...state,
               votes: { ...votes, [action.args.joke]: vote }
             };
    }
    case ADD_ACTIONS.blacklist: {
      const bl = state.blacklist;
      const id = action.args.id;
      const addbl = get_subobj(action.args,
                               ['blacklist', 'blacklisted_by',
                                'blacklist_time']);
      const newbl = bl[id] ? { ...bl[id], ...addbl } : addbl;
      return ({ ...state,
                blacklist: { ...bl, [id]: newbl }
             });
    }
    case UPDATE_ACTIONS.vote: {
      const votes = state.votes;
      const vote = get_subobj(action.args, ['vote', 'flag', 'flag_comment']);
      return { ...state,
               votes: { ...votes, [action.args.joke]: vote }
             };
    }
    case UPDATE_ACTIONS.rm_field: {
      const { [action.args.field]: _, ...profile } = state;
      return profile;
    }
    case UPDATE_ACTIONS.add_not: {
      const nots = state.notifications || {};
      return { ...state,
               notifications: { ...nots, [action.args.id]: action.args }};
    }
    case UPDATE_ACTIONS.rm_not: {
      const nots = state.notifications;
      const { [action.args.id]: _, ...rest } = nots;
      return { ...state, notifications: rest };
    }
    case RESET_ACTIONS.votes: {
        return { ...state, votes: action.args };
      }
    default: return state;
  }
}

const jokes = (state={unvoted:[],voted:[]}, action) => {
  switch (action.type) {
    // here, 'remove' means move from 'unvoted' to 'voted'
    case REMOVE_ACTIONS.joke: {
      let joke = state.unvoted.find(j => j.id === action.args.jokeid);
      if (joke) {
        const unvoted = state.unvoted.filter(
                                      j => j.id !== action.args.jokeid);
        const voted = [joke, ...state.voted];
        return { unvoted: unvoted, voted: voted };
      } else {
        return state;
      }
    }
    case RESET_ACTIONS.jokes: {
      return action.args;
    }
    case ADD_ACTIONS.joke: {
      // used in admin when adding new jokes to db
      return { unvoted: [...state.unvoted, action.args.joke],
               voted: state.voted };
    }
    case UPDATE_ACTIONS.joke: {
      // used in admin when updating joke in db.
      const jokes = state.unvoted.filter(j => j.id !== action.args.joke.id);
      return { unvoted: [...jokes, action.args.joke],
               voted: state.voted };
    }
    default: return state;
  }
}

const others = (state={}, action) => {

  // simply switch this user from current category to new one, if different
  const change_class = (state, oid, newcat) => {
    const oldcat = locate_other(oid, state);
    if (! oldcat || oldcat === newcat) {
      return state;
    } else {
      // new_newcat is newcat with this user added; new_oldcat is
      // old cat with this user removed
      const new_newcat = { ...state[newcat], [oid]: state[oldcat][oid] };
      const { [oid]: _, ...new_oldcat } = state[oldcat];
      return { ...state, [newcat]: new_newcat, [oldcat]: new_oldcat };
    }
  }

  switch (action.type) {
    case RESET_ACTIONS.others: {
      return action.args;
    }
    case REMOVE_ACTIONS.other: {
      const cat = locate_other(action.args.id, state);
      if (! cat) { return state; }
      const restofcat = rm_attr(action.args.id, state[cat]);
      return { ...state, [cat]: restofcat };
    }
    // user action.args.id is definitely in the store
    case UPDATE_ACTIONS.other: {
      const id = action.args.id;
      const cat = locate_other(id, state);
      const prof = state[cat][id];
      const newprof = { ...prof, ...action.args};
      const newcat = classify(newprof);
      // remove id from cat, place newprof in newcat (works even
      // if cat = newcat, which it often will)
      const restofcat = rm_attr(id, state[cat]);
      const newst = { ...state, [cat]: restofcat }
      return { ...newst, [newcat]: { ...state[newcat], [id]: newprof }}
    }
    case ADD_ACTIONS.others: {
      // actions.args is object oid -> public profiles; safe to
      // assume these are not currently in other_profs
      // add to correct category based on oprofile bits pokes, etc.
      let newst = state;
      for (var p of Object.entries(action.args)) {
        const cat = classify(p[1]);
        newst = { ...newst, [cat]: { ...newst[cat], [p[0]]: p[1] }};
      }
      return newst;
    }
    case ADD_ACTIONS.message: {
      const oid = action.args.other_user;
      const st = change_class(state, oid, 'threads');
      const msgs = st.threads[oid].messages || [];
      const newmsgs = [ ...msgs, action.args ];
      const newprof = { ...st.threads[oid], messages: newmsgs };
      const newthreads = { ...st.threads, [oid]: newprof };
      return { ...st, threads: newthreads };
    }
    case RESET_ACTIONS.user_messages: {
      const oid = action.args.other_user;
      const st = change_class(state, oid, 'threads');
      const newprof = { ...st.threads[oid], messages: action.args.msgs };
      const newthreads = { ...st.threads, [oid]: newprof };
      let z = { ...st, threads: newthreads };
      return z;
    }
    default: return state;
  }
}

const ui = (state={}, action) => {
  switch (action.type) {
    case RESET_ACTIONS.ui: {
      return { match_order: 'humor+', convo_id: '', convo_msg: '' };
    }
    case UPDATE_ACTIONS.ui: {
      return { ...state, ...action.args }
    }
    // case UPDATE_ACTIONS.match: {
    //   return { ...state,
    //            match_page: state.match_page.map(
    //                          m => m[0] === action.args.id
    //                               ? [m[0], action.args.class]
    //                               : m) };
    // }
    default: return state;
  }
}

const store_reducer = combineReducers( {
    profile,
    jokes,
    others,
    ui
  }
);

function reset_store(dis, newst) {
  dis(profile_reset_action(newst.profile));
  dis(jokes_reset_action(newst.jokes));
  dis(others_reset_action(newst.others));
  dis(ui_reset_action(newst.ui));
}

const mk_redux_store = (st) => createStore(store_reducer, st);

export { empty_store, message_add_action, vote_add_action,
         blacklist_add_action,
         profile_field_rm_action,
         profile_not_rm_action, profile_not_add_action,
         profile_update_action, joke_remove_action,
         profile_reset_action,
         jokes_reset_action, user_messages_reset_action,
         vote_update_action, joke_add_action, others_reset_action,
         other_remove_action, other_update_action, others_add_action,
         joke_update_action,
         ui_reset_action, ui_update_action,
         store_reducer, reset_store, mk_redux_store
      };
