import React from 'react';

import { TopLogoIcon } from '../../assets/icons/toplogo';

const TopLogo = () => {
  return (
    <div className="flex flex-row space-x-1">
      <div className="font-semibold text-gray-text text-xl mt-1">
        LAUGH
      </div>
      <TopLogoIcon size="medium" />
      <div>
        <div className="font-semibold text-gray-text text-xl mt-1">
          STRUCK
        </div>
      </div>
    </div>
  );
}

export { TopLogo };
