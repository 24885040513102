// parameters that control certain aspects of the site.

const ALPHA_TEST = new Date() < new Date('4/1/2022');
const EARLY_BETA_TEST = new Date() < new Date('5/10/2022');

const LIVE_MODE = window.location.href.includes('laughstruck.com') ||
                  window.location.href.includes('laughstruckproto.web.app');
const NONPROD_MODE = window.location.href.includes('localhost');
const PROD_MODE = ! NONPROD_MODE;
const CYPRESS_MODE = Boolean(window.Cypress);
const TEST_MODE = window.location.href.includes('/test/');
const LOCAL_MODE = NONPROD_MODE && window.location.href.includes(':300');
const FS_EMUL_MODE = NONPROD_MODE && window.location.href.includes(':5005');
const FS_NONEMUL_MODE = NONPROD_MODE && window.location.href.includes(':5000');
const FS_MODE = PROD_MODE || FS_EMUL_MODE || FS_NONEMUL_MODE;

// Sign-up involves voting on all core jokes. To ease testing,
// in test mode fewer jokes are used.
const config$signup_jokes_for_testing = 2;
// minimal number of jokes to calculate a match; same as # of core
// jokes, in principle, but we're keeping separate. not currently used.
const config$min_jokes_for_matching = 0;

// url for jokes
const port = FS_EMUL_MODE ? '5005' : '3000';
const url = PROD_MODE ? 'https://laughstruckproto.web.app'
                      : `http://localhost:${port}`;
const config$static_url = `${url}/static`;
const config$joke_url = `${url}/static/jokes`;
const config$test_url = `${url}/test/assets`;
const config$test_snapshot_url = `${config$test_url}/images`;

// max sizes of profile fields
const config$text_limits = {
  generic: 500,
  brief_intro: 500,
  message: 500,
  email: 200,
  report_joke: 500,
  report_user: 500,
  contact_subject: 100,
  contact_message: 500,
  username: 12,
  password: 32,
};

// number of midiprofs we show on matches page
const config$matches_on_page = PROD_MODE ? 12 : 8;
// min time interval before recalc, in seconds
const config$recalc_interval = 15 * 60;

// max size of a single log doc
// max fs size is 1MB, but we're being conservative
const config$max_logsize = 500000;

export {
  ALPHA_TEST, EARLY_BETA_TEST,
  LIVE_MODE,
  CYPRESS_MODE,
  TEST_MODE,
  NONPROD_MODE,
  PROD_MODE,
  FS_EMUL_MODE,
  FS_NONEMUL_MODE,
  LOCAL_MODE,
  FS_MODE,
  config$signup_jokes_for_testing,
  config$min_jokes_for_matching,
  config$test_snapshot_url,
  config$test_url,
  config$static_url,
  config$joke_url,
  config$text_limits,
  config$matches_on_page,
  config$recalc_interval,
  config$max_logsize,
};
