import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

const tooltips = {
                 showprof: 'show this user\'s profile',
                 blacklist: 'Never show me again',
                 poke: `Let them know you like them`,
                 sendmsg: 'Send message',
                 showthread: 'Show message thread',
                 report: 'Report this user',
               };

// format dates to appear in user messages.
const format_date = date => {
  const d = new Date(date),
        now = new Date();
  const interval = Math.round((now - d) / 60000); // in minutes
  if (interval < 3) {
    return ['today', 'just now'];
  } else if (interval < 30) {
    return ['today', `${interval} minutes ago`];
  } else {
    const h = d.getHours();
    const hr = h === 0 ? ['12', 'AM']
               : h < 12 ? [h + '', 'AM']
               : h === 12 ? ['12', 'PM']
               : [ (h - 12) + '', 'PM'];
    const mn = d.getMinutes();
    const t = `${hr[0]}:${mn <= 9 ? '0'+mn : mn}${hr[1]}`;
    const sameday = (d1, d2) => d1.getFullYear() === d2.getFullYear()
                                && d1.getMonth() === d2.getMonth()
                                && d1.getDate() === d2.getDate();
    const dayofweek = d =>
                [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d.getDay()];
    if (sameday(d, now)) {
      return ['today', t];
    } else if (sameday(d, new Date(now - 24*60*60*1000))) {
      return ['yesterday', t];
    } else if (d > new Date(now - 4*24*60*60*1000)) {
      return [dayofweek(d), t];
    } else {
      return [`${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`, t];
    }
  }
}

// abbreviated time given in minicard for most recent msg
const minitime = date => {
  const d = new Date(date),
        now = new Date();
  const interval = Math.round((now - d) / 60000); // in minutes
  if (interval < 3) {
    return 'just now';
  } else if (interval < 30) {
    return `${interval} min ago`;
  } else {
    const h = d.getHours();
    const hr = h === 0 ? ['12', 'AM']
               : h < 12 ? [h + '', 'AM']
               : h === 12 ? ['12', 'PM']
               : [ (h - 12) + '', 'PM'];
    const mn = d.getMinutes();
    const t = `${hr[0]}:${mn <= 9 ? '0'+mn : mn}${hr[1]}`;
    const sameday = (d1, d2) => d1.getFullYear() === d2.getFullYear()
                                && d1.getMonth() === d2.getMonth()
                                && d1.getDate() === d2.getDate();
    const dayofweek = d =>
                [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d.getDay()];
    if (sameday(d, now)) {
      return t;
    } else if (sameday(d, new Date(now - 24*60*60*1000))) {
      return 'yesterday';
    } else if (d > new Date(now - 4*24*60*60*1000)) {
      return dayofweek(d);
    } else {
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    }
  }
}

const core_explainer = `Core jokes are specially-selected jokes
  that we have found are particularly useful for our matching algorithm.
  All the jokes you rated when you signed up are core jokes.
  We occasionally adjust our core jokes, and when we do that, we
  try to get our users to rate them.`;

const exp_core_badge = `We've added new "core" jokes. These are the jokes
  that we find most helpful for matching, so it's a good idea to vote
  on them.`;

const report_user_explainer = `If you believe this user has entered
  something objectionable - in their photo, profile, whatever - please
  report that to us. We will consider whether the user should be
  banned. The person will be removed from all your pages, exactly as
  if you had clicked the 'thumbs-down' button.`;

const explain_humor = `Match score based only on sense of humor,
  not considering other preferences`;

const explain_humorp = `Match score combining sense of humor
  with all preferences`;

export { tooltips,
         format_date, minitime,
         exp_core_badge,
         core_explainer, report_user_explainer,
         explain_humor, explain_humorp };
