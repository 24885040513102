import React, { useState } from 'react';

import { RegPage } from './RegPage';
import { obj2fun, inches2ft, calculate_age } from '../../utils';
import { classify } from '../pages/utils';
import { seeking_db2ui, gender_db2ui, marital_db2ui, smoker_db2ui,
         drinker_db2ui, children_db2ui, educlevel_db2ui, religious_db2ui,
       }
  from '../pages/utils';
import { nyzip2borough } from '../../assets/NYZips';
import { config$test_url } from '../../config';
import { changePage, isBlank } from '../../utils';
import { FirstMessageOverlay } from './FirstMessageOverlay';
import { MessageAckOverlay } from './MessageAckOverlay';

import { ConvoDotsIcon } from '../../assets/icons/convo-dots';
import { LikeIcon, DislikeIcon } from '../../assets/icons/useractions';
import { InfoIcon } from '../../assets/icons/info';
import { HumorfaceIcon, HumorplusfaceIcon } from '../../assets/icons/humorface';

import { ReportUserOverlay } from './ReportUserOverlay';
import { Explainer } from './Explainer';
import { report_user_explainer, explain_humor, explain_humorp } from './utils';


// this gives both display labels of these text fields and their order
const text_labels = [
   ['brief_intro', 'About me'],
   ['like_to_do', 'What you like to do'],
   ['have_to_do', 'What you have to do'],
   ['like_to_discuss', 'What you like to talk about'],
   ['like_to_read', 'Favorite reads'],
   ['like_to_listen', 'Favorite listens'],
   ['like_to_watch', 'Favorite watches (movies or series)'],
   ['looking_for', 'What you\'re looking for'],
 ];

const db2display = {
    seeking: obj2fun(seeking_db2ui), // x => x,
    gender: obj2fun(gender_db2ui),
    married: obj2fun(marital_db2ui),
    smoker: obj2fun(smoker_db2ui),
    drinker: obj2fun(drinker_db2ui),
    children: obj2fun(children_db2ui),
    educ_level: obj2fun(educlevel_db2ui),
    religious: obj2fun(religious_db2ui),
    zipcode: obj2fun(nyzip2borough),
    height: inches => {
      const [f, i] = inches2ft(inches);
      return `${f} ft, ${i} in`;
    }
  }

const OtherUserOverlay = props => {
  if (! props.showOverlay) {
    return () => <span />;
  }
  switch(props.showOverlay[1]) {
    case 'report':  return <ReportUserOverlay { ...props } />;
    case 'sendmsg': return <FirstMessageOverlay { ...props } />;
    case 'msgack':  return <MessageAckOverlay { ...props } />;
    default: return () => <span />;
  }
}

const BaseOtherUserUI = ({ oprofile, buttons, showOverlay,
                           setShowOverlay }) => {

  // pop-ups for 'report user' info button, humor score explainers
  const [ reportUserEx, setReportUserEx ] = useState(false);
  const [ explainHumor, setExplainHumor ] = useState(false);
  const [ explainHumorP, setExplainHumorP ] = useState(false);

  // humor explainers are "ganged"
  const setExpHumor = () => { setExplainHumor(! explainHumor);
                              setExplainHumorP(false); };
  const setExpHumorP = () => { setExplainHumorP(! explainHumorP);
                               setExplainHumor(false); };
  const resetExpHumors = () => { setExplainHumor(false);
                                 setExplainHumorP(false); }

  const cat = classify(oprofile);
  const can_like = cat === 'matches' || cat === 'pokedbys';
  const first_msg = cat === 'pokedbys' || cat === 'copokes';
  const can_send = first_msg || cat === 'threads';

  const age = calculate_age(oprofile.birthday);

  const InfoEntry = ({ label, field, field2 }) => {
    const v = oprofile[field];
    // ugly. this works only because in all enums, NOINFO has value zero
    let i = v ? db2display[field](v) : 'no information';
    if (field2 && ! isBlank(oprofile[field2])) {
      i = `${i} (${oprofile[field2]})`;
    }
    const s = v ? '' : 'italic';
    return (
      <div className="flex flex-col justify-start">
        <div className="text-sm text-gray-para font-normal">
          { label }
        </div>
        <div className={`text-base text-gray-text font-normal ${s}`}>
          { i }
        </div>
      </div>
    );
  }

  const TextEntry = ({ label, field }) => {
    const entry = oprofile[field];
    return (
      <div className={`flex flex-col justify-start
                   ${ entry ? '' : 'hidden' }` }>
        <div className="text-sm text-gray-para font-normal">
          { label }
        </div>
        <div className="text-base text-gray-text font-normal">
          { entry }
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mx-auto w-full"
         data-cy="otherprofile">
      <div className="hidden sm:block">
          <img style={{ height: "10rem" }}
             alt="small mock ad"
             data-cy="joke-ad-sm"
             src={`${config$test_url}/images/app_ad.png`}
             className="rounded-lg col-span-1 mx-auto"/>
      </div>
      <div className="w-full sm:mt-3">
        <div className="flex text-3xl font-semibold text-gray-text my-4
                        sm:justify-center
                        ">
          About {oprofile.username}
        </div>
      </div>
      <div className="grid grid-cols-5 mt-4">
        <div className="col-span-4 space-y-4 bg-white rounded-lg
                        sm:col-span-5"
             style={{ boxShadow: "0px 3px 50px #0000000D" }} >
          <div className="grid grid-cols-4 mt-4 mb-8">
            <div className="flex flex-col items-center sm:col-span-4">
              <div className="relative w-4/5 my-2 sm:w-3/5 sm:mx-auto">
                <img src={oprofile.snapshot_url}
                     className="rounded-lg w-full"
                     alt={oprofile.username} />
                <div className={`absolute bottom-2 right-2
                                 ${ can_send ? '' : 'hidden' }`}
                     data-cy="showthread-button"
                     onClick={ ev => { if (first_msg) {
                                         setShowOverlay([oprofile, 'sendmsg']);
                                       } else {
                                         changePage('convos', oprofile.id);
                                       }
                                       ev.stopPropagation(); } } >
                  <ConvoDotsIcon />
                </div>
              </div>
              <div className="w-4/5 my-2 bg-gray-bg md:bg-transparent
                              sm:bg-gray-bg rounded-lg">
                <button className={`flex flex-row h-10 w-full justify-center
                                 items-center
                                 ${can_like ? 'hidden' : ''}`}
                     onClick={buttons.blacklist}
                     data-cy="blacklist-button1">
                  <DislikeIcon />
                  <div className="text-xs font-normal text-gray-text ml-1">
                    Pass
                  </div>
                </button>
                <div className={`grid grid-cols-2 divide-x-2 h-10 md:h-20
                                 md:grid-cols-1 md:gap-2
                                 sm:grid-cols-2 sm:h-10
                                 ${can_like ? '' : 'hidden'}`}>
                  <div className="flex flex-row justify-center items-center
                                  md:bg-gray-bg md:rounded-lg">
                    <button className="flex flex-row items-center w-min"
                         onClick={buttons.poke}
                         data-cy="poke-button">
                      <div className="mr-1">
                        <LikeIcon />
                      </div>
                      <div className="text-xs font-normal text-gray-text">
                        Like
                      </div>
                    </button>
                  </div>
                  <div className="flex flex-row justify-center items-center
                                  md:bg-gray-bg md:rounded-lg">
                    <button className={`flex flex-row items-center w-min`}
                         onClick={buttons.blacklist}
                         data-cy="blacklist-button2">
                      <div>
                        <DislikeIcon />
                      </div>
                      <div className="text-xs font-normal text-gray-text ml-1">
                        Pass
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full
                          my-4 space-x-1
                          justify-center items-center">
                <div className="relative">
                  <div data-cy="open-reportuserex"
                       onClick={() => setReportUserEx(! reportUserEx)}>
                    <InfoIcon size="small" />
                  </div>
                  <Explainer open={reportUserEx}
                             pos="-left-8 top-8`"
                             cy_name="reportuserex"
                             close={() => setReportUserEx(false)}
                             exp={report_user_explainer} />
                </div>
                <button className="text-xs font-normal text-gray-para"
                        data-cy="show-report-user"
                        onClick={() => setShowOverlay([oprofile, 'report'])}>
                  Report user
                </button>
              </div>
            </div>
            <div className="col-span-3 sm:col-span-4 sm:items-center sm:ml-10">
              <div className="flex flex-col w-11/12 mr-2 space-y-2">
                <div className="text-xl font-semibold text-gray-text">
                  {oprofile.username}
                </div>
                <div className="text-base font-normal text-gray-para">
                  {age} years old
                </div>
                <div className="flex justify-start mt-2 space-x-2">
                  <div className="relative" data-cy="open-exphumor-other"
                       onClick={() => setExpHumor()}>
                    <div className="flex flex-row items-center my-2 px-2
                                    space-x-1 bg-gray-bg rounded-lg">
                      <HumorfaceIcon />
                      <div className="text-sm font-normal text-gray-text my-1">
                        {`${oprofile.affinity[0]}%`}
                      </div>
                    </div>
                    <Explainer open={explainHumor}
                               cy_name="exphumorother"
                               pos="left-4 bottom-8 sm:-left-4"
                               close={resetExpHumors}
                               exp={explain_humor} />
                  </div>
                  <div className="relative" data-cy="open-exphumorp-other"
                       onClick={setExpHumorP}>
                    <div className="flex flex-row items-center my-2 mx-2 px-2
                                    space-x-1 bg-gray-bg rounded-lg">
                      <HumorplusfaceIcon />
                      <div className="text-sm font-normal text-gray-text my-1">
                        {`${oprofile.affinity[2]}%`}
                      </div>
                    </div>
                    <Explainer open={explainHumorP}
                               cy_name="exphumorpother"
                               pos="left-4 bottom-8 sm:-left-4"
                               close={resetExpHumors}
                               exp={explain_humorp} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-11/12 mr-2 space-y-4 mt-4">
                <div className="grid grid-cols-3 gap-y-4
                                md:grid-cols-2 sm:grid-cols-1">
                  <InfoEntry label="Lives in" field="zipcode" />
                  <InfoEntry label="Looking for" field="seeking" />
                  <InfoEntry label="Height" field="height" />
                  <InfoEntry label="Marital status" field="married" />
                  <InfoEntry label="Smoker" field="smoker" />
                  <InfoEntry label="Drinker" field="drinker" />
                  <InfoEntry label="Kids" field="children" />
                  <InfoEntry label="Highest degree" field="educ_level" />
                  <InfoEntry label="Religion" field="religious"
                             field2="religion" />
                </div>
                { text_labels.map(([fld, lab]) =>
                                   <TextEntry label={lab} field={fld}
                                              key={fld} />)
                }
              </div>
            </div>
          </div>
        </div>
        <img style={{ height: "30rem" }}
             alt="mock ad"
             src={`${config$test_url}/images/side_ad_5.png`}
             className="ml-4 rounded-lg sm:hidden"/>
      </div>
    </div>
  );
}

const OtherUserUI = RegPage(BaseOtherUserUI, OtherUserOverlay);

export { OtherUserUI };
