import React from 'react';

const HeaderSmileyIcon = ({ selected }) =>
  <svg style={{ fill: selected ? "#ff4f23" : "#241f1f" }}
     xmlns="http://www.w3.org/2000/svg" width="15.351" height="15.351" viewBox="0 0 15.351 15.351">
    <title>Jokes</title>
    <path d="M7.676,0a7.676,7.676,0,1,0,7.676,7.676A7.684,7.684,0,0,0,7.676,0Zm0,14.452a6.776,6.776,0,1,1,6.776-6.776A6.784,6.784,0,0,1,7.676,14.452Zm0,0"/>
    <path d="M306.036,142.062a1.594,1.594,0,0,1,2.254,0,.45.45,0,1,0,.636-.636,2.493,2.493,0,0,0-3.526,0h0a.45.45,0,0,0,.636.636Zm0,0" transform="translate(-296.115 -136.477)"/>
    <path d="M81.1,142.062a1.594,1.594,0,0,1,2.254,0,.45.45,0,1,0,.636-.636,2.493,2.493,0,0,0-3.526,0h0a.45.45,0,0,0,.636.636Zm0,0" transform="translate(-77.925 -136.477)"/>
    <path d="M90.416,243H80.782a.45.45,0,0,0-.45.45,5.267,5.267,0,0,0,10.534,0A.45.45,0,0,0,90.416,243ZM85.6,247.817a4.366,4.366,0,0,1-4.345-3.918h8.69A4.366,4.366,0,0,1,85.6,247.817Zm0,0" transform="translate(-77.923 -235.714)"/>
  </svg>;

const HeaderBoltIcon = ({ selected }) =>
  <svg style={{
        fill: '#ffffff',
        stroke: selected ? "#ff4f23" : "#241f1f",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10
      }}
      xmlns="http://www.w3.org/2000/svg" width="8.42" height="18.702"
      viewBox="0 0 8.42 18.702">
    <title>Matches</title>
    <path d="M457.459,404.162a.777.777,0,0,1-.162.438l-.125.154-.03.038L454.3,408.3l-.762.939-3.014,3.716,1.918-4.382,
             1.2-2.749-.008,0,0,0-.1-.029-2.11-.619-.018-.005a1.366,1.366,0,0,1-.893-1.739.087.087,0,0,1,0-.015l1.242-3.584c0-.006,
             0-.012.006-.018l1.578-4.556,4.513.934-2.875,6.567-.008,0v0l.015,0,.007,0,2.043.789.011,0A.646.646,0,0,1,457.459,404.162Z"
          transform="translate(-449.943 -394.75)"/>
      </svg>;

const HeaderCoupleIcon = ({ selected }) =>
  <svg style={{
        fill: "none",
        stroke: selected ? "#ff4f23" : "#241f1f",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="14.456" height="13" viewBox="0 0 14.456 13">
    <title>Connections</title>
    <g transform="translate(-51.5 -601.327)">
      <path d="M11.513,18.942V17.628A2.628,2.628,0,0,0,8.885,
               15H3.628A2.628,2.628,0,0,0,1,17.628v1.314"
            transform="translate(51 594.884)"/>
      <circle cx="2.5" cy="2.5" r="2.5" transform="translate(54.896 601.827)"/>
      <path d="M21.971,18.987V17.673A2.628,2.628,0,0,0,20,15.13"
            transform="translate(43.485 594.84)"/>
      <path d="M16,3.13a2.114,2.114,0,0,1,0,4.1"
            transform="translate(45.856 599.148)"/>
    </g>
  </svg>

const HeaderConvoIcon = ({ selected }) =>
  <svg style={{
         fill: "none",
         stroke: selected ? "#ff4f23" : "#241f1f",
         strokeLinecap: "round",
         strokeLinejoin: "round",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="14.508" height="14.508" viewBox="0 0 14.508 14.508">
    <title>Conversations</title>
    <path d="M16.508,9.379a6.289,6.289,0,0,1-.675,2.852,6.379,6.379,0,0,1-5.7,3.527,
             6.289,6.289,0,0,1-2.852-.675L3,16.508,4.426,12.23A6.289,6.289,0,0,1,3.75,
             9.379a6.379,6.379,0,0,1,3.527-5.7A6.289,6.289,0,0,1,10.129,
             3H10.5a6.364,6.364,0,0,1,6,6Z"
          transform="translate(-2.5 -2.5)"/>
  </svg>

const HeaderGearIcon = ({ selected }) =>
  <svg style={{
         fill: "none",
         stroke: selected ? "#ff4f23" : "#241f1f",
         strokeLinecap: "round",
         strokeLinejoin: "round",
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="16" height="16" viewBox="0 0 16 16">
    <title>Settings</title>
    <g transform="translate(-50.5 -738.5)">
      <circle cx="2.5" cy="2.5" r="2.5" transform="translate(56 744)"/>
      <path d="M13.545,10.545a1.125,1.125,0,0,0,.225,1.241l.041.041a1.364,1.364,0,
               1,1-1.93,1.93l-.041-.041a1.134,1.134,0,0,0-1.923.8v.116a1.364,1.364
               ,0,0,1-2.727,0v-.061a1.125,1.125,0,0,0-.736-1.03,1.125,1.125,0
               ,0,0-1.241.225l-.041.041a1.364,1.364,0,1,1-1.93-1.93l.041-.041a1.134
               ,1.134,0,0,0-.8-1.923H2.364a1.364,1.364,0,0,1,0-2.727h.061a1.125,1.125
               ,0,0,0,1.03-.736A1.125,1.125,0,0,0,3.23,5.214l-.041-.041a1.364,1.364,0
               ,1,1,1.93-1.93l.041.041A1.125,1.125,0,0,0,6.4,3.509h.055a1.125,1.125,0,0
               ,0,.682-1.03V2.364a1.364,1.364,0,0,1,2.727,0v.061a1.134,1.134,0,0,0
               ,1.923.8l.041-.041a1.364,1.364,0,1,1,1.93,1.93l-.041.041A1.125,1.125,0,0
               ,0,13.491,6.4v.055a1.125,1.125,0,0,0,1.03.682h.116a1.364,1.364,0,0,1
               ,0,2.727h-.061A1.125,1.125,0,0,0,13.545,10.545Z"
            transform="translate(50 738)"/>
    </g>
  </svg>


export { HeaderSmileyIcon, HeaderBoltIcon, HeaderCoupleIcon,
         HeaderConvoIcon, HeaderGearIcon };
