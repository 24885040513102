import { recalc_matches } from './matching';
import { NONPROD_MODE } from './config';
import { JOKE_TYPES, JOKE_CAT, JOKE_FLAG,
         GENDER, MARITAL_STATUS, SEEKING,
         SMOKER, SMOKER_PREF, DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         RELIGIOUS, RELIGIOUS_PREF } from './enums';

/************************************************************************
 Classes representing queries from app to server (i.e. api calls)

 'Database' represents the database itself; in particular, it returns values
 somewhat like http responses, or more specifically, axios return values.
 Idea is that this emulates actual db operations that are done on the
 server; this class can be replaced by one that just makes API calls.
 (Or, for more realism, might actually replace this with API calls
 and simulate db receiving/returning such calls.)

 'Query' represents all queries made by the app.  It also returns axios-like
 values; clients select 'data' to get the actual value.  But in addition,
 it can throw exceptions. The Query class is strictly client-side.

 We attempt to do as much as possible in Query. Some operations can only
 be done in the database, either because they involve multiple users
 (e.g. finding matches), or because they involve a lot of data (which
 is filtered to return a smaller amount to the client), or because they
 involve transactions which can be most safely done on the server.

 The layout of the database and the local redux store are in some cases
 very similar. The database layout is shown in the 'defaults' attribute
 of Database, which includes schemas for each table. Again, the idea is
 that the actual database models will match these. Consts like
 JOKE_TYPES, etc. are common to database and local store.
*************************************************************************/

// errors returned from database calls.  this does not count server errors like
// "408 Request Timeout".  Not handling those at the moment.
const DB_ERR = {
  BAD_QUERY:      "BAD_QUERY",     // badly-formed query; should never happen
  NOT_UNIQUE:     "NOT_UNIQUE",    // a field expected to be a key matches multiple records
  ALREADY_EXISTS: "EXISTS",        // trying to add a record that already exists
  MISSING_ARG:    "MISSING_ARG",   // missing reqd arg
  EXTRA_ARG:      "EXTRA_ARG",     // missing reqd arg
  UNKNOWN_QUERY:  "UNKNOWN_QUERY", // should never happen
  NOT_FOUND:      "NOT_FOUND",     // item expected to exist (for update)
  OTHER:          "OTHER",         // specialized
}

// message threads.  a thread is from one user to another, but we need to be
// able to find all thread between two users, in either direction; a thread_id
// is attached to each message identifying the two interlocutors as a set
function thread_id(id, other_id) {
  let x = parseInt(id) < parseInt(other_id) ? [id, other_id] : [other_id, id];
  return `${x[0]},${x[1]}`;
}

// report missing field names for the given query (from Database or Query)
// allows for only conjunction of field names, not disjunction
// e.g. missing_args('get', 'user', args) - args must include 'id'
//
// missing args is strictly an internal error; will never happen in
// production.  particularly should not happen for db calls, since these
// all come from Query.  still, keeping this as a sanity check
//
// Database and Query have their own sets of required args, even in
// some cases where the queries seem "the same"
function missing_args(db_or_q, op, what, args) {
  let reqd = db_or_q.reqd_args[op][what];
  if (reqd === undefined)
    return [DB_ERR.BAD_QUERY, `no such query: '${op}/${what}'`];
  const missing = reqd.filter(p => ! args.hasOwnProperty(p));
  return (
    missing.length
    ? [DB_ERR.MISSING_ARG,
       `'${op}/${what}' missing required arg(s): ${ missing.join(', ') }`]
    : null
  );
}

// for adds, should not supply fields that are not in the db schema
function extra_add_args(db, op, what, args) {
  let schema = what + "_schema";
  if (! db.defaults.hasOwnProperty(schema))
    return [DB_ERR.BAD_QUERY, `no schema for '${op}/${what}'`];
  let fields = db.defaults[schema];
  let extra = Object.keys(args).filter(p => ! fields.hasOwnProperty(p));
  return (
    extra.length
    ?  [DB_ERR.EXTRA_ARG, `'${op}/${what}' has extra arg(s): ${ extra.join(', ') }`]
    : null
  );
}

// see this.defaults below for details of database schemas
// initial value of this.db is an "empty" db; it matches the schemas
// given in defaults to the extent that some models are considered
// as objects ('users', keyed on user id, and 'jokes', keyed on joke id);
// the others are arrays. the distinction - though this should not be
// relied upon - is that for users and jokes, the key uniquely identifies
// a record; for others, we have index fields, but they do not uniquely
// identify a record.
//
// all queries return axios-like object (see axios_response and
// axios_error_response above), with fields status, data, and optionally error
// in most cases, we return good results, with status 200, and the only
// possible error is BAD_QUERY for an ill-formed query (which also can
// never happen).  for example, if a get request fails to find the thing,
// it returns an empty array (a non-error) result.  There are a few
// exceptions, e.g. an attempt to add a user with non-unique email, an
// error is returned
class Database {

  constructor(db) {

    this.get = this.get.bind(this);
    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.reactivate = this.reactivate.bind(this);
    this.blacklist = this.blacklist.bind(this);
    this.unblacklist = this.unblacklist.bind(this);
    this.archive = this.archive.bind(this);

    this.db =
      db ? db : {
        users: {},
        jokes: {},
        message_threads: [],
        messages: [],
        votes: [],
        blacklists: [],
        pokes: [],
        matches: [],
        archives: {},
      };

    // in principle, can never get wrong arg's since queries all come
    // from Query.  adding extra level of protection.
    this.reqd_args = {
      get: {
        user: ['id'],
        user_by_email: ['email'],
        users: [],                  // get seq of all users
        jokes: [],                  // all jokes
        joke: ['joke'],
        votes: ['user'],            // ids of user's voted-on jokes
        votes_of: ['user'],         // records of user's voted-on jokes
        message_threads: ['user'],              // all of user's threads
        message_thread: ['user', 'other_user'], // thread btwn two users
        messages: ['user', 'other_user'],       // msgs btwn two users
        messages_of: ['user'],                  // all of user's messages
        blacklists: ['user'],                   // all user's blacklists
        pokes: ['user'],
        matches: ['user'],           // return matches, if new, after filtering
        current_matches: ['user'],   // return matches, without filtering
        new_matches: ['user'],       // recalc matches
        tentative_matches: ['user'], // recalc matches from prefs only
        profiles: ['ids']
      },
      add: {
        user: ['email', 'password'],
        joke: ['name', 'type', 'cat'],
        message_thread: ['user', 'other_user', 'affinity'],
        message: ['user', 'other_user'],
        vote: ['user', 'joke'], // either 'vote' or 'flag' must be present
        blacklist: ['user', 'other_user'],
        poke: ['user', 'other_user', 'affinity'],
        matches: ['matches']
      },
      update: {
        user: ['id'],
        joke: ['id'],
        blacklist: ['user', 'other_user'],
        match: ['user', 'other_user', 'affinity'],
        message_thread: ['user', 'other_user'],
        vote: ['user', 'joke'],
      },
      remove: {
        joke: ['id'],
        blacklist: ['user', 'other_user'], // blacklist from user to another
        blacklists: ['user'],              // all user's blacklists
        poke: ['user', 'other_user'],      // poke from user to another
        pokes: ['user'],                   // all user's pokes
        match: ['user', 'other_user'],   // one match
        matches: ['user'],                 // user's matches
        message_threads: ['user'],         // all user's threads
        messages_of: ['user'],             // all user's messages
        message_thread: ['user', 'other_user'], // thread btwn two users
        messages: ['user', 'other_user'],  // msgs btwn two users
        votes: ['user'],                   // all user's votes
      }
    }

    // this gives the structure of each table, by giving default values for each
    // field.  an add operation is required to provide certain fields, which will
    // override the defaults (as will any other fields given in args).
    // generally, we have singular schema for a record and plural for a
    // table, e.g. 'joke_schema' gives a default joke record, and 'jokes_schema',
    // representing the entire set of jokes, is an empty object.
    //
    // for the two cases where the schema is an object - user and joke - the keys
    // are the id values in the object (user["10"] = { id: 10, email: ... }),
    // except that the key is a string and the value an int (not for any deep
    // reason, just seems right; this is actually an issue only when writing an
    // object constant, since numbers cannot be given as keys; in most cases,
    // js automatically converts the ints to strings when necessary)
    //
    // A note on dates:  Where we just need a day - signup date and birthday -
    // we use American format mm/dd/yyyy (you can get this from a Date object
    // with .toLocaleDateString()).  Where we need an exact time (message
    // timestamp, thread last-read time), we use ISO format (.toISOString).
    // ISO format is confusing in that it numbers months from zero but days
    // from 1, but of course is perfectly accurate; so we avoid confusion
    // when we can.  Note that we can call new Date(d) for d in either format.
    //
    // TODO: decide which items, if any, need to be time-stamped. currently,
    //       we time-stamp only messages; we also keep sign-up date (not time)
    //       and last-read time for msg threads.
    this.defaults = {
      // db_schema: {
      //   users: users_schema,
      //   jokes: jokes_schema,
      //   message_threads: message_threads_schema,
      //   messages: messages_schema,
      //   votes: votes_schema,
      //   blacklists: blacklists_schema,
      //   pokes: pokes_schema,
      //   matches: matches_schema
      // }
      user_schema: {
        id: 0,  // id of user; probably will use django's assignment of id's, which start at 1
        active: true, // user still active
        signup_date: "",  // mm/dd/yyyy
                          // signup_date != "" => email confirmed (sign-up complete)
        // required for signup
        username: "",     // need not be unique
        email: "",        // *must* be unique
        uid: "",          // unique - provided by fb auth
        password: "",     // taken care of by django; not sure how to handle it in local
        // required to *see* matches (dating prefs) - "group 1"
        seeking: SEEKING.NOINFO,
        gender_pref: GENDER.NOPREF,
        zipcode: "",      // TODO: use an address; zip simpler but obviously less precise
        age_low: 0,
        age_high: 1000,
        geog_limit: 0,    // miles; NOT CURRENTLY USED
        // required to "poke" - "group 2"
        birthday: "",     // string in US format: mm?/dd?/yyyy
        gender: GENDER.NOINFO,
        // required to "poke" - "group 3"
        snapshot: "",     // filename of photo
        snapshot_ar: 0.8, // aspect ratio
        snapshot_url: "", // full url of photo
        brief_intro: "",
        // required to rate non-core jokes
        obscene_okay: true, // N.B. core jokes are never obscene
        // optional
        height: 0, // height in inches
        low_height: 1, // height prefs in inches; low_height = 1 => no pref
        high_height: 200,
        married: MARITAL_STATUS.NOINFO,
        married_pref: null, // set of values from MARITAL_PREF, null = nopref
        smoker: SMOKER.NOINFO,
        smoker_pref: SMOKER_PREF.NOPREF,
        drinker: DRINKER.NOINFO,
        drinker_pref: DRINKER_PREF.NOPREF,
        children: CHILDREN.NOINFO,
        children_pref: CHILDREN_PREF.NOPREF,
        educ_level: EDUC_LEVEL.NOINFO,
        educ_pref: EDUC_PREF.NOPREF,
        religious: RELIGIOUS.NOINFO,
        religious_pref: RELIGIOUS_PREF.NOPREF,
        religion: "",
        last_matchcalc_time: 0, // time of last match recalc
        last_profedit_time: 0,
        have_to_do: "",
        like_to_discuss: "",
        like_to_do: "",
        like_to_listen: "",
        like_to_read: "",
        like_to_watch: "",
        looking_for: "",
      },
      users_schema: {
        // "id": user_schema,  // id matches user's id field
      },
      joke_schema: {
        id: "0",             // django id; starts at 1
        active: true,
        // the joke - type/content/blueness
        type: JOKE_TYPES.TEXT,
        name: "",          // unique name
        text: "",          // joke text for TEXT
        url: "",           // URL for IMAGE and VIDEO
        isObscene: false,  // is the joke obscene?
        cat: 0,            // category: JOKE_CAT.{CORE, NONCORE}
        count: 0,          // # of votes
        mean: 0,           // average vote
        stdev: 0,          // std. dev. of votes (high is good!)
        // joke provenance
        source: "",        // org. name
        copyright_holder: "",
        date_added: "",
      },
      jokes_schema: {
        // "id": joke_schema, where id matches id field of joke
      },
      // record representing all messages between a user and one other user
      // inv: message thread exists from u -> v iff it exists from v -> u
      // (user, other_user) is key
      message_thread_schema: {
        user: 0,              // id of user
        other_user: 0,        // id of other user
        msg_avail: true,      // this user has an unread message from other user
        affinity: [0, 0, 0],  // [humor, humor+ forward, humor+ reverse]
        last_read: "",        // date-time in ISO format: new Date().toISOString()
                              // "yyyy-mm-ddThh:mm:sss.sssZ"; timezone is "zero UTC offset"
                              // last time user read messages from other user
                              // *on any device*; this means *read*, not *downloaded*
                              // "" => this user has never read a msg on
                              // this thread (though they may have written one)
                              // Inv: if msg_avail, there must be a message from
                              //      other user newer than last_read
        latest_message: ""
      },
      message_threads_schema: [
        // all messages threads; unique key is (user,other_user)
      ],
      // messages from user to other_user
      // thread_id(u, v) (which is commutative) is an index (not a key), so that we
      // can find all messages between u1 and u2 (in either direction) by querying on thread_id
      // (this representation is slightly wasteful in that any one of {user, other_user,
      // thread_id} can be determined from the other two)
      message_schema: {
        thread_id: "",    // "sender,receiver" if sender < receiver; ow "receiver,sender"
        user: 0,          // sender
        other_user: 0,    // receiver
        date: "",         // date-time of message in ISO format
        msg: "",
      },
      messages_schema: [
        // message_schema...
      ],
      vote_schema: {
        user: 0,   // id of user
        joke: 0,   // id of joke
        vote: 0,   // votes are integers [0, 5]; [1-5] are actual responses (probably)
                   // 0 indicates that user was shown the joke, but "passed"
                   // TODO: decide whether the above is accurate
        flag: JOKE_FLAG.NONE,
        flag_comment: ""
      },
      votes_schema: [
        // vote_schema
      ],
      blacklist_schema: {
        user: 0,            // id
        other_user: 0,      // id
        blacklist: true,    // inv: blacklist or blacklisted_by (maybe both)
        blacklisted_by: false
        // inv: if user blacklists other_user, then other_user blacklisted_by user
        // it is theoretically possible for both blacklist and blacklisted_by to be true,
        // but it would required very precise timing, because as soon as one user blacklists
        // another, they both disappear from each other's view
        // when a blacklist record is created, necessarily either blacklist or blacklisted_by
        // is true (hence inv given above). but if we were to allow de-blacklisting, we
        // could get blacklist=false and blacklisted_by=false (in both directions); then both
        // records can and should be removed
      },
      blacklists_schema: [
        // blacklist_schema
      ],
      poke_schema: {
        user: 0,       // id
        other_user: 0, // id
        affinity: [0, 0, 0], // [int 0 - 100, int 0 - 100]
        poketime: '',
      },
      pokes_schema: [
        // poke_schema
      ],
      match_schema: {
        user: 0,       // id
        other_user: 0, // id
        affinity: [0, 0, 0], // [int 0 - 100, int 0 - 100]
      },
      matches_schema: [
        // match_schema
      ],
      archives_scheme: {
        // archive is keyed on user ids
        // entry for each user has format:
        // [[query-name, [rec1, rec2, ...]], [query-name, [rec1, rec2, ...]], ...
        // currently query-names are 'messages_of', 'votes', and 'blacklists'
        // these are sufficient to reinstate user; pokes not needed,
        // message_threads can be reproduced from messages
        // (in the case of blacklists, we only archive recs with user = id;
        // but recs with other_user = id are symmetric, so can be reproduced)
      },
    }
  }

  // for some responses, status is sufficient, no data is needed
  // as noted above, we are ignoring return status for now, assuming
  // all responses are "200".  Any errors are given in the body of the response
  axios_response(data = null, status=200) {
    return {
      status: status,
      data: data
    };
  }
  axios_save_response(data = null, status=200) {
    if (NONPROD_MODE) {
      localStorage.setItem('laughstruck_test_db', JSON.stringify(this.db));
    }
    return {
      status: status,
      data: data
    };
  }
  axios_error_response(error, msg, data, status=200) {
    return {
      status: status,
      error: error,
      msg: msg,
      data: data
    };
  }

  // user goes inactive.
  // this is called from update('user'), so active bit already reset
  // - leave profile but reset active bit
  // - remove matches
  // - remove all mentions in blacklists and pokes (i.e.
  //     remove this user's pokes and other user's pokes of this one, etc)
  //     (Leave mentions in matches; these are filtered when delivered)
  // - remove and archive threads and votes
  // note that these 'remove' ops remove records in both directions,
  //   where appropriate
  deactivate(id) {
    let args = { user: id };
    this.remove('pokes', args);
    this.remove('message_threads', args);
    this.archive('messages_of', args);
    this.remove('messages_of', args);
    // only need to archive blacklists in one direction
    this.archive('blacklists', args);
    // but remove blacklists in both directions
    this.remove('blacklists', args);
    this.archive('', args);
    this.remove('votes', args);
    this.remove('matches', args);
  }

  // TODO: fill this in
  // previously-active user is reactivated; restore data from archives
  reactivate(id) {
  }

  // new blacklist has been added from user to other_user
  // remove ancillary data: pokes, message_threads, and messages;
  // archive messages
  blacklist(args) {
    let revargs = { user: args.other_user, other_user: args.user };
    // harmless if no pokes
    this.remove('poke', args);
    this.remove('poke', revargs);
    this.remove('message_thread', args);
    this.remove('message_thread', revargs);
    this.archive('messages', args);
    this.remove('messages', args);
  }

  // TODO: fill this in
  // args = {user, other_user}
  // previous blacklist has been cleared (in both directions);
  // restore thread between users, if any
  // (we do not restore pokes)
  unblacklist(args) {
  }

  // store records obtained from query into archive table,
  // with format: [query-name, [stringified rec1, stringified rec2, ...]]
  archive(what, args) {
    let id = args.user;
    let now = new Date().toISOString();
    if (! this.db.archives[id]) this.db.archives[id] = [];
    let records = this.get(what, args).data;
    this.db.archives[id].push([what, now, JSON.stringify(records)]);
  }

  // get
  // ===
  // access database tables, using info in args.  required args are listed above.
  // in most cases, this is a simple key-based fetch of a record or set of records.
  //
  // Plural is used for query that return an array, singular for those that return
  // a single object
  //
  // errors: only possible error is BAD_QUERY, meaning the wrong arguments were
  //   supplied, but this is in fact impossible. otherwise, get always returns
  //   non-error result. if it cannot find the thing, it just returns empty array
  get(what, args) {

    let err_data = { action: 'add', query: what, ...args };

    let a = missing_args(this, 'get', what, args);
    if (a) return this.axios_error_response(a[0], a[1], err_data);

    switch (what) {
      // FIXME: inactive users are considered non-existent for this query
      //        but probably need another query to get users even if inactive
      //        (presumably not for db_query, but for internal db queries)
      case 'user': { // result: user record
        let users = this.db.users;
        let v = users[args.id];  // users is object keyed on id
        let not_present = v === undefined || ! v.active;
        return this.axios_response(not_present ? [] : [v]);
      }
      case 'user_by_email': { // result: user record
        let users = this.db.users;
        let v = Object.values(users).filter(r => r.email === args.email);
        return this.axios_response(v);
      }
      case 'users': { // result: array of all users
        return this.axios_response(Object.values(this.db.users));
      }
      case 'jokes': { // result: array of all jokes
        return this.axios_response(Object.values(this.db.jokes));
      }
      case 'joke': { // result: single joke
        let joke = this.db.jokes[args.joke];
        if (joke) {
          return this.axios_response([joke]);
        } else {
          return this.axios_response([]);
        }
      }
      // all threads involving this user
      case 'message_threads': {
        let threads = this.db.message_threads;
        let v = Object.values(threads).filter(r => r.user === args.user);
        return this.axios_response(v);
      }
      case 'message_thread': { // thread object for (user[sender], other_user[rcvr])
                               // empty array if thread does not exist
        let threads = this.db.message_threads;
        let v = threads.filter(
                  r => r.user === args.user && r.other_user === args.other_user);
        return this.axios_response(v);
      }
      case 'messages': { // array of messages between 'user' and 'other_user'
                         // sorted on time; only messages > args.since, if present
        let msgs = this.db.messages;
        let v = msgs.filter(r => r.thread_id === thread_id(args.user, args.other_user));
        if (args.since) {
          v = v.filter(m => m.date > args.since)
        }
        v = v.sort((m1, m2) => m1.date - m2.date);
        return this.axios_response(v);
      }
      case 'messages_of': { // array of all messages from or to 'user'
                            // not sorted
        let msgs = this.db.messages;
        let v = msgs.filter(r => r.user === args.user
                                 || r.other_user === args.user);
        return this.axios_response(v);
      }
      case 'votes': { // result: array of ids of voted-on jokes for user
        let votes = this.db.votes;
        let v = votes.filter(r => r.user === args.user).map(j => j.joke);;
        return this.axios_response(v);
      }
      case 'votes_of': { // result: array of all votes for user
        let votes = this.db.votes;
        let v = votes.filter(r => r.user === args.user);
        return this.axios_response(v);
      }
      case 'blacklists': { // result: array of blacklists user->anyone
        let blacklists = this.db.blacklists;
        let v = blacklists.filter(r => r.user === args.user);
        return this.axios_response(v);
      }
      case 'pokes': { // result: array of pokes for user
        let pokes = this.db.pokes;
        let v = pokes.filter(r => r.user === args.user);
        let w = pokes.filter(r => r.other_user === args.user);
        return this.axios_response([v, w]);
      }
      // matches are an approximation, since they are not calculated
      // continuously. we always filter before downloading. ('filter'
      // means remove if other user is no long eligible - e.g. inactive,
      // blacklisted - or no longer compatible - wrong age, address, etc.)
      //
      // There are three types of requests:
      //   - 'matches': download current matches for user, if
      //     they were calculated more recently than given time (or time
      //     is omitted). Filter before downloading, as noted above.
      //   - 'new_matches': recalculate matches and deliver new ones;
      //     for new users and when profile is changed. We still leave
      //     existing matches (after filtering).
      //   - 'tentative_matches': calculate matches based solely
      //     on prefs (not profile info); just for onboarding

      // 'matches': args: { user: <id>, last_recalc_time?: <time> }
      //   1. filter existing matches (i.e. remove any that
      //      are blacklisted or poked or other user is inactive)
      //   2. - if last_recalc_time missing, send matches
      //      - ow, if last_recalc_time < user's last_matchcalc_time, send matches
      //      - ow, if filtering removed anything, send matches
      //      - ow, send null (no change at all)
      // return: null, or object { last_matchcalc_time: ..., matches: ... }
      //   where matches is an array of objects per match table
      case 'matches': {
        let profile = this.get('user', { id: args.user }).data[0];
        // filter match table and return true iff it changed
        let changed = null; // filter_matches(profile, this);
        let matches = this.get('current_matches', { user: args.user }).data;
        let t = profile.last_matchcalc_time;
        if (! args.last_recalc_time
            || args.last_recalc_time < t
            || changed) {
          return this.axios_response({ last_matchcalc_time: t,
                                       matches: matches });
        } else {
          return this.axios_response();
        }
      }

      // recalculate matches. called either for new users or because
      // user has changed profile in significant way, or maybe
      // just because. this is always followed by a call to
      // get 'matches' or 'current_matches', so it returns
      // only the recalc time
      // args = { user: <id> }
      case 'new_matches': {
        let profile = this.get('user', { id: args.user }).data[0];
        recalc_matches(profile, this);
        let t = new Date();
        this.update('user', { user: args.user, last_matchcalc_time: t });
        return this.axios_response(t);
      }

      // TODO: I believe this is obsolete; remove
      // args = { user: <id> }
      //case 'tentative_matches': {
      //  let profile = this.get('user', { id: args.user }).data[0];
      //  // recalc only if matches have never been calc'd
      //  if (! profile.matches_recalc_time) {
      //    recalc_tentative(profile, this);
      //  }
      //  // no last_matches_time, so return existing (just calc'd) matches
      //  return this.get('matches', args);
      //}

      // get unfiltered set of current matches for user
      // args = { user: <id> }
      case 'current_matches': {
        return this.axios_response(
                 this.db.matches.filter(m => m.user === args.user)
               );
      }

      case 'profiles': { // args.ids: array of ids
        // result: array of profiles, in same order as args.ids
        // (some may be missing, e.g. user has since gone inactive)
        // these contain all fields from these users' records that
        // are shown to other users
        let profs = [];
        for (var id of args.ids) {
          let p = this.get('user', {id: id}).data;
          if (p.length === 0) continue;
          p = p[0];
          profs.push({ id: id, // redundant
                       username: p.username,
                       birthday: p.birthday,
                       gender: p.gender,
                       snapshot: p.snapshot,
                       brief_intro: p.brief_intro,
                       height_ft: p.height_ft,
                       height_in: p.height_in,
                       married: p.married,
                       smoker: p.smoker,
                       drinker: p.drinker,
                       children: p.children,
                       educ_level: p.educ_level,
                       religious: p.religious,
                       religion: p.religion,
                     });
        }
        return this.axios_response(profs);
      }

      default:
        return this.axios_error_response(DB_ERR.UNKNOWN_QUERY, "", err_data);
    }
  }

  // get next available id number for table
  // the only tables with id's (that need to be generated) are users and jokes
  static get_max_id(tbl) {
    return Object.values(tbl).map((r) => r.id).reduce((a,b) => Math.max(a,b), 0);
  }

  // add
  // ===
  // add new record to each table.
  // fields are provided in args and are all added; required fields for each query
  // are listed in missing_add_args above; only fields given in schema should be
  // included.
  //
  // all adds are singular, because we only add one record at a time,
  // with one exception: matches is a bulk add of match records
  //
  // almost always returns a simple "success" response - status 200, no data
  // exceptions are user and joke, which return the assigned id
  //
  // no validation of values currently; app itself validates; server may
  // add its own validation (?)
  // we do validate that there are no inappropriare fields ('extra_add_args')
  //
  // errors: returned via error field of response.  status is always 200.
  //
  // Validation: I'm still not sure to what extent the database should validate
  // its own invariants.  Right now it is generally assumed that calls only
  // supplies validated values (the databse does check that we are not adding
  // fields that don't exist). Database validation would be a fail-safe, but
  // a potentially expensive one. (Here's an example: when a record is added that
  // includes a user id, like poke, we should validate that that id actually
  // exists; but we know that the app can never poke a non-existent user; so
  // should we go to the added expense of checking this? It would double the cost
  // of a poke add. So, we're not doing that now, but this makes me uneasy.
  add(what, args) {

    let err_data = { action: 'add', query: what, ...args };

    let a = missing_args(this, 'add', what, args);
    if (a) return this.axios_error_response(a[0], a[1], err_data);
    // adding matches is a special case; see below
    if (what !== 'matches') {
      a = extra_add_args(this, 'add', what, args);
      if (a) return this.axios_error_response(a[0], a[1], err_data);
    }

    let schema = what + "_schema"; // valid or extra_add_args flagged error
    let dflts = this.defaults[schema];

    switch (what) {
      // transaction: check user email, make sure id is unique
      case 'user': { // add user; email must be new (again, should be enforced by app,
                     // but db must enforce all its invariants
        let tbl = this.db.users;
        if (args.hasOwnProperty('id')) {
          return this.axios_error_response(DB_ERR.EXTRA_ARG,
                                           "extra arg 'id' for add/user", err_data);
        }
        // let resp = this.get('user_by_email', args);
        // if (resp.data.length)
        //   return this.axios_error_response(DB_ERR.NOT_UNIQUE, "", err_data);
        let id = Database.get_max_id(tbl) + 1;
        let now = new Date().toLocaleDateString();
        let flds = { id: id + '', signup_date: now };
        tbl[String(id)] = { ...dflts, ...flds, ...args };
        return this.axios_save_response(flds);
      }

      case 'joke': { // new joke entry; calculate fresh id
        let tbl = this.db.jokes;
        let s = Object.values(tbl).filter(r => r.name === args.name);
        if (s.length)
          return this.axios_error_response(DB_ERR.NOT_UNIQUE, "", err_data);
        // not sure if these tests belong here.  there will eventually be an app to
        // add jokes, and the principle has been that we do as much as possible in
        // the app rather than on the server.  Otoh, arguably the db itself should
        // be responsible for maintaining invariants.  Not sure. (Test above -
        // uniqueness of name - def *does* belong here, since client cannot verify it)
        if (args.cat === JOKE_CAT.CORE && args.isObscene)
          return this.axios_error_response(DB_ERR.OTHER,
                         "core joke cannot be obscene", err_data);
        if (args.cat === JOKE_CAT.CORE && args.isObscene)
          return this.axios_error_response(DB_ERR.OTHER,
                         "core joke cannot be obscene", err_data);
        if (args.hasOwnProperty('id'))
          return this.axios_error_response(DB_ERR.EXTRA_ARG,
                         "extra arg 'id' for add/joke", err_data);
        if (args.type === JOKE_TYPES.TEXT && ! args.text) {
          return this.axios_error_response(DB_ERR.MISSING_ARG,
                         "missing arg text for text joke in add/joke", err_data);
        }
        if ((args.type === JOKE_TYPES.IMAGE || args.type === JOKE_TYPES.VIDEO)
            && (args.url === undefined || args.url === "")) {
          return this.axios_error_response(DB_ERR.MISSING_ARG,
                         "missing arg url for image/video joke in add/joke", err_data);
        }
        if (args.hasOwnProperty('text') && args.hasOwnProperty('url')
            && args.text && args.url) {
          return this.axios_error_response(DB_ERR.EXTRA_ARG,
                         "cannot give both text and url for add/joke", err_data);
        }
        let id = Database.get_max_id(tbl) + 1;
        tbl[String(id)] = { ...dflts, id: id + '', ...args };
        return this.axios_save_response({id: id});
      }

      // to avoid possible race condition, add thread in both directions on server
      // assume msg_avail false for other user (which is default)
      // Note: we assume thread is created by user sending a message, so
      //       we set msg_avail true for other user, false for this one.
      case 'message_thread': {
        // inv: only one message thread from u -> v
        // inv: thread from u -> v iff thread from v -> u
        // NB: formerly was invariant that there had to be at least one message for thread
        //     object to exist, but I don't see good reason for that.
        let ids = { user: args.user, other_user: args.other_user };
        let t = this.get('message_thread', ids).data;
        // thread should not exist, since app checked, but still check
        // because of asynchronous mod
        if (t.length !== 0)
          return this.axios_error_response(DB_ERR.ALREADY_EXISTS, "", err_data);
        let tbl = this.db.message_threads;
        tbl.push({ ...dflts, ...args, msg_avail: false})
        const invert = ([a,b,c]) => [a,c,b];
        tbl.push({ ...dflts, user: args.other_user, other_user: args.user,
                   msg_avail: true, affinity: invert(args.affinity) });
        return this.axios_save_response();
      }

      // add message (update receiver->sender thread object done separately by client)
      // no check for thread object; should there be?  in practice, there will be one,
      // but it's not fatal to be without one, so for efficiency, don't check here
      case 'message': {
        let tbl = this.db.messages;
        let t_id = thread_id(args.user, args.other_user);
        tbl.push({ thread_id: t_id, ...args });
        return this.axios_save_response({ thread_id: t_id });
      }

      case 'vote': {
        if (! (args.hasOwnProperty('vote') || args.hasOwnProperty('flag'))) {
          return this.axios_error_response(DB_ERR.MISSING_ARG, "vote or flag", args);
        }
        let tbl = this.db.votes;
        let v = tbl.filter((r) => r.user === args.user && r.joke === args.joke);
        // inv: only one vote for a particular user on a particular joke
        if (v.length)
          return this.axios_error_response(DB_ERR.ALREADY_EXISTS, "", err_data);
        tbl.push( { ...dflts, ...args } );
        return this.axios_save_response();
      }

      // add two records, giving blacklist in both directions.  if blacklist already
      // exists, client should not use add; use update instead
      // args are just user, other_user: user blacklists other_user
      case 'blacklist': {
        let tbl = this.db.blacklists;
        let v = tbl.filter((r) => r.user === args.user && r.other_user === args.other_user);
        // inv: only one blacklist record between user 1 and user 2
        if (v.length)
          return this.axios_error_response(DB_ERR.ALREADY_EXISTS, "", err_data);
        // inv: if user1 blacklists user2, then user2 is blacklisted_by user1, and vice versa
        //      (it is possible, though hard to achieve, for both to blacklist each other)
        tbl.push( { ...args, blacklist: true, blacklisted_by: false } );
        tbl.push( { user: args.other_user, other_user: args.user,
                    blacklist: false, blacklisted_by: true } );
        this.blacklist(args);
        return this.axios_save_response();
      }

      case 'poke': {
        let tbl = this.db['pokes'];
        let v = tbl.filter((r) => r.user === args.user
                                  && r.other_user === args.other_user);
        // inv: for these tables, only one record between user and other_user
        if (v.length)
          return this.axios_error_response(DB_ERR.ALREADY_EXISTS, "", err_data);
        tbl.push( args );
        return this.axios_save_response();
      }

      // add matches in bulk (all other adds add an individual record)
      // 'matches' arg is list of objects { user:, other_user:, etc.}
      // all previous matches for this user will be removed before this call, but no
      // need to assume that
      case 'matches': {
        // just validating args form
        for (var m of args.matches) {
          let keys = Object.keys(m).sort();
          let okay = keys.length === 3
                     && keys[0] === 'affinity'
                     && keys[1] === 'other_user'
                     && keys[2] === 'user';
          if (! okay) {
            return this.axios_error_response(DB_ERR.BAD_QUERY,
                "all matches must have form { user:.., other_user:.., "
                + "affinity:..}",
                err_data);
          }
        }
        if (args.matches.length > 0) {
          // let id = args.matches[0].user;
          this.db.matches = [...this.db.matches, ...args.matches];
          // this.db.users[id].last_matchcalc_time = new Date().toISOString();
        }
        return this.axios_save_response();
      }

      default: {} // impossible
    }
  }

  // update
  // ======
  // update record.
  // fields are provided in args and are all added/modified; required fields for each
  // query are listed in reqd_args.update above (mostly identical to get, since these
  // are just needed to fetch the record being updated).
  //
  // record being updated must exist.
  //
  // all updates are singular, because we only update one record at a time
  //
  // validation and errors: see comment on "add"
  //
  // Note that not all tables have an update operation; for some,
  // the only possible update is removal.
  // These are possible updates:
  //   user, user_by_email:  any field except id; 'user_by_email' cannot change email.
  //     'user' can change email, but it must still be unique
  //   joke: any field except id
  //   blacklist: if u1 blacklists u2, may still update with u2 blacklists u1; in this
  //     case, both (u1,u2) and (u2,u1) records already exist, but 'blacklist' and
  //     'blacklisted_by' fields may change
  //   thread: fields msg_avail and last_read can be updated
  update(what, args) {

    let err_data = { action: 'update', query: what, ...args };

    let a = missing_args(this, 'update', what, args);
    if (a) return this.axios_error_response(a[0], a[1], err_data);
    a = extra_add_args(this, 'update', what, args);
    if (a) return this.axios_error_response(a[0], a[1], err_data);

    switch (what) {
      case 'user': {
        let tbl = this.db.users;
        let r = this.get('user', args);
        if (r.error || r.data.length === 0)
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        let profile = r.data[0];
        if (args.email && args.email !== profile.email) {
          // email is being changed; check uniqueness
          let s = this.get('user_by_email', args);
          if (s.data.length !== 0)
            return this.axios_error_response(DB_ERR.NOT_UNIQUE, "", err_data);
        }
        if (args.hasOwnProperty('active') && args.active !== profile.active) {
          if (args.active) {
            this.reactivate(args.id);
          } else {
            this.deactivate(args.id);
          }
        }
        tbl[String(args.id)] = { ...profile, ...args };
        return this.axios_save_response();
      }

      case 'joke': {
        let tbl = this.db.jokes;
        let joke = tbl[args.id];
        if (! joke)
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        if (args.hasOwnProperty('name')) {
          let s = Object.values(tbl).filter(j => j.name === args.name);
          if (s.length)
            return this.axios_error_response(
                          DB_ERR.NOT_UNIQUE,
                          "name in update/joke",
                          err_data);
        }
        // not sure if these tests belong here. see add above
        // let cat = args.hasOwnProperty('cat') ? args.cat : joke.cat;
        // let isobscene = args.hasOwnProperty('isObscene')
        //                 ? args.isObscene
        //                 : joke.isObscene;
        // if (cat === JOKE_CAT.CORE && isobscene) {
        //   return this.axios_error_response(
        //                   DB_ERR.OTHER,
        //                   "core joke cannot be obscene",
        //                   err_data);
        // }
        // special requirements if changing joke type between text and img/video
        if (args.hasOwnProperty('type') && args.type !== joke.type) {
          // must provide new value of correct type, and null out old value (as a sanity check)
          // require both fields:
          if (! (args.hasOwnProperty('text') && args.hasOwnProperty('url'))) {
            return this.axios_error_response(
                          DB_ERR.OTHER,
                          "when changing joke type, provide empty string for old content",
                          err_data);
          }
          // if changing from text, null out text
          if (joke.type === JOKE_TYPES.TEXT && args.text !== "") {
            return this.axios_error_response(
                          DB_ERR.OTHER,
                          "changing joke type from text, must provide empty 'text' arg",
                          err_data);
          }
          // if changing to text, null out url
          if (args.type === JOKE_TYPES.TEXT && args.url !== "") {
            return this.axios_error_response(
                          DB_ERR.OTHER,
                          "changing joke type from text, must provide empty 'url' arg",
                           err_data);
          }
        } else { // type not changing
          if ((joke.type === JOKE_TYPES.TEXT && args.hasOwnProperty('url'))
              || (joke.type !== JOKE_TYPES.TEXT && args.hasOwnProperty('text'))) {
            return this.axios_error_response(
                          DB_ERR.OTHER,
                          "providing wrong content for joke of type " + joke.type,
                          err_data);
          }
        }
        tbl[joke.id] = { ...joke, ...args };
        return this.axios_save_response();
      }

      case 'message_thread': { // thread must already exist
        let r = this.get('message_thread', args).data;
        if (r.length === 0)
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        r = r[0];
        let threads = this.db.message_threads.filter(
                        r => ! (r.user === args.user && r.other_user === args.other_user));
        this.db.message_threads = [...threads, {...r, ...args}];
        return this.axios_save_response();
      }

      // args are in the form of a match record; remove existing record
      // for this pair of users and insert new one
      case 'match': { // update affinity
        let tbl = this.db.matches;
        let matches = tbl.filter(r => ! (r.user === args.user
                                         && r.other_user === args.other_user));
        this.db.matches = [...matches, args];
        return this.axios_save_response();
      }

      // blacklist already exists
      // only args are user, other_user, and blacklist:
      //   blacklist = true: set blacklist and blacklisted_by
      //   blacklist = false: reset blacklist and blacklisted_by
      // if result of update is blacklist and blacklisted_by both
      // false, then remove records
      // if result is blacklist and blacklisted_by both true, then
      // there is nothing extra to do; all records involving these two
      // users were already cleared when the records were added
      case 'blacklist': {
        let tbl = this.db.blacklists;
        let bls = tbl.filter(
                        r => r.user === args.user && r.other_user === args.other_user);
        if (bls.length === 0)
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", args);
        // bl.length = 1
        let bls2 = tbl.filter(
                        r => r.user === args.other_user && r.other_user === args.user);
        let [bl,bl2] = [bls[0],bls2[0]];
        bl.blacklist = args.blacklist;
        bl2.blacklisted_by = args.blacklist;
        if (! (bl.blacklist || bl.blacklisted_by)) {
          let resp = this.remove('blacklist', args);
          if (resp.error) {
            return resp;
          }
          this.unblacklist(args);
        }
        return this.axios_save_response();
      }

      case 'vote': {
        let tbl = this.db.votes;
        const f = v => v.user === args.user && v.joke === args.joke;
        let v = tbl.find(v => f(v));
        if (v) {
          this.db.votes = [ args, ...tbl.filter(v => !f(v))];
        }
        return this.axios_save_response();
      }

      default: {} // impossible
    }
  }

  // remove
  // ======
  // remove record(s) from tables
  // we allow for limited removal of records (now), in particular, users are not
  // removed but updated with active=false.
  //
  // matches removes all matches for the given user
  //
  // only possible error here is BAD_QUERY (which again is impossible in practice and
  // included only as a sanity check)
  remove(what, args) {

    let err_data = { action: 'update', query: what, ...args };

    let a = missing_args(this, 'remove', what, args);
    if (a) return this.axios_error_response(a[0], a[1], err_data);

    switch (what) {
      case 'joke': {
        let tbl = this.db.jokes;
        if (! tbl[args.id])
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        delete tbl[args.id];
        return this.axios_save_response();
      }

      // not possible now, but could be in future
      // remove in both directions - app responsible to make sure this is kosher
      case 'blacklist': {
        let tbl = this.db.blacklists;
        let [u, o] = [args.user, args.other_user];
        if (! tbl.find(r => r.user === u && r.other_user === o))
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        let newtbl = tbl.filter(r => ! ((r.user === u && r.other_user === o)
                                         || (r.user === o && r.other_user === u)));
        this.db.blacklists = newtbl;
        return this.axios_save_response();
      }

      // remove this user pokes other user (not vice versa)
      case 'poke': {
        let tbl = this.db.pokes;
        let [u, o] = [args.user, args.other_user];
        if (! tbl.find(r => r.user === u && r.other_user === o))
          return this.axios_error_response(DB_ERR.DOES_NOT_EXIST, "", err_data);
        let newtbl = tbl.filter(
                       r => !(r.user === args.user && r.other_user === args.other_user));
        this.db.pokes = newtbl;
        return this.axios_save_response();
      }

      // remove message threads between two users
      case 'message_thread': {
        let tbl = this.db.message_threads;
        let u = args.user, o = args.other_user;
        let newtbl = tbl.filter(
                       r => { let u1 = r.user, o1 = r.other_user;
                              return !((u===u1 && o===o1) || (u===o1 && o===u1));
                            });
        this.db.message_threads = newtbl;
        return this.axios_save_response();
      }

      // remove messages between two users
      case 'messages': {
        let tbl = this.db.messages;
        let tid = thread_id(args.user, args.other_user);
        let newtbl = tbl.filter(r => r.thread_id !== tid);
        this.db.messages = newtbl;
        return this.axios_save_response();
      }

      // unlike other cases, this is a "full reset" - remove all matches for this user
      // error not possible here; it is no error if there are no matches
      // TODO: remove this if not used
      case 'matches': {
        let tbl = this.db.matches;
        let newtbl = tbl.filter(r => r.user !== args.user);
        this.db.matches = newtbl;
        return this.axios_save_response();
      }

      // args = {user: , other_user: }, remove that match, if it exists
      case 'match': {
        let tbl = this.db.matches;
        let newtbl = tbl.filter(r => ! (r.user === args.user
                                        && r.other_user === args.other_user));
        this.db.matches = newtbl;
        return this.axios_save_response();
      }

      // remove all votes of this user
      case 'votes': {
        let tbl = this.db.votes;
        let newtbl = tbl.filter(r => r.user !== args.user);
        this.db.votes = newtbl;
        return this.axios_save_response();
      }

      // remove all pokes and pokedbys of this user
      case 'pokes': {
        let tbl = this.db.pokes;
        let newtbl = tbl.filter(r => r.user !== args.user
                                     && r.other_user !== args.user);
        this.db.pokes = newtbl;
        return this.axios_save_response();
      }

      // remove all this user's blacklists and blacklistedbys
      case 'blacklists': {
        let tbl = this.db.blacklists;
        let newtbl = tbl.filter(r => r.user !== args.user
                                     && r.other_user !== args.user);
        this.db.blacklists = newtbl;
        return this.axios_save_response();
      }

      // remove all this user's threads
      case 'message_threads': {
        let tbl = this.db.message_threads;
        let newtbl = tbl.filter(r => r.user !== args.user
                                     && r.other_user !== args.user);
        this.db.message_threads = newtbl;
        return this.axios_save_response();
      }

      // remove all this user's threads
      case 'messages_of': {
        let tbl = this.db.messages;
        let newtbl = tbl.filter(r => r.user !== args.user
                                     && r.other_user !== args.user);
        this.db.messages = newtbl;
        return this.axios_save_response();
      }

      default: {} // impossible
    }
  }
}



/**********************************************************
 All database invariants except single-field validations
 This list is liable to grow

 1. exists thread u->v, then ! poke(u,v).
    (poke(v,u) is possible but is pointless and should be removed)

 2. exists thread u->v => exists thread v->u

 3. all references to other users or jokes => user or joke should exist
    specifically:
    - message, message_thread: user & other_user
    - vote: user & joke
    - blacklist, poke: user & other_user

 4. exists record r in blacklist u->v:
    - exists blacklist v->u
    - either r.blacklist or r.blacklisted_by
    - there is no record in pokes, message_threads, or messages
      with user = u and other_user = v, or vice versa

5. exists record t in message_threads with u = t.user, v = t.other_user:
    - t.thread_id = thread_id(u, v)
    - t.msg_avail iff there exists m in messages with m.user = v,
      m.other_user = u, and m.date > t.last_read

6. record r in users with r.id = u, r.active = false:
    - there is no record in messages_threads, messages, pokes, blacklists,
      or votes with either user = u or other_user = u.
      (Iow, u is absent from entire db except users and possibly archives)

**********************************************************/

export { Database };
