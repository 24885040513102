import React, { useState, useRef } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { RegPage } from './RegPage';
import { VoteSw } from '../widgets/VoteWidget';
import { Explainer } from './Explainer';

import { TwitterIcon } from '../../assets/icons/twitter';
import { QuestionIcon } from '../../assets/icons/question';
import { ReportJokeOverlay } from './ReportJokeOverlay';
import { InfoIcon } from '../../assets/icons/info';

import { JOKE_CAT, JOKE_TYPES } from '../../enums';
import { core_explainer } from './utils';
import { config$test_url } from '../../config';

// votes
// no jokes left to vote on.
const BaseVoteNoJokes = ({onclick}) =>
    <div className="flex flex-row mt-4 space-x-4
                    sm:flex-col sm:space-y-2 sm:items-center">
      <div className="flex items-center h-10 bg-white rounded-lg px-4">
        <div className="font-normal text-base text-gray-text">
          You've voted on all the jokes!
        </div>
      </div>
      <button className="h-10 bg-orange rounded-lg px-4"
              data-cy="nojokes-return"
              onClick={onclick}>
        <div className="font-normal text-base text-white">
          Return to matches
        </div>
      </button>
    </div>;
const VoteNoJokes = RegPage(BaseVoteNoJokes);

// TODO: add this tooltip to report button
const report_joke_ex = 'Let us know if you think this joke is offensive';

const BaseVoteUI = ({joke, prev_vote, showOverlay, actions}) => {
  const [ coreex, setCoreex ] = useState(false);
  const [ reportEx, setReportEx ] = useState(false);

  let source, sourceIcon;
  if (joke.source) {
    if (joke.source.startsWith('Twitter')) {
      [source, sourceIcon] = [ joke.source.substr(8), <TwitterIcon /> ];
    } else {
      [source, sourceIcon] = [ joke.source, null];
    }
  }

  const core_joke = joke.cat === JOKE_CAT.CORE;

  let vote_ref = useRef();
  return (
    <div className="h-full w-full flex flex-col space-y-16 items-center">
      <div className={`flex flex-col mx-auto space-y-6
                       ${ core_joke ? 'w-4/5' : 'w-full' }`}>
        <div className="hidden sm:block">
          <div className={`${core_joke ? "hidden" : ""}` }>
            <img style={{ height: "10rem" }}
                 alt="small mock ad"
                 data-cy="joke-ad-sm"
                 src={`${config$test_url}/images/app_ad.png`}
                 className="mx-auto rounded-lg col-span-1"/>
          </div>
        </div>
        <div className="text-3xl font-semibold text-gray-text sm:hidden">
          Jokes
        </div>
        <div className="grid grid-cols-5 mt-4">
          <div className={`flex flex-col bg-white w-full
                           space-y-6 rounded-lg
                           sm:col-span-5
                           ${ core_joke ? "col-span-5" : "col-span-4" }`}
               data-cy={`vote-joke-${joke.id}`}
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
            <div className="flex text-3xl font-semibold text-gray-text
                            justify-center m-9">
              How funny is this?
            </div>
            <div className="mx-auto flex flex-col w-full space-y-2">
              <div className="mx-auto w-4/5 bg-gray-bg rounded-lg">
                <div className="flex font-normal text-base text-gray-text
                                m-6 text-left"
                     style={{ whiteSpace: "pre-line" }}>
                  {joke.type === JOKE_TYPES.TEXT
                   ? joke.text.trim()
                   : joke.type === JOKE_TYPES.IMAGE
                     ? <img src={joke.url} alt="pictorial joke" />
                     : <video width="320" height="240" controls>
                         <source src={joke.url} type="video/mp4" />
                         Your browser does not support the video tag.
                       </video>
                  }
                </div>
              </div>
              <div className="mx-auto w-4/5 grid justify-items-end">
                <div className="flex flex-row
                                sm:grid
                                sm:grid-cols-1 sm:gap-1 sm:justify-items-end">
                  <div className={`border rounded-lg mr-1 cols-span-1
                                   ${! joke.source ? 'hidden' : ''}`}>
                    <div className="flex flex-row items-center mx-3 my-2
                                    space-x-2">
                      <span className="font-normal text-gray-para">
                        Credit:
                      </span>
                      <span className="font-normal text-gray-text">
                        {source}
                      </span>
                      <div className={`${sourceIcon ? '' : 'hidden'}`}>
                        { sourceIcon }
                      </div>
                    </div>
                  </div>
                  <div className={`border rounded-lg mr-1 w-min cols-span-1
                                  ${ core_joke ? '' : 'hidden'}`}>
                    <div className="flex flex-row items-center mx-3 my-2
                                    space-x-4">
                      <div className="font-normal text-gray-text">
                        Core
                      </div>
                      <div className="relative">
                        <div data-cy="vote-coreex"
                             onClick={() => setCoreex(! coreex)}>
                          <QuestionIcon />
                        </div>
                        <Explainer open={coreex}
                                   cy_name="coreex"
                                   close={() => setCoreex(false)}
                                   exp={core_explainer} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-10" />
              <div className="mx-auto w-4/5 grid justify-items-center mt-10">
                <VoteSw the_ref={vote_ref}
                        joke_id={joke.id}
                        prev_vote={prev_vote.vote + ''} />
              </div>
              <div className="flex flex-col space-y-8">
                <button className="mx-auto bg-orange rounded-lg w-16 h-6"
                     data-cy="vote"
                     onClick={actions.vote(vote_ref)}>
                  <div className="font-semibold text-white text-sm text-center
                                  mt-0">
                    Rate
                  </div>
                </button>
                <div className="flex flex-row space-x-2 justify-center">
                  <div data-cy="open-reportex"
                       className="relative justify-center"
                       onClick={() => setReportEx(! reportEx)}>
                    <InfoIcon size="tiny" />
                    <Explainer open={reportEx}
                               cy_name="reportex"
                               pos="left-4 bottom-8 sm:-left-24"
                               close={() => setReportEx(false)}
                               exp={report_joke_ex} />
                  </div>
                  <button>
                    <div className="items-start text-sm text-center
                                    font-normal text-gray-para
                                    hover:underline"
                                    data-cy="open-report"
                                    onClick={() => actions.show_report(true)}>
                      Report joke
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="h-6" />
          </div>
          <div className={`${core_joke ? "hidden" : ""} sm:hidden` }>
            <img style={{ height: "30rem" }}
                 alt="mock ad"
                 data-cy="joke-ad"
                 src={`${config$test_url}/images/side_ad_5.png`}
                 className="ml-4 rounded-lg col-span-1"/>
          </div>
        </div>
      </div>
      <div className="h-8" />
    </div>
  );
};

const VoteUI = RegPage(BaseVoteUI, ReportJokeOverlay);

export { VoteNoJokes, VoteUI };
