import { GoodPromise, BadPromise } from './utils'; // eslint-disable-line no-unused-vars
import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { FS_MODE, config$static_url } from './config';
import { GENDER, MARITAL_STATUS, SEEKING,
         SMOKER, SMOKER_PREF, DRINKER, DRINKER_PREF,
         CHILDREN, CHILDREN_PREF,
         EDUC_LEVEL, EDUC_PREF,
         MARITAL_PREF,
         RELIGIOUS, RELIGIOUS_PREF,
         LOGLEVEL,
       } from './enums';
import { firebase, db_query } from './App';

/******************************************
*  Structure of database:
*  Top-level collections:
*    PublicProfiles: { id: pubprofile_schema }
*      -- fields included in downloaded profiles
*      Subdocs (*not* subcollections): votes
*    PrivateProfiles: { id: pvtprofile_scheme }
*      -- admin fields and private fields
*      Subdocs (not subcollection) others { id: other_schema },
*                                  notifications { notid: notification_schema }
*    Jokes: { jokes: { id: joke_schema... } }
*    JokeStats: { id : joke_stats_schema }
*    Messages: messages_schema
*    NextId: docs nextid0, ..., nextid9, each containing a single
*        integer field, nextid; see fsquery/get/fresh_id
*    Admin_userreports: { id1-id2-date: user_report_schema }
*      -- plan to prefix "Admin_" to adminstrative tables
*      -- id1 is reporting user, id2 subject of complaint
*    Admin_prospective: { prospects: { date: prospective_schema, ... } }
*      -- one doc, containing object with dates as keys
*      -- dates as keys are meaningless, but I don't want to use array
*    Admin_contact: { date|email: contact_schema }
*      -- key is meaningless, just has to be unique; both date and email
*      -- are stored in contact record
*      -- The keys here contain periods, which is not "best practices,"
*      -- but this will not be a heavily-trafficked collection
*    Admin_logs: { current: { <date>: [ <index>, <size> ] },
*                  <date>_<index>: { logs: [ log_schema, ... ]} }
*      -- firestore provides no way to directly check size of doc, but
*      -- docs are limited to 1MB. So, use one doc to record sizes,
*      -- then separate docs for each log file on each date (adding
*      -- new log files as existing file fills up)
*      -- <date> is in mm/dd/yyyy ('toLocaleDateString') format
*    Admin_queries: { uid|date : [ query_schema ] }
*      -- Key is just "|date" if no uid
*      -- query schemas are in array just to reduce db writes; number of
*      -- schemas per array is set to some number (20?)
*      -- This collection is intended only for early tests, to give
*      -- detailed picture of db activity; for simplicity it collects
*      -- FSQuery calls and not db operations; db ops can be derived
*      -- from these. Idea is that after a period of testing, we will dump
*      -- this entire collection and analyze it locally.
*
*  In schemas, we include values just for illustration;
*  actual initial values are given in init_profile below.
*  All dates are in ISO format "2020-01-18T22:19:30.674Z", even when
*  time is not needed (e.g. birthdays), for uniformity
*
*  Fields with * before name are required. Two criteria here: (1) Field is
*  required if it is in doc when doc is created (so always there), or
*  (2) field is required if its absence will cause a crash. For now, we
*  use (1), but (2) is what we really want, so should fix this.
*  Special case for private and public profiles: * for fields that are
*  required if active = true, ** for fields that are *always* required
*
*  pvtprofile_schema: {
*    **active: true, -- user still active
*    **id: "0",  -- id of user; an integer rendered as a string
*    *email: "",        -- *must* be unique
*    last_profedit_time: -- "2020-01-18T22:19:30.674Z"
*                        -- date/time of last match-changing prof edit
*    last_matchcalc_time: -- "2020-01-18T22:19:30.674Z"
*    obscene_okay: true, -- N.B. core jokes are never obscene
*    signup_date: -- "2020-01-18T22:19:30.674Z"
*    **uid: "",   -- auth-provided uid
*    -- subdoc
*    others: { id: others_schema }
*    notifications: { not_id: notification_schema }
*  }
*
*  pubprofile_schema: {
*    active: true, -- user still active
*    **id: "0",      -- id of user; an integer but rendered as a string
*    *age_high: 1000,
*    *age_low: 0,
*    *birthday: "2020-01-18T22:19:30.674Z",
*    *brief_intro: "",
*    *children: CHILDREN.NOINFO,
*    *children_pref: CHILDREN_PREF.NOPREF,
*    *drinker: DRINKER.NOINFO,
*    *drinker_pref: DRINKER_PREF.NOPREF,
*    *educ_level: EDUC_LEVEL.NOINFO,
*    *educ_pref: EDUC_PREF.NOPREF,
*    *gender: GENDER.NOINFO,
*    *gender_pref: GENDER.NOPREF,
*    geog_limit: 0,    -- miles; NOT CURRENTLY USED
*    have_to_do: "",
*    height: 0, -- in inches
*    high_height: 200, -- in inches
*    like_to_discuss: "",
*    like_to_do: "",
*    like_to_listen: "",
*    like_to_read: "",
*    like_to_watch: "",
*    looking_for: "",*
*    low_height: 1, -- in inches; 1 => no preference
*    married: MARITAL_STATUS.NOINFO,
*    married_pref: null, -- set of values from MARITAL_PREF, null = nopref
*    religion: "",
*    religious: RELIGIOUS.NOINFO,
*    religious_pref: RELIGIOUS_PREF.NOPREF,
*    seeking: SEEKING.NOINFO,
*    smoker: SMOKER.NOINFO,
*    smoker_pref: SMOKER_PREF.NOPREF,
*    snapshot: "",     -- filename or url of photo
*    snapshot_url: "", -- url
*    **uid: "", -- auth-provided uid,
*    username: "",     -- need not be unique
*    *zipcode: "",      -- TODO: use an address; zip simpler but obviously less precise
*    // subdoc
*    votes: { j: vote_schema, ... } -- j a joke id
*  },
*
*  // all fields are optional; bool fields default to false
*  // when other user profiles are fetched and put in store (in 'others'),
*  // they can be augmented with these fields, iow 'others' in store are
*  // basically merge of public profile and these fields
*  others_schema: {
*    blacklist: bool,
*    blacklisted_by: bool,
*    match: bool,
*    pokes: bool,
*    pokedby: bool,
*    pokedby_seen: bool,   -- used to set connect count in top menu
*    poketime: datestring,     -- date/time in ISO format; time other user poked
*                          -- this one; used to order profiles on connects page
*                          -- [Should be 'pokedby_time']
*    thread: bool,         -- at least one message has been sent, in
*    last_sent: datestring, -- last time user sent msg to other
*    last_rcvd: datestring, -- last time other sent msg to user
*    [last_read: "2020-01-18T22:19:30.674Z" ]   -- timestamp of
*                          -- newest message the last time this user
*                          -- looked at other user's messages; absent =>
*                          -- user has never read other user's messages
*  }
*
*  // blacklist_schema is used in store, not in db
*  blacklist_schema: {
*    blacklist: true,    -- inv: blacklist or blacklisted_by (maybe both)
*    blacklisted_by: false,
*    blacklist_time: string, -- date/time in ISO format; when user blacklisted other
*    -- inv: if user blacklists other_user, then other_user blacklisted_by user
*    -- it is theoretically possible for both blacklist and blacklisted_by to be true,
*    -- but it would require very precise timing, because as soon as one user blacklists
*    -- another, they both disappear from each other's view.
*    -- when a blacklist record is created, necessarily either blacklist or blacklisted_by
*    -- is true (hence inv given above). but if we were to allow de-blacklisting, we
*    -- could get blacklist=false and blacklisted_by=false (in both directions); then both
*    -- records can and should be removed
*  },
*
*  vote_schema: {
*    vote: 0,   -- votes are integers; [1-5] are responses (low to high)
*               -- 0 is default; used only if user reports joke (so no vote)
*    flag: JOKE_FLAG.NONE,
*    flag_comment: ""
*  },
*
*  inv's of jokes:
*    - id and name unique; id matches key in object
*    - type = image or video => url != "" and text = ""
*    - type = text => url = "" and text != ""
*  joke_schema: {
*    active: true,
*    id: 0,
*    -- the joke - type/content/blueness
*    type: JOKE_TYPES.TEXT,
*    name: "",          -- unique name
*    text: "",          -- joke text for TEXT
*    url: "",           -- URL for IMAGE and VIDEO
*    isObscene: false,  -- is the joke obscene?
*    cat: 0,            -- category: JOKE_CAT.{CORE, NONCORE}
*    -- joke provenance
*    source: "",        -- org. name
*    copyright_holder: "",
*    date_added: "",
*    comments: "",      -- any addl info
*  },
*
*  joke_stats_schema: {
*    id: 0, // redundant, but allows us to use Object.values w.l.o. info
*    count: 0,          -- # of votes
*    votes: {
*      1: 0,  -- number of each vote
*      2: 0,
*      3: 0,
*      4: 0,
*      5: 0,
*    },
*    reports: [ { id: user-id, flag: 0, flag_comment: '' }]
*  }
*
*  -- thread_id is pair 'id1,id2' where id1 < id2
*  -- Collection Messages contains docs whose names are thread_ids.
*  -- doc 'id1,id2' contains one field, 'messages', containing
*  -- array of all messages between id1 and id2
*  -- I believe making the document an object is not necessary, but
*  -- it's harmless, and it does allow the possibility of adding more
*  -- thread-level data in the future
*  messages_schema: {
*    thread_id: { messages: [ message_scheme ] }
*  },
*
*  -- messages between user and other_user
*  message_schema: {
*    thread_id: "id1,id2",
*    user: 0,          -- sender (either id1 or id2)
*    other_user: 0,    -- receiver (the other of id1 or id2)
*    date: "2020-01-18T22:19:30.674Z", -- date-time of message in ISO format
*    msg: "",
*  },
*
*  user_report_schema: {
*    reporter: id,
*    subject: id,
*    date: ISO format date/time,
*    complaint: string, // see report form
*    location: string,  // ditto
*    comment: string,   // ditto
*    resolution: {  // may be absent
*      outcome: string,  // e.g. banned, email - short descr of result
*      date: ISO format date/time, // date of original entry
*      admin_name: string
*      comment: string, // if there are actions on this complaint subsequent
*                       // to first resolution, add all info (incl. date) here
*    }
*  }
*
*  -- prospective users; email is key
*  prospective_schema: {
*    date: ISO format date/time,
*    email: string,
*    zipcode: string
*  }
*
*  -- notification_cat is one of: newmsg (to be augmented)
*  -- key depends on notification. for newmsg: <id>-<date>
*  notification_schema: {
*    id: string, // key as described above
*    date: ISO format date/time, // post date
*    start_date: ISO format date/time, // same as date if absent
*    exp_date: ISO format date/time, // not. is outdated and should be deleted
*    cat: string, // as above - redundant; determines placement (?)
*    termination: string, // 'click', 'timeout', 'permanent', etc.?
*    timeout: int, // seconds; used only if termination = timeout
*    button: string, // button text when termination = click
*    text: string
*  }
*
*  -- logs are stored in db collection Admin_logs, never in store
*  log_schema: {
*    level: int, // 0 - 3, representing debug, info, warning, error
*    date: ISO format date/time,
*    // note: user id, if any, will normally be included in 'args' below
*    uid: string, // current user's UID (empty if none)
*    // see fsquery for error format; these fields are just copied from that
*    api: string,
*    op: string,
*    args: {...},
*    msg: string,
*  }
*
*  -- contacts are stored in Admin_contacts collection
*  contact_schema: {
*    type: string, // 'contact', 'techsupport'
*    from: string, // email
*    id: string, // if id has been assigned; '' ow
*    username: string, // if signed-in user; '' ow
*    date: ISO format date/time,
*    msgid: `${date}|${email}`, // name of doc in db (see contact_id)
*    subject: string,
*    text: string,
*    status: string, // 'new', 'inprocess', 'resolved'
*    notes: string,
*  }
*
*  -- query_schemas are stored in docs of the form { key : [ query_schema, ...]}
*  -- in collection Admin_queries.
*  -- complete query, plus time and identity of user (when available)
*  query_schema: {
*    op: string, // 'get', 'add', 'update', 'remove', 'admin'
*    what: string, // depends on op; see FSQuery
*    args: string, // stringified args
*    when: ISO format date/time,
*    email: string,
*    uid: string },
*  }
*
*  -- not implemented:
*  archives_schema: {
*    -- archive is keyed on user ids
*    -- entry for each user has format:
*    -- [[query-name, [rec1, rec2, ...]], [query-name, [rec1, rec2, ...]], ...
*    -- currently query-names are 'messages_of', 'votes', and 'blacklists'
*    -- these are sufficient to reinstate user; pokes not needed,
*    -- message_threads can be reproduced from messages
*    -- (in the case of blacklists, we only archive recs with user = id;
*    -- but recs with other_user = id are symmetric, so can be reproduced)
******************************************/

// used as key in Messages collection: 'u,v': [ msgs between u and v ]
// this function isn't user here, but in fsquery. putting it here
// because it is properly part of database structure
function thread_id(id, other_id) {
  let x = parseInt(id) < parseInt(other_id) ? [id, other_id] : [other_id, id];
  return `${x[0]},${x[1]}`;
}
function inv_thread_id(tid) {
  const i = tid.indexOf(',');
  return [tid.slice(0,i), tid.slice(i+1)];
}

// pubfields and pvtfields should match fields given in comment above
// since profile in store mixes public and private, these lists are used
// to split them when storing in db (see fsquery/add-user and update-user)
const pubfields = [
  'active',
  'id',
  'uid', // include to allow writing during signup
  // profile fields
  'birthday',
  'brief_intro',
  'children',
  'drinker',
  'educ_level',
  'gender',
  'height',
  'married',
  'religion',
  'religious',
  'smoker',
  'snapshot',
  'snapshot_ar',
  'snapshot_url',
  'username',
  'zipcode',
  // pref fields
  'age_high',
  'age_low',
  'children_pref',
  'drinker_pref',
  'educ_pref',
  'gender_pref',
  'geog_limit',
  'have_to_do',
  'high_height',
  'like_to_discuss',
  'like_to_do',
  'like_to_listen',
  'like_to_read',
  'like_to_watch',
  'looking_for',
  'low_height',
  'married_pref',
  'religious_pref',
  'seeking',
  'smoker_pref',
  // subdoc
  'votes'
];

// fields that other users never need to see
// NB: listeners/onProfileUpdate contains a partial copy of this
//     list; if it changes, make sure that is updated
const pvtfields = [
  'active',
  'id', // "small" unique integer calculated by us
  'uid', // guid calculated by firebase auth
  'email',
  'password', // included for testing only
  'last_profedit_time', // date/time of last match-changing prof edit
  'last_matchcalc_time',
  'obscene_okay',
  'signup_date', // date/time auth user was created
  // subdoc
  'others',
  'notifications',
];

/******************************************
* STORED PROFILES
* two types of profiles are in store: this user's and other users'
* this is not directly related to the db, but the representations are
* closely related (since they're mostly just taken directly from the db)
*
* USER PROFILE in store (str.getState().profile):
* basically the combined private and public profiles from the db (with
* the exception of pokes and threads fields; see below)
*
* 'init_profile' is just what it sounds like
* used in splash when new user first enters site and starts rating jokes
* includes all fields, though several of them are never read but immediately
* overwritten:
*   - id, signup_date, last_profedit_time: filled in at moment user signs up
*   - birthday, brief_intro, email, gender, obscene_okay,
*     seeking, snapshot, snapshot_ar, snapshot_url, username, zipcode:
*       mandatory fields filled in by user at signup
* for convenience and historical reasons, unlike in db, this includes all
* fields, public and private.  when written to db, 'pvtfields' and 'pubfields'
* above are used to split objects
*
* handling of fields 'pokes' and 'threads':
* their contents are always reflected in other-user profiles, so are
* not needed here and are not retained. as shown here,
* they are initially empty and are written to db as such when user signs up.
* thereafter, they are never populated in store profile; any pokes or
* messages are just written to db.  when user signs in, they are read from
* db and used to set fields in other user profiles, then dropped.
* (votes and blacklist, otoh, are retained because they are used when
* calculating new matches.)
* see comment about other-user profiles below for more info
******************************************/
// this acts as both initial profile in store and initial db entry
// for user, with some changes in both cases:
//   profile: no 'others' (instead in separate 'others' field in store)
//   db: fields are split for private and public profiles, no
//       'blacklist' (it is included in others)
const init_profile = {
    active: false, // set true upon completion of signup
    id: "1",
    age_high: 0,
    age_low: 0,
    birthday: "",  //FIXME: handle default correctly
    brief_intro: "",
    children: CHILDREN.NOINFO,
    children_pref: CHILDREN_PREF.NOINFO,
    drinker: DRINKER.NOINFO,
    drinker_pref: DRINKER_PREF.NOINFO,
    educ_level: EDUC_LEVEL.NOINFO,
    educ_pref: EDUC_PREF.NOINFO,
    email: "",
    gender: GENDER.NOINFO,
    gender_pref: [],
    geog_limit: 0,
    have_to_do: "",
    height: 0,
    high_height: 200,
    last_matchcalc_time: "", // must start as boolean false
    last_profedit_time: "",
    like_to_discuss: "",
    like_to_do: "",
    like_to_listen: "",
    like_to_read: "",
    like_to_watch: "",
    looking_for: "",
    low_height: 1, // low_height = 1 means no preference given
    married: MARITAL_STATUS.NOINFO,
    married_pref: [ MARITAL_PREF.SINGLE, MARITAL_PREF.MARRIED,
                    MARITAL_PREF.OPEN ],
    obscene_okay: true, // only field with a default pref
    religion: "",
    religious: RELIGIOUS.NOINFO,
    religious_pref: RELIGIOUS_PREF.NOINFO,
    seeking: SEEKING.NOINFO,
    signup_date: "",
    smoker: SMOKER.NOINFO,
    smoker_pref: SMOKER_PREF.NOINFO,
    snapshot: "",
    snapshot_url: `${config$static_url}/assets/images/default-avatar.jpg`,
    snapshot_ar: 0.935,
    // snapshot: "",
    // snapshot_ar: 1.0,
    // snapshot_url: "",
    username: "",
    zipcode: "",
    // subdocs
    notifications: {},
    others: {}, // in db, not in state
    blacklist: {}, // in state, not in db
    votes: {},
};

// fields which are counted when we calculate whether user's
// profile/prefs are complete (for message on match page)
// excludes 'religion' (which is *really* optional), and
// fields whose initial values are legit, incl married_pref and height
// prefs (low_height, high_height), which are initialized to no-pref
const optional_fields = [
  "age_high",
  "age_low",
  "children",
  "children_pref",
  "drinker",
  "drinker_pref",
  "educ_level",
  "educ_pref",
  "gender",
  "gender_pref",
  "have_to_do",
  "height",
  "like_to_discuss",
  "like_to_do",
  "like_to_listen",
  "like_to_read",
  "like_to_watch",
  "looking_for",
  "married",
  "religious",
  "religious_pref",
  "seeking",
  "smoker",
  "smoker_pref",
];

const optional_field_values = optional_fields.map(f => [f, init_profile[f]]);

function fbDateTime() {
  return FS_MODE
         ? firebase.firestore.Timestamp.now().toDate().toISOString()
         : new Date().toISOString();
}

function approx_size(obj) {
  return Math.round(JSON.stringify(obj).length * 1.5);
}

const log = args => db_query.add('log', args);
const error = args => log({ level: LOGLEVEL.ERROR, ...args });
const warn = args => log({ level: LOGLEVEL.WARN, ...args });
const debug = args => log({ level: LOGLEVEL.DEBUG, ...args });
const info = args => log({ level: LOGLEVEL.INFO, ...args });

// date is almost certainly unique, but add random digits jic
function contact_id(msg) {
  return `${msg.date}|${msg.from}`;
}

export { fbDateTime, thread_id, inv_thread_id,
         init_profile,
         pvtfields, pubfields, optional_fields, optional_field_values,
         approx_size,
         log, error, warn, debug, info,
         contact_id, };
