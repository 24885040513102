import React from 'react';

// appears in other user page if there is an ongoing convo
// and in UserCard when messaging is allowed
const ConvoDotsIcon = () =>
  <img style={{ height: "3rem", width: "3rem" }}
       alt="conversation bubble"
       src="/static/assets/images/convo-dots.svg" />

export { ConvoDotsIcon };
