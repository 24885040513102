import React, { createRef, useState } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { useStore, useDispatch } from 'react-redux';
import { Redirect } from '@reach/router';

import { RegPage } from '../ui/RegPage';
import { Waiting } from '../pages/StaticPages';
import { RadioButtonSw, RadioButtonGridSw } from '../widgets/RadioButton';
import { TextSw, TextareaSw } from '../widgets/TextWidget';

import { PopupNote } from '../ui/PopupNote';

import { auth, db_query, signed_in } from '../../App';
import { set_wait_for_data, wait_for_data } from '../Main';
import { joke_add_action, joke_update_action } from '../../store';
import { JOKE_CAT, JOKE_TYPES } from '../../enums';
import { CYPRESS_MODE } from '../../config';
import { get_subobj, objectMap } from '../../utils';

const BaseAdmin = () => {

  const [dis, str] = [useDispatch(), useStore()];

  // for jokes only. 'add' (default) or 'fetched'
  const [ formstate, setFormstate ] = useState('add')

  // for users; anon_users is the list of users
  const [ anon_users, setAnon_users ] = useState([]);

  // for contacts; messages is object key -> contact_schema (see dbstructure)
  // expanded are msgs shown in full; object key -> bool
  const [ messages, setMessages ] = useState({});
  const [ expanded, setExpanded ] = useState({});

  const [ removePopup, setRemovePopup ] = useState('');

  // wait for jokes
  const [ ready, setReady ] = useState(false);
  React.useEffect(() => {
    const f = () => {
        wait_for_data.then(() => setReady(true));
    };
    f();
  }, [ ready ]);

  if (! (signed_in()
         && (auth.currentUser.email === 'kamin@illinois.edu'
             || auth.currentUser.email === 'julia.kamin@gmail.com'))) {
    return <Redirect to='/splash' noThrow />;
  }

  if (! ready) {
    return <Waiting />;
  }

  //////////////////////////////////////////////////
  // JOKES
  //////////////////////////////////////////////////
  const refs = {
    // text fields
    id: createRef(),
    name: createRef(),
    url: createRef(),
    source: createRef(),
    copyright_holder: createRef(),
    date_added: createRef(),
    // textarea fields
    text: createRef(),
    comments: createRef(),
    // radio button fields
    active: createRef(),
    type: createRef(),
    isObscene: createRef(),
    cat: createRef(),
  };
  const text_fields = ['id', 'name', 'url', 'date_added',
                       'source', 'copyright_holder',
                       'text', 'comments'];
  const radio_fields = ['active', 'cat', 'type', 'isObscene'];

  const get_newjokeid = () =>
         '' + (1 + Math.max(...str.getState().jokes.unvoted
                                             .map(j => parseInt(j.id))));
  const get_dflts = () => ({
    id: get_newjokeid(),
    name: '',
    url: '',
    source: '',
    copyright_holder: '',
    date_added: new Date().toLocaleDateString(),
    text: '',
    comments: '',
    active: 'active',
    cat: 'non-core',
    type: 'text',
    isObscene: 'no',
  });

  // form on this page uses text for some fields where db uses
  // ints. translate joke to form values...
  const joke_to_form = joke => ({
    ...joke, // fields id, name, text, url, source, copyright_holder
    active: joke.active ? 'active' : 'inactive',
    cat: joke.cat === JOKE_CAT.CORE ? 'core' : 'non-core',
    type: { [JOKE_TYPES.TEXT]: 'text',
            [JOKE_TYPES.IMAGE]: 'image',
            [JOKE_TYPES.VIDEO]: 'video' }[joke.type],
    isObscene: joke.isObscene ? 'yes' : 'no',
    date_added: new Date(joke.date_added).toLocaleDateString(),
  });

  // ... and vice versa
  const form_to_joke = form => ({
    ...form, // fields id, name, text, url, source, copyright_holder
    active: form.active === 'active',
    cat: form.cat === 'core' ? JOKE_CAT.CORE : JOKE_CAT.NONCORE,
    type: { text: JOKE_TYPES.TEXT,
            image: JOKE_TYPES.IMAGE,
            video: JOKE_TYPES.VIDEO }[form.type],
    isObscene: form.isObscene === 'yes',
    date_added: new Date(form.date_added).toISOString(),
  });

  // check if joke is valid.
  // if so, return true; if not, set error and return false
  const valid_joke = (joke, textwidgets, radiowidgets) => {
    if (joke.text !== '' && joke.url !== '') {
      alert("only one of 'text' and 'url' should be filled in");
      return false;
    }
    if (joke.type === JOKE_TYPES.TEXT && joke.text === '') {
      textwidgets.text.setLocalerr('text-type joke must have text');
      return false;
    }
    if (joke.type !== JOKE_TYPES.TEXT && joke.url === '') {
      textwidgets.url.setLocalerr('non-text joke must have url');
      return false;
    }
    return true;
  }

  // respond to 'add' button
  // validate all fields, then do db ops (add joke and jokestats),
  // and clear form
  const submit_add = () => {
    const textrefs = get_subobj(refs, text_fields),
          radiorefs = get_subobj(refs, radio_fields);
    const textwidgets = objectMap(textrefs, r => r.current),
          radiowidgets = objectMap(radiorefs, r => r.current);

    const textdata = objectMap(textwidgets, w => w.getText()),
          radiodata = objectMap(radiowidgets, w => w.getChoice());
    const newjoke = form_to_joke({ ...textdata, ...radiodata });

    const jokes = str.getState().jokes.unvoted;
    if (! newjoke.id.match(/^[1-9][0-9]*$/)) {
      textwidgets.id.setLocalerr('id must be a positive integer');
      return;
    }
    if (jokes.find(j => j.id === newjoke.id)) {
      textwidgets.id.setLocalerr('id already in use');
      return;
    }
    if (jokes.find(j => j.name === newjoke.name)) {
      textwidgets.name.setLocalerr('name already in use');
      return;
    }
    if (! valid_joke(newjoke, textwidgets, radiowidgets)) {
      return;
    }

    dis(joke_add_action({ joke: newjoke }))
    // no need to wait
    db_query.add('joke', newjoke);
    db_query.add('jokestats',
                 {
                   id: newjoke.id,
                   count: 0,
                   votes: { 1: 0, 2: 0, 3: 0,
                            4: 0, 5: 0},
                   reports: []
                 });

    submit_clear();
  }

  // fetch joke from store based on id or nm.
  // one of those fields must be filled in; joke with that name or id
  // must exist; if both fields given, joke with that id must have that name
  const fetch_joke = () => {
    const id = refs.id.current.getText();
    const nm = refs.name.current.getText();
    let joke;
    if (id === '' && nm === '') {
      refs.name.current.setLocalerr('id or name field must be filled in');
      return null;
    }
    const jokes = str.getState().jokes.unvoted;
    if (id !== '') {
      joke = jokes.find(j => j.id === id);
      if (! joke) {
        alert('id does not match any joke');
        return null;
      }
      if (nm !== '') {
        if (nm !== joke.name) {
          refs.name.current.setLocalerr('name does not match the name of joke ' + id);
          return null;
        }
      }
    } else {
      joke = jokes.find(j => j.name === nm);
      if (! joke) {
        refs.name.current.setLocalerr('name does not match any joke');
        return null;
      }
    }
    return joke;
  }

  // respond to 'update' button
  // some fields have changed. validate them, create new joke object
  // from field values, do db update, clear form
  const submit_update = () => {
    // note: no need to check id and name again, because
    // those fields were disabled after joke was fetched
    const textrefs = get_subobj(refs, text_fields),
          radiorefs = get_subobj(refs, radio_fields);
    const textwidgets = objectMap(textrefs, r => r.current),
          radiowidgets = objectMap(radiorefs, r => r.current);

    const textdata = objectMap(textwidgets, w => w.getText()),
          radiodata = objectMap(radiowidgets, w => w.getChoice());
    const newjoke = form_to_joke({ ...textdata, ...radiodata });

    if (! valid_joke(newjoke, textwidgets, radiowidgets)) {
      return;
    }

    dis(joke_update_action({ joke: newjoke }))
    db_query.add('joke', newjoke);

    submit_clear();
  }

  // respond to 'fetch' button
  // fetch joke, set form fields accordingly
  const submit_fetch = () => {
    const joke = fetch_joke();
    if (! joke) {
      return;
    }

    const values = joke_to_form(joke);

    for (var k of text_fields) {
      refs[k].current.setText(values[k]);
    }
    for (k of radio_fields) {
      refs[k].current.setChoice(values[k]);
    }

    setFormstate('fetched');
  };

  // respond to 'clear' button
  // reset all fields to default, set form state to 'add'
  const submit_clear = () => {
    const dflts = get_dflts();
    for (var k of text_fields) {
      refs[k].current.setText(dflts[k]);
      refs[k].current.setLocalerr('');
    }
    for (k of radio_fields) {
      refs[k].current.setChoice(dflts[k]);
    }
    setFormstate('add');
  };

  const dflts = get_dflts();

  //////////////////////////////////////////////////
  // USERS
  //////////////////////////////////////////////////
  function fetch_anon_users() {
    setReady(false);
    set_wait_for_data(
      db_query.admin('anon_users')
              .then(users => setAnon_users(users))
    );
  }

  function remove_user(user) {
    return () => {
      if (user.email !== '') {
        setRemovePopup(`user ${user.id} is not anonymous`);
        return;
      }
      setRemovePopup(`removing anonymous user ${user.id} (${user.uid})`);
      // no need to wait
      db_query.admin('rm_user', user)
      const other_users = anon_users.filter(u => u.id !== user.id);
      setAnon_users([ ...other_users, { signup_date: '', id: '', uid: user.uid } ]);
    }
  }

  //////////////////////////////////////////////////
  // CONTACTS/TECHSUPPORT
  //////////////////////////////////////////////////
  const contact_refs = {
    range: createRef(),
    status: createRef(),
    type: createRef(),
    user: createRef(),
  };

  const contact_dflts = {
    range: 'week',
    status: 'new',
    type: 'all',
  }

  const contacts_fetch = () => {
    const rng = contact_refs.range.current.getAttribute('value'),
          stat = contact_refs.status.current.getAttribute('value'),
          typ = contact_refs.type.current.getAttribute('value'),
          m = contact_refs.user.current.getText();
    const days = { day: 1, week: 7, month: 30 }[rng];
    const start = new Date(new Date() - days * 24 * 60 * 60 * 1000).toISOString();

    let where_clauses = [['date', '>=', start]];
    console.log('admin331', stat)
    if (stat !== 'all') {
      if (stat === 'unresolved') {
        where_clauses.push(['status', 'in', ['new', 'inprocess']]);
      } else {
        where_clauses.push(['status', '==', stat]);
      }
    }
    if (typ !== 'all') {
      where_clauses.push(['type', '==', typ]);
    }
    if (m) {
      const email_re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
      const id_re = /^[1-9][0-9]*$/;
      const username_re = /^[a-zA-Z ]+$/;
      if (m.match(email_re)) {
        where_clauses.push(['from', '==', m]);
      } else if (m.match(id_re)) {
        where_clauses.push(['id', '==', m]);
      } else if (m.match(username_re)) {
        where_clauses.push(['username', '==', m]);
      } else {
        contact_refs.user.current.setLocalerr('Must be email, id, or username');
        return;
      }
    }

    setReady(false);
    set_wait_for_data(
      db_query.admin('get_contacts', { wheres: where_clauses })
              .then(msgs => { setMessages(Object.fromEntries(
                                           msgs.map(m => [m.msgid, m])));
                              setExpanded(Object.fromEntries(
                                msgs.map(m => [ m.msgid, false ])));
                            })
      );
  }

  const bydate_desc = (m1, m2) => new Date(m2.date) - new Date(m1.date);

  // note: data-cy names here are not unique, so tests should
  //       avoid multiple message with same email address
  const SummaryContact = ({ msg, unexpand }) =>
    <div className="flex flex-row space-x-4">
      <button onClick={ () => setExpanded(
                                 { ...expanded, [msg.msgid]: ! unexpand }) }
              data-cy={`expand-${msg.from}`}
              className="border-2 border-orange">
        { unexpand ? "Collapse" : "Expand" }
      </button>
      <div>
        { new Date(msg.date).toLocaleString()}
      </div>
      <div>
        { msg.type === 'contact' ? 'C' : 'T' }
      </div>
      <div>
        { msg.from }
      </div>
      <div className={ (msg.id || msg.username) ? '' : "hidden" }>
        (
          { msg.id }
          { (msg.id && msg.username) ? ',' : '' }
          { msg.username }
        )
      </div>
      <div>
        "{ msg.subject }"
      </div>
    </div>;

  // refs for edit fields of individual contact records,
  // namely status and notes.
  // map msgid -> { status: ref, notes: ref }
  // filled in when contacts are expanded
  let field_refs = {};

  function contact_update(msgid, refs) {
    const stat = field_refs[msgid].status.current.getAttribute('value'),
          note = field_refs[msgid].notes.current.getText();
    const newflds = { status: stat, notes: note };
    setMessages({ ...messages,
                  [msgid]: { ...messages[msgid], ...newflds }});
    db_query.admin('update_contact', { msgid: msgid, ...newflds });
  }

  const FullContact = ({ msg }) => {
    if (! field_refs[msg.msgid]) {
      field_refs[msg.msgid] = { status: createRef(), notes: createRef() };
    }
    return (
      <div data-cy={`contact-${msg.from}`}>
        <div className="flex flex-col space-y-2">
          <SummaryContact msg={msg} unexpand={true} />
          {msg.text}
          <div className="mt-2 w-2/3">
            <div className="flex flex-row space-x-4">
              <div className="font-normal text-lg">
                status:
              </div>
              <div className="mt-2 w-full">
                <RadioButtonGridSw the_ref={field_refs[msg.msgid].status}
                               cols={3}
                               cy_prefix={`admin_field_${msg.from}_status`}
                               dflt={msg.status}
                               choices={['new', 'inprocess', 'resolved']} />
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              notes:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextareaSw ref={field_refs[msg.msgid].notes}
                          margin="mr-4"
                          dflt={msg.notes}
                          rows={10}
                          cols={100}
                          maxchars={100000}
                          prompt="Limit of 100000 characters"
                          cy_name={`admin_field_${msg.from}_notes`} />
            </div>
          </div>
          <div>
            <button onClick={ () => contact_update(msg.msgid, field_refs) }
                    data-cy={`admin_${msg.from}_submit`}
                    className="border-2 border-orange">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////
  // DOWNLOAD QUERIES
  //////////////////////////////////////////////////

  // Would prefer to put text in a file, but that seems complicated.
  // We just put it in a window from which it can be copied manually
  function put_in_browser_window(txt) {
    var oMyBlob = new Blob([ txt ], {type : 'text/html'});
    window.open(URL.createObjectURL(oMyBlob));
  }

  function get_queries() {
    db_query.admin('get_queries')
            .then(data => put_in_browser_window(JSON.stringify(data)));
  }

  //////////////////////////////////////////////////
  // MAILING LISTS
  //////////////////////////////////////////////////
  function get_mailing_lists() {
    db_query.admin('get_collection', { collection: 'PrivateProfiles' })
            .then(qs => {
              // new pokes
              let newpokes = [],
                  newmsgs = [];
              qs.forEach(s => {
                const user = s.data();
                if (! user || ! user.others || ! user.active) {
                  return;
                }
                // should not consider pokes/msgs from other users who are
                // inactive or blacklisted. Checking active status is hard,
                // and unlikely to come up often, so we don't do that
                const others = Object.values(user.others)
                               .filter(o => ! (o.blacklist || o.blacklisted_by));
                // new poke (might have failed to reset poke bits
                // when msg thread began, so ignore if thread)
                if (others.some(o => o.pokedby && ! o.pokedby_seen
                                               && ! o.thread)) {
                  newpokes.push(user.email);
                }
                // new msg - other has sent a message and this
                // user either has never read msgs from other or
                // not since new message
                if (others.some(o => o.thread && o.last_rcvd
                                     && (! o.last_read ||
                                         o.last_read < o.last_rcvd))) {
                  newmsgs.push(user.email);
                }
              });
              const txt = '<pre>UNSEEN POKES\n' +
                          newpokes.join('\n') +
                          '\n\nNEW MESSAGES\n' +
                          newmsgs.join('\n') +
                          '</pre>';
              if (CYPRESS_MODE) {
                const placeholder = document.createElement("div");
                placeholder.innerHTML = txt;
                const node = placeholder.firstElementChild;
                document.getElementById('get-mailing-lists')
                        .appendChild(node);
              } else {
                put_in_browser_window(txt);
              }
            });
  }

  return (
    <React.Fragment>
      <div className="flex flex-col space-y-8 bg-white rounded-lg w-full p-4"
            style={{ marginLeft: '3rem', marginRight: '3rem' }}
            id="topofadminpage">
        <div className="flex flex-row space-x-4">
          <div className="font-bold text-2xl">
            Admin page
          </div>
          <div className="w-8" />
          <button className="bg-gray-bg outline-2 outline-orange rounded-lg
                             py-2 px-4"
                  data-cy="goto-jokes">
            <a href="#editjokes">Jokes</a>
          </button>
          <button className="bg-gray-bg outline-2 outline-orange rounded-lg
                             py-2 px-4"
                  data-cy="goto-users">
            <a href="#usermgmt">Users</a>
          </button>
          <button className="bg-gray-bg outline-2 outline-orange rounded-lg
                             py-2 px-4"
                  data-cy="goto-contact">
            <a href="#contacts">Contact/Support</a>
          </button>
          <button className="bg-gray-bg outline-2 outline-orange rounded-lg
                             py-2 px-4"
                  onClick={get_queries}
                  data-cy="get-queries">
            DB download
          </button>
          <button className="bg-gray-bg outline-2 outline-orange rounded-lg
                             py-2 px-4"
                  onClick={get_mailing_lists}
                  data-cy="get-mailing-lists"
                  id="get-mailing-lists">
            Mailing lists
          </button>
        </div>
        <div className="font-bold text-xl pt-4" id="editjokes">
          Edit jokes
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-4">
            <div>
              <div className="flex flex-row text-gray-text items-center
                              justify-center w-24 h-8 bg-gray-300
                              font-normal">
                <button className="hover:text-blue-500 text-center"
                     data-cy={'admin-add-button'}
                     onClick={submit_add}>
                   Add
                 </button>
              </div>
            </div>
            <div>
              <div className="flex flex-row text-gray-text items-center
                              justify-center w-24 h-8 bg-gray-300
                              font-normal">
                <button className="hover:text-blue-500 text-center"
                     data-cy={`admin-fetch-button`}
                     onClick={submit_fetch}>
                   Fetch
                 </button>
              </div>
            </div>
            <div>
              <div className="flex flex-row text-gray-text items-center
                              justify-center w-24 h-8 bg-gray-300
                              font-normal">
                <button className={`text-center
                                    ${formstate !== 'fetched' ? '' : 'hover:text-blue-500'}`}
                     data-cy={'admin-update-button'}
                     disabled={formstate !== 'fetched'}
                     onClick={submit_update}>
                   Update
                 </button>
              </div>
            </div>
            <div>
              <div className="flex flex-row text-gray-text items-center
                              justify-center w-24 h-8 bg-gray-300
                              font-normal">
                <button className="hover:text-blue-500 text-center"
                     data-cy={'admin-clear-button'}
                     onClick={submit_clear}>
                   Clear form
                 </button>
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              active:
            </div>
            <RadioButtonSw ref={refs.active}
                           cy_prefix="admin_active"
                           cols={2}
                           dflt={dflts.active}
                           choices={['active', 'inactive']} />
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              id:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.id}
                      label=""
                      type="text"
                      disabled={formstate === 'fetched' ? 1 : 0}
                      dflt={dflts.id}
                      cy_name="admin_id" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              name:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.name}
                      label=""
                      type="text"
                      disabled={formstate === 'fetched' ? 1 : 0}
                      dflt={dflts.name}
                      cy_name="admin_name" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              type:
            </div>
            <RadioButtonSw ref={refs.type}
                           cy_prefix="admin_type"
                           dflt={dflts.type}
                           choices={['text', 'image', 'video']} />
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              obscene:
            </div>
            <RadioButtonSw ref={refs.isObscene}
                           cy_prefix="admin_obscene"
                           dflt={dflts.type}
                           choices={['yes', 'no']} />
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              category:
            </div>
            <RadioButtonSw ref={refs.cat}
                           cy_prefix="admin_cat"
                           dflt={dflts.cat}
                           choices={['core', 'non-core']} />
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              text:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextareaSw ref={refs.text}
                          margin="mr-4"
                          dflt={dflts.text}
                          rows={10}
                          cols={100}
                          maxchars={10000}
                          prompt="Limit of 10000 characters"
                          cy_name="admin_text" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              url:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.url}
                      label=""
                      type="text"
                      dflt={dflts.url}
                      cy_name="admin_url" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              date added:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.date_added}
                      label=""
                      type="text"
                      dflt={dflts.date_added}
                      cy_name="admin_date_added" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              source:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.source}
                      label=""
                      type="text"
                      dflt={dflts.source}
                      cy_name="admin_source" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              copyright holder:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextSw ref={refs.copyright_holder}
                      label=""
                      type="text"
                      dflt={dflts.copyright_holder}
                      cy_name="admin_copyright" />
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-normal text-lg">
              comments:
            </div>
            <div className="border-orange border-2 rounded-lg">
              <TextareaSw ref={refs.comments}
                          margin="mr-4"
                          dflt={dflts.comments}
                          rows={10}
                          cols={100}
                          maxchars={10000}
                          prompt="Limit of 10000 characters"
                          cy_name="admin_comments" />
            </div>
          </div>
        </div>
        <div className="font-bold text-xl pt-4" id="usermgmt">
          User mgmt
        </div>
        <div className="flex flex-col y-space-6">
          <div className="flex flex-row space-x-2 items-center mb-4">
            <div className="flex flex-row text-gray-text items-center
                            justify-center w-24 h-8 bg-gray-300
                            font-normal">
              <button className="hover:text-blue-500 text-center"
                   data-cy={`admin-anon-btn`}
                   onClick={fetch_anon_users}>
                 Anon users
              </button>
            </div>
            <div className="text-sm font-normal">
              (Users with PrivateProfile without email)
            </div>
          </div>
          <div className="flex flex-row text-gray-text items-center
                          w-auto h-8 bg-gray-300 mb-4">
            <div className="font-normal text-lg w-32 justify-left">Date</div>
            <div className="font-normal text-lg w-12 justify-left">Id</div>
            <div className="font-normal text-lg w-80 justify-left">UID</div>
            <div className="font-normal text-lg w-24 justify-left">Action</div>
          </div>
          { anon_users.map(u =>
              <div className="flex flex-row font-normal text-lg" key={u.uid}>
                <div className="w-32">
                  { u.signup_date
                    ? new Date(u.signup_date).toLocaleDateString()
                    : '' }
                </div>
                <div className="w-12">{u.id}</div>
                <div className={`w-80 ${u.signup_date ? '' : 'text-red-500'}`}>
                  {u.uid}
                </div>
                <div className={`w-24 hover:text-blue-500
                                 ${u.signup_date === '' ? 'hidden' : ''}`}>
                  <button onClick={remove_user(u)}>
                    Remove
                  </button>
                </div>
              </div>
            )}
        </div>
        <div className="font-bold text-xl pt-4" id="contacts">
          Contacts/Tech support
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row space-x-4 w-full">
            <div className="font-normal text-lg w-1/6">
              range: past
            </div>
            <div className="mt-2 w-1/2">
              <RadioButtonGridSw the_ref={contact_refs.range}
                             cols={3}
                             cy_prefix="admin_contact_range"
                             dflt={contact_dflts.range}
                             choices={['day', 'week', 'month']} />
            </div>
          </div>
          <div className="flex flex-row space-x-4 w-full">
            <div className="font-normal text-lg w-1/6">
              status:
            </div>
            <div className="mt-2 w-2/3">
              <RadioButtonGridSw the_ref={contact_refs.status}
                             cols={4}
                             cy_prefix="admin_contact_status"
                             dflt={contact_dflts.status}
                             choices={['new', 'inprocess', 'unresolved', 'all']} />
            </div>
          </div>
          <div className="flex flex-row space-x-4 w-full">
            <div className="font-normal text-lg w-1/6">
              type:
            </div>
            <div className="mt-2 w-1/2">
              <RadioButtonGridSw the_ref={contact_refs.type}
                             cols={3}
                             cy_prefix="admin_contact_type"
                             dflt={contact_dflts.type}
                             choices={['contact', 'techsupport', 'all']} />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 w-full">
            <div className="font-normal text-lg">
              email, username, or id:
            </div>
              <TextSw ref={contact_refs.user}
                      label=""
                      type="text"
                      margin="mx-4"
                      cy_name="admin_contact_user" />
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex flex-row text-gray-text items-center
                            justify-center w-24 h-8 bg-gray-300
                            font-normal">
              <button className="hover:text-blue-500 text-center"
                   data-cy={'admin-contacts-button'}
                   onClick={contacts_fetch}>
               Fetch
              </button>
            </div>
            <div className="flex flex-row text-gray-text items-center
                            justify-center w-24 h-8 bg-gray-300
                            font-normal">
              <button className="hover:text-blue-500 text-center"
                   data-cy={'admin-collapseall-button'}
                   onClick={() => setExpanded({})}>
               Collapse all
              </button>
            </div>
          </div>
          <hr className="border-blue-500" />
          <div className="italic">
            Edit/submit one contact at a time
          </div>
          <hr className="border-blue-500" />
          <div>
            <div data-cy="admin-messages-list"
                 className="flex flex-col space-y-2 mt-4">
              {
                Object.values(messages)
                      .sort(bydate_desc)
                      .map(m => {
                        const k = m.msgid;
                        return expanded[k]
                               ? <div key={k}>
                                   <hr className="border-blue-500 mb-1" />
                                   <FullContact msg={m} />
                                   <hr className="border-blue-500 mt-1" />
                                 </div>
                               : <SummaryContact msg={m} key={k} />;
                       })
              }
            </div>
          </div>
        </div>

      </div>
      <PopupNote notmsg={removePopup}
           onclose={() => setRemovePopup('')}
           nottype="info" />
    </React.Fragment>
  );
}
const Admin = RegPage(BaseAdmin);

export { Admin };
