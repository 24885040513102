import React, { useEffect } from 'react';

import { BellIcon } from '../../assets/icons/notify';
import { CYPRESS_MODE } from '../../config';

const PopupNote = ({ nottype, notmsg, onclose }) => {

  useEffect(() => {
      if (notmsg) {
        const timeMsg = setTimeout(onclose, CYPRESS_MODE ? 10 : 4000);
        return () => {
            clearTimeout(timeMsg);
          }
      }
    }, [ notmsg, onclose ]);

  return (
    <div className={`fixed z-10
                     ${ notmsg ? '' : 'hidden' }
                     ${ nottype === 'error'
                        ? "bottom-0 left-1/2 transform -translate-x-1/2"
                        : "inset-y-4 right-8" }`}
         data-cy="popupnote">
      <div className="flex justify-start">
        <div className="flex flex-row my-8 mx-4 rounded-lg bg-orange
                        space-x-3"
             style={{ background:
                          `transparent
                           linear-gradient(147deg, #FFBB23 0%, #FF4F23 100%)
                           0% 0% no-repeat padding-box` }} >
          <div className="flex flex-row my-3 mx-3 space-x-2">
            <BellIcon />
            <div className="text-sm font-normal text-white">
              {notmsg}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PopupNote };
