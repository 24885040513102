import { navigate } from '@reach/router';

// change page, don't push on stack if just refreshing
function changePage(p, arg1) {
  let url = `/${p}` + (arg1 ?  `/${arg1}` : '');
  navigate(url, { replace: url === window.location.pathname });
}

// TODO: delete these if not used
// args are sets, returns a set
function intersection(set1, set2) { // eslint-disable-line no-unused-vars
  return new Set([...set1].filter(k => set2.has(k)));
}
// args are sets, returns a list
function setdiff(set1, set2) { // eslint-disable-line no-unused-vars
  return [...set1].filter(k => ! set2.has(k));
}
// args are sets, returns a list
function union(set1, set2) { // eslint-disable-line no-unused-vars
  return [...new Set([...set1, ...set2])];
}

// birthday given in US format
function calculate_age (birthday) { // eslint-disable-line no-unused-vars
  let bday = new Date(birthday);
  let now = new Date();

  var years = (now.getFullYear() - bday.getFullYear());

  if ((now.getMonth() < bday.getMonth()) ||
      (now.getMonth() === bday.getMonth() && now.getDate() < bday.getDate())) {
      years--;
  }
  return years;
}

// birth date of someone 'age' years old whose birthday is today
// if someone's birth date is this or earlier, they are 'age' or older
// return date in ISO format
function latest_bday(age) {
  const now = new Date();
  const y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
  const yy = y - age;
  return new Date(`${m}/${d}/${yy}`).toISOString();
}

// birth date of someone 'age' years old whose birthday is tomorrow
// if someone's birth date is this date or later, they are 'age' or younger
// return date in ISO format
function earliest_bday(age) {
  const now = new Date();
  const y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
  const yy = y - age;
  return new Date(`${m}/${d + 1}/${yy - 1}`).toISOString();
}

function toUS (date) {
  return new Date(date).toLocaleDateString();
}
function toISO (date) {
  return new Date(date).toISOString();
}

// inches an integer
function inches2ft(inches) {
  return [Math.floor(inches / 12), inches % 12];
}
function ft2inches(f, i) {
  return f * 12 + i;
}

const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  );

const zip = (a, b) => {
  let zipped = [];
  for (var i = 0; i < a.length; ++i) {
    zipped.push([a[i], b[i]]);
  }
  return zipped;
}

// equality for arrays of primitives
function equalPrimArray(a, b) {
  return a.length === b.length
         && zip(a, b).every(([x, y]) => x === y);
}

const obj2fun = obj => k => obj[k];
const idfun = x => x;

// return { f : obj[f] } where f in fields and obj[f] defined
const get_subobj = (obj, fields) => {
  let newobj = {};
  fields.forEach(f => {
    if (obj.hasOwnProperty(f)) {
      newobj[f] = obj[f];
    }
  });
  return newobj;
}

function split(a, pred) {
  return [ a.filter(pred),
           a.filter(x => ! pred(x)) ];
}

const isDigit = c => '0' <= c && c <= '9';
const isAlpha = c => ('a' <= c && c <= 'z') || ('A' <= c && c <= 'Z');
const isAlphanumeric = // eslint-disable-line no-unused-vars
        c => isDigit(c) || isAlpha(c) || c === '_';
const isWSChar = s => s === ' ' || s ==='\t';
const isBlank = s => [...s].every(c => c === ' ' || c === '\t');
const isNumber = s => /^\d+$/.test(s);
const num2text = i => (
  {0: 'zero',
   1: 'one',
   2: 'two',
   3: 'three',
   4: 'four',
   5: 'five',
   6: 'six',
   7: 'seven',
   8: 'eight',
   9: 'nine',
   10: 'ten',
   11: 'eleven',
   12: 'twelve',
   13: 'thirteen',
   14: 'fourteen',
   15: 'fifteen',
   16: 'sixteen',
   17: 'seventeen',
   18: 'eighteen',
   19: 'nineteen',
   20: 'twenty',
 }[i]
);

function num_to_words(n) {
  const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy",
                "eighty", "ninety"];
  return (n === 18 ? "eighteen"
          : n === 19 ? "nineteen"
          : n > 99 ? n.toString()
          : n % 10 === 0 ? tens[n / 10]
          : `${tens[Math.floor(n / 10)]}-${ones[n % 10]}`);
}

// number of rows occupied by a string if the field has width w
// key is considering spaces - line break is made on a space if possible
function text_rows(w, s) {
  let lines = 1,
      start = 0;
  while (true) {
    let eol = start + w;
    let firstnl = s.indexOf('\n', start);
    if (firstnl !== -1 && firstnl < eol) {
      lines++;
      start = firstnl + 1;
      continue;
    }
    if (s.length < eol) {
      return lines;
    }
    lines++;
    let lastspace = s.lastIndexOf(' ', eol);
    start = lastspace === -1 ? eol : lastspace;
  }
}

// invert a 1-to-1 object: swap({k1:v1, k2:v2, ..}) = {v1:k1, v2:k2}
let invert = (o, r={}) => Object.keys(o).map(x => r[o[x]]=x) && r; // eslint-disable-line no-unused-vars

// turn array of objects into object, getting keys from a field of
// the array values, e.g.: if a = [{id: 1, ...}, {id: 2, ..}, ...],
// objectify(a, 'id') = {1 : {id: 1, ...}, 2: {id: 2, ..}, ...}
function objectify (a, fld) { // eslint-disable-line no-unused-vars
  let o = {};
  for (var r of a) o[r[fld]] = r;
  return o;
}
// similar, but for case where value of fld can be represented more than
// once. objects in a are placed in an array
function objectify_array(a, fld) { // eslint-disable-line no-unused-vars
  let o = {};
  for (var r of a) {
    if (o[r[fld]]) {
      o[r[fld]].push(r);
    } else {
      o[r[fld]] = [r];
    }
  }
  return o;
}

// non-destructively remove property a from object o (if it is there)
function rm_attr(a, o) { // eslint-disable-line no-unused-vars
  let m = Object.entries(o).filter(e => e[0] !== String(a));
  return Object.fromEntries(m);
  // For the record, the following one line should work, but in the app,
  // it doesn't.  Rather, 'key' matches a value, and then 'rest'
  // includes that part of the object as well.  Crazy.
  // I haven't been able to reproduce this behavior in a separate example
  //const rm_attr = (key, {[key]: _, ...rest}) => rest;
}

function isEmptyObj(obj) {
  for (var prop in obj) {
    // extra check to exclude inherited props
    if(obj.hasOwnProperty(prop))
        return false;
  }
  return true;
}

function size(obj){
  return Object.keys(obj).length;
}

// good idea to use this only for small objects
function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// object o1 = { k1: ..., k2: ... }, o2 = { k2: ---, k3: --- },
// produce merged object { k1: ..., k2: ...---, k3: ---}
function merge(...os) {
  const merge2 = (o1, o2) => {
    const keys = Object.keys({...o1, ...o2})
    const entries = keys.map(k => [k, { ...o1[k], ...o2[k] }]);
    return Object.fromEntries(entries);
  }
   return os.reduce(merge2);
 }

 // L a list of n lists. Create list contain all lists of length n created
 // by taking one element from each list in L. (Exception: ignore empty lists)
 function all_combs(L) {
   // one-level flatten
   function flatten(N) {
     if (N.length === 1) {
       return N[0];
     }
     else {
       return [ ...N[0], ...flatten(N.slice(1)) ];
     }
   }

   // same as all_combs, but M contains no empty lists
   function aux(M) {
     if (M.length === 1) {
       return M[0].map(x => [x]);
     } else {
       const q = aux(M.slice(1));
       return flatten(M[0].map(e => q.map(r => [e, ...r])));
     }
   }
   return aux(L.filter(l => l.length > 0));
 }

// fields in o exist and have same values in p, recursively
// o primitive => p is same value
// o object => every attribute i in o => o[i] sub_included in p[i]
// o array => <this gets complicated>
//   1. if o[0] is primitive, it is assumed that o and p are
//      arrays of primitives; every element of o must appear in p
//   2. ow, both are assumed to be arrays of objects. the primitive
//      values within those objects are assumed to form a key,
//      i.e. uniquely identify an element.
//      for every obj i in o, there must be an obj j in p that
//      matches o's key, and we must have i sub_included in j
function sub_include(o, p) { // eslint-disable-line no-unused-vars
  const primvals = obj => {
    let it = Object.entries(obj).filter(v => typeof(v[1]) != 'object');
    return Object.fromEntries(it);
  }
  let t = Array.isArray(o) ? 'array' : typeof(o);
  let u = Array.isArray(p) ? 'array' : typeof(p);
  if (t !== u)
    return false;
  switch (t) {
  case 'object': {
      return Object.keys(o)
               .every(k => sub_include(o[k], p[k]));
    }
  case 'array': {
      if (o.length === 0 && p.length === 0)
        return true;
      if (typeof(o[0]) != 'object') {
        return o.every(v => p.indexOf(v) >= 0);
      }
      for (var x of o) {
        let q = primvals(x);
        let found = false;
        for (var y of p) {
          if (sub_include(q, y)) {
            found = true;
            let m = sub_include(x, y);
            if (!m) return false;
              else break;
          }
        }
        return found; /* either found and not sub_include, or not found */
      }
      return true;
    }
  default: {
      return o === p;
    }
  }
}

// Warning: this misses one case: arrays of primitives are considered
//          equal only as sets; order and repetitions don't matter
const obj_equal = (o, p) => sub_include(o, p) && sub_include(p, o);

// TODO: these functions are defined in clsx.min.js, but I cannot
//       see how to use them from that file. after switching to
//       regular npm/import thing, should use that file and
//       remove these definitions
function toVal(mix) { // eslint-disable-line no-unused-vars
        var k, y, str='';
        if (mix) {
                if (typeof mix === 'object') {
                        if (Array.isArray(mix)) {
                                for (k=0; k < mix.length; k++) {
                                        if (mix[k] && (y = toVal(mix[k]))) {
                                                str && (str += ' ');
                                                str += y;
                                        }
                                }
                        } else {
                                for (k in mix) {
                                        if (mix[k] && (y = toVal(k))) {
                                                str && (str += ' ');
                                                str += y;
                                        }
                                }
                        }
                } else if (typeof mix !== 'boolean' && !mix.call) {
                        str && (str += ' ');
                        str += mix;
                }
        }
        return str;
}

/*
// this function combines react class names, but I'm not using it.
// if I do need it, I believe this is in a package somewhere that
// I should import instead of defining this
function clsx () {
        var i=0, x, str='';
        while (i < arguments.length) {
                if (x = toVal(arguments[i++])) {
                        str && (str += ' ');
                        str += x
                }
        }
        return str;
}
*/

function consolelog(...args) {
  const arg2str = a => {
    let s;
    try {
      s = JSON.parse(JSON.stringify(a));
    }
    catch (e) {
      s = a + "";
    }
    return s;
  }
  console.log(...args.map(arg2str))
}

function GoodPromise(v) {
  return new Promise((resolve, reject) => resolve(v));
}
function BadPromise(err) {
  return new Promise((resolve, reject) => reject(err));
}
function promiseall(promises) {
  return Promise.all(promises.filter(p => Boolean(p)));
}

// refresh, but possibly add '/x' at end if it isn't there
// this is for displaying thread: if not currently displayed,
// add id or '1', depending
// x is optional
function refresh(x) {
  const href = window.location.href;
  if (! x || href.endsWith(x)) {
    // in this case we're just refreshing the current page (to rerender),
    // so don't push it on history
    navigate(window.location.href, { replace: true });
  } else {
    // in this case, we're going to a new url
    navigate(window.location.href + '/' + x);
  }
}

export {
  changePage,
  intersection, setdiff, union, calculate_age,
  latest_bday, earliest_bday,
  isDigit, isAlpha, isAlphanumeric, isBlank, num2text, num_to_words,
  isNumber, isWSChar, text_rows,
  inches2ft, ft2inches,
  objectMap, zip, equalPrimArray, obj2fun, idfun, get_subobj,
  invert, objectify, objectify_array, rm_attr, split,
  sub_include, obj_equal, isEmptyObj, size, clone, merge, all_combs,
  toVal, consolelog,
  GoodPromise, BadPromise, promiseall,
  refresh,
  toUS, toISO,
};
