const nyzip2borough = {
  10001: 'Manhattan',
  10002: 'Manhattan',
  10003: 'Manhattan',
  10004: 'Manhattan',
  10005: 'Manhattan',
  10006: 'Manhattan',
  10007: 'Manhattan',
  10008: 'Manhattan',
  10009: 'Manhattan',
  10010: 'Manhattan',
  10011: 'Manhattan',
  10012: 'Manhattan',
  10013: 'Manhattan',
  10014: 'Manhattan',
  10015: 'Manhattan',
  10016: 'Manhattan',
  10017: 'Manhattan',
  10018: 'Manhattan',
  10019: 'Manhattan',
  10020: 'Manhattan',
  10021: 'Manhattan',
  10022: 'Manhattan',
  10023: 'Manhattan',
  10024: 'Manhattan',
  10025: 'Manhattan',
  10026: 'Manhattan',
  10027: 'Manhattan',
  10028: 'Manhattan',
  10029: 'Manhattan',
  10030: 'Manhattan',
  10031: 'Manhattan',
  10032: 'Manhattan',
  10033: 'Manhattan',
  10034: 'Manhattan',
  10035: 'Manhattan',
  10036: 'Manhattan',
  10037: 'Manhattan',
  10038: 'Manhattan',
  10039: 'Manhattan',
  10040: 'Manhattan',
  10041: 'Manhattan',
  10043: 'Manhattan',
  10044: 'Manhattan',
  10045: 'Manhattan',
  10046: 'Manhattan',
  10047: 'Manhattan',
  10048: 'Manhattan',
  10055: 'Manhattan',
  10060: 'Manhattan',
  10069: 'Manhattan',
  10072: 'Manhattan',
  10079: 'Manhattan',
  10080: 'Manhattan',
  10081: 'Manhattan',
  10082: 'Manhattan',
  10087: 'Manhattan',
  10090: 'Manhattan',
  10094: 'Manhattan',
  10095: 'Manhattan',
  10096: 'Manhattan',
  10098: 'Manhattan',
  10099: 'Manhattan',
  10101: 'Manhattan',
  10102: 'Manhattan',
  10103: 'Manhattan',
  10104: 'Manhattan',
  10105: 'Manhattan',
  10106: 'Manhattan',
  10107: 'Manhattan',
  10108: 'Manhattan',
  10109: 'Manhattan',
  10110: 'Manhattan',
  10111: 'Manhattan',
  10112: 'Manhattan',
  10113: 'Manhattan',
  10114: 'Manhattan',
  10115: 'Manhattan',
  10116: 'Manhattan',
  10117: 'Manhattan',
  10118: 'Manhattan',
  10119: 'Manhattan',
  10120: 'Manhattan',
  10121: 'Manhattan',
  10122: 'Manhattan',
  10123: 'Manhattan',
  10124: 'Manhattan',
  10125: 'Manhattan',
  10126: 'Manhattan',
  10128: 'Manhattan',
  10129: 'Manhattan',
  10130: 'Manhattan',
  10131: 'Manhattan',
  10132: 'Manhattan',
  10133: 'Manhattan',
  10138: 'Manhattan',
  10149: 'Manhattan',
  10150: 'Manhattan',
  10151: 'Manhattan',
  10152: 'Manhattan',
  10153: 'Manhattan',
  10154: 'Manhattan',
  10155: 'Manhattan',
  10156: 'Manhattan',
  10157: 'Manhattan',
  10158: 'Manhattan',
  10159: 'Manhattan',
  10160: 'Manhattan',
  10161: 'Manhattan',
  10162: 'Manhattan',
  10163: 'Manhattan',
  10164: 'Manhattan',
  10165: 'Manhattan',
  10166: 'Manhattan',
  10167: 'Manhattan',
  10168: 'Manhattan',
  10169: 'Manhattan',
  10170: 'Manhattan',
  10171: 'Manhattan',
  10172: 'Manhattan',
  10173: 'Manhattan',
  10174: 'Manhattan',
  10175: 'Manhattan',
  10176: 'Manhattan',
  10177: 'Manhattan',
  10178: 'Manhattan',
  10179: 'Manhattan',
  10184: 'Manhattan',
  10185: 'Manhattan',
  10196: 'Manhattan',
  10197: 'Manhattan',
  10199: 'Manhattan',
  10203: 'Manhattan',
  10211: 'Manhattan',
  10212: 'Manhattan',
  10213: 'Manhattan',
  10242: 'Manhattan',
  10249: 'Manhattan',
  10256: 'Manhattan',
  10257: 'Manhattan',
  10258: 'Manhattan',
  10259: 'Manhattan',
  10260: 'Manhattan',
  10261: 'Manhattan',
  10265: 'Manhattan',
  10268: 'Manhattan',
  10269: 'Manhattan',
  10270: 'Manhattan',
  10271: 'Manhattan',
  10272: 'Manhattan',
  10273: 'Manhattan',
  10274: 'Manhattan',
  10275: 'Manhattan',
  10276: 'Manhattan',
  10277: 'Manhattan',
  10278: 'Manhattan',
  10279: 'Manhattan',
  10280: 'Manhattan',
  10281: 'Manhattan',
  10282: 'Manhattan',
  10285: 'Manhattan',
  10286: 'Manhattan',
  10292: 'Manhattan',
  10301: 'Staten Island',
  10302: 'Staten Island',
  10303: 'Staten Island',
  10304: 'Staten Island',
  10305: 'Staten Island',
  10306: 'Staten Island',
  10307: 'Staten Island',
  10308: 'Staten Island',
  10309: 'Staten Island',
  10310: 'Staten Island',
  10311: 'Staten Island',
  10312: 'Staten Island',
  10313: 'Staten Island',
  10314: 'Staten Island',
  10451: 'Bronx',
  10452: 'Bronx',
  10453: 'Bronx',
  10454: 'Bronx',
  10455: 'Bronx',
  10456: 'Bronx',
  10457: 'Bronx',
  10458: 'Bronx',
  10459: 'Bronx',
  10460: 'Bronx',
  10461: 'Bronx',
  10462: 'Bronx',
  10463: 'Bronx',
  10464: 'Bronx',
  10465: 'Bronx',
  10466: 'Bronx',
  10467: 'Bronx',
  10468: 'Bronx',
  10469: 'Bronx',
  10470: 'Bronx',
  10471: 'Bronx',
  10472: 'Bronx',
  10473: 'Bronx',
  10474: 'Bronx',
  10475: 'Bronx',
  10499: 'Bronx',
  11004: 'Queens',
  11005: 'Queens',
  11101: 'Queens',
  11102: 'Queens',
  11103: 'Queens',
  11104: 'Queens',
  11105: 'Queens',
  11106: 'Queens',
  11109: 'Queens',
  11120: 'Queens',
  11201: 'Brooklyn',
  11202: 'Brooklyn',
  11203: 'Brooklyn',
  11204: 'Brooklyn',
  11205: 'Brooklyn',
  11206: 'Brooklyn',
  11207: 'Brooklyn',
  11208: 'Brooklyn',
  11209: 'Brooklyn',
  11210: 'Brooklyn',
  11211: 'Brooklyn',
  11212: 'Brooklyn',
  11213: 'Brooklyn',
  11214: 'Brooklyn',
  11215: 'Brooklyn',
  11216: 'Brooklyn',
  11217: 'Brooklyn',
  11218: 'Brooklyn',
  11219: 'Brooklyn',
  11220: 'Brooklyn',
  11221: 'Brooklyn',
  11222: 'Brooklyn',
  11223: 'Brooklyn',
  11224: 'Brooklyn',
  11225: 'Brooklyn',
  11226: 'Brooklyn',
  11228: 'Brooklyn',
  11229: 'Brooklyn',
  11230: 'Brooklyn',
  11231: 'Brooklyn',
  11232: 'Brooklyn',
  11233: 'Brooklyn',
  11234: 'Brooklyn',
  11235: 'Brooklyn',
  11236: 'Brooklyn',
  11237: 'Brooklyn',
  11238: 'Brooklyn',
  11239: 'Brooklyn',
  11240: 'Brooklyn',
  11241: 'Brooklyn',
  11242: 'Brooklyn',
  11243: 'Brooklyn',
  11244: 'Brooklyn',
  11245: 'Brooklyn',
  11247: 'Brooklyn',
  11248: 'Brooklyn',
  11249: 'Brooklyn',
  11251: 'Brooklyn',
  11252: 'Brooklyn',
  11254: 'Brooklyn',
  11255: 'Brooklyn',
  11256: 'Brooklyn',
  11351: 'Queens',
  11352: 'Queens',
  11354: 'Queens',
  11355: 'Queens',
  11356: 'Queens',
  11357: 'Queens',
  11358: 'Queens',
  11359: 'Queens',
  11360: 'Queens',
  11361: 'Queens',
  11362: 'Queens',
  11363: 'Queens',
  11364: 'Queens',
  11365: 'Queens',
  11366: 'Queens',
  11367: 'Queens',
  11368: 'Queens',
  11369: 'Queens',
  11370: 'Queens',
  11371: 'Queens',
  11372: 'Queens',
  11373: 'Queens',
  11374: 'Queens',
  11375: 'Queens',
  11377: 'Queens',
  11378: 'Queens',
  11379: 'Queens',
  11380: 'Queens',
  11381: 'Queens',
  11385: 'Queens',
  11386: 'Queens',
  11390: 'Queens',
  11405: 'Queens',
  11411: 'Queens',
  11412: 'Queens',
  11413: 'Queens',
  11414: 'Queens',
  11415: 'Queens',
  11416: 'Queens',
  11417: 'Queens',
  11418: 'Queens',
  11419: 'Queens',
  11420: 'Queens',
  11421: 'Queens',
  11422: 'Queens',
  11423: 'Queens',
  11424: 'Queens',
  11425: 'Queens',
  11426: 'Queens',
  11427: 'Queens',
  11428: 'Queens',
  11429: 'Queens',
  11430: 'Queens',
  11431: 'Queens',
  11432: 'Queens',
  11433: 'Queens',
  11434: 'Queens',
  11435: 'Queens',
  11436: 'Queens',
  11439: 'Queens',
  11451: 'Queens',
  11499: 'Queens',
  11690: 'Queens',
  11691: 'Queens',
  11692: 'Queens',
  11693: 'Queens',
  11694: 'Queens',
  11695: 'Queens',
  11697: 'Queens',
}

const nyzips = Object.keys(nyzip2borough).sort();
// const current_zipcodes = new Set(Object.keys(nyzip2borough));
const current_zipcodes = new Set([
  '72007'
  // Appleton, WI
  // '54911', '54913', '54914', '54915', '54136', '54113', '54140',
  // '54130', '54942', '54956', '54952', '54169', '54944', '54129'
  // Portland, ME
  // '04002', '04003', '04005', '04006', '04011', '04015', '04017', '04019', '04020',
  // '04021', '04024', '04029', '04030', '04032', '04038', '04039',
  // '04041', '04042', '04043', '04046', '04047', '04048', '04049', '04050', '04055',
  // '04061', '04062', '04063', '04064', '04066', '04069', '04071',
  // '04072', '04073', '04074', '04076', '04079', '04083', '04084', '04085',
  // '04086', '04087', '04090', '04091', '04092', '04093', '04096', '04097',
  // '04101', '04102', '04103', '04105', '04106', '04107', '04108', '04019', '04110',
  // '04210', '04222', '04240', '04250', '04252', '04260',
  // '04530', '14562'
]);

export { nyzip2borough, nyzips, current_zipcodes };
