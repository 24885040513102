import React, { useRef } from 'react';

import { RadioButtonSw } from '../widgets/RadioButton';
import { TextareaSw } from '../widgets/TextWidget';
import { config$text_limits } from '../../config';
import { CancelIcon } from '../../assets/icons/cancel';

const ReportJokeOverlay = ({ actions }) => {
  const obscene_ref = useRef(),
        report_ref = useRef();
  return (
    <div className="flex pt-16 content-center justify-center">
      <div className="relative flex flex-col bg-white rounded-lg space-y-6
                      sm:w-11/12"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <button className="absolute top-0 right-0 mt-4 mr-4"
             onClick={() => actions.show_report(false)}>
          <CancelIcon />
        </button>
        <div className="mx-12 text-2xl font-bold text-gray-text
                        text-center mt-6">
          Report Joke
        </div>
        <div className="mx-6 text-sm font-normal text-gray-text mt-6
                        text-center">
          If you find this joke objectionable, please let us know why:
        </div>
        <div className="flex flex-col justify-start mx-6 space-y-4">
          <RadioButtonSw the_ref={obscene_ref}
                         cy_prefix="report-joke"
                         choices={['Obscene', 'Racist', 'Sexist', 'Other']} />
        </div>
        <div>
          <TextareaSw the_ref={report_ref}
                      margin="mx-4"
                      label={"If you'd like to say more, go ahead:"}
                      rows={3}
                      maxchars={config$text_limits.report_joke}
                      prompt={`Limit of ${config$text_limits.report_joke} characters`}
                      cy_name="vote_report" />
        </div>
        <div>
          <div className="flex flex-col items-center mr-4">
            <div className="w-10">
            </div>
            <button data-cy="report-submit"
                    onClick={e => {
                                actions.report(obscene_ref, report_ref)(e);
                                actions.show_report(false);
                              }}>
              <div className="flex flex-col bg-orange rounded-lg mt-4 w-28
                              h-10 content-center">
                <div className="font-semibold text-white text-sm my-auto">
                  Report
                </div>
              </div>
            </button>
            <button className="flex flex-col justify-center text-sm font-bold text-gray-400
                               mt-2 hover:underline"
                 data-cy="report-cancel"
                 onClick={() => actions.show_report(false)}>
              Cancel
            </button>
          </div>
        </div>
        <div className="h-10">
        </div>
      </div>
    </div>
  );
}

export { ReportJokeOverlay };
