import React, { useState } from 'react';
import { Redirect } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';

import { OtherUserUI } from '../ui/OtherUserUI';
import { NotFound } from './StaticPages';

import { changePage, refresh } from '../../utils';
import { blacklist, poke, fetch_other, send_message } from './utils';
import { signed_in } from '../../App';

// OtherUser - invoked from various places
const OtherUser = ({ oid, ...props }) => {

  // overlay is user-report dialog
  const [ showOverlay, setShowOverlay ] = useState(null);

  const [dis, str] = [useDispatch(), useStore()];


  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  const oprofile = fetch_other(oid, str.getState().others);

  if (! oprofile) {
    return <NotFound />;
  }

  const buttons = {
       blacklist: () => { blacklist(dis, str, oprofile);
                          changePage('matches'); },
       poke: () => { poke(dis, str, oprofile);
                     refresh(); },
       sendmsg: (p, r) => send_message(dis, str, p, r),
    };

  return (
    <React.Fragment>
      <OtherUserUI oprofile={oprofile}
                   buttons={buttons}
                   showOverlay={showOverlay}
                   setShowOverlay={setShowOverlay}>
      </OtherUserUI>
    </React.Fragment>
  );
}

export { OtherUser };
