import React from 'react';

// used to indicate drop-down menu in edit pages
const DownArrowIcon = () =>
  <svg version="1.1" id="Layer_1"
       xmlns="http://www.w3.org/2000/svg"
       x="0px" y="0px"
       viewBox="0 0 386.257 386.257"
       style={{ enableBackground: "new 0 0 386.257 386.257",
                height: "0.5rem", width: "0.5rem" }}>
    <title>Drop-down menu</title>
    <polygon points="0,96.879 193.129,289.379 386.257,96.879 "/>
  </svg>;

export { DownArrowIcon };
