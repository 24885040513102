import React from 'react';
import { RegPage } from './RegPage';
import { config$test_url } from '../../config';

import { LeftChevronIcon, RightChevronIcon } from '../../assets/icons/chevrons';
import { ReportUserOverlay } from './ReportUserOverlay';
import { FirstMessageOverlay } from './FirstMessageOverlay';
import { MessageAckOverlay } from './MessageAckOverlay';
import { UserCard } from './UserCard';

const LeftButton = () =>
  <button className="flex w-10 h-8 bg-white rounded-lg
                  items-center justify-center">
      <LeftChevronIcon />
  </button>;

const RightButton = () =>
  <button className="flex w-10 h-8 bg-white rounded-lg
                  items-center justify-center">
    <RightChevronIcon />
  </button>;

const ConnectsOverlay = props => {
  if (! props.showOverlay) {
    return () => <span />;
  }
  switch(props.showOverlay[1]) {
    case 'report':  return <ReportUserOverlay { ...props } />;
    case 'sendmsg': return <FirstMessageOverlay { ...props } />;
    case 'msgack':  return <MessageAckOverlay { ...props } />;
    default: return () => <span />;
  }
}

// pokes, pokedbys, copokes: lists of profiles (pokes not displayed)
// buttons: poke, blacklist, sendmsg, see_prof
// first_copoke, etc - first card in horizontal scroll, counting from 1
// showOverlay, etc - null or [ oid, overlaytype ] (see ConnectsOverlay above)
const BaseConnectionsUI = ({pokes, pokedbys, copokes,
                            buttons,
                            first_copoke, first_pokedby,
                            scroll_copoke, scroll_pokedby,
                            showOverlay, setShowOverlay }) => {

  const copokes_shown = copokes.length === 0 ? copokes
                                             : copokes.slice(first_copoke - 1);
  const pokedbys_shown = pokedbys.length === 0 ? pokedbys
                                             : pokedbys.slice(first_pokedby - 1);
  // check if arrows enabled, e.g. l_enabled(first_copoke, copokes)
  const l_enabled = (i, lis) => lis.length > 0 && i > 1;
  const r_enabled = (i, lis) => lis.length > 0 && i !== lis.length;
  return (
    <div className="flex flex-col mx-auto w-full">
      <div className="hidden sm:block">
          <img style={{ height: "10rem" }}
             alt="small mock ad"
             data-cy="joke-ad-sm"
             src={`${config$test_url}/images/app_ad.png`}
             className="mx-auto rounded-lg col-span-1"/>
      </div>
      <div className="w-4/5 sm:hidden">
        <div className="flex text-3xl font-semibold text-gray-text my-4">
          Connections
        </div>
      </div>
      <div className="grid grid-cols-5 mt-4">
        <div className="col-span-4 space-y-4 sm:col-span-5">
          <div className="flex flex-row justify-between">
            <div className="flex text-xl font-semibold text-gray-text">
              You like each other
            </div>
            <div className="flex flex-row space-x-4 mr-6 touch:hidden">
              <div onClick={scroll_copoke(-1)}
                   className={ l_enabled(first_copoke, copokes) ? '' : 'opacity-40' }
                   data-cy="scroll-copoke-l">
                <LeftButton />
              </div>
              <div onClick={scroll_copoke(1)}
                   className={ r_enabled(first_copoke, copokes) ? '' : 'opacity-40' }
                   data-cy="scroll-copoke-r">
                <RightButton />
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4 space-x-4
                          overflow-hidden touch:overflow-x-auto">
            { copokes_shown.map((p, i) =>
                    <div key={i}>
                      <UserCard oprofile={p}
                                buttons={buttons}
                                overlay='report'
                                setShowOverlay={setShowOverlay} />
                    </div>)
            }
          </div>
          <div className={`${ copokes_shown.length > 0 ? "hidden" : ""}`}>
            <div className="flex flex-row mt-4 justify-between
                        items-center bg-white rounded-lg">
              <div className="ml-4 my-4">
                Hold tight. When someone that you have liked "likes"
                you back - or vice versa - you'll see them here.
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex text-xl font-semibold text-gray-text mt-4">
              They like you
            </div>
            <div className="flex flex-row space-x-4 mr-6 mt-4 touch:hidden">
              <div onClick={scroll_pokedby(-1)}
                   className={ l_enabled(first_pokedby, pokedbys) ? '' : 'opacity-40' }
                   data-cy="scroll-pokedby-l">
                <LeftButton />
              </div>
              <div onClick={scroll_pokedby(1)}
                   className={ r_enabled(first_pokedby, pokedbys) ? '' : 'opacity-40' }
                   data-cy="scroll-pokedby-r">
                <RightButton />
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4 space-x-4
                          overflow-hidden touch:overflow-x-auto">
            { pokedbys_shown.map((p, i) =>
                    <div key={i}>
                      <UserCard oprofile={p}
                                buttons={buttons}
                                setShowOverlay={setShowOverlay} />
                    </div>)
            }
          </div>
          <div className={`${ pokedbys_shown.length > 0 ? "hidden" : ""}`}>
            <div className="flex flex-row mt-4 justify-between
                        items-center bg-white rounded-lg">
              <div className="ml-4 my-4">
                Once more people get to check you out and "like" you,
                they'll show up here.
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden">
          <img style={{ height: "30rem" }}
               alt="mock ad"
               src={`${config$test_url}/images/side_ad_5.png`}
               className="ml-4 rounded-lg"/>
        </div>
      </div>
      <div className="h-8" />
    </div>
  );
};

const ConnectionsUI = RegPage(BaseConnectionsUI, ConnectsOverlay);

export { ConnectionsUI };
