import React, { useState } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars
import { Redirect } from '@reach/router';
import { useStore, useDispatch } from 'react-redux';

import { ConnectionsUI } from '../ui/ConnectionsUI';

import { blacklist, poke, send_message, goto_profile } from './utils';
import { db_query, signed_in } from '../../App';
import { other_update_action } from '../../store';
import { refresh } from '../../utils';

// Connections gives midi-profiles of users in pokes and pokedbys
// groups (see utils/classify)
const Connections = props => {

  // Connections page has several overlays: user-report, send msg, msg ack
  // showOverlay gives oid as usual, overlay gives type of overlay:
  // none (''), report, sendmsg, msgack
  const [ showOverlay, setShowOverlay ] = useState(null);

  // leftmost item shown in list, indexed from 1. zero if list is empty
  const [ first_copoke, setFirst_copoke ] = useState(1);
  const [ first_pokedby, setFirst_pokedby ] = useState(1);

  const [dis, str] = [useDispatch(), useStore()];

  if (! signed_in()) {
    return <Redirect to='/splash' noThrow />;
  }

  // adjust newpokes count in header
  let others = str.getState().others;
  const newpokes = Object.values({ ...others.copokes, ...others.pokedbys})
                         .filter(p => ! p.pokedby_seen);
  if (newpokes.length > 0) {
    const id = str.getState().profile.id;
    for (var oprof of newpokes) {
      // no need to wait
      db_query.update('other', { id: id, oid: oprof.id, pokedby_seen: true });
      dis(other_update_action({ id: oprof.id, pokedby_seen: true }));
    }
  }

  others = str.getState().others;

  // pokes are users this user has poked; we are not currenly
  // displaying those, but they're still in here
  const bytime = (c1, c2) => {
    const t = c => c.poketime || '1/1/2000';
    return new Date(t(c2)) - new Date(t(c1));
  }

  const [pokes, pokedbys, copokes] =
    ['pokes', 'pokedbys', 'copokes']
      .map(cat => Object.values(others[cat]).sort(bytime));
  if (first_copoke > copokes.length) {
    setFirst_copoke(copokes.length);
  }
  if (first_pokedby > pokedbys.length) {
    setFirst_pokedby(pokedbys.length);
  }
  const scroll_copoke = dir => () => {
    const newstart = first_copoke + dir;
    if (1 <= newstart && newstart <= copokes.length)
      setFirst_copoke(newstart);
  }
  const scroll_pokedby = dir => () => {
    const newstart = first_pokedby + dir;
    if (1 <= newstart && newstart <= pokedbys.length) {
      setFirst_pokedby(newstart);
    }
  }

  const buttons = {
    blacklist: p => () => { blacklist(dis, str, p);
                            refresh(); },
    poke: p => () => { poke(dis, str, p);
                       refresh(); },
    show_msg_overlay: p => setShowOverlay([p, 'sendmsg']),
    sendmsg: (p, r) => send_message(dis, str, p, r),
    see_prof: p => () => goto_profile(p.id),
  }

  return (
    <ConnectionsUI pokes={pokes} pokedbys={pokedbys} copokes={copokes}
                   buttons={buttons}
                   showOverlay={showOverlay}
                   setShowOverlay={setShowOverlay}
                   first_copoke={first_copoke}
                   first_pokedby={first_pokedby}
                   scroll_copoke={scroll_copoke}
                   scroll_pokedby={scroll_pokedby} />
  );
};

export { Connections };
