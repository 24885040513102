import React, { useRef, useState } from 'react';

import { TopLogo } from './TopLogo';
import { InfoIcon } from '../../assets/icons/info';
import { CancelIcon } from '../../assets/icons/cancel';
import { BeforeOverlay } from './BeforeOverlay';

import { FooterPage } from './RegPage';
import { Prospective } from './Prospective';
import { Explainer } from './Explainer';

import { changePage } from '../../utils';
import { CYPRESS_MODE, TEST_MODE, config$text_limits } from '../../config';
import { TextSw } from '../widgets/TextWidget';

// const location_explainer = `Laughstruck is running a beta
//   test in the Portland, ME, metropolitan area, including zip codes
//   within about 25 miles of Portland.`;
const location_explainer = `We have shut down Laughstruck.`;

const BaseSplashUI = ({username, obscene_ok, open_signup,
                       open_prospect, actions,
                       errmsg, reset_errmsg,
                       show_darn }) => {

  const [location_ex, setLocation_ex] = useState(false);

  const blur = open_prospect || open_signup;

  let refs = { email: useRef(), pwd: useRef() };

  return (
    <div>
      <div className="">
        <div className={`sm:relative grid grid-cols-2 sm:flex sm:flex-col
                         h-5/6 w-full
                         ${blur ? " opacity-20" : ""}`}>
          <div className="grid col-span-1 sm:flex sm:order-last">
            <div className="flex ml-32 mt-24 sm:mt-0 sm:ml-8 sm:mr-8">
              <div className="flex flex-col space-y-3">
                <div className="sm:invisible">
                  <TopLogo />
                </div>
                <div className="text-3xl text-gray-text font-bold">
                  Welcome to Laughstruck
                </div>
                <div className="text-lg col-span-full text-gray-para">
                  Looking to connect with someone who gets the
                  joke &mdash; and gets you?
                  <br/>
                  Rate some jokes. Tell us about yourself.
                  Find someone to laugh with!
                </div>
                <div className="flex justify-start space-x-2">
                  <div>
                    <button className="bg-orange rounded-lg border
                                    mt-4 w-32 h-10 md:w-24"
                         onClick={() => actions.open_signup('0')}
                         data-cy="letsgo-button">
                      <div className="font-semibold text-white
                                      md:text-xs
                                      text-sm text-center mt-0">
                        Get Started
                      </div>
                    </button>
                  </div>
                  <div>
                    <button className="bg-transparent rounded-lg border
                                    border-gray-text mt-4 w-32 h-10 md:w-24"
                                    onClick={actions.sign_in}
                                    data-cy="splash-signin">
                      <div className="font-semibold text-gray-text
                                     md:text-xs
                                     text-sm text-center mt-0">
                        Sign In
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex flex-row space-x-2 pt-24">
                  <div className="">
                    <div className="relative">
                      <div data-cy="splash-locationex"
                           onClick={() => setLocation_ex(! location_ex)}>
                        <InfoIcon />
                      </div>
                      <Explainer open={location_ex}
                                 pos="left-4 bottom-8"
                                 cy_name="location_ex"
                                 close={() => setLocation_ex(false)}
                                 exp={location_explainer} />
                    </div>
                  </div>
                  <div className="text-sm text-gray-text hidden">
                    Laughstruck is currently in alpha-testing mode.
                  </div>
                  <div className="text-lg text-gray-text">
                    Laughstruck is no longer active. If you helped us
                    test the site, we thank you and wish you luck
                    in finding your laugh-mate.
                  </div>
                  <div className="text-sm text-gray-text hidden">
                    Laughstruck is currently in beta, making matches -
                    for free! -
                    in and around Portland, ME.
                    If you want us to let you know when we're in your
                    area, <button className="font-bold hover:underline"
                                data-cy="openprospect-button"
                                onClick={() => actions.open_prospect('0')}>
                      click here.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex flex-col">
            <div className="sm:relative w-2/3 sm:w-full flex mx-auto mt-10 lg:my-auto"
                 style={{ boxShadow: " 0px 3px 60px #00000029" }}>
              <img src="images/laughing_couple_1.jpg"
                   alt="man and woman laughing"
                   className="rounded-lg shadow-xl
                              w-full h-full
                              sm:hidden
                              sm:shadow-none sm:rounded-none"/>
              <img src="images/laughing_couple_mobile.jpg"
                   alt="man and woman laughing"
                   className="rounded-lg shadow-xl
                              w-full h-full
                              hidden sm:block
                              sm:shadow-none sm:rounded-none"/>
            </div>
          </div>
        </div>
        <div className={`fixed inset-0 ${open_prospect ? "" : "hidden"}`}>
          <Prospective save_prospect={actions.save_prospect}
                       mode={open_prospect}
                       errmsg={errmsg} reset_errmsg={reset_errmsg}
                       open={Boolean(open_prospect)}
                       onclose={() => actions.open_prospect('')} />
        </div>
        <div className={"absolute top-8" + (open_signup ? "" : " hidden")}>
          <BeforeOverlay cancel={() => actions.open_signup('')}
                         refs={refs}
                         open_signup={open_signup}
                         errmsg={errmsg} reset_errmsg={reset_errmsg}
                         obscene_ok={obscene_ok}
                         actions={actions} />
        </div>
      </div>
    </div>
  );
}

const BaseSigninUI = ({ signin, lost_passwd, errmsg, reset_errmsg }) => {
  let refs = { email: useRef(), pwd: useRef() };

  return (
    <div className="relative">
      <div className="h-full flex flex-col pt-16 space-y-16 items-center">
        <TopLogo />
        <div className="flex flex-col bg-white rounded-lg space-y-6 mx-3"
             style={{ boxShadow: "0px 3px 50px #0000000D" }}>
          <div className="mx-auto text-2xl font-bold text-gray-text mt-6">
            Sign In
          </div>
          <div className="mx-4">
            <TextSw the_ref={refs.email}
                    label="Email"
                    margin="mx-2"
                    errmsg={errmsg.email}
                    reset_errmsg={reset_errmsg}
                    dflt={TEST_MODE && ! CYPRESS_MODE ? "a@email.com" : "" }
                    maxchars={config$text_limits.email}
                    prompt="your email"
                    cy_name="signin_email" />
          </div>
          <div className="mx-4">
            <TextSw the_ref={refs.pwd}
                    label="Password"
                    type="password"
                    margin="mx-2"
                    errmsg={errmsg.pwd}
                    reset_errmsg={reset_errmsg}
                    dflt={TEST_MODE && ! CYPRESS_MODE ? "apwapw" : "" }
                    maxchars={config$text_limits.password}
                    prompt="your password"
                    cy_name="signin_pwd" />
          </div>
          <div>
            <div className="flex flex-row items-center mr-4">
              <button className="items-start text-sm font-bold text-gray-text
                              ml-4 mt-2 hover:underline"
                    onClick={lost_passwd}>
                Forgot password?
              </button>
              <div className="w-10"></div>
              <button className="bg-orange rounded-lg mt-4 w-32 h-8">
                <div className="font-semibold text-white text-sm
                                   text-center mt-0"
                     data-cy="signin-button"
                     onClick={signin(refs)}>
                  Login
                </div>
              </button>
            </div>
          </div>
          <div className="h-16">
          </div>
        </div>
      </div>
    </div>
  );
}

const BasePasswordResetReqUI = ({ errmsg, reset_errmsg, lost_passwd }) => {
  const reset_ref = useRef();
  return (
    <div className="relative">
      <div className="h-full flex flex-col pt-16 space-y-16 items-center">
        <TopLogo />
        <div className="flex flex-col bg-white rounded-lg
                        space-y-6 mx-3
                        lg:w-1/2 md:w-2/3 sm:w-11/12"
             style={{ boxShadow: "0px 3px 50px #0000000D" }}>
          <div className="absolute top-0 right-0 mt-4 mr-4"
               onClick={() => changePage('splash')}>
            <button>
              <CancelIcon />
            </button>
          </div>
          <div className="mt-8" />
          <div className="flex flex-col space-y-6">
            <div className="text-center text-2xl font-bold text-gray-text mt-8">
              Reset Password
            </div>
            <div className="text-sm font-normal mx-4 text-gray-text mt-6">
              Type in your email and we will send you instructions
              on how to reset your password.
            </div>
            <TextSw ref={reset_ref}
                    label="Email"
                    margin="mx-6"
                    errmsg={errmsg.email}
                    reset_errmsg={reset_errmsg}
                    maxchars={config$text_limits.email}
                    prompt="Your email"
                    cy_name="reset_email" />
            <div className="flex flex-col items-center">
              <button>
                <div className="flex flex-col bg-orange rounded-lg mt-4 w-32 h-8">
                  <div className="font-semibold text-white text-sm
                                  text-center mt-1.5"
                       onClick={lost_passwd(reset_ref)} >
                    Reset
                  </div>
                </div>
              </button>
              <div className="items-start text-sm font-normal text-gray-para
                              mt-2 hover:underline"
                   onClick={() => changePage('splash')}>
                <button>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="h-16" />
        </div>
      </div>
    </div>
  );
}

const SplashUI = FooterPage(BaseSplashUI);
const SigninUI = FooterPage(BaseSigninUI);
const PasswordResetReqUI = FooterPage(BasePasswordResetReqUI);

export { SplashUI, SigninUI, PasswordResetReqUI };
