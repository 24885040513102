import React, { useRef, useState } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { EditPage } from './RegPage';
import { TextSw, NakedTextSw, TextareaSw, SnapshotSw }
  from '../widgets/TextWidget';
import { DropdownSw } from '../widgets/Dropdown';
import { MultiRadioSw } from '../widgets/RadioButton';

import { toUS, inches2ft } from '../../utils';
import { gender_ui2db, gender_db2ui,
         marital_ui2db, marital_db2ui,
         smoker_ui2db, smoker_db2ui,
         drinker_ui2db, drinker_db2ui,
         children_ui2db, children_db2ui,
         educlevel_ui2db, educlevel_db2ui,
         religious_ui2db, religious_db2ui,
         seeking_ui2db, seeking_db2ui,
         genderpref_db2ui,
         maritalpref_db2ui,
         smokerpref_ui2db, smokerpref_db2ui,
         drinkerpref_ui2db, drinkerpref_db2ui,
         childrenpref_ui2db, childrenpref_db2ui,
         educpref_ui2db, educpref_db2ui,
         religiouspref_ui2db, religiouspref_db2ui,
         username_changecheck,
  } from '../pages/utils';

import { config$text_limits } from '../../config';

// profile-editing pages
const BaseEditAccountUI = ({profile, submit, errmsg, reset_errmsg }) => {

  const refs = {
    email: useRef(),
    oldpwd: useRef(),
    newpwd: [ useRef(), useRef() ],
    zipcode: useRef(),
    obscene: useRef(),
  };
  const pwdrefs = [ useRef(), useRef(), useRef() ];
  const resetpw = () => pwdrefs.forEach(r => r.current.setText(''));

  return (
    <div className="space-y-4 bg-white rounded-lg
                    col-span-3 lg:col-span-5 md:col-span-6 sm:col-span-1"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
      <form onSubmit={submit(refs, resetpw)}
            className="mb-4">
        <div className="fixed top-8 right-24 z-10">
          <button className="flex justify-center items-center
                             h-10 bg-orange rounded-lg"
                  type="submit" data-cy="account-submit">
            <div className="text-sm text-white font-bold mx-8">
              Save changes
            </div>
          </button>
        </div>
        <div className="flex flex-row ml-4 mt-4 justify-start
                        col-span-2">
          <div className="text-xl text-gray-text font-bold mb-4">
            Account Settings
          </div>
        </div>
        <div className="grid grid-cols-2 mt-6 gap-6 gap-y-10
                        lg:grid-cols-1">
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              Your email
            </div>
            <div className="text-base text-gray-para font-normal">
              Your current email address
            </div>
          </div>
          <div>
            <div className="flex flex-col mx-4 space-y-4 justify-start">
                <TextSw the_ref={refs.email}
                        label="Email"
                        type="text"
                        dflt={profile.email}
                        errmsg={errmsg.email}
                        reset_errmsg={reset_errmsg}
                        maxchars={config$text_limits.email}
                        cy_name="edit_email" />
            </div>
          </div>
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              Change password
            </div>
            <div className="text-base text-gray-para font-normal">
              Change your current password to a newer one
            </div>
          </div>
          <div>
            <div className="flex flex-col mx-4 space-y-4 justify-start">
              <div className="flex flex-col space-y-2 justify-start">
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.oldpwd}
                          ref={pwdrefs[0]}
                          label="Current password"
                          type="password"
                          errmsg={errmsg.oldpwd}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.password}
                          prompt="Enter your current password"
                          cy_name="edit_pwd" />
                </div>
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.newpwd[0]}
                          ref={pwdrefs[1]}
                          type="password"
                          errmsg={errmsg.pwd1}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.password}
                          prompt="New password"
                          cy_name="edit_pwd1" />
                </div>
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.newpwd[1]}
                          ref={pwdrefs[2]}
                          type="password"
                          errmsg={errmsg.pwd2}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.password}
                          prompt="Retype new password"
                          cy_name="edit_pwd2" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              Miscellaneous
            </div>
          </div>
          <div className="flex flex-col mx-4 space-y-4 justify-start">
            <div className="flex flex-col space-y-2 justify-start">
              <TextSw the_ref={refs.zipcode}
                      label="ZIP code"
                      dflt={profile.zipcode}
                      errmsg={errmsg.zipcode}
                      maxchars={5}
                      reset_errmsg={reset_errmsg}
                      cy_name="edit_zipcode" />
            </div>
            <div className="flex flex-col space-y-2 justify-start">
              <DropdownSw the_ref={refs.obscene}
                          label="Are you okay seeing jokes with sexual content?"
                          reset_errmsg={reset_errmsg}
                          dflt={profile.obscene_okay ? "Yes" : "No"}
                          choices={['Yes', 'No']}
                          cy_prefix="edit_obscene" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const EditAccountUI = EditPage(BaseEditAccountUI);

// entry for large text fields in profile edit
const TextEntry = ({ label, dflt, errmsg, reset_errmsg, the_ref, cy_name }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col mx-4 space-y-2 justify-start">
        <div className="text-base text-gray-text font-bold">
          { label }
        </div>
        <div className="text-base text-gray-para font-normal">
          Limit {config$text_limits.brief_intro} characters
        </div>
      </div>
      <div>
        <TextareaSw the_ref={the_ref}
                    errmsg={errmsg}
                    margin="mr-4 lg:ml-4"
                    dflt={dflt}
                    rows={3}
                    reset_errmsg={reset_errmsg}
                    maxchars={config$text_limits.brief_intro}
                    prompt="Type here"
                    cy_name={cy_name} />
      </div>
    </React.Fragment>
  );
}

// personal info editing pages
const BaseEditProfileUI = ({ profile, submit, errmsg, reset_errmsg,
                             snapshot, setSnapshot }) => {

  // note: this gives order of appearance on page
  const refs = {
    username: useRef(),
    gender: useRef(),
    dob: useRef(),
    snapshot: useRef(),
    height: [ useRef(), useRef() ],
    marital: useRef(),
    smoker: useRef(),
    drinker: useRef(),
    children: useRef(),
    educlevel: useRef(),
    religious: useRef(),
    religion: useRef(),
    brief_intro: useRef(),
    like_to_do: useRef(),
    have_to_do: useRef(),
    like_to_discuss: useRef(),
    like_to_read: useRef(),
    like_to_listen: useRef(),
    like_to_watch: useRef(),
    looking_for: useRef(),
  }

  const [dflt_ft, dflt_in] = profile.height === 0
                             ? ['', '']
                             : inches2ft(profile.height).map(String);

  return (
    <div className="space-y-4 bg-white rounded-lg
                    col-span-3 lg:col-span-5 md:col-span-6 sm:col-span-1"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
      <form onSubmit={submit(refs)}
            className="mb-4">
        <div className="fixed top-8 right-28 z-10
                        sm:top-8 sm:right-16">
          <button type="submit"
                  data-cy="profile-submit"
                  className="flex justify-center items-center
                      h-10 bg-orange rounded-lg">
            <div className="text-sm text-white font-bold mx-8">
              Save changes
            </div>
          </button>
        </div>
        <div className="flex flex-row ml-4 mt-4 justify-start
                    col-span-2">
          <div className="text-xl text-gray-text font-bold mb-4">
            My Profile
          </div>
        </div>
        <div className="grid grid-cols-2 mt-6 gap-6 gap-y-10
                        xl:grid-cols-1">
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              Basic info
            </div>
            <div className="text-base text-gray-para font-normal">
              Your basic information like your username,
              age, etc.
            </div>
          </div>
          <div className="xl:ml-4">
            <div className="flex flex-col space-y-4 justify-start">
              <SnapshotSw the_ref={refs.snapshot}
                          label=""
                          snapshot={snapshot}
                          setSnapshot={setSnapshot}
                          dflt={[ profile.snapshot, profile.snapshot_url,
                                  profile.snapshot_ar ]}
                          errmsg={errmsg.snapshot}
                          reset_errmsg={reset_errmsg}
                          margin="mr-4"
                          textmargin="mx-4 xl:-mx-8 lg:mx-2"
                          prompt=""
                          cy_name="edit_snapshot" />
              <div className="flex flex-col space-y-2 justify-start">
                <TextSw the_ref={refs.username}
                        label="Username"
                        type="text"
                        margin="mr-4 lg:ml-0"
                        dflt={profile.username}
                        maxchars={config$text_limits.username}
                        onchange={username_changecheck}
                        errmsg={errmsg.username}
                        reset_errmsg={reset_errmsg}
                        prompt="letters and spaces only"
                        cy_name="edit_username" />
                <div className="text-sm font-normal text-gray-text
                                mr-4 lg:mr-2">
                  (This is the name others will see on your profile.
                  Letters and spaces only.
                  Limit {config$text_limits.username} characters.)
                </div>
              </div>
              <DropdownSw the_ref={refs.gender}
                          label="Gender"
                          errmsg={errmsg.gender}
                          dflt={gender_db2ui[profile.gender]}
                          prompt="Please select one"
                          choices={Object.keys(gender_ui2db)}
                          cy_prefix="edit_gender" />

              <TextSw the_ref={refs.dob}
                      label="Date of birth"
                      type="text"
                      margin="mr-4 lg:ml-0"
                      dflt={toUS(profile.birthday)}
                      errmsg={errmsg.dob}
                      reset_errmsg={reset_errmsg}
                      maxchars={10}
                      prompt="dd/dd/dddd"
                      cy_name="edit_dob" />

            </div>
          </div>
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              A bit more about you
            </div>
            <div className="text-base text-gray-para font-normal">
              What's your height? Do you smoke? Tell us about
              your relationship status.
            </div>
          </div>
          <div className="lg:ml-4">
            <div className="flex flex-col space-y-4 justify-start">
              <div className="text-sm text-gray-text font-normal">
                Height
              </div>
              <div className="grid grid-cols-2 gap-2 mr-4">
                <DropdownSw the_ref={refs.height[0]}
                            unit="ft"
                            errmsg={errmsg.feet}
                            reset_errmsg={reset_errmsg}
                            dflt={dflt_ft}
                            prompt="Select one"
                            choices={['4', '5', '6', '7']}
                            cy_prefix="edit_feet" />

                <DropdownSw the_ref={refs.height[1]}
                            unit="in"
                            errmsg={errmsg.inches}
                            reset_errmsg={reset_errmsg}
                            dflt={dflt_in}
                            prompt="Select one"
                            choices={['0', '1', '2', '3', '4', '5',
                                      '6', '7', '8', '9', '10', '11']}
                            cy_prefix="edit_inches" />
              </div>

              <DropdownSw the_ref={refs.smoker}
                          label="Smoker"
                          errmsg={errmsg.smoker}
                          dflt={smoker_db2ui[profile.smoker]}
                          prompt="Please select one"
                          choices={Object.keys(smoker_ui2db)}
                          cy_prefix="edit_smoker" />


              <DropdownSw the_ref={refs.drinker}
                          label="Drinker"
                          errmsg={errmsg.drinker}
                          dflt={drinker_db2ui[profile.drinker]}
                          prompt="Please select one"
                          choices={Object.keys(drinker_ui2db)}
                          cy_prefix="edit_drinker" />

              <DropdownSw the_ref={refs.marital}
                          label="Marital status"
                          errmsg={errmsg.marital}
                          dflt={marital_db2ui[profile.married]}
                          prompt="Please select one"
                          choices={Object.keys(marital_ui2db)}
                          cy_prefix="edit_marital" />

              <DropdownSw the_ref={refs.children}
                          label="Kids"
                          errmsg={errmsg.children}
                          dflt={children_db2ui[profile.children]}
                          prompt="Please select one"
                          choices={Object.keys(children_ui2db)}
                          cy_prefix="edit_children" />

              <DropdownSw the_ref={refs.educlevel}
                          label="Highest degree"
                          errmsg={errmsg.educlevel}
                          dflt={educlevel_db2ui[profile.educ_level]}
                          prompt="Please select one"
                          choices={Object.keys(educlevel_ui2db)}
                          cy_prefix="edit_educlevel" />

              <DropdownSw the_ref={refs.religious}
                          label="Religious"
                          errmsg={errmsg.religious}
                          dflt={religious_db2ui[profile.religious]}
                          prompt="Please select one"
                          choices={Object.keys(religious_ui2db)}
                          cy_prefix="edit_religious" />

              <TextSw the_ref={refs.religion}
                      label="Your religion"
                      type="text"
                      margin="mr-4"
                      dflt={profile.religion}
                      errmsg={errmsg.religion}
                      reset_errmsg={reset_errmsg}
                      maxchars={30}
                      prompt="Type here (max 30 chars)"
                      cy_name="edit_religion" />

            </div>
          </div>
          <TextEntry dflt={profile.brief_intro}
                     errmsg={errmsg.brief_intro}
                     label="Tell us a bit about yourself"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.brief_intro}
                     cy_name="edit_brief_intro" />
           <TextEntry dflt={profile.like_to_do}
                     errmsg={errmsg.like_to_do}
                     label="What you like to do"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.like_to_do}
                     cy_name="edit_like_to_do" />
           <TextEntry dflt={profile.have_to_do}
                     errmsg={errmsg.have_to_do}
                     label="What you have to do"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.have_to_do}
                     cy_name="edit_have_to_do" />
           <TextEntry dflt={profile.like_to_discuss}
                     errmsg={errmsg.like_to_discuss}
                     label="What you like to talk about"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.like_to_discuss}
                     cy_name="edit_like_to_discuss" />
          <TextEntry dflt={profile.like_to_read}
                     errmsg={errmsg.like_to_read}
                     label="Favorite reads"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.like_to_read}
                     cy_name="edit_like_to_read" />
          <TextEntry dflt={profile.like_to_listen}
                     errmsg={errmsg.like_to_listen}
                     label="Favorite listens"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.like_to_listen}
                     cy_name="edit_like_to_listen" />
          <TextEntry dflt={profile.like_to_watch}
                     errmsg={errmsg.like_to_watch}
                     label="Favorite watches (movies or tv)"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.like_to_watch}
                     cy_name="edit_like_to_watch" />
          <TextEntry dflt={profile.looking_for}
                     errmsg={errmsg.looking_for}
                     label="What you're looking for"
                     reset_errmsg={reset_errmsg}
                     the_ref={refs.looking_for}
                     cy_name="edit_looking_for" />
        </div>
      </form>
      {/*
      { showCrop
        ? <div className={"fixed inset-0" + (showCrop ? "" : " hidden")}>
            <CropperOverlay profile={profile}
                            snapshot={snapshot}
                            setSnapshot={setSnapshot}
                            cancel={() => setShowCrop(false)}
                            refs={refs}
                            submit={submit}
                            errmsg={errmsg} reset_errmsg={reset_errmsg} />
          </div>
        : <span />
      }
      */}
    </div>
  );
}
const EditProfileUI = EditPage(BaseEditProfileUI);

const BaseEditBillingUI = ({submit}) => {
  // let billing_ref = useRef();
  return (
    <div className="space-y-4 bg-white rounded-lg
                    col-span-3 lg:col-span-5 md:col-span-6 sm:col-span-1">
      <div className="bg-white rounded-lg">
        <div className="px-4 py-4 text-base font-normal text-gray-text">
          <div className="flex flex-col space-y-2">
            <div>
              In this Beta run, your Laughstruck subscription is on us.
            </div>
            <div>
              Enjoy!
            </div>
          </div>
        </div>
      </div>
      <div className="h-full" />
    </div>
  );
}
const EditBillingUI = EditPage(BaseEditBillingUI);

const BaseEditPrefsUI = ({profile, submit, errmsg, reset_errmsg }) => {

  const [showHeightpref, setShowHeightpref] = useState(profile.low_height !== 1);

  const refs = {
    seeking: useRef(),
    genderpref: useRef(),
    agepref: [ useRef(), useRef() ],
    heightpref: [ useRef(), useRef(), useRef(), useRef(), useRef()],
    maritalpref: useRef(),
    smokerpref: useRef(),
    drinkerpref: useRef(),
    childrenpref: useRef(),
    educpref: useRef(),
    religiouspref: useRef(),
  }

  const [dflt_lowft, dflt_lowin] = profile.low_height === 1
                                   ? ['', '']
                                   : inches2ft(profile.low_height).map(String);
  const [dflt_highft, dflt_highin] = profile.low_height === 1
                                   ? ['', '']
                                   : inches2ft(profile.high_height).map(String);

  return (
    <div className="relative space-y-4 bg-white rounded-lg
                    col-span-3 lg:col-span-5 md:col-span-6 sm:col-span-1"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
      <form onSubmit={submit(refs)}
            className="mb-4">
        <div className="fixed top-8 right-24 z-10">
          <button type="submit"
                  data-cy='prefs-submit'
                  className="flex justify-center items-center
                      h-10 bg-orange rounded-lg">
            <div className="text-sm text-white font-bold mx-8">
              Save changes
            </div>
          </button>
        </div>
        <div className="flex flex-row ml-4 mt-4 justify-start
                        col-span-2">
          <div className="text-xl text-gray-text font-bold mb-4">
            My Preferences
          </div>
        </div>
        <div className="grid grid-cols-2 mt-6 gap-6 gap-y-10
                        sm:grid-cols-1">
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              What are you looking for?
            </div>
            <div className="text-base text-gray-para font-normal">
              Tell us what you want
            </div>
          </div>
          <div className="sm:ml-4">
            <div className="flex flex-col space-y-4 justify-start">
              <DropdownSw the_ref={refs.seeking}
                          label="Looking for"
                          errmsg={errmsg.seeking}
                          dflt={seeking_db2ui[profile.seeking]}
                          prompt="Please select one"
                          choices={Object.keys(seeking_ui2db)}
                          cy_prefix="edit_seeking" />
              <div className="flex flex-col space-y-2">
                <div className="font-normal text-sm text-gray-text mb-2">
                With (pick one or more):
                </div>
                <MultiRadioSw
                   ref={refs.genderpref}
                   errmsg={errmsg.genderpref}
                   reset_errmsg={reset_errmsg}
                   choices={["Men", "Women",
                             "Nonbinary people"]}
                   dflt={genderpref_db2ui(profile.gender_pref)}
                   cy_prefix="edit_genderpref" />
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                  <div className="mx-0 text-sm font-normal text-gray-text">
                    Between the ages of
                  </div>
                  <div className="flex flex-row">
                    <NakedTextSw the_ref={refs.agepref[0]}
                            label=""
                            type="text"
                            margin="mr-4 lg:ml-4"
                            dflt={profile.age_low}
                            errmsg={errmsg.agelow}
                            reset_errmsg={reset_errmsg}
                            prompt="age (>= 18)"
                            cy_name="edit_agelow" />
                    <div className="flex mx-auto text-small h-10 font-normal
                                 text-gray-600 items-center">
                      <div className="flex mx-auto mr-2 -ml-2">
                        &
                      </div>
                    </div>
                    <NakedTextSw the_ref={refs.agepref[1]}
                            label=""
                            margin="mr-4 lg:ml-4"
                            type="text"
                            dflt={profile.age_high}
                            errmsg={errmsg.agehigh}
                            reset_errmsg={reset_errmsg}
                            prompt="age (>= minimum age)"
                            cy_name="edit_agehigh" />
                  </div>
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.heightpref[0]}
                            label="Height preference"
                            dflt={profile.low_height === 1 ? 'No preference' : 'Between'}
                            choices={['No preference', 'Between']}
                            action={ev => setShowHeightpref(
                                              ev.target.getAttribute('label') === 'Between')}
                            cy_prefix="edit_heightpref" />
                <div className={ showHeightpref ? '' : 'hidden' }>
                  <div className="grid grid-cols-2 gap-2 mr-4">
                    <DropdownSw the_ref={refs.heightpref[1]}
                                unit="ft"
                                errmsg={errmsg.lowfeet}
                                reset_errmsg={reset_errmsg}
                                dflt={dflt_lowft}
                                prompt="Select one"
                                choices={['4', '5', '6', '7']}
                                cy_prefix="edit_lowfeet" />

                    <DropdownSw the_ref={refs.heightpref[2]}
                                unit="in"
                                errmsg={errmsg.lowinches}
                                reset_errmsg={reset_errmsg}
                                dflt={dflt_lowin}
                                prompt="Select one"
                                choices={['0', '1', '2', '3', '4', '5',
                                          '6', '7', '8', '9', '10', '11']}
                                cy_prefix="edit_lowinches" />
                  </div>
                  <div className="h-0.5" />
                  <div className="text-sm text-gray-text font-normal">
                    and
                  </div>
                  <div className="h-0.5" />
                    <div className="grid grid-cols-2 gap-2 mr-4">
                      <DropdownSw the_ref={refs.heightpref[3]}
                                  unit="ft"
                                  errmsg={errmsg.highfeet}
                                  reset_errmsg={reset_errmsg}
                                  dflt={dflt_highft}
                                  prompt="Select one"
                                  choices={['4', '5', '6', '7']}
                                  cy_prefix="edit_highfeet" />

                      <DropdownSw the_ref={refs.heightpref[4]}
                                  unit="in"
                                  errmsg={errmsg.highinches}
                                  reset_errmsg={reset_errmsg}
                                  dflt={dflt_highin}
                                  prompt="Select one"
                                  choices={['0', '1', '2', '3', '4', '5',
                                            '6', '7', '8', '9', '10', '11']}
                                  cy_prefix="edit_highinches" />
                    </div>
                  </div>
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <div className="font-normal text-sm text-gray-text mb-2">
                I'm willing to be matched with people who are (pick 1 or more):
                </div>
                <MultiRadioSw
                   ref={refs.maritalpref}
                   errmsg={errmsg.maritalpref}
                   reset_errmsg={reset_errmsg}
                   choices={["Single", "In non-open relationship",
                             "In open relationship"]}
                   dflt={maritalpref_db2ui(profile.married_pref)}
                   cy_prefix="edit_maritalpref" />
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-4 space-y-2 justify-start">
            <div className="text-base text-gray-text font-bold">
              What are your preferences?
            </div>
            <div className="text-base text-gray-para font-normal">
              What are you looking for in a person?
            </div>
          </div>
          <div className="sm:ml-4">
            <div className="flex flex-col space-y-4 justify-start">
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.smokerpref}
                            label="Smoker"
                            errmsg={errmsg.smokerpref}
                            dflt={smokerpref_db2ui[profile.smoker_pref]}
                            prompt="Please select one"
                            choices={Object.keys(smokerpref_ui2db)}
                            cy_prefix="edit_smokerpref" />
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.drinkerpref}
                            label="Drinker"
                            errmsg={errmsg.drinkerpref}
                            dflt={drinkerpref_db2ui[profile.drinker_pref]}
                            prompt="Please select one"
                            choices={Object.keys(drinkerpref_ui2db)}
                            cy_prefix="edit_drinkerpref" />
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.childrenpref}
                            label="Kids"
                            errmsg={errmsg.childrenpref}
                            dflt={childrenpref_db2ui[profile.children_pref]}
                            prompt="Please select one"
                            choices={Object.keys(childrenpref_ui2db)}
                            cy_prefix="edit_childrenpref" />
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.educpref}
                            label="Prefer someone with at least"
                            errmsg={errmsg.educpref}
                            dflt={educpref_db2ui[profile.educ_pref]}
                            prompt="Please select one"
                            choices={Object.keys(educpref_ui2db)}
                            cy_prefix="edit_educpref" />
              </div>
              <div className="flex flex-col space-y-2 justify-start">
                <DropdownSw the_ref={refs.religiouspref}
                            label="Religious"
                            errmsg={errmsg.religiouspref}
                            dflt={religiouspref_db2ui[profile.religious_pref]}
                            prompt="Please select one"
                            choices={Object.keys(religiouspref_ui2db)}
                            cy_prefix="edit_religiouspref" />
            </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const EditPrefsUI = EditPage(BaseEditPrefsUI);

export { EditAccountUI, EditBillingUI, EditPrefsUI, EditProfileUI, };
