import React, { useState } from 'react';
import { consolelog } from '../../utils'; // eslint-disable-line no-unused-vars

import { ConvoDotsIcon } from '../../assets/icons/convo-dots';
import { InfoIcon } from '../../assets/icons/info';
import { HumorfaceIcon, HumorplusfaceIcon } from '../../assets/icons/humorface';
import { LikeIcon, DislikeIcon } from '../../assets/icons/useractions';
import { Explainer } from './Explainer';

import { classify } from '../pages/utils';
import { calculate_age } from '../../utils';
import { report_user_explainer, explain_humor, explain_humorp } from './utils';

// used when user's id is in match_page but they are blacklisted
const EmptyUserCard = () => {
  return (
    <div>
    </div>
  );
}

// buttons = { buttonname: oprofile => function }
// used on matches and connections pages
// any user displayed in usercard is classified as either pokedbys,
// copokes, or matches. threads only appear in userminicard, pokes are
// not displayed anywhere, and blacklists barely exist
const UserCard = ({ oprofile, buttons, setShowOverlay }) => {

  // pop-up for 'report user' info button
  const [ reportUserEx, setReportUserEx ] = useState(false);
  const [ explainHumor, setExplainHumor ] = useState(false);
  const [ explainHumorP, setExplainHumorP ] = useState(false);

  // humor explainers are "ganged"
  const setExpHumor = () => { setExplainHumor(! explainHumor);
                              setExplainHumorP(false); };
  const setExpHumorP = () => { setExplainHumorP(! explainHumorP);
                               setExplainHumor(false); };
  const resetExpHumors = () => { setExplainHumor(false);
                                 setExplainHumorP(false); }

  const oid = oprofile.id;

  const cat = classify(oprofile);
  const pokeable = cat === 'matches' || cat === 'pokedbys';
  const msgable = cat === 'pokedbys' || cat === 'copokes';

  const [w, h] = oprofile.snapshot_ar <= 1
                 ? [ `${oprofile.snapshot_ar * 10}rem`, '10rem' ]
                 : [ '10rem', `${10 / oprofile.snapshot_ar}rem` ];

  return (
    <div className="w-44 mx-0 rounded-lg justify-center mb-4"
         style={{ boxShadow: "0px 3px 50px #0000000D" }}
         data-cy={`midiprofile-${oid}`}
         data-cy-cat="midiprofile">
      <div className="grid grid-rows-12 h-auto gap-2">
        <div className="relative row-span-6 w-full cursor-pointer
                        flex flex-row justify-center"
             style={{ height: "10rem"}}
             data-cy={`showprof${oid}-button`}
             onClick={ev => { if (ev.target.getAttribute('alt')
                                    === 'conversation bubble') {
                                buttons.show_msg_overlay(oprofile);
                              } else {
                                buttons.see_prof(oprofile)();
                              }
                            }}>
          <img src={oprofile.snapshot_url}
               alt={`face of ${oprofile.username}`}
               style={{ width: w, height: h }}
               className="rounded-t-lg" />
          <div className={`absolute bottom-2 right-2 z-10
                           cursor-pointer
                           ${ msgable ? '' : 'hidden' }`}
                 data-cy={`sendmsg${oid}-button`}>
            <div data-target="convobubble">
              <ConvoDotsIcon size="large" />
            </div>
          </div>
        </div>
        <div className="grid row-span-6 bg-white rounded-b-lg -my-2 h-auto">
          <div className="flex flex-col">
            <div className="flex justify-start mt-2 ml-2 space-x-2 items-center">
              <div className="text-base font-bold text-gray-text">
                { oprofile.username }
              </div>
              <div className="text-base font-normal mx-2 text-gray-text">
                { `${calculate_age(oprofile.birthday)}y` }
              </div>
            </div>
            <div className="flex justify-start mt-2 space-x-2">
              <div className="relative" data-cy="open-exphumor"
                   onClick={setExpHumor}>
                <div className="flex flex-row my-2 mx-2 px-2 bg-gray-bg
                                space-x-1 rounded-lg items-center">
                  <HumorfaceIcon />
                  <div className="text-sm font-normal text-gray-text my-1 ml-0.5">
                    {`${oprofile.affinity[0]}%`}
                  </div>
                </div>
                <Explainer open={explainHumor}
                           cy_name="exphumor"
                           pos="left-4 bottom-8 sm:-left-24"
                           close={resetExpHumors}
                           exp={explain_humor} />
              </div>
              <div className="relative" data-cy="open-exphumorp"
                   onClick={setExpHumorP}>
                <div className="flex flex-row my-2 mx-2 px-2 bg-gray-bg
                                space-x-1 rounded-lg items-center">
                  <HumorplusfaceIcon />
                  <div className="text-sm font-normal text-gray-text my-1 ml-0.5">
                    {`${oprofile.affinity[2]}%`}
                  </div>
                </div>
                <Explainer open={explainHumorP}
                           cy_name="exphumorp"
                           pos="left-4 bottom-8 sm:-left-24"
                           close={resetExpHumors}
                           exp={explain_humorp} />
              </div>
            </div>
            <div className="flex justify-start mx-2 mt-2 space-x-2">
              <div className="text-sm font-normal text-gray-text">
                { oprofile.brief_intro.substr(0, 20)}
              </div>
            </div>
            <div className="flex justify-end mx-2 my-3 space-x-2">
              <div className="relative">
                <div data-cy="open-reportuserex"
                     onClick={() => setReportUserEx(! reportUserEx)}>
                  <InfoIcon size="tiny" />
                </div>
                <Explainer open={reportUserEx}
                           cy_name="reportuserex"
                           pos="left-4 bottom-8 sm:-left-24"
                           close={() => setReportUserEx(false)}
                           exp={report_user_explainer} />
              </div>
              <button className="text-xs font-normal text-gray-para"
                   data-cy={`report${oid}-button`}
                   onClick={() => setShowOverlay([oprofile, 'report'])}>
                Report user
              </button>
            </div>
            <div className={ pokeable ? '' : 'hidden' }>
              <div className="flex w-full mt-2 space-x-2 bg-white rounded-b-lg"
                   style={{ boxShadow: "0px -10px 50px #0000000D" }}>
                <div className="grid grid-cols-12 gap-2 divide-x-2 w-full">
                  <div className="flex col-span-6 my-2">
                    <div className="flex flex-row w-full justify-center">
                      <button className="flex space-x-2 justify-center"
                           data-cy={`poke${oid}-button`}
                           onClick={ buttons.poke(oprofile) }>
                        <div>
                          <LikeIcon />
                        </div>
                        <div className="text-xs font-normal text-gray-text">
                          Like
                        </div>
                      </button>
                    </div>
                  </div>
                  <button className="flex col-span-6 flex-row w-full justify-center my-2"
                       data-cy={`blacklist${oid}-button1`}
                       onClick={ buttons.blacklist(oprofile) }>
                    <div>
                      <DislikeIcon />
                    </div>
                    <div className="text-xs font-normal text-gray-text ml-1">
                      Pass
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className={ pokeable ? 'hidden' : '' }>
              <div className="flex w-full mt-2 bg-white rounded-b-lg"
                   style={{ boxShadow: "0px -10px 50px #0000000D" }}>
                <div className="grid grid-cols-12 w-full">
                  <button className="flex col-span-12 flex-row w-full justify-center my-2"
                       data-cy={`blacklist${oid}-button2`}
                       onClick={ buttons.blacklist(oprofile) }>
                    <div>
                      <DislikeIcon />
                    </div>
                    <div className="text-xs font-normal text-gray-text ml-1">
                      Pass
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { UserCard, EmptyUserCard };
