import React from 'react';

import { changePage } from '../../utils';
import { MessageAckIcon } from '../../assets/icons/message-ack';

const MessageAckOverlay = ({ showOverlay, setShowOverlay }) => {
  const otherpage = window.location.href.includes('other/');
  return (
    <div className="flex my-32 content-center justify-center">
      <div className="flex flex-col bg-white rounded-lg space-y-6
                      w-1/3 md:w-1/2 sm:w-11/12"
           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
        <div className="mx-12 text-2xl font-bold text-gray-text text-center mt-6">
          Message Sent
        </div>
        <div className="flex mx-auto">
          <MessageAckIcon />
        </div>
        <div className={otherpage ? "hidden" : ""}>
          <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
            Your message has been sent.
            Keep the discussion going
            in <button className="font-bold underline"
                     data-cy="go-to-convo"
                     onClick={() => changePage('convos', showOverlay[0].id)}>
               conversations,</button>
          </div>
          <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
            or return to <button className="font-bold underline"
                               data-cy="return-to-connect"
                               onClick={() => {
                                         setShowOverlay(null);
                                         changePage('connects');
                                       }}>
               connections</button>
          </div>
        </div>
        <div className={otherpage ? "" : "hidden"}>
          <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
            Your message has been sent.
            Return to <button className="font-bold underline"
                     data-cy="go-to-convo"
                     onClick={() => { setShowOverlay(null);
                                      changePage('other', showOverlay[0].id)} }>
               user's profile,</button>
          </div>
          <div className="mx-6 text-sm font-normal text-gray-text mt-6 text-center">
            or go to <button className="font-bold underline"
                     data-cy="go-to-convo"
                     onClick={() => changePage('convos', showOverlay[0].id)}>
               conversations</button> to send another message.
          </div>
        </div>
        <div className="h-10">
        </div>
      </div>
    </div>
  );
}

export { MessageAckOverlay };
