import React from 'react';
import { useStore, useDispatch } from 'react-redux';

import { PopupNote } from './PopupNote';

import { profile_not_rm_action } from '../../store';
import { size } from '../../utils';

const Notifications = page => {
  const [dis, str] = [useDispatch(), useStore()];
  const profile = str.getState().profile;
  const no_nots = ! profile
                  || ! profile.notifications
                  || size(profile.notifications) === 0;
  if (no_nots) {
    return <span className="hidden"/>;
  }

  const note = Object.values(profile.notifications)[0];
  const removeNote = note => {
    dis(profile_not_rm_action(note));
  }

  return (
    <PopupNote notmsg={note.text}
               nottype="info"
               onclose={() => removeNote(note)} />
  );
}

export { Notifications };
