import React from 'react';

// basic svg sizes differ. scale is calculated to make them equal
const FaceIcon = (scale, svg) => ({ size }) => {
  const s = `${scale * (size === 'big' ? 1.5 : 1)}em`;
  const m = size === 'big' ? "-mt-2" : "";
  return (
    <div className={m}>
      { svg(s) }
    </div>
  );
}

const sad_face = sz =>
  <svg xmlns="http://www.w3.org/2000/svg"
       style={{ width: sz, height: sz }}
       width="40.51" height="40.51" viewBox="0 0 40.51 40.51">

    <title>Sad face</title>
    <g transform="translate(-308.574 -281.411)">
      <g transform="translate(308.574 281.411)">
        <g transform="translate(0 0)">
          <circle style={{ fill: "#ffd05d" }}
                  cx="14.253" cy="14.253" r="14.253"
                  transform="translate(0 28.137) rotate(-80.783)"/>
        </g>
        <g transform="translate(4.341 4.187)">
          <path style={{ fill: "#fdc453" }}
                d="M354,306.451a14.255,14.255,0,0,1-19.467,19.825A14.254,14.254,0,1,0,354,306.451Z"
                transform="translate(-334.537 -306.451)"/>
        </g>
        <g transform="translate(3.051 3.151)">
          <path style={{ fill: "#ffd77f" }}
                d="M327.806,314.962a13.232,13.232,0,0,1,23.722-8.067,13.234,13.234,0,1,0-21.964,14.662A13.173,13.173,0,0,1,327.806,314.962Z"
                transform="translate(-326.821 -300.258)"/>
        </g>
        </g>
        <g transform="translate(318.868 299.189)">
          <path style={{ fill: "#695342" }}
                d="M382.251,393.136a6.1,6.1,0,0,0-12.116,0Z"
                transform="translate(-370.135 -387.729)"/>
        </g>
          <path style={{ fill: "#f06669" }}
                d="M370.135,411.722s5.111-3.768,12.116,0Z"
                transform="translate(-51.267 -107.127)"/>
        <g transform="translate(316.499 292.936)">
          <g transform="translate(0 0)">
            <path style={{ fill: "#303030" }}
                  d="M356.707,355.391a.6.6,0,0,1-.372-1.081l2.056-1.6-2.125-1.25a.6.6,0,0,1,.612-1.042l2.891,1.7a.6.6,0,0,1,.065,1l-2.757,2.148A.6.6,0,0,1,356.707,355.391Z"
                  transform="translate(-355.968 -350.333)"/>
          </g>
        <g transform="translate(12.754 0)">
          <path style={{ fill: "#303030" }}
                d="M435.6,355.391a.6.6,0,0,1-.371-.128l-2.757-2.148a.6.6,0,0,1,.065-1l2.891-1.7a.6.6,0,1,1,.612,1.042l-2.125,1.25,2.055,1.6a.6.6,0,0,1-.372,1.081Z"
                transform="translate(-432.241 -350.334)"/>
        </g>
      </g>
    </g>
  </svg>;

const confused_face = sz =>
  <svg xmlns="http://www.w3.org/2000/svg"
       style={{ width: sz, height: sz }}
       width="40.51" height="40.51" viewBox="0 0 40.51 40.51">
    <title>Confused face</title>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <g transform="translate(0)">
            <circle style={{ fill: "#ffd05d" }}
                    cx="14.322" cy="14.322" r="14.322"
                    transform="translate(0 20.255) rotate(-45)"/>
          </g>
          <g transform="translate(8.186 8.031)">
            <path style={{ fill: "#fdc453" }}
                  d="M1267.8,524.432a14.325,14.325,0,0,1-19.562,19.922,14.324,14.324,0,1,0,19.563-19.922Z"
                  transform="translate(-1248.235 -524.432)"/>
          </g>
          <g transform="translate(6.889 6.99)">
            <path style={{ fill: "#ffd77f" }}
                  d="M1241.509,533.015a13.3,13.3,0,0,1,23.838-8.106,13.3,13.3,0,1,0-22.071,14.733A13.238,13.238,0,0,1,1241.509,533.015Z"
                  transform="translate(-1240.519 -518.239)"/>
          </g>
        </g>
      </g>
      <g transform="translate(10.057 12.997)">
        <g transform="translate(0 0.877)">
          <ellipse style={{ fill: "#fff" }}
                   cx="3.284" cy="3.284" rx="3.284" ry="3.284"
                   transform="translate(0 6.482) rotate(-80.783)"/>
          <path style={{ fill: "#303030" }}
                d="M1274.985,563.025a3.272,3.272,0,0,0-3.961-.491.814.814,0,0,0-.177.425c-.064.685.82,1.328,1.974,1.436s2.143-.359,2.207-1.044A.775.775,0,0,0,1274.985,563.025Z"
                transform="translate(-1268.916 -561.597)"/>
        </g>
        <g transform="translate(11.985 0)">
          <circle style={{ fill: "#fff" }}
                  cx="3.284" cy="3.284" r="3.284"
                  transform="translate(0 4.644) rotate(-45)"/>
          <path style={{ fill: "#303030" }}
                d="M1351.529,563.025a3.272,3.272,0,0,0-3.961-.491.814.814,0,0,0-.177.425c-.065.685.82,1.328,1.974,1.436s2.143-.359,2.207-1.044A.779.779,0,0,0,1351.529,563.025Z"
                transform="translate(-1344.583 -560.721)"/>
        </g>
      </g>
      <path style={{ fill: "#5e4939" }}
            d="M1298.507,608.622a5.768,5.768,0,0,0-2.707-1.44,7.313,7.313,0,0,0-3.089-.057,7.736,7.736,0,0,0-2.836,1.176,6.718,6.718,0,0,0-2.083,2.19.336.336,0,0,0,.44.468l.314-.155c.737-.365,1.434-.71,2.141-.986a13.431,13.431,0,0,1,2.407-.715,11.854,11.854,0,0,1,2.48-.213,15.547,15.547,0,0,1,2.636.3.336.336,0,0,0,.3-.569Z"
            transform="translate(-1272.922 -585.085)"/>
    </g>
  </svg>

const smiley_face = sz =>
  <svg xmlns="http://www.w3.org/2000/svg"
       style={{ width: sz, height: sz }}
       width="40.51" height="40.51" viewBox="0 0 40.51 40.51">
    <title>Smiley face</title>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <circle style={{ fill: "#ffd05d" }}
                  cx="14.322" cy="14.322" r="14.322"
                  transform="translate(0 20.255) rotate(-45)"/>
        <g transform="translate(8.186 8.031)">
          <path style={{ fill: "#fdc453" }}
                d="M125.675,742.414a14.324,14.324,0,0,1-19.562,19.922,14.324,14.324,0,1,0,19.562-19.922Z"
                transform="translate(-106.113 -742.414)"/>
        </g>
        <g transform="translate(6.889 6.99)">
          <path style={{ fill: "#ffd77f" }}
                d="M99.387,751a13.3,13.3,0,0,1,23.838-8.106,13.3,13.3,0,1,0-22.071,14.734A13.235,13.235,0,0,1,99.387,751Z"
                transform="translate(-98.397 -736.221)"/>
        </g>
      </g>
      <path style={{ fill: "#5e4939" }}
            d="M139.01,827.374a.473.473,0,0,0-.769.516,7.179,7.179,0,0,0,13.277.132.472.472,0,0,0-.763-.521C148.659,829.532,144.209,832.424,139.01,827.374Z"
            transform="translate(-124.626 -804.952)"/>
      </g>
      <g transform="translate(13.923 15.913)">
        <g transform="translate(0 0)">
          <path style={{ fill: "#303030" }}
                d="M142.367,791.1c0,.982-.473,1.779-1.055,1.779s-1.055-.8-1.055-1.779.473-1.779,1.055-1.779S142.367,790.12,142.367,791.1Z"
                transform="translate(-140.256 -789.324)"/>
          <g transform="translate(10.553)">
            <path style={{ fill: "#303030" }}
                  d="M205.171,791.1c0,.982-.472,1.779-1.055,1.779s-1.055-.8-1.055-1.779.473-1.779,1.055-1.779S205.171,790.12,205.171,791.1Z"
                  transform="translate(-203.06 -789.324)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>;

const happy_face = sz =>
  <svg xmlns="http://www.w3.org/2000/svg"
       style={{ width: sz, height: sz }}
       width="64.815" height="64.815" viewBox="0 0 64.815 64.815">
    <title>Happy face</title>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <circle style={{ fill: "#ffd05d" }}
                  cx="22.916" cy="22.916" r="22.916"
                  transform="translate(0 32.407) rotate(-45)"/>
        </g>
        <g transform="translate(13.097 12.849)">
          <path style={{ fill: "#fdc453" }}
                d="M137.412,88.469a22.919,22.919,0,0,1-31.3,31.875,22.917,22.917,0,1,0,31.3-31.875Z"
                transform="translate(-106.113 -88.469)"/>
        </g>
        <g transform="translate(11.023 11.184)">
          <path style={{ fill: "#ffd77f" }}
                d="M99.98,105.917a21.275,21.275,0,0,1,38.141-12.969,21.277,21.277,0,1,0-35.314,23.573A21.176,21.176,0,0,1,99.98,105.917Z"
                transform="translate(-98.397 -82.276)"/>
        </g>
      </g>
      <g transform="translate(22.277 24.002)">
        <g transform="translate(0 0)">
          <path style={{ fill: "#303030" }}
                d="M143.633,132.8c0,1.572-.756,2.846-1.688,2.846s-1.689-1.274-1.689-2.846.756-2.846,1.689-2.846S143.633,131.227,143.633,132.8Z"
                transform="translate(-140.256 -129.953)"/>
          <g transform="translate(16.885)">
            <path style={{ fill: "#303030" }}
                  d="M206.437,132.8c0,1.572-.756,2.846-1.688,2.846s-1.689-1.274-1.689-2.846.756-2.846,1.689-2.846S206.437,131.227,206.437,132.8Z"
                  transform="translate(-203.06 -129.953)"/>
          </g>
        </g>
      </g>
      <path style={{ fill: "#695342" }}
            d="M120.664,169.28a15.5,15.5,0,0,0,30.8,0Z"
            transform="translate(-103.654 -134.705)"/>
      <path style={{ fill: "#f06669" }}
            d="M138.376,198.524a15.483,15.483,0,0,0,20.91.335l-.142-.333C148.8,193.483,138.517,198.455,138.376,198.524Z"
            transform="translate(-116.605 -154.438)"/>
    </g>
  </svg>;

const laughing_face = sz =>
  <svg xmlns="http://www.w3.org/2000/svg"
       style={{ width: sz, height: sz }}
       width="40.49" height="40.49" viewBox="0 0 40.49 40.49">
    <title>Laughing face</title>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <circle style={{ fill: "#ffd05d" }}
                  cx="14.315" cy="14.315" r="14.315"
                  transform="translate(0 20.245) rotate(-45)"/>
        <g transform="translate(8.182 8.027)">
          <path style={{ fill: "#fdc453" }}
                d="M1267.788,306.451a14.318,14.318,0,0,1-19.553,19.912,14.317,14.317,0,1,0,19.553-19.912Z"
                transform="translate(-1248.235 -306.451)"/>
        </g>
        <g transform="translate(6.886 6.987)">
          <path style={{ fill: "#ffd77f" }}
                d="M1241.509,315.027a13.29,13.29,0,0,1,23.826-8.1,13.292,13.292,0,1,0-22.06,14.726A13.229,13.229,0,0,1,1241.509,315.027Z"
                transform="translate(-1240.519 -300.258)"/>
        </g>
        </g>
        <g transform="translate(11.781 15.397)">
          <path style={{ fill: "#303030" }}
                d="M1270.408,355.413a.607.607,0,0,1-.373-1.086l2.065-1.609-2.135-1.255a.607.607,0,1,1,.615-1.046l2.9,1.708a.607.607,0,0,1,.065,1l-2.769,2.158A.6.6,0,0,1,1270.408,355.413Z"
                transform="translate(-1269.666 -350.333)"/>
        <g transform="translate(12.81 0)">
          <path style={{ fill: "#303030" }}
                d="M1349.315,355.413a.6.6,0,0,1-.372-.128l-2.769-2.158a.607.607,0,0,1,.065-1l2.9-1.708a.607.607,0,0,1,.615,1.046l-2.134,1.255,2.064,1.609a.607.607,0,0,1-.373,1.086Z"
                transform="translate(-1345.939 -350.334)"/>
        </g>
      </g>
    </g>
    <g transform="translate(3.418 19.738)">
      <g transform="translate(25.546 0.092)">
        <path style={{ fill: "#7cc4e8" }}
              d="M1372.307,376.726c-1.059,6.355.8,8.474.8,8.474a3.77,3.77,0,1,0,6.415-3.962S1378.463,378.624,1372.307,376.726Z"
              transform="translate(-1371.981 -376.726)"/>
        <path style={{ fill: "#62bbe8" }}
              d="M1391.489,397.356a3.77,3.77,0,0,0,4.267-5.6,6.237,6.237,0,0,0-2.438-2.388C1395.248,393.922,1393.1,396.292,1391.489,397.356Z"
              transform="translate(-1388.213 -387.246)"/>
      </g>
      <g transform="translate(0 0)">
        <path style={{ fill: "#7cc4e8" }}
              d="M1227.5,376.178c-6.11,2.043-7.109,4.681-7.109,4.681a3.77,3.77,0,0,0,6.507,3.81S1228.709,382.506,1227.5,376.178Z"
              transform="translate(-1219.873 -376.178)"/>
        <path style={{ fill: "#62bbe8" }}
              d="M1220.34,409.371a3.77,3.77,0,0,0,6.946,1.145,6.236,6.236,0,0,0,.966-3.273C1225.136,411.081,1222.037,410.3,1220.34,409.371Z"
              transform="translate(-1220.262 -402.026)"/>
      </g>
    </g>
      <g transform="translate(12.044 22.142)">
        <path style={{ fill: "#695342" }}
              d="M1271.233,390.493a8.254,8.254,0,0,0,16.4,0Z"
              transform="translate(-1271.233 -390.493)"/>
        <path style={{ fill: "#f06669" }}
              d="M1286.333,414.7a8.246,8.246,0,0,0,11.136.178l-.076-.177C1291.886,412.018,1286.408,414.666,1286.333,414.7Z"
              transform="translate(-1283.797 -409.637)"/>
      </g>
    </g>
  </svg>;


const SadfaceIcon = FaceIcon(2, sad_face);
const ConfusedfaceIcon = FaceIcon(2, confused_face);
const SmileyfaceIcon = FaceIcon(2, smiley_face);
const HappyfaceIcon = FaceIcon(2, happy_face);
const LaughingfaceIcon = FaceIcon(2, laughing_face);

export { SadfaceIcon, ConfusedfaceIcon, SmileyfaceIcon,
         HappyfaceIcon, LaughingfaceIcon };
