import { get_subobj, rm_attr, size, clone } from './utils';
import { GoodPromise, BadPromise } from './utils'; // eslint-disable-line no-unused-vars
import { consolelog } from './utils'; // eslint-disable-line no-unused-vars
import { ALPHA_TEST, PROD_MODE, FS_MODE, config$max_logsize } from './config';
import { DB_ERR, } from './enums';
import { auth, no_auth, firebase } from './App';
import { thread_id, pvtfields, pubfields, fbDateTime, approx_size }
  from './dbstructure';
import { warn, contact_id } from './dbstructure';

const FSQDEBUG = false;

// Uniform error message structure.
// These are the types of value that are "thrown" when
// a promise is rejected:
// caller provides api ('db', 'auth', 'storage), op (name of function),
// and possibly additional info (an object)
// 'args' comes from the api error
// msg is a string often included as 'msg' or 'code' in api error
function format_error(api, op, info) {
  return args => ({
    api: api,
    op: op,
    args: args,
    msg: args.msg || args.code || 'no-msg',
    ...(info && {info: info})
  });
}

// f is function string => { api, op, args, message }
function add_catch(promise_f, f) {
  return new Promise(
    (resolve, reject) =>
      promise_f().then(r => resolve(r))
                 .catch(err => {
                    // error cannot go into db because it is a "custom object"
                    const err1 = JSON.parse(JSON.stringify(f(err)));
                    warn(err1);
                    reject(err1);
                  })
          );
}

// op is 'name' of function (e.g. 'PrivateProfile[24].update')
// args is error result returned from call. in auth and fs, this
// always include a 'code' field
function c(promise_f, api, op, info) {
  return add_catch(promise_f, format_error(api, op, info));
}

class FSAuth {

  constructor(auth) {
    this.auth = auth;
  }

  // errors: auth/{auth/invalid-email auth/user-disabled,
  //               auth/user-not-found, auth/wrong-password }
  // note that firebase auth emits an error on the console for
  // bad signin. this seems to be wai and not preventable
  signInWithEmailAndPassword(m, p) {
    return c(() => this.auth.signInWithEmailAndPassword(m, p),
             'auth', 'signInWithEmailAndPassword',
             { email: m });
  }

  // errors: auth/operation-not-allowed
  signInAnonymously() {
    return c(() => this.auth.signInAnonymously(),
             'auth', 'signInAnonymously');
  }

  // not promise-returning
  onAuthStateChanged(f) {
    return this.auth.onAuthStateChanged(f);
  }

  // errors: auth/{invalid-persistence-type, unsupported-persistence-type}
  setPersistence(x) {
    return c(() => this.auth.setPersistence(x),
             'auth', 'setPersistence');
  }

  // used only for debugging. regular code uses signInAnonymously
  // and then linkWithCredentials
  createUserWithEmailAndPassword(m, p) {
    return this.auth.createUserWithEmailAndPassword(m, p);
  }

  fetchSignInMethodsForEmail(m) {
    return this.auth.fetchSignInMethodsForEmail(m);
  }

  // errors: none
  // according to docs, signOut cannot fail, so not handling it
  signOut() {
    return this.auth.signOut();
  }

  sendPasswordResetEmail(m) {
    return this.auth.sendPasswordResetEmail(m);
  }

  // gets and sets do not return promises
  get currentUser() {
    return this.auth.currentUser;
  }

  get EmailAuthProvider() {
    return this.auth.EmailAuthProvider;
  }

  set currentUser(u) {
    return this.auth.currentUser = u;
  }

  // debug only
  _restoreSignin(u) {
    return this.auth._restoreSignin(u);
  }
}

// f is of type firebase.storage.Reference
const storage_put = (f, s) =>
  new Promise((resolve, reject) => {
    let uploadTask = f.put(s);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
      error: error => {
        reject({
            api: 'storage',
            op: 'put',
            args: error,
            msg: error.message
          });
      },
      complete: () => {
        if (uploadTask.snapshot.bytesTransferred === 0) {
          reject({
              api: 'storage',
              op: 'put',
              args: {},
              msg: 'Unable to upload image'
            });
        }
        resolve(uploadTask.snapshot);
      }
    });
  });


// log_fsquery - in small-scale (alpha) tests, we store *all* queries
// in the database - in 'Admin_queries' - for analysis purposes.
// Rather than write each operation as a separate document, we
// gather several in an array and wait until it is "full".
// (if it never gets this full, we just lose it; this is just for
// statistical analysis, so not worth the trouble to make sure we
// get every single query.)
// for testing, it's easier to use a small chunk size; for
// actual work, we want the chunk size big enough to not write a lot
// of documents, but small enough that we don't drop too many.
const QUERIES_TO_WRITE = PROD_MODE ? 25 : 2;
let queries = [];

function log_fsquery(db, op, what, args) {
  if (! ALPHA_TEST) {
    return;
  }
  const user = auth && auth.currentUser;
  const args_s = args ? JSON.stringify(args) : '';
  const query_rec = {
    op: op,
    what: what,
    args: args_s,
    when: fbDateTime(),
    email: (user && user.email) || '',
    uid: (user && user.uid) || '',
  }
  queries.push(query_rec);
  if (queries.length >= QUERIES_TO_WRITE) {
    const key = `${query_rec.uid}|${query_rec.when}`;
    db.collection('Admin_queries')
      .doc(key)
      .set({ queries: queries });
    queries = [];
  }
}

// FSQuery class represents queries from app.  App sends query to Query, and
// Query sends query (or possible several, depending) to Database.
// Every query returns a promise. The promise may include then clauses,
// but never catch clauses; clients should catch any errors.
//
// Client provides db as ctor argument. This may be either an fsdb instance
// or an actual firestore instance. In a very few cases, queries behave
// differently for these two; boolean FS_MODE is true iff db is firestore.
class FSQuery {

  constructor(fsdb) {
    this.db = fsdb;

    // map "coll.id" -> unsubscribe function
    this._unsubscribe = {};
    // map "coll.id" -> bool; initially maps to true, switches
    // to false on first probe
    this._just_subscribed = {};

    // methods
    this.subscribe = this.subscribe.bind(this);
    this.subscribeAll = this.subscribeAll.bind(this);
    this.just_subscribed = this.just_subscribed.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.unsubscribeAll = this.unsubscribeAll.bind(this);
    this.get = this.get.bind(this);
    this.add = this.add.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
    this.admin = this.admin.bind(this);
    this.missing_args = this.missing_args.bind(this);
    this.save = this.save.bind(this);

    // used to sanity-check queries; see missing_args above
    this.reqd_args = {
      get: {
        user_by_uid: ['uid'],
        fresh_id: [],
        jokes: [],
        message_thread: ['user', 'other_user'],
        messages: ['user', 'other_user'],
        potential_matches: ['conditions'],
        profiles: ['ids'],
      },
      add: {
        user: ['id'],
        message: ['message', 'first_message'],
        vote: ['user', 'joke', 'vote', 'flag', 'flag_comment'],
        poke: ['user', 'other_user'],
        blacklist: ['user', 'other_user'],
        matches: ['user', 'other_users'],
        joke: ['id'],
        jokestats: ['id'],
        userreport: ['reporter', 'subject', 'date', 'complaint', 'location'],
        prospective: ['date'], // date is key for docs in this collection
        contact: ['type', 'date', 'subject', 'from', 'text'],
        log: ['level'],
      },
      update: {
        user: ['id'],
        other: ['id', 'oid'],
        message_thread: ['user', 'other_user'],
        joke: ['id'],
        jokestat: ['joke', 'vote'],
        jokereport: ['id', 'joke', 'flag', 'flag_comment'],
      },
      remove: {
        pokes: ['user', 'other_user'],
        others: ['user', 'other_users'],
        matches: ['user', 'other_users'],
      },
      admin: {
        anon_users: [],
        update_userreport: ['key', 'date', 'outcome', 'comment', 'admin_name'],
        rm_user: ['id', 'uid'],
        get_contacts: ['wheres'],
        update_contact: ['msgid'],
        get_queries: [],
        get_collection: ['collection'],
      },
    }
  }

  // queries receive a sanity check. this is just for debugging, and
  // throws an uncaught error
  missing_args(op, what, args) {
    const reqd = this.reqd_args[op][what];
    if (reqd === undefined) {
      throw new Error(
        `Error ${DB_ERR.BAD_QUERY}: no such query: '${op}/${what}'`);
    }
    const missing = reqd.filter(p => ! args.hasOwnProperty(p));
    if (missing.length) {
      throw new Error(
        `Error ${DB_ERR.MISSING_ARG}: '${op}/${what}' missing required arg(s):
         ${ missing.join(', ') }`);
    }
  }

  // for tests, which are local, we try to mimic the persistence of the db
  // by storing the current state in localStorage. this permits us to,
  // for example, restore the state when we return from off-site, just
  // as we do in production. all add/update/remove ops should end with
  // a call to 'save'.
  save(result) {
    if (! PROD_MODE) {
      localStorage.setItem('laughstruck_test_db', JSON.stringify(this.db.db));
    }
    return result;
  }

  // technical problem: when one subscribes to a document, the very first
  // thing that happens is that the callback function is called, which
  // allows us to get an initial look at the document. good in principle,
  // but we don't want this, because we do our own initialization before
  // subscribing. see listeners.js for usage of this method.
  just_subscribed (coll, id) {
    const key = `${coll}.${id}`;
    if (this._just_subscribed[key]) {
      this._just_subscribed[key] = false;
      return true;
    } else {
      return false;
    }
  }

  // subscribe to collection 'coll.id' with callback f
  subscribe(coll, id, f) {
    const key = `${coll}.${id}`;
    // ignore if already subscribed (shouldn't happen)
    if (! this._unsubscribe[key]) {
      this._just_subscribed[key] = true;
      this._unsubscribe[key] = this.db.collection(coll)
                                      .doc(id)
                                      .onSnapshot({ next: f })
      // simply to correctly simulate behavior of live db
      // this call in fact is detected as the first one (just_subscribed)
      // and does nothing. in FS_MODE, the underlying db makes this call
      // (with the same effect). 'external_update' is explained in TECH NOTE
      // in cypress/integration/pages.spec.js.
      if (! FS_MODE) {
        global.external_update = true;
        const snapshot = this.db.collection(coll).doc(id).get_snapshot();
        f(snapshot);
        global.external_update = false;
      }
    }
  }

  subscribeAll(docfuns) {
    docfuns.forEach(docfun => this.subscribe(...docfun));
  }

  // document key is either coll or, if id defined, 'coll.id'
  unsubscribe(coll, id) {
    const key = id ? `${coll}.${id}` : coll;
    if (this._unsubscribe[key]) {
      this._unsubscribe[key]();
      this._unsubscribe[key] = null;
      this._just_subscribed[key] = null;
    }
  }

  unsubscribeAll() {
    Object.keys(this._unsubscribe)
          .forEach(key => this.unsubscribe(key));
  }

  get(what, args) {
    if (FSQDEBUG) consolelog('fsq261', what, args)
    // errors: no error are possible here; per doc, only
    //     error is if doc does not exist, but these docs do.
    //     in the case of get('profiles'), it is conceivable
    //     that they don't exist, but in that case we simply
    //     omit that user from the result array
    if (! PROD_MODE) this.missing_args('get', what, args);
    if (ALPHA_TEST) log_fsquery(this.db, 'get', what, args);

    switch (what) {
      case 'jokes': {
        // get all jokes, in order of index in 'jokes' object
        // this is confusing: in firebase console, in Jokes/jokes
        // doc, jokes are ordered alphabetically, so joke 10 comes
        // before joke 3, but get delivers them in numerical order.
        // no error is possible
        return this.db.collection('Jokes').doc('jokes').get()
                   .then(jokes => Object.values(jokes.data())
                                        .filter(j => j.active));
      }

      case 'fresh_id': {
        // no error (transaction fails only if one of the db ops in it fails)
        //
        // firestore allows only one write/sec to any document, so
        // to reduce contention on nextid, we "shard" it with ten
        // docs, nextid0,..., nextid9, initialized to 10, 1, 2, ..., and
        // then incremented by 10. we choose a bucket randomly.
        // if not in PROD_MODE, we're testing, so prefer predictability
        const bucket = PROD_MODE ? Math.floor(Math.random() * Math.floor(10))
                                 : 1;
        let iddocref = this.db.collection('NextId').doc(`nextid${bucket}`);
        // avoid the classic race condition where two people simultaneously
        // read and update the 'nextid' in the db, by using a transaction.
        return (
          this.db.runTransaction(tr => {
            return (
              tr.get(iddocref)
                .then(iddoc => {
                  const id = iddoc.data().nextid;
                  tr.update(iddocref, { nextid: id + 10 });
                  return id + '';
                })
            );
          })
          .then(id => { this.save(); return id; })
        );
      }

      case 'user_by_uid': {
        // no error
        return this.db.collection('PrivateProfiles')
               .where('uid', '==', args.uid)
               .get()
               .then(u => { let pvt;
                            // iterates at most once, so pvt can = undef
                            u.forEach(v => { pvt = v.data(); });
                            return pvt; })
               .then(pvt => {
                 if (pvt) {
                    return this.db.collection('PublicProfiles')
                               .doc(pvt.id)
                               .get()
                               .then(pub => [{...pvt, ...pub.data()}] );
                  } else {
                    return [];
                  }
                });
      }

      // require: args.ids non-empty
      case 'profiles': {
        // returns the public profile of ids
        // they should always exist (even if inactive), but we
        // still check jic. (should remove these ids from user's
        // profile, but since this should never happen, won't bother)
        return Promise.all(
            args.ids.map(id => this.db.collection('PublicProfiles')
                                   .doc(id)
                                   .get())
          )
          .then(ps => (ps.filter(p => p.exists)
                         .map(p => p.data())));
      }

      case 'messages': {
        // download all messages between user and other_user
        return (
          this.db
              .collection('Messages')
              .doc(thread_id(args.user, args.other_user))
              .get()
              .then(ms => {
                if (! ms.data()) { // thread does not exist (shouldn't happen)
                  return [];
                } else {
                  return ms.data().messages;
                }
              })
        );
      }

      case 'potential_matches': {
        // args.conditions is array of arrays of [fld, op, val]
        // create as many queries as length of arg.conditions
        const mk_query = conds => {
          let query = this.db.collection('PublicProfiles');
          for (var [fld, op, val] of conds) {
            query = query.where(fld, op, val);
          }
          return query;
        }
        const queries = args.conditions.map(mk_query);
        const promises = queries.map(q =>
          q.get().then(users => {
            let r = [];
            users.forEach(u => r.push(u.data()));
            return r;
          }));
        return Promise.all(promises)
                      .then(rs => [].concat(...rs));
      }

      default: {
        throw new Error(`No such query: get/${what}`);
      }
    }

  }

  add(what, args) {
    if (FSQDEBUG) consolelog('fsq394', what, args)
    // errors: per docs, set cannot fail; update fails if doc
    // does not exist
    if (! PROD_MODE) this.missing_args('add', what, args);
    if (ALPHA_TEST) log_fsquery(this.db, 'add', what, args);

    switch (what) {
      case 'user': {
        const id = args.id;
        let pvt_part = get_subobj(args, pvtfields);
        const pub_part = get_subobj(args, pubfields);
        return (
          Promise.all(
            [ this.db.collection('PrivateProfiles')
                  .doc(id).set(pvt_part),
              this.db.collection('PublicProfiles')
                  .doc(id).set(pub_part),
            ])
            .then(() => this.save())
          );
      }

      // args = message object: user, other_user, thread_id, msg, date
      case 'message': {
        const msg = args.message;
        const date = msg.date;

        const mod_threads = (id, oid) => {
          const pvtdoc = this.db.collection('PrivateProfiles').doc(id);
          const otherdoc = this.db.collection('PrivateProfiles').doc(oid);
          return (
            Promise.all([
              pvtdoc.set({ others: { [oid]: { thread: true, last_sent: date } } },
                         { merge: true }),
              otherdoc.set({ others: { [id]: { thread: true, last_rcvd: date } } },
                           { merge: true })
            ])
            .then(() => this.save())
          );
        }

        const msgdoc = this.db.collection('Messages').doc(msg.thread_id);
        // add msg before updating thread; when other user is informed
        // of thread update, they will read msgs, so new msg should be there
        const q =
          (args.first_message
          ? msgdoc.set({ messages: [ msg ] })
          : msgdoc.update(
              { messages: (FS_MODE ? firebase.firestore : this.db)
                          .FieldValue.arrayUnion(msg)
              }
          ))
          .then(() => mod_threads(msg.user, msg.other_user));

        return q.then(() => this.save())
      }

      case 'vote': {
        const pubdoc = this.db.collection('PublicProfiles')
                              .doc(args.user);
        const vote = get_subobj(args, ['vote', 'flag', 'flag_comment'])
        return (
          pubdoc
            .update({ [`votes.${args.joke}`]: vote })
            .then(() => this.save())
        );
      }

      // if this blacklist already exists, client should use update instead
      // TODO: there is no update('blacklist'); what's going on?
      case 'blacklist': {
        const pvtdoc = this.db.collection('PrivateProfiles')
                              .doc(args.user + '');
        const otherdoc = this.db.collection('PrivateProfiles')
                                .doc(args.other_user + '');
        return (
          Promise.all([
            pvtdoc.update({ [`others.${args.other_user}.blacklist`]: true }),
            pvtdoc.update({ [`others.${args.other_user}.blacklist_time`]:
                                                      args.blacklist_time }),
            otherdoc.update({ [`others.${args.user}.blacklisted_by`]: true })
          ])
          .then(() => this.save())
        );
      }

      case 'poke': {
        const pvtdoc = this.db.collection('PrivateProfiles')
                              .doc(args.user + '');
        const otherdoc = this.db.collection('PrivateProfiles')
                                .doc(args.other_user + '');
        return (
          Promise.all(
            [
              pvtdoc.update({ [`others.${args.other_user}.pokes`]: true}),
              otherdoc.update({ [`others.${args.user}`]: {
                                                  pokedby: true,
                                                  pokedby_seen: false,
                                                  poketime: args.poketime } })
            ])
          .then(() => this.save())
          );
      }

      case 'matches': {
        const pvtdoc = this.db.collection('PrivateProfiles')
                              .doc(args.user + '');
        return pvtdoc.update(
            Object.fromEntries(
              args.other_users.map(id => [`others.${id}.match`, true])
            )
          ).then(() => this.save());
      }

      case 'joke': {
        const jokes = this.db.collection('Jokes').doc('jokes');
        return jokes.update({ [args.id]: args })
                    .then(() => this.save())
      }

      // add stats for one joke (args.id)
      case 'jokestats': {
        const jokestats = this.db.collection('JokeStats');
        return jokestats.doc(args.id).set(args)
                        .then(() => this.save())
      }

      case 'userreport': {
        const key = `${args.reporter}-${args.subject}-${args.date}`;
        const doc = this.db.collection('Admin_userreports').doc(key);
        return doc.set(args)
                  .then(() => this.save());
      }

      case 'prospective': {
        return this.db.collection('Admin_prospective')
                   .doc('prospects')
                   .set({ [args.date]: args }, { merge: true })
                   .then(() => this.save());
      }

      case 'contact': {
        const id = contact_id(args);
        const args1 = { ...args, msgid: id };
        return this.db.collection('Admin_contact')
                   .doc(id)
                   .set(args1, { merge: false })
                   .then(() => this.save());
      }

      case 'log': {
        const logcoll = this.db.collection('Admin_logs');
        const sizedoc = logcoll.doc('current');
        // datetime is ISO format, stored in db
        const datetime = fbDateTime();
        // today is US format, used as key (replace '/'s - not
        // permitted in document names in fs)
        const today = new Date(datetime).toLocaleDateString()
                                        .replaceAll('/', '_');
        const uid = no_auth() ? 'no-user' : auth.currentUser.uid;
        const logentry = { ...args, date: datetime, uid: uid }
        const logsize = approx_size(logentry);
        const threshold = config$max_logsize;

        // not returning promise here, because we don't want to wait
        // note that app never reads logs

        // only read is to get sizes; then update sizes, either
        // add new doc for this log, or update existing doc
        // first, get and possibly update sizes
        this.db.runTransaction(tr => (
          tr.get(sizedoc)
          .then(u => {
            const current = u.data(); // { idx: size }
            let newdoc, newidx, newsz;
            if (current[today]) {
              const [ idx, sz ] = current[today];
              if (sz + logsize > threshold) { // curr doc full, add new doc
                [newdoc, newidx, newsz] = [ true, idx + 1, logsize ];
              } else { // curr doc not full, append to it
                [newdoc, newidx, newsz] = [ false, idx, sz + logsize ];
              }
            } else { // no entry for today
              [newdoc, newidx, newsz] = [true, 0, logsize]
            }
            tr.set(sizedoc, { [today]: [ newidx, newsz ] });
            return [ newdoc, newidx, newsz ];
          })
          .then(([ newdoc, idx, sz ]) => {
            if (newdoc) { // create new log doc
              tr.set(logcoll.doc(`${today}_${idx}`),
                     { logs: [ logentry ]});
            } else { // add to existing log doc
              tr.update(logcoll.doc(`${today}_${idx}`),
                        { logs: (FS_MODE ? firebase.firestore : this.db)
                                     .FieldValue.arrayUnion(logentry) });
            }
          })))
        .then(() => this.save());
        break;
      }

      default: { // debugging only; uncaught exception
        throw new Error(`No such query: add/${what}`);
      }
    }
  }

  update(what, args) {
    if (FSQDEBUG) consolelog('fsq607', what, args)
    // errors: per docs, set cannot fail; update fails if doc
    // does not exist
    if (! PROD_MODE) this.missing_args('update', what, args);
    if (ALPHA_TEST) log_fsquery(this.db, 'update', what, args);

    switch (what) {
      case 'user': {
        const id = args.id;
        args = rm_attr('id', args);
        const pvt_part = get_subobj(args, pvtfields);
        const pub_part = get_subobj(args, pubfields);
        let pvt_promise, pub_promise;
        if (size(pvt_part) > 0) {
          const pvtdoc = this.db.collection('PrivateProfiles')
                                .doc(id);
          pvt_promise = pvtdoc.update(pvt_part);
        }
        if (size(pub_part) > 0) {
          const pubdoc = this.db.collection('PublicProfiles')
                                .doc(id);
          pub_promise = pubdoc.update(pub_part);
        }
        return Promise.all(pvt_promise
                           ? pub_promise
                             ? [ pvt_promise, pub_promise ]
                             : [ pvt_promise ]
                           : pub_promise
                             ? [ pub_promise ]
                             : [ ])
                      .then(() => this.save());
      }

      case 'other': {
        let { id, oid, ...rest } = args;
        return this.db.collection('PrivateProfiles')
                      .doc(id)
                      .set({ others: { [oid]: rest } }, { merge: true })
                      .then(() => this.save());
      }

      // called only to update our own thread after reading new messages
      // other users' message threads are updated by us when message sent
      case 'message_thread': {
        const id = args.user,
              oid = args.other_user;
        const pvtdoc = this.db.collection('PrivateProfiles').doc(id);
        return (
          pvtdoc.set({ others: { [oid]: args } }, { merge: true })
                .then(() => this.save())
          );
      }

      // this is actually identical to 'add'
      case 'joke': {
        const jokes = this.db.collection('Jokes').doc('jokes');
        return jokes.update({ [args.id]: args })
                    .then(() => this.save())
      }

      case 'jokestat': {
        const jstat = this.db.collection('JokeStats').doc(args.joke);
        const incr = (FS_MODE ? firebase.firestore : this.db)
                      .FieldValue.increment(1);
        return (
          jstat.update({ count: incr,
                         [`votes.${args.vote}`]: incr })
               .then(() => this.save())
        );
      }

      case 'jokereport': {
        const { joke, vote, ...report } = args; // eslint-disable-line no-unused-vars
        const jstat = this.db.collection('JokeStats').doc(joke);
        const incr = (FS_MODE ? firebase.firestore : this.db)
                      .FieldValue.increment(1);
        const add_report = (FS_MODE ? firebase.firestore : this.db)
                            .FieldValue.arrayUnion(report);
        return (
          jstat.update({ count: incr,
                         reports: add_report })
               .then(() => this.save())
        );
      }

      default: {
        throw new Error(`No such query: update/${what}`);
      }
    }
  }

  remove(what, args) {
    if (FSQDEBUG) consolelog('fsq697', what, args);
    if (! PROD_MODE) this.missing_args('remove', what, args);
    if (ALPHA_TEST) log_fsquery(this.db, 'remove', what, args);

    switch (what) {
      case 'others': {
        // remove users from 'others'
        // args: user = me, other_users = list of ids
        const pvtdoc = this.db.collection('PrivateProfiles')
                              .doc(args.user);
        const del = (FS_MODE ? firebase.firestore : this.db)
                     .FieldValue.delete();
        return (
          pvtdoc.update(Object.fromEntries(
            args.other_users.map(id => [`others.${id}`, del])
          ))
          .then(() => this.save())
        )
      }

      default: {
        throw new Error(`No such query: remove/${what}`);
      }
    }
  }

  // admin function includes any db query that is performed only
  // by admins. it is not further divided into adds, reads, etc.
  admin(what, args) {
    if (! PROD_MODE) this.missing_args('admin', what, args);
    if (ALPHA_TEST) log_fsquery(this.db, 'admin', what, args);

    // this can really never happen because these operations are
    // performed only on admin page, and only admins can get to it
    if (FS_MODE &&
        ! (auth.currentUser
           && [ 'LcLpDbvuohQC77xpb4pX0IYWfFD2',
                'vue7jhOy6ePeuKUWA0m1OhVn6Ni1'
              ].includes(auth.currentUser.uid))) {
      throw new Error('Unauthorized user attempting admin db op')
    }

    switch (what) {
      case 'anon_users': {
        return this.db.collection('PrivateProfiles')
               .where('email', '==', '')
               .get()
               .then(u => { let pvt = [];
                            u.forEach(v => { pvt.push(v.data()); });
                            return pvt;
                          });
      }

      // this query is not currently used. intended for admin page,
      // to report resolution of user reports
      case 'update_userreport': {
        const doc = this.db.collection('Admin_userreports').doc(args.key);
        return doc.update({ resolution: rm_attr('key', args) })
                  .then(() => this.save())
      }

      // args = user profile. only id is currently used, but if I can
      // figure out how to delete auth account, will need uid.
      case 'rm_user': {
        const pvtdoc = this.db.collection('PrivateProfiles').doc(args.id);
        const pubdoc = this.db.collection('PublicProfiles').doc(args.id);
        return Promise.all(
          [ pvtdoc.delete(), pubdoc.delete() ]
        ).then(() => this.save())
      }

      case 'get_contacts': {
        let query = this.db.collection('Admin_contact');
        for (var [fld, op, val] of args.wheres) {
          query = query.where(fld, op, val);
        }
        return (
          query.get().then(msgs => {
            let r = [];
            msgs.forEach(m => r.push(m.data()));
            return r;
          })
        );
      }

      case 'update_contact': {
        const doc = this.db.collection('Admin_contact').doc(args.msgid);
        return doc.update(args)
                  .then(() => this.save());
      }

      // return all data in Admin_queries as json object
      case 'get_queries': {
        // local mock db doesn't have 'Admin_queries' collection, so
        // use this to test locally:
        // const c = this.db.collection('PrivateProfiles');
        const c = this.db.collection('Admin_queries');
        return c.get()
                .then(qs => {
                        const entries = qs.docs.map(
                                            doc => [doc.id, doc.data()]);
                        return Object.fromEntries(entries);
                     });
      }

      // return all private profiles. currently used to gen mailing lists
      // returns Promise(QuerySnapshot)
      case 'get_collection': {
        return this.db.collection(args.collection).get();
      }

      default: {
        throw new Error(`No such query: admin/${what}`);
      }
    }
  }

}

// Abortive (so far) effort to permit db updates in cypress tests
// Idea was to launder "illegal data: custom object" errors by
// placing db code within the app and calling it from tests. does not work
class TestQuery {
  constructor (dbquery) {
    this.dbquery = dbquery;
  }
  clone(o) {
    return Object.fromEntries(Object.entries(o));
  }
  get(what, args) { return this.dbquery.get(what, clone(args)); }
  add(what, args) {
    const c = this.clone(args);
    return this.dbquery.add(what, c); } // clone(args)); }
  update(what, args) { return this.dbquery.update(what, this.clone(args)); }
  remove(what, args) { return this.dbquery.remove(what, clone(args)); }
  admin(what, args) { return this.dbquery.admin(what, clone(args)); }
}

export { FSQuery, FSAuth, storage_put, format_error, TestQuery };
