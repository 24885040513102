import React, { useState, useEffect, useRef } from 'react';
import { Link } from '@reach/router';

import { FooterPage } from './RegPage';
import { PopupNote } from './PopupNote';

import ReCAPTCHA from "react-google-recaptcha";

import { TopLogo } from './TopLogo';
import { TwitterIcon } from '../../assets/icons/twitter';
import { QuestionIcon } from '../../assets/icons/question';
import { CheckmarkIcon } from '../../assets/icons/checkmark';
import { VoteSw } from '../widgets/VoteWidget';
import { DropdownSw } from '../widgets/Dropdown';
import { TextSw, NakedTextSw, SnapshotSw, TextareaSw }
  from '../widgets/TextWidget';
import { MultiRadioSw } from '../widgets/RadioButton';

import { JOKE_TYPES } from '../../enums';
import { gender_ui2db, gender_db2ui,
         seeking_ui2db, seeking_db2ui,
         genderpref_db2ui,
         username_changecheck,
  } from '../pages/utils';
import { config$text_limits, CYPRESS_MODE, LOCAL_MODE
  } from '../../config';
import { core_explainer } from './utils';

const BaseSignupVoteUI = ({joke, progress, jokes_left, prev_vote,
                           errmsg, actions}) => {
  // pop-up explanation of core jokes
  const [ coreex, setCoreex ] = useState(false);
  const [ width, setWidth ] = useState(0);

  const progress_ref = useRef();

  useEffect(() => {
    const f = () => {
        if (progress_ref.current && progress_ref.current.clientWidth !== width) {
          setWidth(progress_ref.current.clientWidth);
        }
      };
      f();
    }, [ width ]);

  const vote_ref = useRef();
  const source_icon = joke.source.startsWith('Twitter')
                      ? <TwitterIcon />
                    : <span />;

  return (
      <div className="h-full flex flex-col py-6 items-center">
        <TopLogo />
        <div ref={progress_ref}
             className={`mx-auto flex flex-row space-x-0 w-3/4
                         sm:w-11/12
                         items-center mt-10
                         ${prev_vote ? 'hidden' : ''}`} >
          <div className="relative h-2 bg-white w-full rounded-lg rounded-l-none" >
            <div className="absolute h-2 bg-orange rounded-lg"
                 style={{ width: `${progress * width}px` }} />
          </div>
        </div>
        <div className="mx-auto flex flex-col w-3/4 sm:w-11/12 bg-white
                    space-y-6 rounded-lg mt-3"
                    style={{ boxShadow: "0px 3px 50px #0000000D" }}>
          <div className="flex text-3xl font-semibold text-gray-text justify-center
                      m-9">
            How funny is this?
          </div>
          <div className="mx-auto flex flex-col w-full space-y-2">
            <div className="mx-auto w-4/5 bg-gray-bg rounded-lg">
              <div className="flex font-normal text-base text-gray-text
                              m-6 text-left"
                          style={{ whiteSpace: "pre-line" }}>
                { joke.type === JOKE_TYPES.TEXT
                  ? joke.text.trim()
                  : joke.type === JOKE_TYPES.IMAGE
                    ? <img src={joke.url} alt="pictorial joke" />
                    : <video width="320" height="240" controls>
                        <source src={joke.url} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                }
              </div>
            </div>
            <div className="mx-auto w-4/5 grid justify-items-end">
              <div className="flex flex-row space-x-1
                              sm:grid sm:grid-cols-1 sm:space-x-0 sm:gap-1
                              sm:justify-items-end">
                  <div className={`border rounded-lg
                                   ${joke.source ? '' : 'hidden'}`}>
                    <div className="flex flex-row items-center mx-3 my-2
                                    space-x-4">
                      <span className="font-normal text-gray-para">
                        Credit:
                      </span>
                      <span className="font-normal text-gray-text">
                        {joke.source}
                      </span>
                      <span className={`${source_icon ? '' : 'hidden'}`}>
                        { source_icon }
                      </span>
                    </div>
                  </div>
                <div className="w-min border rounded-lg">
                  <div className="flex flex-row items-center mx-3 my-2 space-x-2">
                    <div className="font-normal text-sm text-gray-para">
                      Core
                    </div>
                    <div className="relative">
                      <button onClick={() => setCoreex(! coreex)}
                              className="mt-0">
                        <QuestionIcon />
                      </button>
                      <div className={`absolute flex flex-row -right-8 top-8
                                       bg-white w-96 rounded-lg
                                       ${ coreex ? "" : "hidden" }`}
                           style={{ boxShadow: "0px 3px 50px #0000000D" }}>
                        <div className="m-4 text-sm font-normal text-gray-text"
                             onClick={() => setCoreex(false)}>
                          { core_explainer }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-10" />
            <div className="mx-auto w-11/12 grid justify-items-center">
              <VoteSw the_ref={vote_ref}
                      joke_id={joke.id}
                      prev_vote={prev_vote && prev_vote.vote} />
            </div>
            <button className="mx-auto bg-orange rounded-lg w-16 h-6">
              <div className="font-semibold text-white text-sm text-center mt-0"
                   data-cy="vote"
                   onClick={actions.vote(vote_ref)}>
                Next
              </div>
            </button>
            <div className="h-8" />
          </div>
        </div>
        <PopupNote notmsg={errmsg} nottype="error"
                   onclose={() => actions.reset_errmsg()} />
      </div>
  );
};
const SignupVoteUI = FooterPage(BaseSignupVoteUI);

const BaseSignup1UI = ({profile, submit, errmsg, reset_errmsg}) => {

  const refs = {
    gender: useRef(),
    seeking: useRef(),
    genderpref: useRef(),
    agepref: [ useRef(), useRef() ],
    dob: useRef()
  }

  return (
    <div className="">
        <div className="h-full flex flex-col my-6 items-center">
          <div>
            <TopLogo />
          </div>
          <div className="mx-auto flex flex-col w-3/4 sm:w-11/12 bg-white
                      space-y-4 rounded-lg mt-16"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
            <div className="mb-8 mt-8">
              <div className="flex text-3xl font-semibold text-gray-text
                              justify-center mb-4">
                Your info<span className="sm:hidden">rmation</span>
              </div>
              <div className="flex text-base font-normal text-gray-text
                              justify-center xl:mx-4 mx-0">
                Now that our bots have a good idea of your humor,
                let's get the usual dating info.
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1 divide-x-2 divide-gray-300
                            sm:grid-cols-1
                            justify-evenly">
              <div className="flex flex-col space-y-6">
                <div className="flex flex-col space-y-2">
                  <DropdownSw the_ref={refs.gender}
                              label="Gender"
                              margin="mx-4"
                              errmsg={errmsg.gender}
                              dflt={gender_db2ui[profile.gender]}
                              prompt="Please select one"
                              choices={Object.keys(gender_ui2db)}
                              cy_prefix="signup_gender" />
                </div>
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.dob}
                          label="Your date of birth"
                          type="text"
                          margin="mx-4"
                          errmsg={errmsg.dob}
                          reset_errmsg={reset_errmsg}
                          maxchars={10}
                          prompt="mm/dd/yyyy"
                          cy_name="signup_dob" />
                  </div>
              </div>
              <div className="flex flex-col space-y-6 sm:mt-6">
                <div className="flex flex-col space-y-2">
                  <DropdownSw the_ref={refs.seeking}
                              label="What are you looking for?"
                              margin="mx-4"
                              errmsg={errmsg.seeking}
                              dflt={seeking_db2ui[profile.seeking]}
                              prompt="Please select one"
                              choices={Object.keys(seeking_ui2db)}
                              cy_prefix="signup_seeking" />
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="font-normal text-sm text-gray-text mb-2 mx-4">
                  With (pick one or more):
                  </div>
                  <MultiRadioSw
                     ref={refs.genderpref}
                     errmsg={errmsg.genderpref}
                     reset_errmsg={reset_errmsg}
                     choices={["Men", "Women",
                               "Nonbinary people"]}
                     dflt={genderpref_db2ui(profile.gender_pref)}
                     cy_prefix="signup_genderpref" />
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-sm font-normal text-gray-text ml-4">
                    Between the ages of
                  </div>
                  <div className="flex flex-row">
                    <NakedTextSw the_ref={refs.agepref[0]}
                            label=""
                            type="text"
                            margin="mx-4"
                            errmsg={errmsg.agelow}
                            reset_errmsg={reset_errmsg}
                            prompt="at least 18"
                            cy_name="signup_agelow" />

                    <div className="flex mx-auto text-small h-10 font-normal
                                 text-gray-600 items-center">
                      <div className="flex mx-auto">
                        &
                      </div>
                    </div>
                    <NakedTextSw the_ref={refs.agepref[1]}
                            label=""
                            type="text"
                            margin="mx-4"
                            errmsg={errmsg.agehigh}
                            reset_errmsg={reset_errmsg}
                            cy_name="signup_agehigh" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-end mr-4 mt-10 sm:justify-center">
                <button className="bg-orange rounded-lg h-10 w-24 flex
                                justify-center items-center">
                  <div className="font-semibold text-white text-sm"
                       data-cy="signup1button"
                       onClick={submit(refs)}>
                    Next
                  </div>
                </button>
              </div>
              <div className="h-12" />
            </div>
          </div>
        </div>
      </div>
  );
}
const Signup1UI = FooterPage(BaseSignup1UI);

const BaseSignup2UI = ({ profile, submit, errmsg, reset_errmsg,
                         snapshot, setSnapshot }) => {
  const refs = {
    username: useRef(),
    brief_intro: useRef()
  }

  return (
    <div className="">
      <div className="h-full flex flex-col my-6 items-center">
        <div>
          <TopLogo />
        </div>
        <div className="mx-auto flex flex-col w-3/4 sm:w-11/12 bg-white
                    space-y-4 rounded-lg mt-16"
             style={{ boxShadow: "0px 3px 50px #0000000D" }}>
          <div className="mb-0 mt-8">
            <div className="flex text-3xl font-semibold text-gray-text
                            justify-center text-center mb-4 sm:mb-0">
              And a little to show potential laughmates
            </div>
          </div>
          <form onSubmit={submit(refs)}
                autoComplete="off"
                style={{ marginTop: "2rem" }}>
            <div className="grid grid-cols-2 gap-1 justify-evenly
                            sm:grid-cols-1">
              <div className="flex flex-col space-y-6">
                <div className="flex flex-col space-y-2">
                  <SnapshotSw setSnapshot={setSnapshot}
                              snapshot={snapshot}
                              label="Snapshot"
                              margin="mx-4"
                              textmargin="md:ml-4 ml-2 mr-4"
                              errmsg={errmsg.snapshot}
                              reset_errmsg={reset_errmsg}
                              prompt="(Or wait and upload one after signing up.)"
                              cy_name="signup_snapshot" />
                </div>
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.username}
                          label="Username"
                          type="text"
                          margin="mx-4"
                          maxchars={config$text_limits.username}
                          onchange={username_changecheck}
                          errmsg={errmsg.username}
                          reset_errmsg={reset_errmsg}
                          prompt="Enter text"
                          cy_name="signup_username" />
                  <div className="mx-4 text-sm font-normal
                                  text-gray-text">
                    (This is the name others will see on your profile.
                    Letters and spaces only.
                    Limit {config$text_limits.username} characters.)
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-6 h-4/5">
                <div className="flex flex-col space-y-2 h-full sm:mt-6">
                  <TextareaSw the_ref={refs.brief_intro}
                              label="A little about you"
                              margin="mx-4"
                              errmsg={errmsg.brief_intro}
                              reset_errmsg={reset_errmsg}
                              maxchars={config$text_limits.brief_intro}
                              rows="10"
                              prompt={`Your elevator pitch - you'll have an ${''
                                       }opportunity to talk about your hobbies, ${''
                                       }job, etc. after you're signed up ${''
                                       } (max ${config$text_limits.brief_intro} chars)`}
                              cy_name="signup_brief_intro" />
                </div>
              </div>
            </div>
            <div>
              <div className="flex justify-end sm:justify-center
                              mr-4 mt-10 cursor-auto">
                <button className="bg-orange rounded-lg h-10 w-24 flex
                                   justify-center items-center"
                        data-cy="signup2button"
                        type="submit">
                  <div className="font-semibold text-white text-sm">
                    Next
                  </div>
                </button>
              </div>
              <div className="h-12" />
            </div>
          </form>
        </div>
      </div>
      {/*
      <div className={"fixed inset-0" + (open_crop ? "" : " hidden")}>
        <CropperOverlay cancel={() => actions.open_signup('')}
                        refs={refs}
                        open_signup={open_signup}
                        errmsg={errmsg} reset_errmsg={reset_errmsg}
                        obscene_ok={obscene_ok}
                        actions={actions} />
      </div>
      */}
    </div>
  );
}
const Signup2UI = FooterPage(BaseSignup2UI);

const BaseSignup3UI = ({profile, submit, open, close, errmsg, reset_errmsg}) => {

  const refs = {
    email: useRef(),
    pwds: [ useRef(), useRef() ],
    recaptcha: useRef(),
  }

  return (
      <div className="">
        <div className="h-full flex flex-col my-6 items-center">
          <div>
            <TopLogo />
          </div>
          <div className="mx-auto flex flex-col w-3/4 sm:w-11/12 bg-white
                          space-y-4 rounded-lg mt-16"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
            <div className="my-8">
              <div className="flex text-3xl font-semibold text-gray-text
                              mx-6
                              justify-center text-center mb-0">
                Just some final info before you see your laugh matches
              </div>
            </div>
            <form onSubmit={submit(refs)}
                  autoComplete="off"
                  style={{ marginTop: "2rem" }}>
              <div className="grid grid-cols-2 gap-y-8 gap-4 mx-4
                              sm:gap-y-4
                              sm:grid-cols-1">
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.email}
                          label="Your email"
                          type="text"
                          margin="mx-4"
                          errmsg={errmsg.email}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.email}
                          prompt="Enter text"
                          cy_name="signup_email" />
                </div>
                <div />
                <div className="flex flex-col space-y-2">
                  <TextSw the_ref={refs.pwds[0]}
                          label="Choose a password"
                          type="password"
                          margin="mx-4"
                          errmsg={errmsg.pwd1}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.password}
                          prompt="Enter text"
                          cy_name="signup_pwd1" />
                </div>
                <div className="flex flex-col space-y-2 sm:mt-4">
                  <TextSw the_ref={refs.pwds[1]}
                          label="Re-enter password"
                          type="password"
                          margin="mx-4"
                          errmsg={errmsg.pwd2}
                          reset_errmsg={reset_errmsg}
                          maxchars={config$text_limits.password}
                          prompt="Enter text"
                          cy_name="signup_pwd2" />
                </div>
              </div>
              { CYPRESS_MODE || LOCAL_MODE
                ? <span />
                : <div className="ml-8 mt-8">
                    <div>
                      <ReCAPTCHA ref={refs.recaptcha}
                              sitekey="6LfngEwcAAAAAM5T2GJn7j1gTn6zp_26UHKaCliq" />
                    </div>
                    <div className={`text-xs font-normal mx-2 text-red-600
                                    mx-4 mt-1
                                    ${errmsg.recaptcha ? '' : 'hidden'}`}>
                      {errmsg.recaptcha}
                    </div>
                  </div>
              }
              <div>
                <div className="flex justify-end sm:justify-center mr-8 mt-10">
                  <button type="submit" data-cy="signup3button"
                          className="bg-orange rounded-lg h-10 w-24 flex
                                  justify-center items-center">
                    <div className="font-semibold text-white text-sm">
                      Finish
                    </div>
                  </button>
                </div>
                <div className="h-12" />
              </div>
            </form>
          </div>
        </div>
      </div>
  );
}
const Signup3UI = FooterPage(BaseSignup3UI);

const BaseSignupDoneUI = ({ onclick }) => {
  return (
      <div className="">
        <div className="h-full flex flex-col my-6 items-center">
          <div>
            <TopLogo />
          </div>
          <div className="mx-auto w-3/4 bg-white rounded-lg mt-16 sm:w-11/12"
               style={{ boxShadow: "0px 3px 50px #0000000D" }}>
            <div className="flex flex-col space-y-12 mt-8">
              <div className="mx-4 flex flex-col space-y-6">
                <div className="flex text-3xl font-semibold text-gray-text
                                text-center justify-center">
                  Welcome to Laughstruck
                </div>
                <div className="flex text-base font-semibold text-gray-text
                                justify-center">
                  We'll take you right to some matches - might take a minute
                  to prepare. In the meantime, a few tips:
                </div>
              </div>
              <div className="flex flex-col mx-8 space-y-8">
                <div className="mx-4">
                  <div className="flex mx-auto">
                    <CheckmarkIcon />
                    <div className="flex text-lg font-normal text-gray-text ml-2">
                      You'll see two scores for your matches:
                      pure humor match and humor + your prefs.
                    </div>
                  </div>
                </div>
                <div className="mx-4">
                  <div className="flex mx-auto">
                    <CheckmarkIcon adjust={1} />
                    <div className="flex text-lg font-normal text-gray-text ml-2">
                      To get you going quickly,
                      we haven't asked for much info.
                      You'll get better matches if you tell us more:
                      go to 'My Profile' and 'My
                      Preferences' in the 'Settings' menu,
                    </div>
                  </div>
                </div>
                <div className="mx-4">
                  <div className="flex mx-auto">
                    <CheckmarkIcon />
                    <div className="flex text-lg font-normal text-gray-text ml-2">
                      Rate more jokes to help us find
                      better matches.
                    </div>
                  </div>
                </div>
                <div className="mx-4">
                  <div className="flex mx-auto">
                    <CheckmarkIcon />
                    <div className="flex text-lg font-normal text-gray-text ml-2">
                      Be nice! We've got some standards around
                      here, folks.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mx-4 justify-center">
                <span className="text-lg font-normal text-gray-text ml-2">
                  By clicking below to see your matches you agree
                  to our
                  <Link to='/tos'
                       className="mx-1 text-blue-500">terms of service.</Link>
                </span>
              </div>
              <div className="flex justify-center mt-10">
                <button data-cy="signup_done" onClick={onclick}
                        className="bg-orange rounded-lg h-10 w-64
                                   flex justify-center items-center">
                  <div className="font-semibold text-white text-sm">
                    Okay, show me my matches!
                  </div>
                </button>
              </div>
            </div>
            <div className="h-12" />
          </div>
        </div>
      </div>
  );
}
const SignupDoneUI = FooterPage(BaseSignupDoneUI);

export { SignupVoteUI, Signup1UI, Signup2UI, Signup3UI, SignupDoneUI };
